import { EnvironmentUtils } from '.';

const PUBLISHABLE_API_KEY = process.env.REACT_APP_PUBLISHABLE_API_KEY || 'pk_test_1rjQUIa19GwycLMpaJBjd8Be';

const validators = {
    validateStripeAPIKey: key => key && (!EnvironmentUtils.isRunningInProductionMode() || key.startsWith('sk_live_')),
    validateStripePublishableAPIKey: key => key && (!EnvironmentUtils.isRunningInProductionMode() || key.startsWith('pk_live_'))
};

export {
    PUBLISHABLE_API_KEY,
    validators
};
