import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    PageHeader,
    InfoCard,
    FormInput,
    Button
} from '../../shared/components';
import { updateNewMasjid } from '../../store/actions';
import { PaymentUtils } from '../../utils';

function ConfigureDonations({
    DonationsEnabled, ID,
    TaxId,
    NonProfit501C3Approved,
    HasKioskBase,
    ShowCategoriesFirstOnKiosk,
    ShowTaxIdInEmail,
    EndOfYearTaxDeductionEmailTemplate,
    dispatch
}) {
    const [stripeAPIKey, setStripeAPIKey] = useState('');
    const [stripeAPIKeyErrorMessage, setStripeAPIKeyErrorMessage] = useState('');
    const [stripePublishableAPIKey, setStripePublishableAPIKey] = useState('');
    const [taxId, setTaxId] = useState(TaxId || '');
    const [endOfYearTaxDeductionEmailTemplate, setEndOfYearTaxDeductionEmailTemplate] = useState(
        EndOfYearTaxDeductionEmailTemplate
    );
    const [nonProfit501C3Approved, setNonProfit501C3Approved] = useState(NonProfit501C3Approved || '');
    const [stripePublishableAPIKeyErrorMessage, setStripePublishableAPIKeyErrorMessage] = useState('');
    const [updatingKeys, setUpdatingKeys] = useState(false);
    const [updatingTaxId, setUpdatingTaxId] = useState(false);
    const [showTaxIdInEmailCheckboxVisibility, setShowTaxIdInEmailCheckboxVisibility
    ] = useState(ShowTaxIdInEmail || TaxId);
    const [
        showCategoriesFirstOnKiosk,
        setShowCategoriesFirstOnKiosk
    ] = useState(ShowCategoriesFirstOnKiosk);

    const [
        showTaxIdInEmail,
        setShowTaxIdInEmail
    ] = useState(ShowTaxIdInEmail);

    const [updatingKioskSettings, setUpdatingKioskSettings] = useState(false);

    useEffect(() => {
        setEndOfYearTaxDeductionEmailTemplate(EndOfYearTaxDeductionEmailTemplate);
    }, [EndOfYearTaxDeductionEmailTemplate]);

    async function updateKeys() {
        setStripeAPIKeyErrorMessage('');
        setStripePublishableAPIKeyErrorMessage('');
        if (!PaymentUtils.validators.validateStripeAPIKey(
            stripeAPIKey.trim()
        )) {
            setStripeAPIKeyErrorMessage('Stripe API key is not valid. It should look like "sk_live_xxxxxxx"');
            return;
        }
        if (!PaymentUtils.validators.validateStripePublishableAPIKey(
            stripePublishableAPIKey.trim()
        )) {
            setStripePublishableAPIKeyErrorMessage('Stripe publishable API key is not valid. It should look like "pk_live_xxxxxxx"');
            return;
        }

        setUpdatingKeys(true);
        const error = await dispatch(updateNewMasjid({
            ID,
            StripeApiKey: stripeAPIKey.trim(),
            StripePublishableApiKey: stripePublishableAPIKey.trim()
        }));
        setUpdatingKeys(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        setStripeAPIKey('');
        setStripePublishableAPIKey('');
    }

    function updateShowCategoriesFirstOnKiosk() {
        setShowCategoriesFirstOnKiosk(!showCategoriesFirstOnKiosk);
    }

    function updateShowTaxIdInEmail() {
        setShowTaxIdInEmail(!showTaxIdInEmail);
    }
    function updateNonProfit501C3Status() {
        setNonProfit501C3Approved(!nonProfit501C3Approved);
    }

    function updateTaxId(value) {
        if (value && value.length > 0) {
            setShowTaxIdInEmailCheckboxVisibility(true);
        } else {
            setShowTaxIdInEmailCheckboxVisibility(false);
        }
        setTaxId(value);
    }


    async function updateKioskSettings() {
        setUpdatingKioskSettings(true);
        const error = await dispatch(updateNewMasjid({
            ID,
            ShowCategoriesFirstOnKiosk: showCategoriesFirstOnKiosk
        }));
        setUpdatingKioskSettings(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    async function updateMasjidPaymentSettings() {
        setUpdatingTaxId(true);
        let payload = {};
        if (endOfYearTaxDeductionEmailTemplate !== EndOfYearTaxDeductionEmailTemplate) {
            payload.EndOfYearTaxDeductionEmailTemplate = endOfYearTaxDeductionEmailTemplate.trim();
        }
        if (taxId) {
            payload = {
                ...payload,
                TaxId: taxId,
                ShowTaxIdInEmail: showTaxIdInEmail
            };
        }
        payload = {
            ...payload,
            ID,
            NonProfit501C3Approved: nonProfit501C3Approved
        };
        const error = await dispatch(updateNewMasjid(payload));
        setUpdatingTaxId(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    return (
        <div className="view">
            <PageHeader
                title="Configure Donations"
                description="Configure your donations here."
            />
            <div className="view-content">
                <InfoCard
                    title="Enter Stripe API Keys"
                    subTitle="We need to connect your Stripe account to Musalleen so that you may receive donations from us."
                    showFooter={false}
                >
                    <FormInput
                        label="Stripe Publishable API Key"
                        value={stripePublishableAPIKey}
                        type="password"
                        state={stripePublishableAPIKeyErrorMessage ? 'danger' : 'default'}
                        message={stripePublishableAPIKeyErrorMessage}
                        onChangeValue={setStripePublishableAPIKey}
                        onEnter={updateKeys}
                        placeholder="pk_live_xxxxxxx"
                        maxLength={255}
                    />
                    <FormInput
                        label="Stripe API Key"
                        value={stripeAPIKey}
                        type="password"
                        state={stripeAPIKeyErrorMessage ? 'danger' : 'default'}
                        message={stripeAPIKeyErrorMessage}
                        onChangeValue={setStripeAPIKey}
                        placeholder="sk_live_xxxxxxx"
                        maxLength={255}
                    />
                    <Row>
                        <div className="col-md-6 mb-4">
                            <span className="h6 mr-2">Status: </span>
                            <span style={{ color: DonationsEnabled ? 'green' : 'red' }}>
                                {DonationsEnabled ? 'Enabled & Active' : 'Disabled'}
                            </span>
                        </div>
                        <div className="col-md-6" style={{ textAlign: 'right' }}>
                            <Button
                                onClick={updateKeys}
                                loading={updatingKeys}
                            >
                                Update Keys
                            </Button>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-md-12 text-right">
                            <a
                                href="/configure-stripe"
                                onClick={e => {
                                    e.preventDefault();
                                    browserHistory.push('/configure-stripe');
                                }}
                            >
                                Learn how to get these?
                            </a>

                        </div>
                    </Row>
                </InfoCard>
                {HasKioskBase && (
                    <InfoCard
                        title="Kiosk Settings"
                        subTitle="This section lets you change donation related settings inside Kiosks. Changes are applied automatically on all your Kiosks."
                        showFooter={false}
                    >
                        <Row>
                            <div className="col-md-12 mb-4">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onChange={updateShowCategoriesFirstOnKiosk}
                                        checked={showCategoriesFirstOnKiosk}
                                    />
                                    &nbsp;
                                    Show categories instead of
                                    quick amounts on home screen.
                                </Label>
                            </div>
                            <div className="col-md-12" style={{ textAlign: 'right' }}>
                                <Button
                                    onClick={updateKioskSettings}
                                    loading={updatingKioskSettings}
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </Row>
                    </InfoCard>
                )}
                <InfoCard
                    title="Non Profit & Tax Deduction Information"
                    subTitle="The section lets you modify information if you're registered as a non profit."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-12 mb-4">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={updateNonProfit501C3Status}
                                    checked={nonProfit501C3Approved}
                                />
                                &nbsp;
                                Our organization is 501-c3 approved
                            </Label>
                        </div>

                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <FormInput
                                label="Tax Id"
                                value={taxId}
                                type="input"
                                onChangeValue={updateTaxId}
                                placeholder="Tax Id"
                            />
                        </div>
                    </Row>
                    {showTaxIdInEmailCheckboxVisibility && (
                        <Row>
                            <div className="col-md-12 mb-4">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onChange={updateShowTaxIdInEmail}
                                        checked={showTaxIdInEmail}
                                    />
                                    &nbsp;
                                    Show Tax Id in Donation Receipt Emails.
                                </Label>
                            </div>

                        </Row>
                    )}
                    <Row>
                        <div className="col-md-12 mb-4 eoy-email-template-container">
                            <FormInput
                                label="End of Year Tax Deduction Email"
                                value={endOfYearTaxDeductionEmailTemplate}
                                type="textarea"
                                maxLength={Infinity}
                                onChangeValue={setEndOfYearTaxDeductionEmailTemplate}
                            />
                            This email will be sent on 1st of January
                            {' '}
                            {new Date().getFullYear() + 1}
                            {' '}
                            to all donors along with the
                            list of donations they did in the year
                            {' '}
                            {new Date().getFullYear()}
                            {' '}
                            that were eligible for tax deductions.
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12" style={{ textAlign: 'right' }}>
                            <Button
                                onClick={updateMasjidPaymentSettings}
                                loading={updatingTaxId}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Row>
                </InfoCard>
            </div>
        </div>
    );
}

ConfigureDonations.propTypes = {
    ID: PropTypes.number.isRequired,
    DonationsEnabled: PropTypes.bool.isRequired,
    HasKioskBase: PropTypes.bool.isRequired,
    NonProfit501C3Approved: PropTypes.bool.isRequired,
    ShowCategoriesFirstOnKiosk: PropTypes.bool.isRequired,
    TaxId: PropTypes.string,
    ShowTaxIdInEmail: PropTypes.bool.isRequired,
    EndOfYearTaxDeductionEmailTemplate: PropTypes.string,
    dispatch: PropTypes.func.isRequired
};

ConfigureDonations.defaultProps = {
    TaxId: '',
    EndOfYearTaxDeductionEmailTemplate: ''
};

const mapStateToProps = ({
    masjid: {
        data: {
            ID,
            DonationsEnabled,
            HasKioskBase,
            ShowCategoriesFirstOnKiosk,
            NonProfit501C3Approved,
            TaxId,
            ShowTaxIdInEmail,
            EndOfYearTaxDeductionEmailTemplate,
        }
    }
}) => ({
    ID,
    DonationsEnabled,
    HasKioskBase,
    ShowCategoriesFirstOnKiosk,
    TaxId,
    NonProfit501C3Approved,
    ShowTaxIdInEmail,
    EndOfYearTaxDeductionEmailTemplate
});

export default connect(mapStateToProps)(ConfigureDonations);
