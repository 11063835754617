import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routes';
import Provider from './providers';

ReactDOM.render(
    <Provider>
        <Router />
    </Provider>,
    document.getElementById('root')
);
