const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || 'https ://musalleen-m-website-develop.herokuapp.com';
const TVDISPLAY_URL = process.env.REACT_APP_TVDISPLAY_URL || 'https://musalleen-tv-display-develop.herokuapp.com';

const getHomePageLink = websiteUserID => `${WEBSITE_URL}/${websiteUserID}`;
const getJamiaPageLink = websiteUserID => `${WEBSITE_URL}/${websiteUserID}/jamia`;
const getEditPageLink = (mainPageLink, websiteAccessToken) => `${mainPageLink}/edit/?token=${websiteAccessToken}`;
const getTvDisplayLink = masjidID => `${TVDISPLAY_URL}/masjid/${masjidID}`;

export {
    getHomePageLink,
    getJamiaPageLink,
    getEditPageLink,
    getTvDisplayLink
};
