import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    Button,
    DescriptionList,
    Table
} from '../../shared/components';
import { getRecurringDonation } from '../../store/selectors';
import { StringUtils, DateUtils } from '../../utils';
import { cancelRecurringDonation, showDialog, showWarningAlert } from '../../store/actions';

function RecurringDonation({
    recurringDonation: {
        ID,
        Amount,
        FirstName,
        LastName,
        Email,
        CreatedAt,
        AmountWithFees,
        CategoryName,
        MonthsRecurring,
        RemainingMonths,
        DonationPlatform,
        IsComplete,
        RecurringCancelled,
        Donations
    },
    dispatch
}) {
    const [cancelling, setCancelling] = useState(false);

    async function cancel() {
        const confirm = await dispatch(showDialog('Are you sure?', 'Are you sure you to cancel this recurring payment? This action is reversable.'));
        if (confirm) {
            setCancelling(true);
            // All the errors and success message are handled in the action creator itself.
            // The UI just needs to wait.
            await dispatch(cancelRecurringDonation(ID));
            setCancelling(false);
        }
    }

    function viewDonation(donation) {
        browserHistory.push({ pathname: `/donations/${donation.id}`, state: donation.id });
    }


    function viewInStripe({ stripeID, successful }) {
        if (successful === 'No') {
            dispatch(showWarningAlert('You cannot view a payment that failed, sadly.'));
            return;
        }
        window.open(StringUtils.toStripeURL(stripeID), '_blank');
    }

    return (
        <div className="view">
            <PageHeader
                title={StringUtils.formatAmount(Amount) + (IsComplete ? ' - Completed' : RecurringCancelled ? ' - Cancelled' : '')}
                description={`Recurring donation by ${FirstName} ${LastName}.`}
            />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Personal Information"
                            subTitle="Information about the person who donated."
                            showFooter={false}
                        >
                            <Row>
                                <DescriptionList
                                    list={[
                                        { label: 'First Name', value: FirstName },
                                        { label: 'Last Name', value: LastName },
                                        { label: 'Email', value: Email },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Donation Information"
                            subTitle="More information about the recurring donation."
                            showFooter={false}
                        >
                            <Row>
                                <DescriptionList
                                    list={[
                                        { label: 'Date', value: DateUtils.format(CreatedAt, 'MM/DD/YYYY') },
                                        { label: 'Monthly Amount', value: StringUtils.formatAmount(Amount) },
                                        { label: 'Monthly Amount With Fees', value: StringUtils.formatAmount(AmountWithFees) },
                                        { label: 'Total Months Recurring', value: MonthsRecurring },
                                        { label: 'Total Months Remaining', value: RemainingMonths },
                                        { label: 'Donation Category', value: CategoryName },
                                        { label: 'Donation Platform', value: DonationPlatform },
                                        { label: 'Last Donation Made On', value: DateUtils.format(Donations[Donations.length - 1].CreatedAt, 'MM/DD/YYYY') },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
                <Row className="refund-container mb-4">
                    <div className="col-md-12">
                        <Button
                            color="danger"
                            onClick={cancel}
                            disabled={RecurringCancelled || IsComplete}
                            loading={cancelling}
                        >
                            {RecurringCancelled ? 'Cancelled' : 'Cancel'}
                        </Button>
                    </div>
                </Row>
                <Row>
                    <InfoCard
                        title="Donations"
                        subTitle="All the donations made via this recurring donation."
                        showFooter={false}
                        containerClassName="col-md-12"
                    >
                        <div className="mt-2">
                            <Table
                                columns={[
                                    { label: 'Name', key: 'name' },
                                    { label: 'Amount', key: 'amount' },
                                    {
                                        label: 'Date',
                                        key: 'date',
                                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                    },
                                    { label: 'Successful', key: 'successful' }
                                ]}
                                data={Donations.map(donation => ({
                                    id: donation.ID,
                                    name: `${donation.FirstName} ${donation.LastName}`,
                                    amount: StringUtils.formatAmount(donation.Amount),
                                    date: DateUtils.format(donation.CreatedAt, 'MM/DD/YYYY'),
                                    stripeID: donation.StripeID,
                                    successful: donation.Successful ? 'Yes' : 'No'
                                }))}
                                onRowClick={viewDonation}
                                showButtonOne
                                buttonOneLabel="View in Stripe"
                                onButtonOneClick={viewInStripe}
                                enableFilters={false}
                                enableExports={false}
                                defaultSortColumnKey="date"
                            />
                        </div>
                    </InfoCard>
                </Row>
            </div>
        </div>
    );
}

RecurringDonation.propTypes = {
    recurringDonation: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    recurringDonation: getRecurringDonation(state, props)
});

export default connect(mapStateToProps)(RecurringDonation);
