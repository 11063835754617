/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */

import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link, IndexLink } from 'react-router';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';


// icons
import IconDashboard from 'react-icons/lib/md/dashboard';
import IconDown from 'react-icons/lib/md/chevron-right';
import IconDollar from 'react-icons/lib/fa/dollar';
import IconMail from 'react-icons/lib/io/android-mail';
import IconSettingsIO from 'react-icons/lib/io/settings';
import IconStreetView from 'react-icons/lib/fa/street-view';
import IconBookReader from 'react-icons/lib/fa/book';
import IconGlobe from 'react-icons/lib/fa/globe';
// import BsDisplayFill from 'react-icons/fa';

// import IconAccountCircle from 'react-icons/lib/md/account-circle';

import ScrollArea from '../scrollbar';

import './style.scss';
import { Logo } from '..';
import {
    hasDonationsInPlan,
    hasRecurringDonationsInPlan,
    hasClassRegistrationInPlan,
    hasEventRegistrationInPlan,
    hasOnlineProgramRegistrationInPlan,
    hasSuperAdminRole,
    hasTeacherRole
} from '../../../store/selectors';


const NavHead = () => (
    <header className="nav-head">
        <Link to="/">
            <Logo />
        </Link>
        {/*
            <div
                className={`toggle-dot ${props.mini ? 'active': ''}`}
                onClick={props.toggleNav}>
            </div>
        */}
    </header>
);


class NavList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }

    handleClick = (index, pathname, e) => {
        const c = e.currentTarget.className;
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            selected: (c.indexOf('selected') >= 0) ? '' : index
        });
        // Push to top most screen when dropdown is clicked.
        // const {
        //     selected
        // } = this.state;
        // if (selected !== index && pathname) {
        //     browserHistory.push(pathname);
        // }
    }

    handleOpen = (index, e) => {
        e.stopPropagation();
        this.setState({
            selected: index
        });
    }

    handleContact = e => {
        e.preventDefault();
        window.location.href = 'mailto:support@musalleen.com';
        return true;
    }


    render() {
        const {
            HasAppBase,
            HasAppDonationsAddOn,
            HasKioskBase,
            HasPOSBase,
            HasWebsiteBase,
            HasDonationsInPlan,
            HasRecurringDonationsInPlan,
            HasClassRegistrationInPlan,
            HasEventRegistrationInPlan,
            HasOnlineDonationsAddOn,
            HasOnlineRegistrationsInPlan,
            isSuperAdmin,
            isSuperAdminOrTeacher,
            HasTvDisplayBase,
            HasTvDisplayAdvancedAddOn
        } = this.props;
        return (
            <ScrollArea className="nav-list-container" horizontal={false} verticalScrollbarStyle={{ width: '4px', marginLeft: '10px' }}>
                <ul className="list-unstyled nav-list clearfix mt-2">
                    {/* <li><div className="nav-list-title">Dashboard</div></li> */}
                    <li onClick={this.handleClick.bind(this, 0, null)} className={(this.state.selected === 0) ? 'selected' : ''}>
                        <IndexLink to="/" activeClassName="active">
                            <IconDashboard size="18" color="#2962FF" className="icon-dashboard" />
                            <span className="name">Dashboard</span>
                        </IndexLink>
                    </li>
                    {isSuperAdmin && HasDonationsInPlan
                        ? (
                            <Fragment>
                                {/* <li><div className="nav-list-title">Donations</div></li> */}
                                <li onClick={this.handleClick.bind(this, 2, '/donations/list')} className={(this.state.selected === 2) ? 'selected' : ''}>
                                    <Link to="">
                                        <IconDollar size="18" />
                                        <span className="name">Donations</span>
                                        <IconDown size="14" className="icon-down" />
                                    </Link>
                                    <Collapse
                                        isOpen={this.state.selected === 2}
                                        onClick={this.handleOpen.bind(this, 2)}
                                    >
                                        <ul className="inner-drop list-unstyled">
                                            <li><Link to="/donations/list" activeClassName="active">View Donations</Link></li>
                                            {HasRecurringDonationsInPlan ? <li><Link to="/recurring-donations/list" activeClassName="active">View Recurring Donations</Link></li> : null}
                                            <li><Link to="/categories/list" activeClassName="active">Donation Categories</Link></li>
                                            <li><Link to="/configure-donations" activeClassName="active">Configure</Link></li>
                                            {HasOnlineDonationsAddOn ? <li><Link to="/online-donations" activeClassName="active">Online Donations</Link></li> : null}
                                        </ul>
                                    </Collapse>
                                </li>
                            </Fragment>
                        )
                        : null}
                    {/* <li><div className="nav-list-title">Manage</div></li> */}
                    {(isSuperAdmin || !isSuperAdminOrTeacher) && HasWebsiteBase
                        ? (
                            <li onClick={this.handleClick.bind(this, 18, '/announcements/list')} className={(this.state.selected === 18) ? 'selected' : ''}>
                                <Link to="">
                                    <IconGlobe size="18" />
                                    <span className="name">Website</span>
                                    <IconDown size="14" className="icon-down" />
                                </Link>
                                <Collapse
                                    isOpen={this.state.selected === 18}
                                    onClick={this.handleOpen.bind(this, 18)}
                                >
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/website" activeClassName="active">Configure Website</Link></li>
                                    </ul>
                                </Collapse>
                            </li>
                        )
                        : null}
                    {HasAppBase
                        ? (
                            <li onClick={this.handleClick.bind(this, 12, '/announcements/list')} className={(this.state.selected === 12) ? 'selected' : ''}>
                                <Link to="">
                                    <IconStreetView size="18" />
                                    <span className="name">Push Notifications</span>
                                    <IconDown size="14" className="icon-down" />
                                </Link>
                                <Collapse
                                    isOpen={this.state.selected === 12}
                                    onClick={this.handleOpen.bind(this, 12)}
                                >
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/announcements/list" activeClassName="active">Announcements</Link></li>
                                        {/* <li><Link to="/events/list"
                                          activeClassName="active">Events</Link></li> */}
                                        {HasAppDonationsAddOn ? <li><Link to="/donation-requests/list" activeClassName="active">Donation Requests</Link></li> : null}
                                    </ul>
                                </Collapse>
                            </li>
                        )
                        : null}
                    {isSuperAdmin && (
                        <li onClick={this.handleClick.bind(this, 3, '/admins/list')} className={(this.state.selected === 3) ? 'selected' : ''}>
                            <Link to="">
                                <IconSettingsIO size="18" />
                                <span className="name">Manage</span>
                                <IconDown size="14" className="icon-down" />
                            </Link>
                            <Collapse
                                isOpen={this.state.selected === 3}
                                onClick={this.handleOpen.bind(this, 3)}
                            >
                                <ul className="inner-drop list-unstyled">
                                    <li><Link to="/admins/list" activeClassName="active">Admins</Link></li>
                                    {HasKioskBase ? <li><Link to="/kiosks/list" activeClassName="active">Kiosks</Link></li> : null}
                                    {HasPOSBase ? <li><Link to="/pos/list" activeClassName="active">POS Devices</Link></li> : null}
                                    {HasAppBase ? <li><Link to="/groups/list" activeClassName="active">Groups</Link></li> : null}
                                    <li><Link to="/account" activeClassName="active">Account</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                    )}
                    <li onClick={this.handleClick.bind(this, 11, '/salat/daily')} className={(this.state.selected === 11) ? 'selected' : ''}>
                        <Link to="">
                            <IconStreetView size="18" />
                            <span className="name">Salat Times</span>
                            <IconDown size="14" className="icon-down" />
                        </Link>
                        <Collapse
                            isOpen={this.state.selected === 11}
                            onClick={this.handleOpen.bind(this, 11)}
                        >
                            <ul className="inner-drop list-unstyled">
                                <li><Link to="/salat/daily" activeClassName="active">Daily Salat</Link></li>
                                <li><Link to="/salat/jummah" activeClassName="active">Jummah Salat</Link></li>
                            </ul>
                        </Collapse>
                    </li>
                    {
                        HasTvDisplayBase
                        && (
                            <li onClick={this.handleClick.bind(this, 19, '/tvdisplay/dailyprayers')} className={(this.state.selected === 19) ? 'selected' : ''}>
                                <Link to="">
                                    <IconStreetView size="18" />
                                    <span className="name">Tv Display</span>
                                    <IconDown size="14" className="icon-down" />
                                </Link>
                                <Collapse
                                    isOpen={this.state.selected === 19}
                                    onClick={this.handleOpen.bind(this, 19)}
                                >
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/tvdisplay" activeClassName="active">Configure Tv Display</Link></li>
                                        <li><Link to="/tvdisplay/dailyprayers" activeClassName="active">Configure Prayer Times</Link></li>
                                        <li><Link to="/tvdisplay/flyer" activeClassName="active">Configure Slide Show</Link></li>
                                        <li><Link to="/tvdisplay/announcements" activeClassName="active">Configure Announcements</Link></li>
                                        {HasTvDisplayAdvancedAddOn && <li><Link to="/tvdisplay/settings" activeClassName="active">Configure Display Settings</Link></li>}
                                    </ul>
                                </Collapse>
                            </li>
                        )}
                    {(isSuperAdminOrTeacher
                    && (HasClassRegistrationInPlan || HasEventRegistrationInPlan)) && (
                        <li onClick={this.handleClick.bind(this, 17, '/classes/list')} className={(this.state.selected === 17) ? 'selected' : ''}>
                            <Link to="">
                                <IconBookReader size="18" />
                                <span className="name">Programs</span>
                                <IconDown size="14" className="icon-down" />
                            </Link>
                            <Collapse
                                isOpen={this.state.selected === 17}
                                onClick={this.handleOpen.bind(this, 17)}
                            >
                                <ul className="inner-drop list-unstyled">
                                    {HasClassRegistrationInPlan ? <li><Link to="/classes/list" activeClassName="active">Classes</Link></li> : null}
                                    {HasEventRegistrationInPlan ? <li><Link to="/events/list" activeClassName="active">Events</Link></li> : null}
                                    {HasOnlineRegistrationsInPlan ? <li><Link to="/online-registrations" activeClassName="active">Online Registrations</Link></li> : null}
                                </ul>
                            </Collapse>
                        </li>
                    )}
                    <li onClick={this.handleContact}>
                        <Link
                            to="mailto:support@musalleen.com"
                            onClick={event => event.preventDefault()}
                        >
                            <IconMail size="18" />
                            <span className="name">Support</span>
                        </Link>
                    </li>
                </ul>
                {/* end scroll-area */}
            </ScrollArea>
        );
    }
}

NavList.propTypes = {
    HasAppBase: PropTypes.bool.isRequired,
    HasAppDonationsAddOn: PropTypes.bool.isRequired,
    HasKioskBase: PropTypes.bool.isRequired,
    HasPOSBase: PropTypes.bool.isRequired,
    HasWebsiteBase: PropTypes.bool.isRequired,
    HasDonationsInPlan: PropTypes.bool.isRequired,
    HasRecurringDonationsInPlan: PropTypes.bool.isRequired,
    HasClassRegistrationInPlan: PropTypes.bool.isRequired,
    HasEventRegistrationInPlan: PropTypes.bool.isRequired,
    HasOnlineDonationsAddOn: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    isSuperAdminOrTeacher: PropTypes.bool.isRequired,
    HasOnlineRegistrationsInPlan: PropTypes.bool.isRequired,
    HasTvDisplayBase: PropTypes.bool.isRequired
};

NavList.defaultProps = {
    WebsiteUserID: null,
    WebsiteAccessToken: null
};

const mapStateToProps = state => {
    const {
        masjid: {
            data: {
                HasAppBase,
                HasPOSBase,
                HasAppDonationsAddOn,
                HasKioskBase,
                HasWebsiteBase,
                HasOnlineDonationsAddOn,
                HasTvDisplayBase,
                HasTvDisplayAdvancedAddOn
            }
        }
    } = state;
    return {
        HasAppBase,
        HasPOSBase,
        HasAppDonationsAddOn,
        HasKioskBase,
        HasWebsiteBase,
        HasOnlineDonationsAddOn,
        HasDonationsInPlan: hasDonationsInPlan(state),
        HasRecurringDonationsInPlan: hasRecurringDonationsInPlan(state),
        isSuperAdmin: hasSuperAdminRole(state),
        isSuperAdminOrTeacher: hasSuperAdminRole(state) || hasTeacherRole(state),
        HasClassRegistrationInPlan: hasClassRegistrationInPlan(state),
        HasEventRegistrationInPlan: hasEventRegistrationInPlan(state),
        HasOnlineRegistrationsInPlan: hasOnlineProgramRegistrationInPlan(state),
        HasTvDisplayBase,
        HasTvDisplayAdvancedAddOn
    };
};

const StateAwareNavList = connect(mapStateToProps)(NavList);

class SiteNav extends React.Component { // eslint-disable-line

    constructor(props) {
        super(props);
        this.siteNav = createRef();
    }

    componentDidMount = () => {
        // This will disallow scrolling of content
        // that's beneath or beside the sidebar when the sidebar is scrolled.
        this.siteNav.current.ontouchmove = this.preventDefault;
        this.siteNav.current.onwheel = this.onMouseWheel;
    }

    // ~ Reference - https://stackoverflow.com/a/9786325/7641174.

    onMouseWheel = event => {
        event.target.scrollTop -= event.wheelDeltaY;
        this.preventDefault(event);
    }

    preventDefault = event => {
        event = event || window.event;
        if (event.preventDefault) { event.preventDefault(); }
        event.returnValue = false;
    }
    /* eslint-enable */

    render() {
        return (
            // eslint-disable-next-line react/destructuring-assignment
            <nav ref={this.siteNav} className={`site-nav ${this.props.mini ? 'mini' : ''}`}>
                <NavHead {...this.props} />
                <StateAwareNavList />
            </nav>
        );
    }
}

export default SiteNav;
