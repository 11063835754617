/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';


import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Row,
    Col

} from 'reactstrap';
import IconPlus from 'react-icons/lib/md/add';
import IconMinus from 'react-icons/lib/md/delete';

import {
    FormInput,
    Button,
    PageHeader
} from '../../shared/components';
import { savePrayerTimes } from '../../store/actions/tvdisplay';
import UpgradePlanAlert from '../ui/extras/UpgradePlanAlert';
import { convertFrom12to24, convertFrom24To12 } from '../../utils/time';

const timing = 'Timing';

const emptyTimeSlot = '00:undefined';

const PrayerTimes = ({
    tvDisplayPrayerTimes, tvDisplayJumuahPrayerTimeItems,
    hasTvDisplayAdvancedAddOn, dispatch
}) => {
    const [prayerTimeItems, setPrayerTimeItems] = useState();
    const [jummahPrayerTimeItems, setJummahPrayerTimeItems] = useState();
    const [loading, setLoading] = useState(false);

    const [invalidFormMessage, setInvalidFormMessage] = useState('');

    useEffect(() => {
        // Convert timings to 24 hour format as our inputsaccept 24 hour format
        tvDisplayPrayerTimes = tvDisplayPrayerTimes.map((prayer, index) => {
            if (index !== 3) {
                return {
                    Adhan: prayer.Adhan,
                    ID: prayer.ID,
                    Name: prayer.Name,
                    Timing: convertFrom12to24(prayer.Timing),
                    TvDisplayId: prayer.TvDisplayId
                };
            }
            return {
                Adhan: prayer.Adhan,
                ID: prayer.ID,
                Name: prayer.Name,
                Timing: prayer.Timing,
                TvDisplayId: prayer.TvDisplayId
            };
        });
        setPrayerTimeItems(tvDisplayPrayerTimes);
        setJummahPrayerTimeItems(tvDisplayJumuahPrayerTimeItems);
    }, [tvDisplayPrayerTimes]);

    const isAddEnabled = useMemo(
        () => hasTvDisplayAdvancedAddOn && (jummahPrayerTimeItems && jummahPrayerTimeItems.length < 3),
        [jummahPrayerTimeItems],
    );

    const checkForEmptyPrayerTimes = () => {
        let isEmptyTiming = false;
        prayerTimeItems.forEach((prayer) => {
            if (prayer.Timing === emptyTimeSlot) {
                isEmptyTiming = true;
            }
        });
        if (isEmptyTiming) {
            setInvalidFormMessage('You have left one or more prayer times empty.You must fill in all prayer times');
            return true;
        }
        return false;
    };

    const handleOnSave = async () => {
        const formError = checkForEmptyPrayerTimes();
        if (formError) {
            return;
        }
        const updatedData = {};
        updatedData.TvDisplayPrayerTimeItems = prayerTimeItems;
        updatedData.TvDisplayJumuahPrayerTimeItems = jummahPrayerTimeItems;
        updatedData.TvDisplayPrayerTimeItems.forEach((prayer, index) => {
            // skip for maghrib
            if (index === 3) {
                prayer.Timing = 'Sunset';
            } else {
                prayer.Timing = convertFrom24To12(prayer.Timing, index).trim();
            }
        });
        setLoading(true);
        const error = await dispatch(savePrayerTimes(updatedData));
        setLoading(false);
        tvDisplayPrayerTimes.forEach((prayer, index) => {
            // skip for maghrib
            if (index === 3) {
                return;
            }
            prayer.Timing = convertFrom12to24(prayer.Timing, index);
        });
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
    };

    const handlePrayerItemChange = (value, element, property) => {
        setInvalidFormMessage('');
        if (property === timing) {
            value = convertFrom12to24(value).trim();
        }
        setPrayerTimeItems(existingElements => existingElements.map(existingElement => {
            if (existingElement.ID === element.ID) {
                return { ...existingElement, [property]: value };
            }
            return existingElement;
        }));
    };

    const handleJummahPrayerItemChange = (value, element, property) => {
        setJummahPrayerTimeItems(existingElements => existingElements.map(existingElement => {
            if (existingElement.ID === element.ID) {
                return { ...existingElement, [property]: value };
            }
            return existingElement;
        }));
    };

    const onAddJummah = () => {
        if (jummahPrayerTimeItems.length === 0) {
            setJummahPrayerTimeItems(existingItems => [...existingItems,
                {
                    Name: 'Jumuah1',
                    Timing: '12:30 PM',
                    Adhan: '12.00 PM',
                    ID: 1
                }
            ]);
            return;
        }
        setJummahPrayerTimeItems(existingItems => [...existingItems,
            {
                Name: `Jumuah ${existingItems.length}`,
                Timing: '12:30 PM',
                Adhan: '12:00 PM',
                ID: existingItems[existingItems.length - 1].ID + 1
            }
        ]);
    };

    const handlePrayerTimeChanges = (value, element) => {
        const finalTime = convertFrom24To12(value);
        handlePrayerItemChange(finalTime, element, timing);
    };

    return (
        <div className="view">
            <PageHeader title="Configure your tv display" description="Here are your masjid's daily salah timings displayed on TV." />
            <div className="view-content">
                <Row>
                    <Col sm="12" md="6">

                        {/* 5 Times Prayers */}
                        <div className="col-md-3-mb-12">

                            <Card>
                                <CardBody>

                                    <div className="h5 mb-4">Daily Prayers</div>
                                    {
                                        prayerTimeItems && prayerTimeItems.map((element, index) => (
                                            <div className="d-flex" key={element.ID}>
                                                <div className="col-md-6">
                                                    <FormInput
                                                        value={element.Name}
                                                        onChangeValue={(value) => handlePrayerItemChange(value, element, 'Name')}
                                                        label={index === 0 ? 'Prayer' : ''}
                                                        maxLength={30}
                                                    />
                                                </div>

                                                {/* Disabled temporary */}

                                                {/* TODO    Assign col-md-4 to all three inputs when we enable adhaan times */}

                                                {/* <div className="col-md-4">
                                                <FormInput
                                                    value={element.Adhan}
                                                    onChangeValue={(value) => handlePrayerItemChange(value, element, 'Adhan')}
                                                    label={index === 0 ? 'Adhan' : ''}
                                                    maxLength={30}
                                                    disabled={!hasTvDisplayAdvancedAddOn}
                                                />
                                            </div> */}

                                                <div className="col-md-6">
                                                    {index === 3 ? (
                                                        <FormInput
                                                            label={index === 0 ? timing : ''}
                                                            type="text"
                                                            disabled
                                                            value={element.Timing}
                                                        />
                                                    )
                                                        : (
                                                            <FormInput
                                                                label={index === 0 ? timing : ''}
                                                                type="time"
                                                                value={element.Timing}
                                                                onChangeValue={(value) => handlePrayerTimeChanges(value, element)}
                                                            />
                                                        )}
                                                </div>

                                            </div>
                                        ))
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col sm="12" md="6">

                        {/* Jummah Prayers */}

                        <Card>
                            <CardBody>
                                <div className="h5 mb-4">Jummah Prayers</div>
                                {
                                    jummahPrayerTimeItems && jummahPrayerTimeItems.map((element, index) => (
                                        <>
                                            <div className="d-flex">
                                                <div className="col-md-6">
                                                    <FormInput
                                                        value={element.Name}
                                                        onChangeValue={(value) => handleJummahPrayerItemChange(value, element, 'Name')}
                                                        label={index === 0 ? 'Prayer' : ''}
                                                        maxLength={30}
                                                    />
                                                </div>

                                                {/* Disabled temporary */}

                                                {/* TODO    Assign col-md-4 to all three inputs when we enable adhaan times */}

                                                {/* <div className="col-md-4">
                                                <FormInput
                                                    value={element.Adhan}
                                                    onChangeValue={(value) => handleJummahPrayerItemChange(value, element, 'Adhan')}
                                                    label={index === 0 ? 'Adhaan' : ''}
                                                    maxLength={30}
                                                    disabled={!hasTvDisplayAdvancedAddOn}
                                                />
                                                 </div> */}


                                                <div className="col-md-5">
                                                    <FormInput
                                                        value={element.Timing}
                                                        onChangeValue={(value) => handleJummahPrayerItemChange(value, element, timing)}
                                                        label={index === 0 ? timing : ''}
                                                        maxLength={30}
                                                    />
                                                </div>
                                                {hasTvDisplayAdvancedAddOn && (
                                                    <div className="">
                                                        <IconMinus
                                                            color="black"
                                                            className="col-mb-1 float-right"
                                                            size={25}
                                                            onClick={() => {
                                                                setJummahPrayerTimeItems(jummahPrayerTimeItems.filter(item => element.ID !== item.ID));
                                                            }}
                                                            style={jummahPrayerTimeItems.length === 1 ? { marginTop: '30px' } : { marginTop: '28px' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))
                                }
                                {isAddEnabled && (
                                    <div style={{ marginLeft: '8px' }}>
                                        <IconPlus
                                            color="rgb(5,94,85)"
                                            size={25}
                                            onClick={onAddJummah}
                                        />
                                    </div>
                                )}

                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <div className="col-md-12 float-right">
                        {invalidFormMessage && <p style={{ color: 'red' }}>{invalidFormMessage}</p>}
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12 float-right" style={{ marginTop: '10px' }}>
                        <Button
                            onClick={handleOnSave}
                            loading={loading}
                            disabled={invalidFormMessage}
                            size="lg"
                        >
                            Save
                        </Button>
                    </div>
                </Row>

                {/* TODO add adhaan times text in upgradealert text after adhaan time implementation */}
                <UpgradePlanAlert description="To add more jummahs you need to" show={hasTvDisplayAdvancedAddOn} />

            </div>


        </div>
    );
};


PrayerTimes.propTypes = {
    dispatch: PropTypes.func.isRequired,
    tvDisplayPrayerTimes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tvDisplayJumuahPrayerTimeItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired
};


const mapStateToProps = ({ masjid: { data: { ID, TvDisplay, HasTvDisplayAdvancedAddOn } } }) => ({
    masjidId: ID,
    tvDisplayPrayerTimes: TvDisplay.TvDisplayPrayerTimeItems.map((prayer) => ({
        Adhan: prayer.Adhan,
        ID: prayer.ID,
        Name: prayer.Name,
        Timing: prayer.Timing,
        TvDisplayId: prayer.TvDisplayId
    })),
    tvDisplayJumuahPrayerTimeItems: TvDisplay.TvDisplayJumuahPrayerTimeItems,
    hasTvDisplayAdvancedAddOn: HasTvDisplayAdvancedAddOn
});

export default connect(mapStateToProps)(PrayerTimes);
