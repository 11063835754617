import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    Button,
    DescriptionList
} from '../../shared/components';
import { getDonation } from '../../store/selectors';
import { StringUtils, DateUtils } from '../../utils';
import { refundDonation, showDialog, showWarningAlert } from '../../store/actions';

function Donation({
    donation: {
        ID,
        Amount,
        FirstName,
        LastName,
        Email,
        CreatedAt,
        AmountWithFees,
        CategoryName,
        DonationPlatform,
        PaymentByKiosk,
        IsRecurring,
        StripeID,
        Refunded,
        Successful
    },
    dispatch
}) {
    const [refunding, setRefunding] = useState(false);

    async function refund() {
        const confirm = await dispatch(showDialog('Are you sure?', 'Are you sure you to refund this payment? This action is reversable.'));
        if (confirm) {
            setRefunding(true);
            // All the errors are handled in the action creator itself.
            // The UI just needs to wait.
            await dispatch(refundDonation(ID));
            setRefunding(false);
        }
    }

    function viewInStripe(event) {
        event.preventDefault();
        if (!Successful) {
            dispatch(showWarningAlert('You cannot view a payment that failed, sadly.'));
            return;
        }
        window.open(StringUtils.toStripeURL(StripeID), '_blank');
    }

    return (
        <div className="view">
            <PageHeader
                title={StringUtils.formatAmount(Amount) + (Refunded ? ' - Refunded' : !Successful ? ' - Failed' : '')}
                description={`Donation by ${FirstName} ${LastName}.`}
            />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Personal Information"
                            subTitle="Information about the person who donated."
                            showFooter={false}
                        >
                            <Row>
                                <DescriptionList
                                    list={[
                                        { label: 'First Name', value: FirstName },
                                        { label: 'Last Name', value: LastName },
                                        { label: 'Email', value: Email },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Donation Information"
                            subTitle="More information about the donation."
                            showFooter={false}
                        >
                            <Row>
                                <DescriptionList
                                    list={[
                                        { label: 'Date', value: DateUtils.format(CreatedAt, 'MM/DD/YYYY') },
                                        { label: 'Amount', value: StringUtils.formatAmount(Amount) },
                                        { label: 'Amount With Fees', value: StringUtils.formatAmount(AmountWithFees) },
                                        { label: 'Donation Category', value: CategoryName },
                                        { label: 'Donation Platform', value: PaymentByKiosk ? 'Kiosk' : DonationPlatform || 'App' },
                                        { label: 'Recurring', value: IsRecurring ? 'Yes' : 'No' },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                                <a // eslint-disable-line
                                    className="col-md-6"
                                    href="#"
                                    onClick={viewInStripe}
                                >
                                    View in Stripe
                                </a>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
                <Row className="refund-container">
                    <div className="col-md-12">
                        <Button
                            color="danger"
                            onClick={refund}
                            disabled={Refunded || !Successful}
                            loading={refunding}
                        >
                            {Refunded ? 'Refunded' : 'Refund'}
                        </Button>
                    </div>
                </Row>
            </div>
        </div>
    );
}

Donation.propTypes = {
    donation: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
    donation: getDonation(state, props)
});

export default connect(mapStateToProps)(Donation);
