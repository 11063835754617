import client, { returnResponse } from './client';

const { get, post } = client;

const getNotifications = async (masjidID, type) => returnResponse(
    await get(`/masjids/${masjidID}/${type}`)
);

const pushNotification = async (masjidID, notification, groupID, type) => returnResponse(
    await post(
        groupID
            ? `/push/masjids/${masjidID}/groups/${groupID}/${type}/all`
            : `/push/masjids/${masjidID}/${type}/all`,
        notification
    )
);

const getEvents = masjidID => getNotifications(masjidID, 'events');

const pushEvent = (masjidID, event, groupID) => pushNotification(masjidID, event, groupID, 'events');

const getAnnouncements = masjidID => getNotifications(masjidID, 'announcements');

const pushAnnouncement = (masjidID, announcement, groupID) => pushNotification(masjidID, announcement, groupID, 'announcements');

const getDonationsRequests = masjidID => getNotifications(masjidID, 'donationrequests');

const pushDonationRequest = (masjidID, donationRequest, groupID) => pushNotification(masjidID, donationRequest, groupID, 'donationrequests');

export {
    getEvents,
    pushEvent,
    getAnnouncements,
    pushAnnouncement,
    getDonationsRequests,
    pushDonationRequest
};
