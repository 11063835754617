import createActionCreator from './create';
import {
    UPDATE_MAJSID,
} from '../types';
import {
    showNoInternetAlert,
    showBadRequestAlert,
    showServerErrorAlert,
    showSuccessAlert,
} from './alert';
import { WebsiteAPI, Constants } from '../../api';

const updateMasjid = createActionCreator(UPDATE_MAJSID, 'masjid');

export const updateWebsiteDomainName = (
    domainName,
) => async (dispatch, getState) => {
    const { masjid: { data: { WebsiteUserID } } } = getState();

    const { error, originalError } = await WebsiteAPI.updateWebsiteDomainName(
        WebsiteUserID,
        domainName,
    );

    if (error) {
        if (error === Constants.CLIENT_ERROR) {
            dispatch(showBadRequestAlert(originalError));
            return error;
        }
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return error;
        }
        dispatch(showNoInternetAlert());
        return error;
    }

    dispatch(updateMasjid({ WebsiteDomainName: domainName }));

    dispatch(showSuccessAlert('Successfully chosen a domain name for your website!'));

    return null;
};
