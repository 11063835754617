import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import {
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import IconClose from 'react-icons/lib/md/close';
import {
    InfoCard,
    PageHeader,
    FormInput,
    Button,
    DescriptionList,
    Image,
} from '../../shared/components';
import {
    updateClassAsync,
    showServerErrorAlert,
    showDangerAlert
} from '../../store/actions';
import { getClass } from '../../store/selectors';
import { FileUtils, DateUtils, StringUtils } from '../../utils';
import RegistrationsList from './RegistrationsList';
import { ChargeFrequency } from './constants';
import { getProgramLink } from '../../utils/online';

function IslamicClass({
    dispatch, masjidID, HasOnlineClassRegistrationAddOn, HasWebsiteBase, islamicClass
}) {
    const [imageUploading, setImageUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [editDaysModal, setEditDaysModal] = useState(false);
    const [currentClass, setCurrentClass] = useState({ ...islamicClass });
    const [fathersNameApplicable, setFathersNameApplicable] = useState(islamicClass.FathersNameApplicable);
    const [mothersNameApplicable, setMothersNameApplicable] = useState(islamicClass.MothersNameApplicable);
    const [fathersNameRequired, setFathersNameRequired] = useState(islamicClass.FathersNameRequired);
    const [mothersNameRequired, setMothersNameRequired] = useState(islamicClass.MothersNameRequired);
    const [emergencyContactNameApplicable, setEmergencyContactNameApplicable] = useState(islamicClass.EmergencyContactNameApplicable);
    const [emergencyContactNumberApplicable, setEmergencyContactNumberApplicable] = useState(islamicClass.EmergencyContactNumberApplicable);
    const [emergencyContactNumberRequired, setEmergencyContactNumberRequired] = useState(islamicClass.EmergencyContactNumberRequired);
    const [emergencyContactNameRequired, setEmergencyContactNameRequired] = useState(islamicClass.EmergencyContactNameRequired);

    const emptyClass = {
        Title: '',
        Description: '',
        Cost: '',
        RegistrationDeadline: '',
        StartDate: '',
        EndDate: '',
        ChargeFrequency: 'Onetime',
        TotalCapacity: '',
        HouseNumber: '',
        Street: '',
        City: '',
        Zipcode: '',
        State: '',
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: ''
        }]
    };
    const [invalidClass, setInvalidClass] = useState({
        ...emptyClass
    });

    function editIslamicClass() {
        showEditModal();
    }

    async function saveClassImage(image) {
        setImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a masjid and
            // the old one gets overwritten.
            new File(
                [image],
                `program_image_${currentClass.ID}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            ), 'classImages'
        );
        if (error) {
            setImageUploading(false);
            // Upload has failed.
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The class image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            dispatch(showServerErrorAlert());
            return;
        }
        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for a program.
        const programImageUrl = `${finalUrl}?c=${new Date().getTime()}`;
        const updateError = await dispatch(updateClassAsync({
            ID: currentClass.ID,
            ImageUrl: programImageUrl
        }));
        setImageUploading(false);
        if (updateError) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    async function updateClass() {
        let validationError = false;
        const newInvalidClass = {
            ...emptyClass,
            Days: Array(currentClass.Days.length).fill({
                StartTime: '',
                EndTime: '',
                Day: ''
            })
        };

        for (const i in currentClass.Days) {
            const day = currentClass.Days[i];
            for (const [dayKey, dayValue] of Object.entries(day)) {
                if (!dayValue) {
                    newInvalidClass.Days = newInvalidClass.Days.map((invalidDay, index) => { //eslint-disable-line
                        if (+i === index) {
                            validationError = true;
                            return {
                                ...invalidDay,
                                [dayKey]: `${dayKey} can't be empty`
                            };
                        }
                        return invalidDay;
                    });
                }
            }
        }
        // setCurrentClass(currentClass.Cost && currentClass.Cost.toString());
        if (currentClass.Cost === 0) {
            currentClass.Cost = currentClass.Cost.toString();
        }

        for (const key of Object.keys(emptyClass)) {
            if (!currentClass[key]) {
                validationError = true;
                newInvalidClass[key] = `${key} can't be empty`;
            }
        }

        if (currentClass.Cost > 0 && currentClass.Cost <= 0.5) {
            validationError = true;
            newInvalidClass.Cost = 'Class cost cannot be less than 0.5. It can however be 0 in case of a free class.';
        }

        if (currentClass.TotalCapacity < currentClass.RegisteredAttendeeCount) {
            validationError = true;
            newInvalidClass.TotalCapacity = 'Capacity can\'t be less than registered students';
        }

        if (DateUtils.isBefore(currentClass.StartDate, currentClass.RegistrationDeadline, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidClass.StartDate = 'Start date cannot be equal to or before registration deadline.';
        }

        if (DateUtils.isAfter(currentClass.StartDate, currentClass.EndDate, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidClass.EndDate = 'Start date cannot be equal to or after end date.';
        }


        setInvalidClass(newInvalidClass);
        if (validationError) {
            return;
        }


        setLoading(true);
        const newClassWithTimeInMillis = {
            ...currentClass,
            StartDate: DateUtils.format(currentClass.StartDate, 'YYYY/MM/DD'),
            EndDate: DateUtils.format(currentClass.EndDate, 'YYYY/MM/DD'),
            RegistrationDeadline: DateUtils.format(currentClass.RegistrationDeadline, 'YYYY/MM/DD'),
            FathersNameRequired: fathersNameRequired,
            MothersNameRequired: mothersNameRequired,
            FathersNameApplicable: fathersNameApplicable,
            MothersNameApplicable: mothersNameApplicable,
            EmergencyContactNameRequired: emergencyContactNameRequired,
            EmergencyContactNumberRequired: emergencyContactNumberRequired,
            EmergencyContactNameApplicable: emergencyContactNameApplicable,
            EmergencyContactNumberApplicable: emergencyContactNumberApplicable
        };
        const error = await dispatch(updateClassAsync(newClassWithTimeInMillis));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideEditModal();
        hideEditDaysModal();
    }

    function showEditModal() {
        if (islamicClass.Cancelled) {
            dispatch(showDangerAlert('Not Editable! The class has been cancelled so no editing is possible.'));
            return;
        }
        if (!islamicClass.IsEditableBool) {
            dispatch(showDangerAlert('Not Editable! The class can\'t be edited since starting date has already passed.'));
            return;
        }
        setCurrentClass({ ...islamicClass });
        setModal(true);
    }

    function hideEditModal() {
        setModal(false);
    }


    function showEditDaysModal() {
        if (islamicClass.Cancelled) {
            dispatch(showDangerAlert('Not Editable! The class has been cancelled so no editing is possible.'));
            return;
        }
        setEditDaysModal(true);
    }

    function hideEditDaysModal() {
        setEditDaysModal(false);
    }

    function handleClassPropertyChange(column) {
        return function (value) {
            setCurrentClass({ ...currentClass, [column]: value });
        };
    }

    function handleDayPropertyChange(column, index) {
        return function (value) {
            setCurrentClass({
                ...currentClass,
                Days: currentClass.Days.map((day, dayIndex) => {
                    if (dayIndex === index) {
                        return { ...day, [column]: value };
                    }
                    return day;
                })
            });
        };
    }

    function addDayToClass() {
        setCurrentClass({
            ...currentClass,
            // Fill it with the next available label.
            Days: [...currentClass.Days, { StartTime: '', EndTime: '', Day: getDayLabels(null)[0].value }]
        });
        setInvalidClass({
            ...invalidClass,
            Days: [...invalidClass.Days, { StartTime: '', EndTime: '', Day: '' }]
        });
    }

    function handleClassImageChange(files) {
        saveClassImage(files[0]);
    }

    function removeDay(index) {
        return function () {
            if (index === 0) {
                // Don't let the user remove the first day.
                dispatch(showDangerAlert('A class has to be for atleast a single day.'));
                return;
            }
            setCurrentClass({
                ...currentClass,
                Days: currentClass.Days.filter((_, i) => i !== index)
            });
            setInvalidClass({
                ...invalidClass,
                Days: invalidClass.Days.filter((_, i) => i !== index)
            });
        };
    }

    function editDays() {
        showEditDaysModal();
    }

    // Get dropdown options for days excluding the ones that have been set currently.
    // Deal in strings only.
    function getDayLabels(currentDay) {
        const daysInClass = currentClass.Days.map(({ Day }) => `${Day}`);
        return DateUtils.getDaysAsLabelValuePairs()
            .filter(({ value }) => value === `${currentDay}` || !daysInClass.includes(value));
    }

    function canAddAnotherDay() {
        return currentClass.Days.length < 7;
    }

    function getHeaderTitle() {
        return `${islamicClass.Title} - ${StringUtils.humanize(islamicClass.ProgramState)}`;
    }

    function setFathersNameApplicability(value) {
        setFathersNameApplicable(value);
        if (value === false) {
            setFathersNameRequired(false);
        }
    }

    function setMothersNameApplicability(value) {
        setMothersNameApplicable(value);
        if (value === false) {
            setMothersNameRequired(false);
        }
    }

    function setEmergencyContactNameApplicability(value) {
        setEmergencyContactNameApplicable(value);
        if (value === false) {
            setEmergencyContactNameRequired(false);
        }
    }

    function setEmergencyContactNumberApplicability(value) {
        setEmergencyContactNumberApplicable(value);
        if (value === false) {
            setEmergencyContactNumberRequired(false);
        }
    }


    return (
        <div className="view">
            <PageHeader title={getHeaderTitle()} description="All the details of this class." />
            <div className="view-content">

                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Class Infomation"
                            subTitle="More about this class."
                            showFooter={false}
                            showButtonInHeader
                            onClickButtonInHeader={editIslamicClass}
                            buttonInHeaderLabel="Edit Class"
                        >
                            <Row className="mt-4">
                                <DescriptionList
                                    list={[
                                        {
                                            label: 'Image',
                                            value: (
                                                <Image
                                                    title="Class Flyer"
                                                    url={islamicClass.ImageUrl}
                                                    uploading={imageUploading}
                                                    onImageSelected={handleClassImageChange}
                                                />
                                            )
                                        },
                                        {
                                            label: 'Online Link',
                                            value: HasOnlineClassRegistrationAddOn || HasWebsiteBase
                                                ? <a href={getProgramLink(masjidID, islamicClass.ID)} target="_blank" rel="noopener noreferrer">{getProgramLink(masjidID, islamicClass.ID)}</a>
                                                : <Link to="/account">Generate Online Link</Link>
                                        },
                                        { label: 'Title', value: islamicClass.Title },
                                        { label: 'Description', value: islamicClass.Description },
                                        { label: 'Charge Frequency', value: islamicClass.ChargeFrequency },
                                        { label: 'Cost', value: islamicClass.CostFormatted },
                                        { label: 'Registration Deadline', value: islamicClass.RegistrationDeadlineFormatted },
                                        { label: 'Start Date', value: islamicClass.StartDateFormatted },
                                        { label: 'End Date', value: islamicClass.EndDateFormatted },
                                        { label: 'House Number', value: islamicClass.HouseNumber },
                                        { label: 'Street', value: islamicClass.Street },
                                        { label: 'City', value: islamicClass.City },
                                        { label: 'State', value: islamicClass.State },
                                        { label: 'Zipcode', value: islamicClass.Zipcode },
                                        { label: 'Total Capacity', value: islamicClass.TotalCapacity },
                                        { label: 'Shown on Kiosk and Portal', value: islamicClass.ShownOnKioskAndPortal },
                                        { label: 'Cancelled', value: islamicClass.CancelledFormatted },
                                        { label: 'CancelledAt', value: islamicClass.CancelledAt },
                                        { label: "Father's Name Applicable", value: islamicClass.FathersNameIsApplicable },
                                        { label: "Fathers's Name Required", value: islamicClass.FathersNameIsRequired },
                                        { label: "Mother's Name Applicable", value: islamicClass.MothersNameIsApplicable },
                                        { label: "Mother's Name Required", value: islamicClass.MothersNameIsRequired },
                                        { label: 'Emergency Contact Name Applicable', value: islamicClass.EmergencyContactNameIsApplicable },
                                        { label: 'Emergency Contact Name Required', value: islamicClass.EmergencyContactNameIsRequired },
                                        { label: 'Emergency Contact Number Applicable', value: islamicClass.EmergencyContactNumberIsApplicable },
                                        { label: 'Emergency Contact Number Required', value: islamicClass.EmergencyContactNumberIsRequired },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                        <Modal isOpen={modal} toggle={showEditModal} className="default">
                            <ModalHeader toggle={hideEditModal}>Add Class Details</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <div className="col-md-12">
                                        <FormInput
                                            value={currentClass.Title}
                                            onChangeValue={handleClassPropertyChange('Title')}
                                            row
                                            label="Title"
                                            type="text"
                                            message={invalidClass.Title}
                                            state={!invalidClass.Title ? 'default' : 'danger'}
                                            maxLength={30}
                                        />
                                        <FormInput
                                            value={currentClass.Description}
                                            onChangeValue={handleClassPropertyChange('Description')}
                                            row
                                            label="Description"
                                            type="textarea"
                                            message={invalidClass.Description}
                                            state={!invalidClass.Description ? 'default' : 'danger'}
                                            maxLength={200}
                                        />
                                        <FormInput
                                            value={currentClass.ChargeFrequency}
                                            onChangeValue={handleClassPropertyChange('ChargeFrequency')}
                                            row
                                            label="Charge Frequency"
                                            options={[
                                                { label: ChargeFrequency.Onetime, value: ChargeFrequency.Onetime },
                                                { label: ChargeFrequency.Monthly, value: ChargeFrequency.Monthly },
                                                { label: ChargeFrequency.Yearly, value: ChargeFrequency.Yearly }
                                            ]}
                                            type="select"
                                        />
                                        <FormInput
                                            value={currentClass.Cost}
                                            onChangeValue={handleClassPropertyChange('Cost')}
                                            row
                                            label="Cost"
                                            type="number"
                                            min={0}
                                            message={invalidClass.Cost}
                                            state={!invalidClass.Cost ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            value={currentClass.RegistrationDeadline}
                                            onChangeValue={handleClassPropertyChange('RegistrationDeadline')}
                                            row
                                            label="Registration Deadline"
                                            type="date"
                                            message={invalidClass.RegistrationDeadline}
                                            state={!invalidClass.RegistrationDeadline ? 'default' : 'danger'}

                                        />
                                        <FormInput
                                            value={currentClass.StartDate}
                                            onChangeValue={handleClassPropertyChange('StartDate')}
                                            row
                                            label="Start Date"
                                            type="date"
                                            message={invalidClass.StartDate}
                                            state={!invalidClass.StartDate ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            value={currentClass.EndDate}
                                            onChangeValue={handleClassPropertyChange('EndDate')}
                                            row
                                            label="End Date"
                                            type="date"
                                            message={invalidClass.EndDate}
                                            state={!invalidClass.EndDate ? 'default' : 'danger'}

                                        />
                                        <FormInput
                                            value={currentClass.HouseNumber}
                                            onChangeValue={handleClassPropertyChange('HouseNumber')}
                                            row
                                            label="House No"
                                            type="text"
                                            placeholder="HouseNumber"
                                            message={invalidClass.HouseNumber}
                                            state={!invalidClass.HouseNumber ? 'default' : 'danger'}
                                            maxLength={10}
                                        />
                                        <FormInput
                                            value={currentClass.Street}
                                            onChangeValue={handleClassPropertyChange('Street')}
                                            row
                                            label="Street"
                                            type="text"
                                            placeholder="Street"
                                            message={invalidClass.Street}
                                            state={!invalidClass.Street ? 'default' : 'danger'}
                                            maxLength={50}
                                        />
                                        <FormInput
                                            value={currentClass.City}
                                            onChangeValue={handleClassPropertyChange('City')}
                                            row
                                            label="City"
                                            type="text"
                                            placeholder="City"
                                            message={invalidClass.City}
                                            state={!invalidClass.City ? 'default' : 'danger'}
                                            maxLength={25}
                                        />
                                        <FormInput
                                            value={currentClass.State}
                                            onChangeValue={handleClassPropertyChange('State')}
                                            row
                                            label="State"
                                            type="text"
                                            placeholder="State"
                                            message={invalidClass.State}
                                            state={!invalidClass.State ? 'default' : 'danger'}
                                            maxLength={25}
                                        />
                                        <FormInput
                                            value={currentClass.Zipcode}
                                            onChangeValue={handleClassPropertyChange('Zipcode')}
                                            row
                                            label="Zipcode"
                                            type="text"
                                            placeholder="Zipcode"
                                            message={invalidClass.Zipcode}
                                            state={!invalidClass.Zipcode ? 'default' : 'danger'}
                                            maxLength={10}
                                        />
                                        <FormInput
                                            value={currentClass.TotalCapacity}
                                            onChangeValue={handleClassPropertyChange('TotalCapacity')}
                                            row
                                            label="Capacity"
                                            type="number"
                                            placeholder="Total Capacity"
                                            message={invalidClass.TotalCapacity}
                                            state={!invalidClass.TotalCapacity ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            label="Father's Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={fathersNameApplicable}
                                            onChangeValue={setFathersNameApplicability}
                                        />
                                        {fathersNameApplicable && (
                                            <FormInput
                                                label="Father's Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!fathersNameApplicable}
                                                value={fathersNameRequired}
                                                onChangeValue={setFathersNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Mother's Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={mothersNameApplicable}
                                            onChangeValue={setMothersNameApplicability}
                                        />
                                        {mothersNameApplicable && (
                                            <FormInput
                                                label="Mother's Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!mothersNameApplicable}
                                                value={mothersNameRequired}
                                                onChangeValue={setMothersNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Emergency Contact Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={emergencyContactNameApplicable}
                                            onChangeValue={setEmergencyContactNameApplicability}
                                        />
                                        {emergencyContactNameApplicable && (
                                            <FormInput
                                                label="Emergency Contact Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!emergencyContactNameApplicable}
                                                value={emergencyContactNameRequired}
                                                onChangeValue={setEmergencyContactNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Emergency Contact Number&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={emergencyContactNumberApplicable}
                                            onChangeValue={setEmergencyContactNumberApplicability}
                                        />
                                        {emergencyContactNumberApplicable && (
                                            <FormInput
                                                label="Emergency Contact Number is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!emergencyContactNumberApplicable}
                                                value={emergencyContactNumberRequired}
                                                onChangeValue={setEmergencyContactNumberRequired}
                                            />
                                        )}
                                    </div>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button loading={loading} color="primary" onClick={updateClass}>Update</Button>
                                {' '}
                                <Button color="secondary" onClick={hideEditModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={editDaysModal} toggle={showEditDaysModal} className="default">
                            <ModalHeader toggle={hideEditDaysModal}>Class Days</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <div className="col-md-12">
                                        {' '}
                                        {currentClass.Days.map((day, index) => (
                                            <div key={`day-${day.Day}`}>
                                                <Row>
                                                    <div className="col-md-12">
                                                        <span className="text-primary">
                                                            {' '}
                                                            <IconClose
                                                                size={20}
                                                                className="my-3 float-right"
                                                                onClick={removeDay(index)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            {' '}
                                                        </span>
                                                    </div>
                                                </Row>
                                                <FormInput
                                                    value={day.Day}
                                                    onChangeValue={handleDayPropertyChange('Day', index)}
                                                    row
                                                    label="Day"
                                                    options={getDayLabels(day.Day)}
                                                    type="select"
                                                />
                                                <FormInput
                                                    value={day.StartTime}
                                                    onChangeValue={handleDayPropertyChange('StartTime', index)}
                                                    row
                                                    label="Start Time"
                                                    type="time"
                                                    message={invalidClass.Days[index] ? invalidClass.Days[index].StartTime : ''}
                                                    state={!(invalidClass.Days[index] && invalidClass.Days[index].StartTime) ? 'default' : 'danger'}
                                                />
                                                <FormInput
                                                    value={day.EndTime}
                                                    onChangeValue={handleDayPropertyChange('EndTime', index)}
                                                    row
                                                    label="End Time"
                                                    type="time"
                                                    message={invalidClass.Days[index] ? invalidClass.Days[index].EndTime : ''}
                                                    state={!(invalidClass.Days[index] && invalidClass.Days[index].EndTime) ? 'default' : 'danger'}
                                                />
                                                <hr />
                                            </div>
                                        ))}
                                        <Button
                                            color="primary"
                                            onClick={addDayToClass}
                                            disabled={!canAddAnotherDay()}
                                        >
                                            Add Another Day
                                        </Button>
                                    </div>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button loading={loading} color="primary" onClick={updateClass}>Update</Button>
                                {' '}
                                <Button color="secondary" disabled={loading} onClick={hideEditDaysModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Class Days"
                            subTitle="Days on which class takes place."
                            showFooter={false}
                            showButtonInHeader
                            onClickButtonInHeader={editDays}
                            buttonInHeaderLabel="Edit Days"
                        >
                            {islamicClass.Days.map(day => (
                                <DescriptionList
                                    containerClassName="mt-4"
                                    key={`day-readonly-${day.Day}`}
                                    list={[
                                        { label: 'Day', value: DateUtils.weekDayName(day.Day) },
                                        { label: 'Start Time', value: DateUtils.format(DateUtils.parse(day.StartTime, 'HH:mm:ss'), 'hh:mm A') },
                                        { label: 'End Time', value: DateUtils.format(DateUtils.parse(day.EndTime, 'HH:mm:ss'), 'hh:mm A') }]}
                                />
                            ))}
                            <hr />
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    <RegistrationsList islamicClass={islamicClass} />
                </Row>
            </div>
        </div>
    );
}

IslamicClass.propTypes = {
    dispatch: PropTypes.func.isRequired,
    masjidID: PropTypes.number.isRequired,
    HasOnlineClassRegistrationAddOn: PropTypes.bool.isRequired,
    HasWebsiteBase: PropTypes.bool.isRequired,
    islamicClass: PropTypes.shape({}).isRequired
};

const mapStateToProps = (state, props) => ({
    masjidID: state.masjid.data.ID,
    HasOnlineClassRegistrationAddOn: state.masjid.data.HasOnlineClassRegistrationAddOn,
    HasWebsiteBase: state.masjid.data.HasWebsiteBase,
    islamicClass: getClass(state, props)
});


export default connect(mapStateToProps)(IslamicClass);
