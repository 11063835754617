import {
    showBadRequestAlert,
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert
} from './alert';
import { Constants, TvDisplayApi } from '../../api';
import {
    ADD_TV_DISPLAY_ANNOUNCEMENT,
    UPDATE_TV_DISPLAY,
    UPDATE_TV_DISPLAY_ANNOUNCEMENT,
    DELETE_TV_DISPLAY_ANNOUNCEMENT,
    ADD_TV_DISPLAY_FLYER,
    UPDATE_TV_DISPLAY_FLYER,
    DELETE_TV_DISPLAY_FLYER,
    UPDATE_TV_DISPLAY_SETTINGS,
} from '../types/tvdisplay';
import createActionCreator from './create';

export const updateTvDisplay = createActionCreator(UPDATE_TV_DISPLAY, 'tvdisplay');
export const addTvDisplayAnnouncement = createActionCreator(ADD_TV_DISPLAY_ANNOUNCEMENT, 'tvdisplay');
export const updateTvDisplayAnnouncement = createActionCreator(UPDATE_TV_DISPLAY_ANNOUNCEMENT, 'tvdisplay');
export const deleteTvDisplayAnnouncement = createActionCreator(DELETE_TV_DISPLAY_ANNOUNCEMENT, 'tvdisplay');
export const addTvDisplayFlyer = createActionCreator(ADD_TV_DISPLAY_FLYER, 'tvdisplay');
export const updateTvDisplayFlyer = createActionCreator(UPDATE_TV_DISPLAY_FLYER, 'tvdisplay');
export const deleteTvDisplayFlyer = createActionCreator(DELETE_TV_DISPLAY_FLYER, 'tvdisplay');
export const updateTvDisplaySettings = createActionCreator(UPDATE_TV_DISPLAY_SETTINGS, 'tvdisplay');


export const savePrayerTimes = (prayertimes) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .saveTvDisplayPrayerTimes(TvDisplay.ID, prayertimes);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateTvDisplay(data));

    dispatch(showSuccessAlert('Successfully added prayer time!'));

    return null;
};

export const addNewAnnouncement = (announcement) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .addNewTvDisplayAnnouncement(TvDisplay.ID, announcement);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(addTvDisplayAnnouncement(data));

    dispatch(showSuccessAlert('Successfully added prayer time!'));

    return null;
};

export const updateAnnouncement = (announcement) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .saveTvDisplayAnnouncement(TvDisplay.ID, announcement.ID, announcement);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(updateTvDisplayAnnouncement(data));

    dispatch(showSuccessAlert('Successfully updated announcement!'));

    return null;
};

export const deleteAnnouncement = (announcementId) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .deleteTvDisplayAnnouncement(TvDisplay.ID, announcementId);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(deleteTvDisplayAnnouncement(data));

    dispatch(showSuccessAlert('Successfully deleted announcement!'));

    return null;
};

export const addFlyer = (newFlyerData) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .addNewTvDisplayFlyer(TvDisplay.ID, newFlyerData);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(addTvDisplayFlyer(data));

    dispatch(showSuccessAlert('Successfully added new slide!'));

    return null;
};

export const updateFlyer = (currentlyEditingFlyer) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .saveTvDisplayFlyer(TvDisplay.ID, currentlyEditingFlyer, currentlyEditingFlyer.ID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(updateTvDisplayFlyer(data));

    dispatch(showSuccessAlert('Successfully updated slide!'));

    return null;
};

export const deleteFlyer = (flyerId) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .deleteTvDisplayFlyer(TvDisplay.ID, flyerId);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(deleteTvDisplayFlyer(data));

    dispatch(showSuccessAlert('Successfully deleted slide!'));

    return null;
};

export const updateShowHideTvDisplaySettings = (settings) => async (dispatch, getState) => {
    const { masjid: { data: { TvDisplay } } } = getState();
    const { error, data, originalError } = await TvDisplayApi
        .updateTvDisplaySettings(TvDisplay.ID, settings);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(updateTvDisplaySettings(data));

    dispatch(showSuccessAlert('Successfully updated tv display settings!'));

    return null;
};
