import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Row,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    FormGroup,
} from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    DescriptionList,
    Button,
    FormInput
} from '../../shared/components';
import { getParticipant } from '../../store/selectors';
import { refundParticipantAsync } from '../../store/actions';
import { StringUtils } from '../../utils';

function EventCharge({
    dispatch,
    chargeID,
    registration: {
        ProgramID,
        PaidInFullFormatted,
        AttendeeID,
        Cost,
        RegistrationState,
        Charges
    }
}) {
    const {
        CreatedAt,
        AmountPaid,
        AmountPaidFormatted,
        AmountPaidMinusFees,
        IsRecurring,
        Refunded,
        RefundedAmount,
        RefundedFormatted,
        StripeID,
        RefundStripeID
    } = Charges.find(c => c.ID === chargeID);
    const [refundModal, setRefundModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({
        Amount: '',
        RefundAmount: ''
    });
    const [currentRegistration, setCurrentRegistration] = useState({
        ProgramID,
        AttendeeID,
        ChargeID: chargeID,
        Unenroll: true,
        WithRefund: false,
        Amount: `${Cost - AmountPaid}`,
        RefundAmount: AmountPaid
    });


    async function refundParticipant() {
        if (!currentRegistration.RefundAmount) {
            setValidation({
                RefundAmount: 'Refund Amount can\'t be empty.'
            });
            return;
        }
        setLoading(true);
        const error = await dispatch(refundParticipantAsync(currentRegistration));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideRefundModal();
    }


    function hideRefundModal() {
        setRefundModal(false);
    }

    function showRefundModal() {
        setRefundModal(true);
    }

    function changeRefundAmount(value) {
        setCurrentRegistration({
            ...currentRegistration,
            RefundAmount: value,
        });
    }

    function viewInStripe() {
        window.open(StringUtils.toStripeURL(StripeID), '_blank');
    }

    return (
        <div className="view">
            <PageHeader title="Event Charge" description="All the details about this charge." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Charge Information"
                    subTitle="Datailed information about the charge"
                    showFooter={false}
                >
                    <Row>
                        <DescriptionList
                            list={[
                                { label: 'Charged At', value: CreatedAt },
                                { label: 'Stripe ID', value: StripeID || '-' },
                                { label: 'Refunded Stripe ID', value: RefundStripeID || '-' },
                                { label: 'Amount Paid', value: AmountPaidFormatted },
                                { label: 'Amount Paid Minus Fees', value: AmountPaidMinusFees || '-' },
                                { label: 'Is Recurring', value: IsRecurring },
                                { label: 'Registration State', value: RegistrationState },
                                { label: 'Paid In Full', value: PaidInFullFormatted },
                                { label: 'Refunded', value: RefundedFormatted },
                                { label: 'Refunded Amount', value: RefundedAmount },
                                {
                                    label: 'View Payment In Stripe',
                                    value: (
                                        <Button
                                            color="secondary"
                                            onClick={viewInStripe}
                                            disabled={!StripeID}
                                        >
                                            View in Stripe
                                        </Button>
                                    )
                                },

                                {
                                    label: 'Refund',
                                    value: (
                                        <Button
                                            color="danger"
                                            onClick={showRefundModal}
                                            disabled={Refunded}
                                        >
                                            {Refunded ? 'Already Refunded' : 'Refund'}
                                        </Button>
                                    )
                                }
                            ]}
                            containerClassName="col-md-12"
                        />
                    </Row>
                </InfoCard>


                <Modal isOpen={refundModal} toggle={showRefundModal} className="default">
                    <ModalHeader toggle={hideRefundModal}>Refund</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div>
                                <FormInput
                                    value={currentRegistration.RefundAmount}
                                    onChangeValue={changeRefundAmount}
                                    row
                                    label="Refund Amount"
                                    type="number"
                                    placeholder="Refund Amount"
                                    message={validation.RefundAmount}
                                    state={!validation.RefundAmount ? 'default' : 'danger'}
                                    max={AmountPaid}
                                    min={0}
                                    disabled={Refunded}
                                />
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="danger" onClick={refundParticipant}>Refund</Button>
                        {' '}
                        <Button color="secondary" onClick={hideRefundModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

EventCharge.propTypes = {
    dispatch: PropTypes.func.isRequired,
    registration: PropTypes.shape({}).isRequired,
    chargeID: PropTypes.number.isRequired
};


const mapStateToProps = (state, props) => ({
    registration: getParticipant(state, props),
    chargeID: props.location.state.chargeID
});

export default connect(mapStateToProps)(EventCharge);
