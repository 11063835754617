/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';

import { browserHistory } from 'react-router';
import {
    PageHeader,
    Table,
    Button,
    FormInput
} from '../../shared/components';
import {
    addAdminAsync, deleteAdminAsync, showDialog, showDangerAlert
} from '../../store/actions';

function AdminsList({ dispatch, admins, currentAdminEmail }) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [invalidFirstName, setInvalidFirstName] = useState('');
    const [invalidLastName, setInvalidLastName] = useState('');
    const [invalidPassword, setInvalidPassword] = useState('');
    const [invalidEmail, setInvalidEmail] = useState('');
    const [newAdmin, setNewAdmin] = useState({
        FirstName: '',
        LastName: '',
        Role: 'SUPER_ADMIN',
        Password: '',
        Email: '',
        PhoneNumber: ''
    });

    function showModal() {
        setModal(true);
    }

    function hideModal() {
        setModal(false);
    }

    function showCreateAdminModal() {
        showModal();
    }

    function viewAdmin(admin) {
        browserHistory.push({ pathname: `/admins/${admin.Email}`, state: admin.Email });
    }

    async function saveAdmin() {
        setInvalidFirstName('');
        setInvalidLastName('');
        setInvalidEmail('');
        setInvalidPassword('');

        if (!newAdmin.FirstName) {
            setInvalidFirstName('First Name can\'t be empty.');
            return;
        }
        if (!newAdmin.LastName) {
            setInvalidLastName('Last Name can\'t be empty.');
            return;
        }
        if (!newAdmin.Email) {
            setInvalidEmail('Email can\'t be empty.');
            return;
        }
        if (!newAdmin.Password) {
            setInvalidPassword('Password can\'t be empty.');
            return;
        }

        setLoading(true);
        const error = await dispatch(addAdminAsync(newAdmin));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }

    async function deleteAdmin({ Email }) {
        if (Email === currentAdminEmail) {
            dispatch(showDangerAlert('Sadly, you cannot delete your own admin account.'));
            return;
        }
        const confirm = await dispatch(showDialog('Are you sure?', 'Are you sure want to delete the admin ?'));
        if (!confirm) {
            return;
        }
        const error = await dispatch(deleteAdminAsync(Email));
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }

    function handleAdminPropertyChange(column) {
        return function (value) {
            setNewAdmin({ ...newAdmin, [column]: value });
        };
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Admins" description="Here are all the admins" />
                <div className="view-content">
                    <Row>
                        <Card className="col-md-12">
                            <CardBody>
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={showCreateAdminModal}
                                            >
                                                Add New Admin
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Table
                                    title="Admins"
                                    columns={[
                                        { label: 'First Name', key: 'FirstName' },
                                        { label: 'Last Name', key: 'LastName' },
                                        { label: 'Email', key: 'Email' },
                                        { label: 'Role', key: 'Role' },
                                        {
                                            label: 'Phone Number',
                                            key: 'PhoneNumber',
                                            render: phoneNumber => phoneNumber || '-'
                                        },
                                    ]}
                                    data={admins.map(admin => ({ ...admin, id: admin.Email }))}
                                    enableFilters={false}
                                    enableExports={false}
                                    onRowClick={viewAdmin}
                                    showButtonOne
                                    buttonOneLabel="Delete"
                                    onButtonOneClick={deleteAdmin}
                                />
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={modal} toggle={showModal} className="default modal-lg">
                        <ModalHeader toggle={hideModal}>Add Admin Details</ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className="col-md-12">
                                    <FormInput
                                        value={newAdmin.FirstName}
                                        onChangeValue={handleAdminPropertyChange('FirstName')}
                                        row
                                        label="First Name"
                                        type="text"
                                        placeholder="First Name"
                                        message={invalidFirstName}
                                        state={invalidFirstName ? 'danger' : 'default'}
                                        maxLength={25}
                                    />
                                    <FormInput
                                        value={newAdmin.LastName}
                                        onChangeValue={handleAdminPropertyChange('LastName')}
                                        row
                                        label="Last Name"
                                        type="text"
                                        placeholder="Last Name"
                                        message={invalidLastName}
                                        state={invalidLastName ? 'danger' : 'default'}
                                        maxLength={25}
                                    />
                                    <FormInput
                                        value={newAdmin.Email}
                                        onChangeValue={handleAdminPropertyChange('Email')}
                                        row
                                        label="Email"
                                        type="text"
                                        placeholder="Email"
                                        message={invalidEmail}
                                        state={invalidEmail ? 'danger' : 'default'}
                                        maxLength={75}
                                    />
                                    <FormInput
                                        value={newAdmin.PhoneNumber}
                                        onChangeValue={handleAdminPropertyChange('PhoneNumber')}
                                        row
                                        label="Phone Number"
                                        maxLength={20}
                                    />
                                    <FormInput
                                        value={newAdmin.Password}
                                        onChangeValue={handleAdminPropertyChange('Password')}
                                        row
                                        label="Temporary Password"
                                        type="password"
                                        placeholder="Temporary Password"
                                        message={invalidPassword}
                                        state={invalidPassword ? 'danger' : 'default'}
                                    />
                                    <FormInput
                                        value={newAdmin.Role}
                                        onChangeValue={handleAdminPropertyChange('Role')}
                                        row
                                        label="Role"
                                        type="select"
                                        placeholder="Role"
                                        options={[
                                            { key: 'SUPER_ADMIN', value: 'SUPER_ADMIN', label: 'Super Admin' },
                                            { key: 'ADMIN', value: 'ADMIN', label: 'Admin' },
                                            { key: 'TEACHER', value: 'TEACHER', label: 'Teacher' }
                                        ]}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button loading={loading} color="primary" onClick={saveAdmin}>Save</Button>
                            {' '}
                            <Button color="secondary" onClick={hideModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

AdminsList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    admins: PropTypes.arrayOf(PropTypes.shape({
        FirstName: PropTypes.string.isRequired,
        LastName: PropTypes.string.isRequired,
        Role: PropTypes.string.isRequired,
        Password: PropTypes.string,
        Email: PropTypes.string.isRequired
    })).isRequired,
    currentAdminEmail: PropTypes.string.isRequired
};

const mapStateToProps = ({ admin: { email }, masjid: { data: { Admins } } }) => ({
    admins: Admins,
    currentAdminEmail: email
});

export default connect(mapStateToProps)(AdminsList);
