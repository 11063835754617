import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import {
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import IconClose from 'react-icons/lib/md/close';
import {
    InfoCard,
    PageHeader,
    FormInput,
    Button,
    DescriptionList,
    Image,
} from '../../shared/components';
import {
    updateEventAsync,
    showServerErrorAlert,
    showDangerAlert
} from '../../store/actions';
import { getEvent } from '../../store/selectors';
import { FileUtils, DateUtils, StringUtils } from '../../utils';
import RegistrationsList from './RegistrationsList';
import { getProgramLink } from '../../utils/online';

function Event({
    dispatch, event, masjidID, HasOnlineEventRegistrationAddOn, HasWebsiteBase
}) {
    const [imageUploading, setImageUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [editDaysModal, setEditDaysModal] = useState(false);
    const [currentEvent, setCurrentEvent] = useState({ ...event });
    const [fathersNameApplicable, setFathersNameApplicable] = useState(event.FathersNameApplicable);
    const [mothersNameApplicable, setMothersNameApplicable] = useState(event.MothersNameApplicable);
    const [fathersNameRequired, setFathersNameRequired] = useState(event.FathersNameRequired);
    const [mothersNameRequired, setMothersNameRequired] = useState(event.MothersNameRequired);
    const [emergencyContactNameApplicable, setEmergencyContactNameApplicable] = useState(event.EmergencyContactNameApplicable);
    const [emergencyContactNumberApplicable, setEmergencyContactNumberApplicable] = useState(event.EmergencyContactNumberApplicable);
    const [emergencyContactNumberRequired, setEmergencyContactNumberRequired] = useState(event.EmergencyContactNumberRequired);
    const [emergencyContactNameRequired, setEmergencyContactNameRequired] = useState(event.EmergencyContactNameRequired);

    const emptyEvent = {
        Title: '',
        Description: '',
        Cost: '',
        RegistrationDeadline: '',
        StartDate: '',
        EndDate: '',
        TotalCapacity: '',
        HouseNumber: '',
        Street: '',
        City: '',
        Zipcode: '',
        State: '',
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: ''
        }]
    };
    const [invalidEvent, setInvalidEvent] = useState({
        ...emptyEvent
    });

    function editevent() {
        showEditModal();
    }

    async function saveEventImage(image) {
        setImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a masjid and
            // the old one gets overwritten.
            new File(
                [image],
                `program_image_${currentEvent.ID}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            ), 'eventImages'
        );
        if (error) {
            setImageUploading(false);
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The event image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            // Upload has failed.
            dispatch(showServerErrorAlert());
            return;
        }

        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for a program.
        const programImageUrl = `${finalUrl}?c=${new Date().getTime()}`;
        const updateError = await dispatch(updateEventAsync({
            ID: currentEvent.ID,
            ImageUrl: programImageUrl
        }));
        setImageUploading(false);
        if (updateError) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    async function updateEvent() {
        let validationError = false;
        const newInvalidEvent = {
            ...emptyEvent,
            Days: Array(currentEvent.Days.length).fill({
                StartTime: '',
                EndTime: '',
                Day: ''
            })
        };

        for (const i in currentEvent.Days) {
            const day = currentEvent.Days[i];
            for (const [dayKey, dayValue] of Object.entries(day)) {
                if (!dayValue) {
                    newInvalidEvent.Days = newInvalidEvent.Days.map((invalidDay, index) => { //eslint-disable-line
                        if (+i === index) {
                            validationError = true;
                            return {
                                ...invalidDay,
                                [dayKey]: `${dayKey} can't be empty`
                            };
                        }
                        return invalidDay;
                    });
                }
            }
        }
        if (currentEvent.Cost === 0) {
            currentEvent.Cost = currentEvent.Cost.toString();
        }
        for (const key of Object.keys(emptyEvent)) {
            if (!currentEvent[key]) {
                validationError = true;
                newInvalidEvent[key] = `${key} can't be empty`;
            }
        }

        if (currentEvent.Cost > 0 && currentEvent.Cost <= 0.5) {
            validationError = true;
            newInvalidEvent.Cost = 'Class cost cannot be less than 0.5. It can however be 0 in case of a free event.';
        }

        if (currentEvent.TotalCapacity < currentEvent.RegisteredAttendeeCount) {
            validationError = true;
            newInvalidEvent.TotalCapacity = 'Capacity can\'t be less than registered participants';
        }

        if (DateUtils.isBefore(currentEvent.StartDate, currentEvent.RegistrationDeadline, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidEvent.StartDate = 'Start date cannot be equal to or before registration deadline.';
        }

        if (currentEvent.StartDate !== currentEvent.EndDate && DateUtils.isAfter(currentEvent.StartDate, currentEvent.EndDate, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidEvent.EndDate = 'Start date cannot be after end date.';
        }

        if (DateUtils.getDaysBetweenDateStrings(currentEvent.EndDate, currentEvent.StartDate, 'YYYY-MM-DD') > 6) {
            validationError = true;
            newInvalidEvent.EndDate = 'An event cannot span for more than one week.';
        }

        setInvalidEvent(newInvalidEvent);
        if (validationError) {
            return;
        }

        setLoading(true);
        const newEventWithTimeInMillis = {
            ...currentEvent,
            StartDate: DateUtils.format(currentEvent.StartDate, 'YYYY/MM/DD'),
            EndDate: DateUtils.format(currentEvent.EndDate, 'YYYY/MM/DD'),
            RegistrationDeadline: DateUtils.format(currentEvent.RegistrationDeadline, 'YYYY/MM/DD'),
            FathersNameRequired: fathersNameRequired,
            MothersNameRequired: mothersNameRequired,
            FathersNameApplicable: fathersNameApplicable,
            MothersNameApplicable: mothersNameApplicable,
            EmergencyContactNameRequired: emergencyContactNameRequired,
            EmergencyContactNumberRequired: emergencyContactNumberRequired,
            EmergencyContactNameApplicable: emergencyContactNameApplicable,
            EmergencyContactNumberApplicable: emergencyContactNumberApplicable
        };

        const error = await dispatch(updateEventAsync(newEventWithTimeInMillis));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideEditModal();
        hideEditDaysModal();
    }

    function showEditModal() {
        if (event.Cancelled) {
            dispatch(showDangerAlert('Not Editable! The event has been cancelled so no editing is possible.'));
            return;
        }
        if (!event.IsEditableBool) {
            dispatch(showDangerAlert('Not Editable! The event can\'t be edited since starting date has already passed.'));
            return;
        }
        setCurrentEvent({ ...event });
        setModal(true);
    }

    function hideEditModal() {
        setModal(false);
    }


    function showEditDaysModal() {
        if (event.Cancelled) {
            dispatch(showDangerAlert('Not Editable! The event has been cancelled so no editing is possible.'));
            return;
        }
        setEditDaysModal(true);
    }

    function hideEditDaysModal() {
        setEditDaysModal(false);
    }

    function handleClassPropertyChange(column) {
        return function (value) {
            setCurrentEvent({ ...currentEvent, [column]: value });
        };
    }

    function handleDayPropertyChange(column, index) {
        return function (value) {
            setCurrentEvent({
                ...currentEvent,
                Days: currentEvent.Days.map((day, dayIndex) => {
                    if (dayIndex === index) {
                        return { ...day, [column]: value };
                    }
                    return day;
                })
            });
        };
    }

    function addDayToClass() {
        setCurrentEvent({
            ...currentEvent,
            // Fill it with the next available label.
            Days: [...currentEvent.Days, { StartTime: '', EndTime: '', Day: getDayLabels(null)[0].value }]
        });
        setInvalidEvent({
            ...invalidEvent,
            Days: [...invalidEvent.Days, { StartTime: '', EndTime: '', Day: '' }]
        });
    }

    function handleClassImageChange(files) {
        saveEventImage(files[0]);
    }

    function removeDay(index) {
        return function () {
            if (index === 0) {
                // Don't let the user remove the first day.
                dispatch(showDangerAlert('An event has to be for atleast a single day.'));
                return;
            }
            setCurrentEvent({
                ...currentEvent,
                Days: currentEvent.Days.filter((_, i) => i !== index)
            });
            setInvalidEvent({
                ...invalidEvent,
                Days: invalidEvent.Days.filter((_, i) => i !== index)
            });
        };
    }

    function editDays() {
        showEditDaysModal();
    }

    // Get dropdown options for days excluding the ones that have been set currently.
    // Deal in strings only.
    function getDayLabels(currentDay) {
        const daysInClass = currentEvent.Days.map(({ Day }) => `${Day}`);
        return DateUtils.getDaysAsLabelValuePairs()
            .filter(({ value }) => value === `${currentDay}` || !daysInClass.includes(value));
    }

    function canAddAnotherDay() {
        return currentEvent.Days.length < 7;
    }

    function getHeaderTitle() {
        return `${event.Title} - ${StringUtils.humanize(event.ProgramState)}`;
    }

    function setFathersNameApplicability(value) {
        setFathersNameApplicable(value);
        if (value === false) {
            setFathersNameRequired(false);
        }
    }

    function setMothersNameApplicability(value) {
        setMothersNameApplicable(value);
        if (value === false) {
            setMothersNameRequired(false);
        }
    }

    function setEmergencyContactNameApplicability(value) {
        setEmergencyContactNameApplicable(value);
        if (value === false) {
            setEmergencyContactNameRequired(false);
        }
    }

    function setEmergencyContactNumberApplicability(value) {
        setEmergencyContactNumberApplicable(value);
        if (value === false) {
            setEmergencyContactNumberRequired(false);
        }
    }

    return (
        <div className="view">
            <PageHeader title={getHeaderTitle()} description="All the details of this event." />
            <div className="view-content">

                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Event Infomation"
                            subTitle="More about this event."
                            showFooter={false}
                            showButtonInHeader
                            onClickButtonInHeader={editevent}
                            buttonInHeaderLabel="Edit Event"
                        >
                            <Row className="mt-4">
                                <DescriptionList
                                    list={[
                                        {
                                            label: 'Image',
                                            value: (
                                                <Image
                                                    title="Event Flyer"
                                                    url={event.ImageUrl}
                                                    uploading={imageUploading}
                                                    onImageSelected={handleClassImageChange}
                                                />
                                            )
                                        },
                                        {
                                            label: 'Online Link',
                                            value: HasOnlineEventRegistrationAddOn || HasWebsiteBase
                                                ? <a href={getProgramLink(masjidID, event.ID)} target="_blank" rel="noopener noreferrer">{getProgramLink(masjidID, event.ID)}</a>
                                                : <Link to="/account">Generate Online Link</Link>
                                        },
                                        { label: 'Title', value: event.Title },
                                        { label: 'Description', value: event.Description },
                                        { label: 'Cost', value: event.CostFormatted },
                                        { label: 'Registration Deadline', value: event.RegistrationDeadlineFormatted },
                                        { label: 'Start Date', value: event.StartDateFormatted },
                                        { label: 'End Date', value: event.EndDateFormatted },
                                        { label: 'House Number', value: event.HouseNumber },
                                        { label: 'Street', value: event.Street },
                                        { label: 'City', value: event.City },
                                        { label: 'State', value: event.State },
                                        { label: 'Zipcode', value: event.Zipcode },
                                        { label: 'Total Capacity', value: event.TotalCapacity },
                                        { label: 'Shown on Kiosk and Portal', value: event.ShownOnKioskAndPortal },
                                        { label: 'Cancelled', value: event.CancelledFormatted },
                                        { label: 'CancelledAt', value: event.CancelledAt },
                                        { label: "Father's Name Applicable", value: event.FathersNameIsApplicable },
                                        { label: "Fathers's Name Required", value: event.FathersNameIsRequired },
                                        { label: "Mother's Name Applicable", value: event.MothersNameIsApplicable },
                                        { label: "Mother's Name Required", value: event.MothersNameIsRequired },
                                        { label: 'Emergency Contact Name Applicable', value: event.EmergencyContactNameIsApplicable },
                                        { label: 'Emergency Contact Name Required', value: event.EmergencyContactNameIsRequired },
                                        { label: 'Emergency Contact Number Applicable', value: event.EmergencyContactNumberIsApplicable },
                                        { label: 'Emergency Contact Number Required', value: event.EmergencyContactNumberIsRequired },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                        <Modal isOpen={modal} toggle={showEditModal} className="default">
                            <ModalHeader toggle={hideEditModal}>Add Event Details</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <div className="col-md-12">
                                        <FormInput
                                            value={currentEvent.Title}
                                            onChangeValue={handleClassPropertyChange('Title')}
                                            row
                                            label="Title"
                                            type="text"
                                            message={invalidEvent.Title}
                                            state={!invalidEvent.Title ? 'default' : 'danger'}
                                            maxLength={30}
                                        />
                                        <FormInput
                                            value={currentEvent.Description}
                                            onChangeValue={handleClassPropertyChange('Description')}
                                            row
                                            label="Description"
                                            type="textarea"
                                            message={invalidEvent.Description}
                                            state={!invalidEvent.Description ? 'default' : 'danger'}
                                            maxLength={200}
                                        />
                                        <FormInput
                                            value={currentEvent.Cost}
                                            onChangeValue={handleClassPropertyChange('Cost')}
                                            row
                                            label="Cost"
                                            type="number"
                                            min={0}
                                            message={invalidEvent.Cost}
                                            state={!invalidEvent.Cost ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            value={currentEvent.RegistrationDeadline}
                                            onChangeValue={handleClassPropertyChange('RegistrationDeadline')}
                                            row
                                            label="Registration Deadline"
                                            type="date"
                                            message={invalidEvent.RegistrationDeadline}
                                            state={!invalidEvent.RegistrationDeadline ? 'default' : 'danger'}

                                        />
                                        <FormInput
                                            value={currentEvent.StartDate}
                                            onChangeValue={handleClassPropertyChange('StartDate')}
                                            row
                                            label="Start Date"
                                            type="date"
                                            message={invalidEvent.StartDate}
                                            state={!invalidEvent.StartDate ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            value={currentEvent.EndDate}
                                            onChangeValue={handleClassPropertyChange('EndDate')}
                                            row
                                            label="End Date"
                                            type="date"
                                            message={invalidEvent.EndDate}
                                            state={!invalidEvent.EndDate ? 'default' : 'danger'}

                                        />
                                        <FormInput
                                            value={currentEvent.HouseNumber}
                                            onChangeValue={handleClassPropertyChange('HouseNumber')}
                                            row
                                            label="House No"
                                            type="text"
                                            placeholder="HouseNumber"
                                            message={invalidEvent.HouseNumber}
                                            state={!invalidEvent.HouseNumber ? 'default' : 'danger'}
                                            maxLength={10}
                                        />
                                        <FormInput
                                            value={currentEvent.Street}
                                            onChangeValue={handleClassPropertyChange('Street')}
                                            row
                                            label="Street"
                                            type="text"
                                            placeholder="Street"
                                            message={invalidEvent.Street}
                                            state={!invalidEvent.Street ? 'default' : 'danger'}
                                            maxLength={50}
                                        />
                                        <FormInput
                                            value={currentEvent.City}
                                            onChangeValue={handleClassPropertyChange('City')}
                                            row
                                            label="City"
                                            type="text"
                                            placeholder="City"
                                            message={invalidEvent.City}
                                            state={!invalidEvent.City ? 'default' : 'danger'}
                                            maxLength={25}
                                        />
                                        <FormInput
                                            value={currentEvent.State}
                                            onChangeValue={handleClassPropertyChange('State')}
                                            row
                                            label="State"
                                            type="text"
                                            placeholder="State"
                                            message={invalidEvent.State}
                                            state={!invalidEvent.State ? 'default' : 'danger'}
                                            maxLength={25}
                                        />
                                        <FormInput
                                            value={currentEvent.Zipcode}
                                            onChangeValue={handleClassPropertyChange('Zipcode')}
                                            row
                                            label="Zipcode"
                                            type="text"
                                            placeholder="Zipcode"
                                            message={invalidEvent.Zipcode}
                                            state={!invalidEvent.Zipcode ? 'default' : 'danger'}
                                            maxLength={10}
                                        />
                                        <FormInput
                                            value={currentEvent.TotalCapacity}
                                            onChangeValue={handleClassPropertyChange('TotalCapacity')}
                                            row
                                            label="Capacity"
                                            type="number"
                                            placeholder="Total Capacity"
                                            message={invalidEvent.TotalCapacity}
                                            state={!invalidEvent.TotalCapacity ? 'default' : 'danger'}
                                        />
                                        <FormInput
                                            label="Father's Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={fathersNameApplicable}
                                            onChangeValue={setFathersNameApplicability}
                                        />
                                        {fathersNameApplicable && (
                                            <FormInput
                                                label="Father's Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!fathersNameApplicable}
                                                value={fathersNameRequired}
                                                onChangeValue={setFathersNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Mother's Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={mothersNameApplicable}
                                            onChangeValue={setMothersNameApplicability}
                                        />
                                        {mothersNameApplicable && (
                                            <FormInput
                                                label="Mother's Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!mothersNameApplicable}
                                                value={mothersNameRequired}
                                                onChangeValue={setMothersNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Emergency Contact Name&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={emergencyContactNameApplicable}
                                            onChangeValue={setEmergencyContactNameApplicability}
                                        />
                                        {emergencyContactNameApplicable && (
                                            <FormInput
                                                label="Emergency Contact Name is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!emergencyContactNameApplicable}
                                                value={emergencyContactNameRequired}
                                                onChangeValue={setEmergencyContactNameRequired}
                                            />
                                        )}
                                        <FormInput
                                            label="Emergency Contact Number&nbsp;&nbsp;"
                                            type="checkbox"
                                            value={emergencyContactNumberApplicable}
                                            onChangeValue={setEmergencyContactNumberApplicability}
                                        />
                                        {emergencyContactNumberApplicable && (
                                            <FormInput
                                                label="Emergency Contact Number is Required&nbsp;&nbsp;"
                                                type="checkbox"
                                                disabled={!emergencyContactNumberApplicable}
                                                value={emergencyContactNumberRequired}
                                                onChangeValue={setEmergencyContactNumberRequired}
                                            />
                                        )}
                                    </div>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button loading={loading} color="primary" onClick={updateEvent}>Update</Button>
                                {' '}
                                <Button color="secondary" onClick={hideEditModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={editDaysModal} toggle={showEditDaysModal} className="default">
                            <ModalHeader toggle={hideEditDaysModal}>Event Schedule</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <div className="col-md-12">
                                        {' '}
                                        {currentEvent.Days.map((day, index) => (
                                            <div key={`day-${day.Day}`}>
                                                <Row>
                                                    <div className="col-md-12">
                                                        <span className="text-primary">
                                                            {' '}
                                                            <IconClose
                                                                size={20}
                                                                className="my-3 float-right"
                                                                onClick={removeDay(index)}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            {' '}
                                                        </span>
                                                    </div>
                                                </Row>
                                                <FormInput
                                                    value={day.Day}
                                                    onChangeValue={handleDayPropertyChange('Day', index)}
                                                    row
                                                    label="Day"
                                                    options={getDayLabels(day.Day)}
                                                    type="select"
                                                />
                                                <FormInput
                                                    value={day.StartTime}
                                                    onChangeValue={handleDayPropertyChange('StartTime', index)}
                                                    row
                                                    label="Start Time"
                                                    type="time"
                                                    message={invalidEvent.Days[index] ? invalidEvent.Days[index].StartTime : ''}
                                                    state={!(invalidEvent.Days[index] && invalidEvent.Days[index].StartTime) ? 'default' : 'danger'}
                                                />
                                                <FormInput
                                                    value={day.EndTime}
                                                    onChangeValue={handleDayPropertyChange('EndTime', index)}
                                                    row
                                                    label="End Time"
                                                    type="time"
                                                    message={invalidEvent.Days[index] ? invalidEvent.Days[index].EndTime : ''}
                                                    state={!(invalidEvent.Days[index] && invalidEvent.Days[index].EndTime) ? 'default' : 'danger'}
                                                />
                                                <hr />
                                            </div>
                                        ))}
                                        <Button
                                            color="primary"
                                            onClick={addDayToClass}
                                            disabled={!canAddAnotherDay()}
                                        >
                                            Add Another Day
                                        </Button>
                                    </div>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button loading={loading} color="primary" onClick={updateEvent}>Update</Button>
                                {' '}
                                <Button color="secondary" disabled={loading} onClick={hideEditDaysModal}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Event Schedule"
                            subTitle="Timings for each event day."
                            showFooter={false}
                            showButtonInHeader
                            onClickButtonInHeader={editDays}
                            buttonInHeaderLabel="Edit Days"
                        >
                            {event.Days.map(day => (
                                <DescriptionList
                                    containerClassName="mt-4"
                                    key={`day-readonly-${day.Day}`}
                                    list={[
                                        { label: 'Day', value: DateUtils.weekDayName(day.Day) },
                                        { label: 'Start Time', value: DateUtils.format(DateUtils.parse(day.StartTime, 'HH:mm:ss'), 'hh:mm A') },
                                        { label: 'End Time', value: DateUtils.format(DateUtils.parse(day.EndTime, 'HH:mm:ss'), 'hh:mm A') }]}
                                />
                            ))}
                            <hr />
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    <RegistrationsList event={event} />
                </Row>
            </div>
        </div>
    );
}

Event.propTypes = {
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.shape({}).isRequired,
    masjidID: PropTypes.number.isRequired,
    HasOnlineEventRegistrationAddOn: PropTypes.bool.isRequired,
    HasWebsiteBase: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
    event: getEvent(state, props),
    masjidID: state.masjid.data.ID,
    HasOnlineEventRegistrationAddOn: state.masjid.data.HasOnlineEventRegistrationAddOn,
    HasWebsiteBase: state.masjid.data.HasWebsiteBase
});


export default connect(mapStateToProps)(Event);
