const types = {
    CLASS: 'Class',
    EVENT: 'Event'
};

const registrationStates = {
    Enrolled: 'Enrolled',
    Unenrolled: 'Unenrolled',
    PaymentPending: 'PaymentPending'
};

const programStates = {
    Cancelled: 'Cancelled',
    Full: 'Full',
    RegistrationOpen: 'Registration Open',
    RegistrationClosed: 'Registration Closed',
    Started: 'Started',
    Ended: 'Ended'
};

const hasPaidInFull = registrationState => registrationState !== registrationStates.PaymentPending;

export {
    types,
    registrationStates,
    programStates,
    hasPaidInFull
};
