import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader,
    FormGroup, Input, Label
} from 'reactstrap';
import { browserHistory } from 'react-router';
import { getEvents } from '../../store/selectors';
import {
    PageHeader,
    Table,
    Button
} from '../../shared/components';
import {
    cancelEventAsync,
    showDangerAlert
} from '../../store/actions';
import { DateUtils } from '../../utils';

function EventsList({ dispatch, events }) {
    const [loading, setLoading] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [refundEvent, setRefundEvent] = useState({ RefundAll: false });

    function viewEvent(event) {
        browserHistory.push({ pathname: `/events/${event.ID}`, state: event.ID });
    }

    function addNewEvent() {
        browserHistory.push('/events/add');
    }

    function showCancelEventModal(islamicEvent) {
        if (islamicEvent.Cancelled) {
            dispatch(showDangerAlert('Cannot cancel an already cancelled event.'));
            return;
        }
        setRefundEvent({ ...refundEvent, ID: islamicEvent.ID });
        setCancelModal(true);
    }

    function hideCancelEventModal() {
        setCancelModal(false);
    }

    async function cancelEvent() {
        setLoading(true);
        const error = await dispatch(cancelEventAsync(refundEvent));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
        hideCancelEventModal();
    }

    function changeRefundAll() {
        setRefundEvent({ ...refundEvent, RefundAll: !refundEvent.RefundAll });
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Events" description="Here are all the Events" />
                <div className="view-content">
                    <Row>
                        <Card className="col-md-12">
                            <CardBody>
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={addNewEvent}
                                            >
                                                Add New Event
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Table
                                    title="Events"
                                    filters={[
                                        {
                                            label: 'Title',
                                            key: 'Title'
                                        },
                                        {
                                            label: 'Events',
                                            key: 'IsEditable',
                                            filterInputType: 'select',
                                            filterSelectOptions: ['All'].concat(['Editable']),
                                            filterFunc(editable, chosenOption) {
                                                return chosenOption === 'All' || editable;
                                            }
                                        },
                                        {
                                            label: 'Events From',
                                            key: 'StartDate',
                                            filterInputType: 'date',
                                            filterFunc(date, chosenDate) {
                                                return DateUtils.isBefore(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                            }
                                        },
                                        {
                                            label: 'Events To',
                                            key: 'EndDate',
                                            filterInputType: 'date',
                                            filterFunc(date, chosenDate) {
                                                return DateUtils.isAfter(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                            }
                                        }
                                    ]}
                                    columns={[
                                        { label: 'Title', key: 'Title' },
                                        {
                                            label: 'Start Date',
                                            key: 'StartDate',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        {
                                            label: 'End Date',
                                            key: 'EndDate',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        {
                                            label: 'Registration Deadline',
                                            key: 'RegistrationDeadline',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        { label: 'Shown on Kiosk and Portal', key: 'ShownOnKioskAndPortal' },
                                        { label: 'No of Registrations', key: 'RegisteredAttendeeCount' }
                                    ]}
                                    data={events}
                                    enableFilters
                                    enableExports={false}
                                    onRowClick={viewEvent}
                                    showButtonOne
                                    buttonOneLabel="Cancel"
                                    onButtonOneClick={showCancelEventModal}
                                    defaultSortColumnKey="RegistrationDeadline"
                                />
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={cancelModal} toggle={showCancelEventModal} className="default">
                        <ModalHeader toggle={hideCancelEventModal}>Cancel Event</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <legend className="col-form-legend font-weight-bold">
                                    Are you sure you want to cancel the event
                                </legend>
                                <div>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            onChange={changeRefundAll}
                                            checked={refundEvent.RefundAll}
                                        />
                                        &nbsp;
                                        Refund Fee of all the Registrants/Participants
                                    </Label>
                                </div>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button loading={loading} color="danger" onClick={cancelEvent}>Cancel Event</Button>
                            {' '}
                            <Button color="secondary" onClick={hideCancelEventModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

EventsList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
    events: getEvents(state)
});

export default connect(mapStateToProps)(EventsList);
