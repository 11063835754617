import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import './index.scss';
import { hasSuperAdminRole, hasDonationsInPlan } from '../../../store/selectors';

function PageHeader({
    title,
    description,
    headTitle,
    totalDonations,
    isSuperAdmin,
    HasDonationsInPlan
}) {
    return (
        <div className="view-header d-flex align-items-center">
            <Helmet>
                <title>{`Musalleen | ${headTitle || title}`}</title>
            </Helmet>
            <header className="text-white">
                <h1 className="h5 title text-uppercase">{title}</h1>
                <p className="mb-0 subtitle">
                    {description}
                </p>
            </header>
            <div className="ml-auto d-flex mt-2">
                {/* <div className="hidden-sm-down">
                    <LineChart width={100} height={35} data={tinyChartData}>
                        <Line type="monotone" dataKey="growth"
                            stroke="#FFEA00" strokeWidth={1} dot={{ strokeWidth: 0, r: 2 }} />
                        <Tooltip content={<IncomeGrowthTooltip />} />
                    </LineChart>
                    <p className="small text-uppercase text-white">Income Growth</p>
                    </div> */
                }
                {/**
                * Let's hide total donations if admin is not SUPER ADMIN or
                * masjid doesn't have donations in plain.
                */}
                {isSuperAdmin && HasDonationsInPlan && (
                    <div className="ml-5" style={{ textAlign: 'right' }}>
                        {/* <BarChart width={100} height={35} data={tinyChartData}>
                            <Bar type="monotone" dataKey="traffic" fill="#FFEA00" />
                        </BarChart> */}
                        <div className="h4 text-white">{totalDonations}</div>
                        <p className="small text-uppercase text-white text-nowrap">Total Donations</p>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const {
        masjid: {
            data: {
                TotalDonations
            }
        }
    } = state;
    return {
        totalDonations: TotalDonations || '$0.00',
        isSuperAdmin: hasSuperAdminRole(state),
        HasDonationsInPlan: hasDonationsInPlan(state)
    };
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    /**
     * Title for document's <head>. By default it uses the `title` prop.
     */
    headTitle: PropTypes.string,
    /**
     * @ignore
     */
    totalDonations: PropTypes.string.isRequired,
    /**
     * @ignore
     */
    isSuperAdmin: PropTypes.bool.isRequired,
    /**
     * @ignore
     */
    HasDonationsInPlan: PropTypes.bool.isRequired
};

PageHeader.defaultProps = {
    headTitle: ''
};

export default connect(mapStateToProps)(PageHeader);
