
import { returnResponse, externalClient, geoCodeClient } from './client';


const { get } = externalClient;

const getCountries = async () => returnResponse(
    await get('/countries')
);

const getStates = async (country) => returnResponse(
    await get('/states/', {
        country
    })
);

const getCities = async (state) => returnResponse(
    await get('/cities/by-state/', {
        state
    })
);


const getGeoCodeAddress = async (lat, long) => returnResponse(
    await geoCodeClient.get('reverse', {
        format: 'jsonv2',
        lat,
        lon: long
    })
);


export {
    getCountries,
    getStates,
    getCities,
    getGeoCodeAddress,
};
