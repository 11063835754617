import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
    InfoCard,
    Table
} from '../../shared/components';
import { DateUtils } from '../../utils';

function Registrations({ event: { Registrations: registrations, ...event } }) {
    function viewParticipant({ ProgramID, AttendeeID }) {
        browserHistory.push({
            pathname: `/events/${ProgramID}/participants/${AttendeeID}`,
            state: {
                attendeeID: AttendeeID,
                programID: ProgramID
            }
        });
    }
    function mapRegistration(registration) {
        return {
            ...registration,
            FirstName: registration.Attendee.FirstName,
            LastName: registration.Attendee.LastName,
            PhoneNumber: registration.Attendee.PhoneNumber,
            Email: registration.Attendee.Email,
            Age: registration.Attendee.Age,
            Gender: registration.Attendee.Gender,
            FathersName: registration.Attendee.FathersName || '-',
            MothersName: registration.Attendee.MothersName || '-',
            EmergencyContactName: registration.Attendee.EmergencyContactName || '-',
            EmergencyContactNumber: registration.Attendee.EmergencyContactNumber || '-'
        };
    }
    return (
        <InfoCard
            title="Registrations"
            subTitle="All the registrations made for this event."
            showFooter={false}
            containerClassName="col-md-12"
        >
            <Table
                title=""
                columns={[
                    { label: 'Name', key: 'Name' },
                    { label: 'Email', key: 'Email' },
                    { label: 'Phone Number', key: 'PhoneNumber' },
                    { label: 'Age', key: 'Age' },
                    { label: 'Gender', key: 'Gender' },
                    {
                        label: 'Enrolled At',
                        key: 'EnrolledAt',
                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                    },
                    { label: 'Unenrolled', key: 'Unenrolled' },
                    ...(event.FathersNameApplicable ? [{ label: 'Father\'s Name', key: 'FathersName' }] : []),
                    ...(event.MothersNameApplicable ? [{ label: 'Mother\'s Name', key: 'MothersName' }] : []),
                    ...(event.EmergencyContactNameApplicable ? [{ label: 'Emergency Contant Name', key: 'EmergencyContactName' }] : []),
                    ...(event.EmergencyContactNumberApplicable ? [{ label: 'Emergency Contant Number', key: 'EmergencyContactNumber' }] : [])
                ]}
                data={registrations.map(r => mapRegistration(r))}
                enableFilters={false}
                enableExports
                onRowClick={viewParticipant}
                containerClassName="mt-2"
            />
        </InfoCard>
    );
}

Registrations.propTypes = {
    event: PropTypes.shape({
        FathersNameApplicable: PropTypes.bool.isRequired,
        MothersNameApplicable: PropTypes.bool.isRequired,
        EmergencyContactNameApplicable: PropTypes.bool.isRequired,
        EmergencyContactNumberApplicable: PropTypes.bool.isRequired,
        Registrations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired
};

export default Registrations;
