
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Row,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal,
} from 'reactstrap';

import {
    PageHeader,
    Button,
    FormInput,
    InfoCard,
    Image
} from '../../shared/components';


import {
    showDialog,
    showDangerAlert,
    updateShowHideTvDisplaySettings,
    showSuccessAlert
} from '../../store/actions';
import { FileUtils, StringUtils } from '../../utils';


const TvDisplaySettings = ({
    showQuran, showHadith, dispatch, existingLogoUrl, showFlyer, hasTvDisplayAdvancedAddOn
}) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedLogo, setUpdatedLogo] = useState();
    const [editing, setEditing] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleShowQuranOrHadith = async ({ ShowQuran, ShowHadith }) => {
        const error = await
        dispatch(updateShowHideTvDisplaySettings({ ShowQuran, ShowHadith }));
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
    };

    const uploadLogoImage = async (image) => {
        setImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a announcement and
            // the old one gets overwritten.
            new File(
                [image],
                `flyer_image_${Math.floor(Math.random() * (100 - 1 + 1)) + 1}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            ), 'TvDisplayLogo'
        );
        if (error) {
            setImageUploading(false);
            // Upload has failed.
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The Flyer image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            // dispatch(showServerErrorAlert());
            return;
        }
        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for an Image.
        const logoImageUrl = `${finalUrl}?c=${new Date().getTime()}`;
        setUpdatedLogo(logoImageUrl);

        setImageUploading(false);
    };

    const handleLogoChange = (files) => {
        uploadLogoImage(files[0]);
    };
    const onSubmitLogoEdit = async () => {
        setLoading(true);

        if (!updatedLogo) {
            dispatch(showDangerAlert('Logo cannot be empty.'));
            setLoading(false);
            return;
        }
        // eslint-disable-next-line max-len
        const error = await dispatch(updateShowHideTvDisplaySettings({ TvDisplayMasjidLogo: updatedLogo }));
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        setShowModal(false);
        setLoading(false);
        dispatch(showSuccessAlert('Successfully updated tv display updatedLogo!'));
    };

    const handleShowHideFlyer = async (value) => {
        const error = await
        dispatch(updateShowHideTvDisplaySettings({ ShowFlyer: value }));
        if (error) {
            // The errors and success message have already been handled in the action creator.

        }
    };

    const onSubmitDelete = async () => {
        const confirm = await dispatch(showDialog(
            'Are you sure?',
            'Are you sure you want to delete Logo?'
        ));
        if (confirm) {
            setLoading(true);
            const error = await dispatch(updateShowHideTvDisplaySettings({ TvDisplayMasjidLogo: '' }));
            if (error) {
                // The errors and success message have already been handled in the action creator.

            } else {
                setUpdatedLogo();
                setEditing(false);
                dispatch(showSuccessAlert('Successfully deleted tv display logo!'));
                setShowModal(false);
                setLoading(false);
            }
        }
    };


    return (
        <div className="view">

            <PageHeader title="Tv Display Settings" description="Configure your tv display settings." />
            <div className="view-content view-dashboard">
                <Row>
                    {/* Configure Quran/Hadith */}

                    <div className="col-md-6">
                        <InfoCard
                            title="Quran/Hadith"
                            subTitle="Choose whether to display Quran verses or not "
                            showFooter={false}
                        >
                            <div className="col-md-12 d-flex">
                                <div className="form-check col-md-4">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="show quran"
                                        checked={!hasTvDisplayAdvancedAddOn ? true : showQuran}
                                        onChange={() => handleShowQuranOrHadith({ ShowQuran: true, ShowHadith: false })}
                                    />
                                    <label className="form-check-label" htmlFor="quran">
                                        Display Quran
                                    </label>
                                </div>

                                {/* Disabled temporary */}

                                {/* <div className="form-check col-md-4">
                                    <input className="form-check-input" type="radio"
                                    name="show hadith" disabled={!hasTvDisplayAdvancedAddOn}
                                    id="hadith"
                                    checked={hasTvDisplayAdvancedAddOn && showHadith}
                                    onChange={() =>
                                        handleShowQuranOrHadith(
                                            { ShowQuran: false, ShowHadith: true }
                                            )}
                                         />

                                    <label className="form-check-label" htmlFor="hadith">
                                        Display Hadith
                                    </label>

                                </div> */}
                                <div className="form-check col-md-4 ">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="show none"
                                        disabled={!hasTvDisplayAdvancedAddOn}
                                        checked={hasTvDisplayAdvancedAddOn && !showQuran && !showHadith}
                                        onChange={() => handleShowQuranOrHadith({ ShowQuran: false, ShowHadith: false })}
                                    />
                                    <label className="form-check-label" htmlFor="none" disabled={!hasTvDisplayAdvancedAddOn}>
                                        Display None
                                    </label>
                                </div>
                            </div>
                        </InfoCard>
                    </div>
                    {/* Configure Flyer */}

                    <div className="col-md-6">
                        <InfoCard
                            title="Slideshow"
                            subTitle="Choose whether to display slideshow"
                            showFooter={false}
                        >
                            <div className="col-md-12 d-flex">
                                <div className="form-check col-md-4">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="show flyer"
                                        checked={showFlyer}
                                        onChange={() => handleShowHideFlyer(true)}
                                    />
                                    <label className="form-check-label" htmlFor="quran">
                                        Show Slides
                                    </label>
                                </div>
                                <div className="form-check col-md-4 ">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="hide flyer"
                                        disabled={!hasTvDisplayAdvancedAddOn}
                                        checked={!showFlyer}
                                        onChange={() => handleShowHideFlyer(false)}
                                    />
                                    <label className="form-check-label" htmlFor="none" disabled={!hasTvDisplayAdvancedAddOn}>
                                        Show Iqama only
                                    </label>
                                </div>
                            </div>
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    {/* Configure Flyer */}

                    <div className="col-md-6">
                        <InfoCard
                            title="Logo"
                            // containerClassName="col-md-12"
                            subTitle="Change your Logo"
                            footerContent={<div />}
                            footerHref={`/categories/${2}`}
                            footerIsLink={false}
                        >
                            <div className="d-flex align-items-center flex-column">


                                {existingLogoUrl && hasTvDisplayAdvancedAddOn && (
                                    <img src={existingLogoUrl} className="img-thumbnail" alt="avatar" style={{ height: '100px', width: '100px', borderRadius: '50%' }} />
                                )}
                                <br />
                                <Button
                                    style={{ marginTop: '15px' }}
                                    onClick={() => {
                                        if (existingLogoUrl) {
                                            setEditing(true);
                                        }
                                        setShowModal(true);
                                    }}
                                >
                                    {existingLogoUrl ? 'Update Logo' : 'Upload Logo'}

                                </Button>

                            </div>
                        </InfoCard>
                    </div>
                </Row>
                <div>
                    <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)} className="default">
                        <ModalHeader toggle={() => setShowModal(!showModal)}>
                            {editing ? 'Edit Logo' : 'Upload Logo'}


                        </ModalHeader>
                        <ModalBody>

                            <div className="align-items-center text-center ">

                                {existingLogoUrl || updatedLogo
                                    ? (
                                        <Image
                                            title="Logo Image"
                                            url={updatedLogo || existingLogoUrl}
                                            uploading={loading}
                                            onImageSelected={handleLogoChange}
                                            label="Image"
                                        />
                                    ) : (
                                        <>
                                            {!editing && (
                                                <FormInput
                                                    value=""
                                                    onChangeValue={handleLogoChange}
                                                    type="file"
                                                    row
                                                    placeholder="Upload Logo"
                                                />

                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>

                            <Button loading={imageUploading || loading} color="primary" onClick={onSubmitLogoEdit} disabled={!existingLogoUrl && !updatedLogo}>{editing ? 'Update Logo' : 'Save'}</Button>

                            {editing && (
                                <Button color="danger" loading={loading} onClick={onSubmitDelete}>Delete Logo</Button>
                            )}
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

TvDisplaySettings.propTypes = {
    showHadith: PropTypes.bool.isRequired,
    showQuran: PropTypes.bool.isRequired,
    existingLogoUrl: PropTypes.string.isRequired,
    hasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    showFlyer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ masjid: { data } }) => ({
    masjidID: data.ID,
    showFlyer: data.TvDisplay.ShowFlyer,
    showHadith: data.TvDisplay.ShowHadith,
    showQuran: data.TvDisplay.ShowQuran,
    existingLogoUrl: data.TvDisplay.MasjidTvDisplayLogo,
    hasTvDisplayAdvancedAddOn: data.HasTvDisplayAdvancedAddOn
});

export default connect(mapStateToProps)(TvDisplaySettings);
