import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    Row
} from 'reactstrap';
import IconClose from 'react-icons/lib/md/close';
import {
    PageHeader,
    InfoCard,
    FormInput,
    Button
} from '../../shared/components';
import { DateUtils, FileUtils } from '../../utils';
import {
    showServerErrorAlert,
    addClassAsync,
    showDangerAlert
} from '../../store/actions';
import { ChargeFrequency } from './constants';

function AddIslamicClass({ masjid, dispatch }) {
    const [classImage, setClassImage] = useState(null);
    const [classImageName, setClassImageName] = useState('');
    const [loading, setLoading] = useState(false);
    const [fathersNameApplicable, setFathersNameApplicable] = useState(false);
    const [mothersNameApplicable, setMothersNameApplicable] = useState(false);
    const [fathersNameRequired, setFathersNameRequired] = useState(false);
    const [mothersNameRequired, setMothersNameRequired] = useState(false);
    const [emergencyContactNameApplicable, setEmergencyContactNameApplicable] = useState(false);
    const [emergencyContactNumberApplicable, setEmergencyContactNumberApplicable] = useState(false);
    const [emergencyContactNumberRequired, setEmergencyContactNumberRequired] = useState(false);
    const [emergencyContactNameRequired, setEmergencyContactNameRequired] = useState(false);

    const emptyClass = {
        Title: '',
        Description: '',
        Cost: '',
        RegistrationDeadline: '',
        TotalCapacity: '',
        StartDate: '',
        EndDate: '',
        ChargeFrequency: 'Onetime',
        HouseNumber: '',
        Street: '',
        City: '',
        Zipcode: '',
        State: '',
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: ''
        }]
    };
    const [invalidClass, setInvalidClass] = useState({
        ...emptyClass
    });
    const [newClass, setNewClass] = useState({
        ...emptyClass,
        Street: masjid.Street,
        City: masjid.City,
        Zipcode: masjid.Zipcode,
        State: masjid.State,
        HouseNumber: masjid.HouseNumber,
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: '1'
        }]
    });

    function goBack() {
        browserHistory.push('/classes/list');
    }

    async function saveClass() {
        let validationError = false;
        const newInvalidClass = {
            ...emptyClass,
            Days: Array(newClass.Days.length).fill({
                StartTime: '',
                EndTime: '',
                Day: ''
            })
        };

        for (const i in newClass.Days) {
            const day = newClass.Days[i];
            for (const [dayKey, dayValue] of Object.entries(day)) {
                if (!dayValue) {
                    newInvalidClass.Days = newInvalidClass.Days.map((invalidDay, index) => { //eslint-disable-line
                        if (+i === index) {
                            validationError = true;
                            return {
                                ...invalidDay,
                                [dayKey]: `${dayKey} can't be empty`
                            };
                        }
                        return day;
                    });
                }
            }
        }

        for (const key of Object.keys(emptyClass)) {
            if (!newClass[key]) {
                validationError = true;
                newInvalidClass[key] = `${key} can't be empty`;
            }
        }

        if (newClass.Cost > 0 && newClass.Cost <= 0.5) {
            validationError = true;
            newInvalidClass.Cost = 'Class cost cannot be less than 0.5. It can however be 0 in case of a free class.';
        }

        if (DateUtils.isBefore(newClass.StartDate, newClass.RegistrationDeadline, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidClass.StartDate = 'Start date cannot be equal to or before registration deadline.';
        }

        if (DateUtils.isAfter(newClass.StartDate, newClass.EndDate, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidClass.EndDate = 'Start date cannot be equal to or after end date.';
        }

        setInvalidClass(newInvalidClass);
        if (validationError) {
            dispatch(showDangerAlert('You have errors in the form above that need to be fixed before you can add a new class.'));
            return;
        }

        setLoading(true);
        let imageUrl = null;
        if (classImage) {
            // User has chosen an image.
            const { location, error } = await FileUtils.uploadFile(classImage, 'classImages');
            if (error) {
                setLoading(false);
                if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                    dispatch(showDangerAlert(`The class image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                    return;
                }
                // Upload has failed.
                dispatch(showServerErrorAlert());
                return;
            }
            const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
            imageUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        }

        const newClassWithTimeInMillis = {
            ...newClass,
            StartDate: DateUtils.format(newClass.StartDate, 'YYYY/MM/DD'),
            EndDate: DateUtils.format(newClass.EndDate, 'YYYY/MM/DD'),
            RegistrationDeadline: DateUtils.format(newClass.RegistrationDeadline, 'YYYY/MM/DD'),
            ImageUrl: imageUrl,
            FathersNameRequired: fathersNameRequired,
            MothersNameRequired: mothersNameRequired,
            FathersNameApplicable: fathersNameApplicable,
            MothersNameApplicable: mothersNameApplicable,
            EmergencyContactNameRequired: emergencyContactNameRequired,
            EmergencyContactNumberRequired: emergencyContactNumberRequired,
            EmergencyContactNameApplicable: emergencyContactNameApplicable,
            EmergencyContactNumberApplicable: emergencyContactNumberApplicable
        };


        const error = await dispatch(addClassAsync(newClassWithTimeInMillis));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        browserHistory.push('/classes/list');
    }

    function handleClassPropertyChange(column) {
        return function (value) {
            setNewClass({ ...newClass, [column]: value });
        };
    }

    function handleDayPropertyChange(column, index) {
        return function (value) {
            setNewClass({
                ...newClass,
                Days: newClass.Days.map((day, dayIndex) => {
                    if (dayIndex === index) {
                        return { ...day, [column]: value };
                    }
                    return day;
                })
            });
        };
    }

    function addDayToClass() {
        setNewClass({
            ...newClass,
            // Fill it with the next available label.
            Days: [...newClass.Days,
                {
                    StartTime: newClass.Days[0].StartTime,
                    EndTime: newClass.Days[0].EndTime,
                    Day: getDayLabels(null)[0].value
                }
            ]
        });
        setInvalidClass({
            ...invalidClass,
            Days: [...invalidClass.Days, { StartTime: '', EndTime: '', Day: '' }]
        });
    }

    function handleClassImageChange() {
        return function (files) {
            setClassImageName(files[0].name);
            setClassImage(files[0]);
        };
    }

    function removeDay(index) {
        return function () {
            if (index === 0) {
                // Don't let the user remove the first day.
                dispatch(showDangerAlert('A class has to be for atleast a single day.'));
                return;
            }
            setNewClass({
                ...newClass,
                Days: newClass.Days.filter((_, i) => i !== index)
            });
            setInvalidClass({
                ...invalidClass,
                Days: newClass.Days.filter((_, i) => i !== index)
            });
        };
    }

    // Get dropdown options for days excluding the ones that have been set currently.
    // Deal in strings only.
    function getDayLabels(currentDay) {
        const daysInClass = newClass.Days.map(({ Day }) => `${Day}`);
        return DateUtils.getDaysAsLabelValuePairs()
            .filter(({ value }) => value === `${currentDay}` || !daysInClass.includes(value));
    }

    function canAddAnotherDay() {
        return newClass.Days.length < 7;
    }
    function setFathersNameApplicability(value) {
        setFathersNameApplicable(value);
        if (value === false) {
            setFathersNameRequired(false);
        }
    }

    function setMothersNameApplicability(value) {
        setMothersNameApplicable(value);
        if (value === false) {
            setMothersNameRequired(false);
        }
    }

    function setEmergencyContactNameApplicability(value) {
        setEmergencyContactNameApplicable(value);
        if (value === false) {
            setEmergencyContactNameRequired(false);
        }
    }

    function setEmergencyContactNumberApplicability(value) {
        setEmergencyContactNumberApplicable(value);
        if (value === false) {
            setEmergencyContactNumberRequired(false);
        }
    }

    return (
        <div className="view">
            <PageHeader title="Add New Class" description="Let's add a new class which people can sign up for." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Class Information"
                    subTitle="Add class details here."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-6 col-offset-md-6">
                            <FormInput
                                value={newClass.Title}
                                onChangeValue={handleClassPropertyChange('Title')}
                                label="Title"
                                message={invalidClass.Title}
                                state={!invalidClass.Title ? 'default' : 'danger'}
                                maxLength={30}
                            />
                            <FormInput
                                value={newClass.Description}
                                onChangeValue={handleClassPropertyChange('Description')}
                                label="Description"
                                type="textarea"
                                maxLength={200}
                                message={invalidClass.Description}
                                state={!invalidClass.Description ? 'default' : 'danger'}
                            />
                            <FormInput
                                value={newClass.ChargeFrequency}
                                onChangeValue={handleClassPropertyChange('ChargeFrequency')}
                                label="Charge Frequency"
                                options={[
                                    {
                                        label: ChargeFrequency.Onetime,
                                        value: ChargeFrequency.Onetime
                                    },
                                    {
                                        label: ChargeFrequency.Monthly,
                                        value: ChargeFrequency.Monthly
                                    },
                                    {
                                        label: ChargeFrequency.Yearly,
                                        value: ChargeFrequency.Yearly
                                    }
                                ]}
                                type="select"
                            />
                            <FormInput
                                value={newClass.Cost}
                                onChangeValue={handleClassPropertyChange('Cost')}
                                label="Cost"
                                type="number"
                                min={0}
                                message={invalidClass.Cost}
                                state={!invalidClass.Cost ? 'default' : 'danger'}
                            />
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newClass.RegistrationDeadline}
                                        onChangeValue={handleClassPropertyChange('RegistrationDeadline')}
                                        label="Registration Deadline"
                                        type="date"
                                        message={invalidClass.RegistrationDeadline}
                                        state={!invalidClass.RegistrationDeadline ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newClass.StartDate}
                                        onChangeValue={handleClassPropertyChange('StartDate')}
                                        label="Start Date"
                                        type="date"
                                        message={invalidClass.StartDate}
                                        state={!invalidClass.StartDate ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newClass.EndDate}
                                        onChangeValue={handleClassPropertyChange('EndDate')}
                                        label="End Date"
                                        type="date"
                                        message={invalidClass.EndDate}
                                        state={!invalidClass.EndDate ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>

                            <FormInput
                                value={newClass.HouseNumber}
                                onChangeValue={handleClassPropertyChange('HouseNumber')}
                                label="House No"
                                placeholder="HouseNumber"
                                message={invalidClass.HouseNumber}
                                state={!invalidClass.HouseNumber ? 'default' : 'danger'}
                                maxLength={10}
                            />
                            <FormInput
                                value={newClass.Street}
                                onChangeValue={handleClassPropertyChange('Street')}
                                label="Street"
                                placeholder="Street"
                                message={invalidClass.Street}
                                state={!invalidClass.Street ? 'default' : 'danger'}
                                maxLength={50}
                            />
                            <FormInput
                                value={newClass.City}
                                onChangeValue={handleClassPropertyChange('City')}
                                label="City"
                                placeholder="City"
                                message={invalidClass.City}
                                state={!invalidClass.City ? 'default' : 'danger'}
                                maxLength={25}
                            />
                            <FormInput
                                value={newClass.State}
                                onChangeValue={handleClassPropertyChange('State')}
                                label="State"
                                placeholder="State"
                                message={invalidClass.State}
                                state={!invalidClass.State ? 'default' : 'danger'}
                                maxLength={25}
                            />
                            <FormInput
                                value={newClass.Zipcode}
                                onChangeValue={handleClassPropertyChange('Zipcode')}
                                label="Zipcode"
                                placeholder="Zipcode"
                                message={invalidClass.Zipcode}
                                state={!invalidClass.Zipcode ? 'default' : 'danger'}
                                maxLength={10}
                            />
                            <FormInput
                                value={newClass.TotalCapacity}
                                onChangeValue={handleClassPropertyChange('TotalCapacity')}
                                label="Capacity"
                                type="number"
                                message={invalidClass.TotalCapacity}
                                state={!invalidClass.TotalCapacity ? 'default' : 'danger'}
                                placeholder="Total Capacity"
                            />
                            <FormInput
                                value=""
                                onChangeValue={handleClassImageChange()}
                                label="Image Url"
                                type="file"
                                size="sm"
                                placeholder={`${classImageName}`}
                            />
                        </div>
                    </Row>
                </InfoCard>
                <InfoCard
                    title="Attendee Information"
                    subTitle="Control what information an attendee should provide."
                    showFooter={false}
                >

                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Father's Name&nbsp;&nbsp;"
                                type="checkbox"
                                value={fathersNameApplicable}
                                onChangeValue={setFathersNameApplicability}
                            />
                        </div>
                        {fathersNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Father's Name is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!fathersNameApplicable}
                                    value={fathersNameRequired}
                                    onChangeValue={setFathersNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Mother's Name .&nbsp;&nbsp;"
                                type="checkbox"
                                value={mothersNameApplicable}
                                onChangeValue={setMothersNameApplicability}
                            />
                        </div>
                        {mothersNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Mother's Name is  Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!mothersNameApplicable}
                                    value={mothersNameRequired}
                                    onChangeValue={setMothersNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Emergency Contact Name .&nbsp;&nbsp;"
                                type="checkbox"
                                value={emergencyContactNameApplicable}
                                onChangeValue={setEmergencyContactNameApplicability}
                            />
                        </div>
                        {emergencyContactNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Emergency Contact Name is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!emergencyContactNameApplicable}
                                    value={emergencyContactNameRequired}
                                    onChangeValue={setEmergencyContactNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Emergency Contact Number&nbsp;&nbsp;"
                                type="checkbox"
                                value={emergencyContactNumberApplicable}
                                onChangeValue={setEmergencyContactNumberApplicability}
                            />
                        </div>
                        {emergencyContactNumberApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Emergency Contact Number is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!emergencyContactNumberApplicable}
                                    value={emergencyContactNumberRequired}
                                    onChangeValue={setEmergencyContactNumberRequired}
                                />
                            </div>
                        )}
                    </Row>
                </InfoCard>
                <InfoCard
                    title="Days"
                    subTitle="Fill in days of the week this class will take place."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-6 col-offset-md-6">
                            {newClass.Days.map((day, index) => (
                                <div key={`${day.Day}`}>
                                    <Row>
                                        <div className="col-md-12">
                                            <span className="text-primary">
                                                {' '}
                                                <IconClose
                                                    size={20}
                                                    className="my-3 float-right"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={removeDay(index)}
                                                />
                                                {' '}
                                            </span>
                                        </div>
                                    </Row>
                                    <FormInput
                                        value={day.Day}
                                        onChangeValue={handleDayPropertyChange('Day', index)}
                                        row
                                        label="Day"
                                        options={getDayLabels(day.Day)}
                                        type="select"
                                    />
                                    <FormInput
                                        value={day.StartTime}
                                        onChangeValue={handleDayPropertyChange('StartTime', index)}
                                        row
                                        label="Start Time"
                                        type="time"
                                        message={invalidClass.Days[index] ? invalidClass.Days[index].StartTime : ''}
                                        state={!(invalidClass.Days[index] && invalidClass.Days[index].StartTime) ? 'default' : 'danger'}
                                    />
                                    <FormInput
                                        value={day.EndTime}
                                        onChangeValue={handleDayPropertyChange('EndTime', index)}
                                        row
                                        label="End Time"
                                        type="time"
                                        message={invalidClass.Days[index] ? invalidClass.Days[index].EndTime : ''}
                                        state={!(invalidClass.Days[index] && invalidClass.Days[index].EndTime) ? 'default' : 'danger'}
                                    />
                                    <hr />
                                </div>
                            ))}
                            <Button
                                color="primary"
                                onClick={addDayToClass}
                                disabled={!canAddAnotherDay()}
                            >
                                Add Another Day
                            </Button>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className="col-md-12 d-flex justify-content-between">
                            <Button color="secondary" disabled={loading} onClick={goBack}>Cancel</Button>
                            <Button loading={loading} color="primary" onClick={saveClass}>Add Class</Button>
                        </div>
                    </Row>
                </InfoCard>
            </div>
        </div>
    );
}

AddIslamicClass.propTypes = {
    dispatch: PropTypes.func.isRequired,
    masjid: PropTypes.shape({}).isRequired
};
const mapStateToProps = ({ masjid: { data } }) => ({
    masjid: data
});

export default connect(mapStateToProps)(AddIslamicClass);
