import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
    PieChart,
    Pie,
    Legend,
    Cell,
    Sector,
    ResponsiveContainer
} from 'recharts';
import {
    Card,
    CardBody,
    CardTitle,
    Row
} from 'reactstrap';
import IconDot from 'react-icons/lib/md/fiber-manual-record';
import {
    getLatestDonations,
    hasDonationsInPlan,
    hasRecurringDonationsInPlan,
    hasClassRegistrationInPlan,
    hasEventRegistrationInPlan,
    hasSuperAdminRole,
    hasTeacherRole
} from '../../store/selectors';
import { InfoCard, Button } from '../../shared/components';
import { DonationUtils } from '../../utils';
import PerformActionsAlert from './PerformActionsAlert';

const getColorForDonationPlatform = platform => {
    switch (platform) {
    case DonationUtils.platforms.KIOSK:
        return '#448AFF';
    case DonationUtils.platforms.ONLINE:
        return '#F6FF00';
    case DonationUtils.platforms.POS:
        return '#EA546d';
    default:
        return '#00E676';
    }
};

const trafficSourceColors = [
    getColorForDonationPlatform(DonationUtils.platforms.KIOSK),
    getColorForDonationPlatform(DonationUtils.platforms.ANDROID),
    getColorForDonationPlatform(DonationUtils.platforms.ONLINE),
    getColorForDonationPlatform(DonationUtils.platforms.POS)
];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent // eslint-disable-line
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize={12}>
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

class DonationsPieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }

    render() {
        const { activeIndex } = this.state;
        const { data } = this.props;
        return (
            <ResponsiveContainer className="pie-chart-container">
                <PieChart>
                    <Pie
                        onMouseEnter={this.onPieEnter}
                        data={data}
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        outerRadius={90}
                        dataKey="value"
                        innerRadius={70}
                        paddingAngle={4}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                fill={trafficSourceColors[index]}
                                key={entry.name}
                            />
                        ))}
                    </Pie>
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

DonationsPieChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })).isRequired
};

const TransactionTable = ({ data }) => (
    <table className="table">

        <tbody>
            {data.map(item => (
                <tr key={item.id}>
                    <td className="d-flex flex-column">
                        <strong>{item.name}</strong>
                        <small>{item.date}</small>
                    </td>
                    <td className="align-middle">{item.amount}</td>
                    <td className="align-middle text-nowrap">
                        <IconDot size="12" color={getColorForDonationPlatform(item.donationPlatform)} />
                        &nbsp;
                        {`By ${item.donationPlatform}`}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);


TransactionTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

function ViewContent({
    masjid,
    latestDonations,
    isSuperAdmin,
    isSuperAdminOrTeacher,
    HasDonationsInPlan,
    HasRecurringDonationsInPlan,
    HasClassRegistrationInPlan,
    HasEventRegistrationInPlan,
}) {
    const {
        SubscriptionState,
        HasKioskBase,
        HasAppBase,
        HasPOSBase,
        HasAppDonationsAddOn,
        HasOnlineDonationsAddOn,
        TotalActiveMonthlyRecurringDonationsAmount,
        TotalActiveMonthlyRecurringDonations,
        TotalMonthlyRecurringDonations,
        TotalDonations,
        TotalDonationsFromKiosk,
        TotalDonationsFromApp,
        TotalDonationsFromOnline,
        TotalDonationsFromPOS,
        CategoriesCount,
        AdminsCount,
        GroupsCount,
        MembersCount,
        KiosksCount,
        POSDevicesCount,
        NotificationsCount,
        // EventsCount,
        AnnouncementCount,
        DonationRequestCount,
        Classes,
        Events
    } = masjid;

    const TotalDonationsFromAppNumber = TotalDonationsFromApp.replace(/[$|,]/g, '') - 0;
    const TotalDonationsFromKioskNumber = TotalDonationsFromKiosk.replace(/[$|,]/g, '') - 0;
    const TotalDonationsFromOnlineNumber = TotalDonationsFromOnline.replace(/[$|,]/g, '') - 0;
    const TotalDonationsFromPOSNumber = TotalDonationsFromPOS.replace(/[$|,]/g, '') - 0;
    const TotalDonationsNumber = TotalDonationsFromAppNumber
        + TotalDonationsFromKioskNumber + TotalDonationsFromOnlineNumber
        + TotalDonationsFromPOSNumber;
    const pieChartData = [{
        name: 'Kiosk',
        // Basically if both the donations are 0,
        // keep 50 for both so the chart doesn't appear empty.
        value: (TotalDonationsNumber
            ? TotalDonationsFromKioskNumber
            : 25.00)
    }, {
        name: 'App',
        value: (TotalDonationsNumber
            ? TotalDonationsFromAppNumber
            : 25.00)
    }, {
        name: 'Online',
        value: (TotalDonationsNumber
            ? TotalDonationsFromOnlineNumber
            : 25.00)
    }, {
        name: 'POS',
        value: (TotalDonationsNumber
            ? TotalDonationsFromPOSNumber
            : 25.00)
    }];

    function getMasjidState() {
        switch (SubscriptionState) {
        case 'ACTIVE':
            return 'Active';
        case 'NON_ACTIVE':
            return 'Not Active';
        case 'GRACE_PERIOD':
            return 'Payment Failed';
        case 'UNSUBSCRIBED':
            return 'Unsubscribed';
        case 'PENDING_UNSUBSCRIBED':
            return 'Scheduled to Cancel';
        default:
            return 'NA';
        }
    }

    function renderSalatCards() {
        return (
            <Fragment>
                <InfoCard
                    title="Salat Timings"
                    subTitle="See your daily timings"
                    footerContent="Fill Schedule"
                    footerHref="/salat/daily"
                />
                <InfoCard
                    title="Jummah Salat Timings"
                    subTitle="See your jummah timings"
                    footerContent="Fill Schedule"
                    footerHref="/salat/jummah"
                />
            </Fragment>

        );
    }

    return (
        <div className="view-content view-dashboard">
            {isSuperAdmin && <PerformActionsAlert />}
            <Row className="mb-4">
                {/* blocks */}
                {!isSuperAdmin && (
                    <div className="col-sm-6 col-md-3">
                        {renderSalatCards()}
                    </div>
                )}
                <div className="col-sm-6 col-md-3">
                    <InfoCard
                        title="Masjid Status"
                        subTitle="The status of Masjid"
                        footerContent={getMasjidState()}
                        footerIsLink={false}
                    />
                    {isSuperAdmin && renderSalatCards()}
                </div>
                {isSuperAdmin && (
                    <div className="col-sm-6 col-md-3">
                        <InfoCard
                            title="Kiosk Donations"
                            subTitle={HasKioskBase ? 'Enabled' : 'Disabled'}
                            footerContent="Change Plan"
                            footerHref="/account"
                            locked={!HasKioskBase}
                        />
                        <InfoCard
                            title="App Donations"
                            subTitle={HasAppDonationsAddOn ? 'Enabled' : 'Disabled'}
                            footerContent="Change Plan"
                            footerHref="/account"
                            locked={!HasAppDonationsAddOn}
                        />
                        <InfoCard
                            title="Online Donations"
                            subTitle={HasOnlineDonationsAddOn ? 'Enabled' : 'Disabled'}
                            footerContent="Change Plan"
                            footerHref="/account"
                            locked={!HasOnlineDonationsAddOn}
                        />

                    </div>
                )}
                {/* Donation Pie */}
                {isSuperAdmin && (
                    <div className="mb-4 col-sm-12 col-md-6">
                        <Card>
                            <CardBody className={!HasDonationsInPlan ? 'locked-section' : ''}>
                                <CardTitle className="text-uppercase h6">
                                    Donation Sources
                                </CardTitle>
                                <div style={{ width: '100%', height: '276px' }}>
                                    <DonationsPieChart data={pieChartData} />
                                </div>
                            </CardBody>
                        </Card>
                        <Row>
                            <InfoCard
                                containerClassName="mt-4 col-md-6"
                                title="POS Donations"
                                subTitle={HasPOSBase ? 'Enabled' : 'Disabled'}
                                footerContent="Change Plan"
                                footerHref="/account"
                                locked={!HasPOSBase}
                            />
                        </Row>
                    </div>

                )}
            </Row>
            {isSuperAdmin && <div className="h5 mb-4">Recurring Donations</div>}
            {isSuperAdmin && (
                <Row className="mb-4">
                    <InfoCard
                        title="Recurring Monthly (Active)"
                        subTitle={TotalActiveMonthlyRecurringDonationsAmount}
                        footerHref="/recurring-donations/list"
                        containerClassName="col-md-4"
                        locked={!HasRecurringDonationsInPlan}
                    />
                    <InfoCard
                        title="Recurring Donations (Active)"
                        subTitle={TotalActiveMonthlyRecurringDonations}
                        footerHref="/recurring-donations/list"
                        containerClassName="col-md-4"
                        locked={!HasRecurringDonationsInPlan}
                    />
                    <InfoCard
                        title="Recurring Donations (Total)"
                        subTitle={TotalMonthlyRecurringDonations}
                        footerHref="/recurring-donations/list"
                        containerClassName="col-md-4"
                        locked={!HasRecurringDonationsInPlan}
                    />
                </Row>
            )}

            {isSuperAdmin && <div className="h5 mb-4">Total Donations</div>}

            {isSuperAdmin && (
                <Row className="mb-4">
                    <InfoCard
                        title="Total Donations"
                        subTitle={TotalDonations}
                        footerHref="/donations/list"
                        containerClassName="col-md-3"
                        locked={!HasDonationsInPlan}
                    />
                    <InfoCard
                        title="Kiosk Donations"
                        subTitle={TotalDonationsFromKiosk}
                        footerHref="/donations/list"
                        containerClassName="col-md-3"
                        locked={!HasKioskBase}
                    />
                    <InfoCard
                        title="App Donations"
                        subTitle={TotalDonationsFromApp}
                        footerHref="/donations/list"
                        containerClassName="col-md-3"
                        locked={!HasAppDonationsAddOn}
                    />
                    <InfoCard
                        title="Online Donations"
                        subTitle={TotalDonationsFromOnline}
                        footerHref="/donations/list"
                        containerClassName="col-md-3"
                        locked={!HasOnlineDonationsAddOn}
                    />
                    <InfoCard
                        title="POS Donations"
                        subTitle={TotalDonationsFromPOS}
                        footerHref="/donations/list"
                        containerClassName="col-md-3"
                        locked={!HasPOSBase}
                    />
                    <InfoCard
                        title="Donation Categories"
                        subTitle={CategoriesCount}
                        footerHref="/categories/list"
                        containerClassName="col-md-3"
                        locked={!HasDonationsInPlan}
                    />
                </Row>
            )}

            {isSuperAdminOrTeacher && <div className="h5 mb-4">Programs</div>}

            {isSuperAdminOrTeacher && (
                <Row>
                    <InfoCard
                        title="Classes"
                        subTitle={Classes.length}
                        footerHref="/classes/list"
                        containerClassName="col-md-3"
                        locked={!HasClassRegistrationInPlan}
                    />
                    <InfoCard
                        title="Events"
                        subTitle={Events.length}
                        footerHref="/events/list"
                        containerClassName="col-md-3"
                        locked={!HasEventRegistrationInPlan}
                    />
                </Row>
            )}

            <div className="h5 mb-4">Administration</div>

            {isSuperAdmin && (
                <Row>
                    <InfoCard
                        title="Admins"
                        subTitle={AdminsCount}
                        footerHref="/admins/list"
                        containerClassName="col-md-3"
                    />
                    <InfoCard
                        title="Groups"
                        subTitle={GroupsCount}
                        footerHref="/groups/list"
                        containerClassName="col-md-3"
                        locked={!HasAppBase}
                    />
                    <InfoCard
                        title="Members"
                        subTitle={MembersCount}
                        footerHref="#"
                        containerClassName="col-md-3"
                        locked={!HasAppBase}
                        footerIsLink={false}
                        footerContent="Members"
                    />
                    <InfoCard
                        title="Kiosks"
                        subTitle={KiosksCount}
                        footerHref="/kiosks/list"
                        containerClassName="col-md-3"
                        locked={!HasKioskBase}
                    />

                </Row>
            )}

            <Row className="mb-4">

                {isSuperAdmin && (
                    <InfoCard
                        title="POS Devices"
                        subTitle={POSDevicesCount}
                        footerHref="/pos/list"
                        containerClassName="col-md-3"
                        locked={!HasPOSBase}
                    />
                )}
                <InfoCard
                    title="Notifications Sent"
                    subTitle={NotificationsCount}
                    footerHref="/announcements/list"
                    containerClassName="col-md-3"
                    locked={!HasAppBase}
                />
                {/* <InfoCard
                    title="Events"
                    subTitle={EventsCount}
                    footerHref="/events/list"
                    containerClassName="col-md-3"
                    locked={!HasAppBase}
                /> */}
                <InfoCard
                    title="Announcements"
                    subTitle={AnnouncementCount}
                    footerHref="/announcements/list"
                    containerClassName="col-md-3"
                    locked={!HasAppBase}
                />
                <InfoCard
                    title="Donation Requests"
                    subTitle={DonationRequestCount}
                    footerHref="/donation-requests/list"
                    containerClassName="col-md-3"
                    locked={!HasAppDonationsAddOn}
                />
            </Row>

            {isSuperAdmin && (
                <Row>
                    {/* Latest Donations */}
                    <div className="col-md-12 mb-4">
                        <Card>
                            <CardBody className={!HasDonationsInPlan ? 'locked-section' : ''}>
                                <CardTitle className="text-uppercase h6">Latest Donations</CardTitle>
                                <div className="small mb-4 card-subtitle">
                                    <Link to="/donations/list">
                                        <Button size="sm" onClick={() => { }}>
                                            View all
                                        </Button>
                                    </Link>
                                </div>
                                {latestDonations.length
                                    ? <TransactionTable data={latestDonations} />
                                    : <div className="h6">No donations available.</div>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            )}
        </div>
    );
}

ViewContent.propTypes = {
    masjid: PropTypes.shape({}).isRequired,
    isSuperAdmin: PropTypes.bool.isRequired,
    isSuperAdminOrTeacher: PropTypes.bool.isRequired,
    HasDonationsInPlan: PropTypes.bool.isRequired,
    HasRecurringDonationsInPlan: PropTypes.bool.isRequired,
    HasClassRegistrationInPlan: PropTypes.bool.isRequired,
    HasEventRegistrationInPlan: PropTypes.bool.isRequired,
    latestDonations: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
    masjid: state.masjid.data,
    isSuperAdmin: hasSuperAdminRole(state),
    isSuperAdminOrTeacher: hasSuperAdminRole(state) || hasTeacherRole(state),
    HasDonationsInPlan: hasDonationsInPlan(state),
    HasRecurringDonationsInPlan: hasRecurringDonationsInPlan(state),
    HasClassRegistrationInPlan: hasClassRegistrationInPlan(state),
    HasEventRegistrationInPlan: hasEventRegistrationInPlan(state),
    latestDonations: getLatestDonations(state)
});


export default connect(mapStateToProps)(ViewContent);
