import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row,
} from 'reactstrap';
import IconTablet from 'react-icons/lib/fa/tablet';
import Select from 'react-select';
import {
    PageHeader,
    InfoCard,
    Button,
    PaymentWidget
} from '../../shared/components';
import { StringUtils, PaymentUtils } from '../../utils';
import { MasjidAPI, Constants } from '../../api';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    showDialog,
    purchasePOSDevices
} from '../../store/actions';


function PurchasePOS({ dispatch }) {
    const [inventory, setInventory] = useState({
        POSPrice: '',
        NumberOfPOSAvailable: 0,
    });
    const [inventoryLoading, setInventoryLoading] = useState(true);

    const [processingPayment, setProcessingPayment] = useState(false);

    const [cart, setCart] = useState({
        pos: 0,
    });

    function setCartValue() {
        return function ({ value }) {
            setCart({
                ...cart,
                pos: value
            });
        };
    }

    const [showPaymentWidget, setShowPaymentWidget] = useState(false);

    function closePaymentWidget() {
        setShowPaymentWidget(false);
    }

    async function onPayment(token) {
        setProcessingPayment(true);
        const error = await dispatch(purchasePOSDevices(
            token,
            cart.pos,
        ));
        setProcessingPayment(false);
        if (error) {
            // Error handling has already been taken care of in the action creator.
            return;
        }
        // Push to pos list screen so the admin can see the new pos devices.
        browserHistory.push('/pos/list');
    }

    async function checkout() {
        if (!getTotal()) {
            dispatch(showDangerAlert('Cannot checkout with zero pos devices. Please select some and try again.'));
            return;
        }
        const confirm = await dispatch(showDialog(
            'Are you sure?',
            `Are you sure you want to checkout? The total costs of your items is ${StringUtils.formatAmount(getTotal(), false)}.`
        ));
        if (confirm) {
            setShowPaymentWidget(true);
        }
    }

    useEffect(() => {
        loadInventory();
    }, []);

    async function loadInventory() {
        const { error, data } = await MasjidAPI.getInventory();
        if (error) {
            // Ignoring CLIENT_ERROR as we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        setInventory(data);
        setInventoryLoading(false);
    }

    function getQuantityOptions(number) {
        return Array(number + 1).fill(null).map((_, index) => ({ value: index, label: `${index}` }));
    }

    function getTotal() {
        const {
            pos,
        } = cart;
        const {
            POSPrice,
        } = inventory;
        return (pos * POSPrice);
    }

    return (
        <div className="view">
            <PageHeader title="Purchase POS" description="You can purchase more pos devices from here." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="POS Devices"
                            subTitle="Choose one or more pos that you want to add to your existing pos fleet by choosing a quantity from the dropdown."
                            showFooter={false}
                        >
                            <Row className="mt-4">
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={50} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            POS
                                        </span>
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.POSPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfPOSAvailable
                                                - cart.pos}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfPOSAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue()}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>

                            </Row>
                            <div>
                                <br />
                                Note:
                                <br />
                                All the pos shown above and come with the following items.
                                <br />
                                • Pre Installed Software.
                                <br />
                            </div>
                            <Row className="checkout-container mt-5 mb-4">
                                <div className="col-md-6 h6">
                                    Total:
                                    {' '}
                                    {StringUtils.formatAmount(getTotal(), false)}
                                </div>
                                <div className="col-md-6 text-right">
                                    <Button
                                        disabled={inventoryLoading}
                                        loading={processingPayment}
                                        onClick={checkout}
                                    >
                                        Checkout
                                    </Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>
            <PaymentWidget
                show={showPaymentWidget}
                onClose={closePaymentWidget}
                onPayment={onPayment}
                totalAmount={StringUtils.formatAmount(getTotal(), false)}
                apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
            />
        </div>
    );
}

PurchasePOS.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect()(PurchasePOS);
