import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Row
} from 'reactstrap';
import { browserHistory } from 'react-router';
import {
    PageHeader,
    Table
} from '../../shared/components';
import { getRecurringDonations } from '../../store/selectors';
import { DateUtils } from '../../utils';

function RecurringDonationsList({ categories, recurringDonations }) {
    function viewDonation(donation) {
        browserHistory.push({ pathname: `/recurring-donations/${donation.id}`, state: donation.id });
    }

    return (
        <div className="view">
            <PageHeader title="Recurring Donations" description="Here are all your recurring donations." />
            <div className="view-content view-dashboard">
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Table
                                title="Recurring Donations"
                                columns={[
                                    { label: 'Name', key: 'name' },
                                    { label: 'Category', key: 'categoryName' },
                                    { label: 'Amount', key: 'amount' },
                                    {
                                        label: 'Total Months',
                                        key: 'monthsRecurring',
                                        sortFunction: (m1, m2) => m1 - m2
                                    },
                                    {
                                        label: 'Date',
                                        key: 'date',
                                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                    },
                                    {
                                        label: 'Is Cancelled',
                                        key: 'recurringCancelled',
                                        render: (recurringCancelled) => (recurringCancelled ? 'Yes' : 'No')
                                    }
                                ]}
                                data={recurringDonations}
                                filters={[
                                    {
                                        label: 'Name',
                                        key: 'name'
                                    },
                                    {
                                        label: 'Category',
                                        key: 'categoryName',
                                        filterInputType: 'select',
                                        filterSelectOptions: ['All'].concat(categories.map(c => c.CategoryName)),
                                        filterFunc(categoryName, chosenCategoryName) {
                                            return chosenCategoryName === 'All' || chosenCategoryName === categoryName;
                                        }
                                    },
                                    {
                                        label: 'From',
                                        key: 'date',
                                        filterInputType: 'date',
                                        filterFunc(date, chosenDate) {
                                            return DateUtils.isBefore(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                        }
                                    },
                                    {
                                        label: 'To',
                                        key: 'date',
                                        filterInputType: 'date',
                                        filterFunc(date, chosenDate) {
                                            return DateUtils.isAfter(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                        }
                                    },
                                    {
                                        label: 'Is Cancelled',
                                        key: 'recurringCancelled',
                                        filterInputType: 'select',
                                        filterSelectOptions: ['Doesn\'t Matter', 'Yes', 'No'],
                                        filterFunc(recurringCancelled, recurringCancelledChosen) {
                                            return recurringCancelledChosen === 'Doesn\'t Matter' || (recurringCancelledChosen === 'Yes' && recurringCancelled) || (recurringCancelledChosen === 'No' && !recurringCancelled);
                                        }
                                    }
                                ]}
                                onRowClick={viewDonation}
                                defaultSortColumnKey="date"
                            />
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </div>
    );
}

RecurringDonationsList.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
        CategoryName: PropTypes.string.isRequired,
        ID: PropTypes.number.isRequired
    })).isRequired,
    recurringDonations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        stripeID: PropTypes.string,
        date: PropTypes.string.isRequired,
        recurringCancelled: PropTypes.bool.isRequired,
    })).isRequired
};

const mapStateToProps = state => ({
    categories: state.masjid.data.Categories,
    recurringDonations: getRecurringDonations(state)
});

export default connect(mapStateToProps)(RecurringDonationsList);
