import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import {
    PageHeader,
    InfoCard,
    DescriptionList,
    Button,
    FormInput
} from '../../shared/components';
import { updateGroupAsync } from '../../store/actions';

function Group({
    dispatch, group: {
        Name, GroupCode, ID, Description, MasjidID
    }
}) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [invalidName, setInvalidName] = useState('');
    const [invalidGroupCode, setInvalidGroupCode] = useState('');
    const [currentGroup, setcurrentGroup] = useState({
        Name,
        GroupCode,
        ID,
        Description: Description || '',
        MasjidID
    });

    function showModal() {
        setModal(true);
    }

    function hideModal() {
        setModal(false);
    }

    function showGroupUpdateModal() {
        showModal();
    }

    function handleAdminPropertyChange(column) {
        return function (value) {
            setcurrentGroup({ ...currentGroup, [column]: value });
        };
    }

    async function updateGroup() {
        setInvalidName('');
        setInvalidGroupCode('');

        if (!currentGroup.Name) {
            setInvalidName('Name can\'t be empty');
            return;
        }
        if (!currentGroup.GroupCode) {
            setInvalidGroupCode('Group Code can\'t be empty');
            return;
        }
        const transformedGroup = {
            ...currentGroup,
            GroupCode: currentGroup.GroupCode.toUpperCase()
        };
        setLoading(true);
        const error = await dispatch(updateGroupAsync(transformedGroup));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Group" description="Here is one of your groups." />
                <div className="view-content">
                    <Row>
                        <div className="col-md-12">
                            <InfoCard
                                title="Group Infomation"
                                subTitle="More about this Group."
                                showFooter={false}
                                showButtonInHeader
                                buttonInHeaderLabel="Edit Group"
                                onClickButtonInHeader={showGroupUpdateModal}
                            >
                                <Row>
                                    <DescriptionList
                                        list={[
                                            { label: 'Name', value: Name },
                                            { label: 'Group Code', value: GroupCode },
                                            { label: 'Description', value: Description || '-' }
                                        ]}
                                        containerClassName="col-md-12"
                                    />
                                </Row>
                            </InfoCard>
                        </div>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={showModal} className="default modal-lg">
                    <ModalHeader toggle={hideModal}>Add Group Details</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-md-12">
                                <FormInput
                                    value={currentGroup.Name}
                                    onChangeValue={handleAdminPropertyChange('Name')}
                                    row
                                    disabled
                                    label="Name"
                                    type="text"
                                    placeholder="Name"
                                    message={invalidName}
                                    state={invalidName ? 'danger' : 'default'}
                                    maxLength={25}
                                />
                                <FormInput
                                    value={currentGroup.GroupCode}
                                    onChangeValue={handleAdminPropertyChange('GroupCode')}
                                    row
                                    label="Group Code"
                                    type="text"
                                    placeholder="SUN101"
                                    message={invalidGroupCode}
                                    state={invalidGroupCode ? 'danger' : 'default'}
                                    maxLength={10}
                                />
                                <FormInput
                                    value={currentGroup.Description}
                                    onChangeValue={handleAdminPropertyChange('Description')}
                                    row
                                    label="Description"
                                    type="text"
                                    placeholder="Description"
                                    maxLength={160}
                                />
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="primary" onClick={updateGroup}>Save</Button>
                        {' '}
                        <Button color="secondary" onClick={hideModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}
Group.propTypes = {
    dispatch: PropTypes.func.isRequired,
    group: PropTypes.shape({}).isRequired
};

const mapStateToProps = (state, props) => ({
    group: state.masjid.data.Groups.find(g => g.ID === props.location.state)
});
export default connect(mapStateToProps)(Group);
