import React from 'react';
import {
    Router,
    Route,
    IndexRoute,
    Redirect,
    browserHistory
} from 'react-router';
import App from './App';
import Dashboard from './views/dashboard';
import Widgets from './views/widgets';
import Account from './views/account';

// ui elements
import Buttons from './views/ui/buttons';
import Typography from './views/ui/typography';
import Cards from './views/ui/cards';
import Modals from './views/ui/modals';
import Notification from './views/ui/notification';
import Extras from './views/ui/extras';

// forms
import FormGeneral from './views/forms/general';

// charts
import Charts from './views/charts';

// tables
import Tables from './views/tables';

// pages
import SignIn from './views/pages/signin';
import OpsSignIn from './views/pages/opsSignin';
import Register from './views/pages/register';

import Page404 from './views/pages/404';
import {
    DonationsList,
    Donation,
    RecurringDonation,
    ConfigureDonations,
    RecurringDonationsList,
    OnlineDonations,
    ConfigureStripe
} from './views/donations';
import { CategoryList, Category } from './views/categories';

import {
    KiosksList,
    PurchaseKiosks
} from './views/kiosks';
import {
    // EventsList,
    // AddEvent,
    // Event,
    AnnouncementsList,
    AddAnnouncement,
    Announcement,
    DonationRequestsList,
    AddDonationRequest,
    DonationRequest
} from './views/notifications';
import { AdminsList, Admin } from './views/admins';
import { GroupList, Group } from './views/groups';
import {
    DailySalat,
    JummahSalat
} from './views/salat';
import { AnalyticsUtils } from './utils';
import {
    IslamicClassList,
    IslamicClass,
    Student,
    AddIslamicClass
} from './views/classes';
import {
    EventsList,
    Event,
    Participant,
    AddEvent
} from './views/events';
import OnlineRegistrations from './views/onlineRegistrations';
import {
    mustBeSuperAdmin,
    mustBeSuperAdminOrTeacher,
    mustHaveKioskBase,
    mustHaveAppBase,
    mustHaveAppDonationsInPlan,
    mustHaveRecurringDonationsInPlan,
    mustHaveClassRegistrationInPlan,
    mustHaveEventRegistrationInPlan,
    mustHaveDonationsInPlan,
    mustHaveOnlineDonationsInPlan,
    mustHaveOnlineProgramRegistrationInPlan,
    mustHavePOSBase,
    mustHaveWebsiteBase,
    mustHaveTvDisplayBase
} from './hocs';
import POSList from './views/pos/POSList';
import PurchasePOS from './views/pos/PurchasePOS';
import RentKiosks from './views/kiosks/RentKiosks';
import ClassCharge from './views/classes/ClassCharge';
import EventCharge from './views/events/EventCharge';
import Website from './views/website';
import PrayerTimes from './views/tvDisplay/PrayerTimes';
import Flyer from './views/tvDisplay/Flyer';
import TvDisplay from './views/tvDisplay/TvDisplay';
import Announcements from './views/tvDisplay/Announcements';
import TvDisplaySettings from './views/tvDisplay/TvDisplaySettings';

const routes = (
    <Route>
        <Route component={App} path="/">
            <IndexRoute component={Dashboard} />
            <Route path="donations/list" component={mustHaveDonationsInPlan(mustBeSuperAdmin(DonationsList))} />
            <Route path="donations/:id" component={mustHaveDonationsInPlan(mustBeSuperAdmin(Donation))} />
            <Route path="recurring-donations/list" component={mustHaveRecurringDonationsInPlan(mustBeSuperAdmin(RecurringDonationsList))} />
            <Route path="recurring-donations/:id" component={mustHaveRecurringDonationsInPlan(mustBeSuperAdmin(RecurringDonation))} />
            <Route path="categories/list" component={mustHaveDonationsInPlan(mustBeSuperAdmin(CategoryList))} />
            <Route path="categories/:id" component={mustHaveDonationsInPlan(mustBeSuperAdmin(Category))} />
            <Route path="configure-donations" component={mustHaveDonationsInPlan(mustBeSuperAdmin(ConfigureDonations))} />
            <Route path="configure-stripe" component={mustHaveDonationsInPlan(mustBeSuperAdmin(ConfigureStripe))} />
            <Route path="online-donations" component={mustHaveOnlineDonationsInPlan(mustBeSuperAdmin(OnlineDonations))} />
            <Route path="admins/list" component={mustBeSuperAdmin(AdminsList)} />
            <Route path="admins/:email" component={mustBeSuperAdmin(Admin)} />
            <Route path="kiosks/list" component={mustHaveKioskBase(mustBeSuperAdmin(KiosksList))} />
            <Route path="kiosks/purchase" component={mustHaveKioskBase(mustBeSuperAdmin(PurchaseKiosks))} />
            <Route path="kiosks/rent" component={mustHaveKioskBase(mustBeSuperAdmin(RentKiosks))} />
            <Route path="pos/list" component={mustHavePOSBase(mustBeSuperAdmin(POSList))} />
            <Route path="pos/purchase" component={mustHavePOSBase(mustBeSuperAdmin(PurchasePOS))} />
            <Route path="groups/list" component={mustHaveAppBase(mustBeSuperAdmin(GroupList))} />
            <Route path="groups/:id" component={mustHaveAppBase(mustBeSuperAdmin(Group))} />
            {/* <Route path="events/list" component={mustHaveAppBase(EventsList)} />
            <Route path="events/add" component={mustHaveAppBase(AddEvent)} />
            <Route path="events/:id" component={mustHaveAppBase(Event)} /> */}
            <Route path="announcements/list" component={mustHaveAppBase(AnnouncementsList)} />
            <Route path="announcements/add" component={mustHaveAppBase(AddAnnouncement)} />
            <Route path="announcements/:id" component={mustHaveAppBase(Announcement)} />
            <Route path="donation-requests/list" component={mustHaveAppDonationsInPlan(DonationRequestsList)} />
            <Route path="donation-requests/add" component={mustHaveAppDonationsInPlan(AddDonationRequest)} />
            <Route path="donation-requests/:id" component={mustHaveAppDonationsInPlan(DonationRequest)} />
            <Route path="salat/daily" component={DailySalat} />
            <Route path="salat/jummah" component={JummahSalat} />
            <Route path="account" component={mustBeSuperAdmin(Account)} />
            <Route path="classes/add" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(AddIslamicClass))} />
            <Route path="classes/list" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(IslamicClassList))} />
            <Route path="classes/:id" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(IslamicClass))} />
            <Route path="classes/:id/students/:studentId" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(Student))} />
            <Route path="classes/:id/students/:studentId/charge/:chargeId" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(ClassCharge))} />
            <Route path="events/add" component={mustHaveEventRegistrationInPlan(mustBeSuperAdminOrTeacher(AddEvent))} />
            <Route path="events/list" component={mustHaveEventRegistrationInPlan(mustBeSuperAdminOrTeacher(EventsList))} />
            <Route path="events/:id" component={mustHaveEventRegistrationInPlan(mustBeSuperAdminOrTeacher(Event))} />
            <Route path="events/:id/participants/:participantId" component={mustHaveEventRegistrationInPlan(mustBeSuperAdminOrTeacher(Participant))} />
            <Route path="events/:id/participants/:participantId/charge/:chargeId" component={mustHaveClassRegistrationInPlan(mustBeSuperAdminOrTeacher(EventCharge))} />
            <Route path="online-registrations" component={mustHaveOnlineProgramRegistrationInPlan(mustBeSuperAdminOrTeacher(OnlineRegistrations))} />
            <Route path="website" component={mustHaveWebsiteBase(mustBeSuperAdmin(Website))} />
            <Route path="widgets" component={Widgets} />
            <Route path="ui/buttons" component={Buttons} />
            <Route path="ui/typography" component={Typography} />
            <Route path="ui/cards" component={Cards} />
            <Route path="ui/modals" component={Modals} />
            <Route path="ui/notification" component={Notification} />
            <Route path="ui/extras" component={Extras} />
            <Route path="forms/general" component={FormGeneral} />
            <Route path="charts" component={Charts} />
            <Route path="tables" component={Tables} />
            <Route path="tvdisplay" component={TvDisplay} />
            <Route path="tvdisplay/dailyprayers" component={mustHaveTvDisplayBase(PrayerTimes)} />
            <Route path="tvdisplay/flyer" component={mustHaveTvDisplayBase(Flyer)} />
            <Route path="tvdisplay/announcements" component={mustHaveTvDisplayBase(Announcements)} />
            <Route path="tvdisplay/settings" component={mustHaveTvDisplayBase(TvDisplaySettings)} />

        </Route>
        <Route component={SignIn} path="pages/signin" />
        <Route component={OpsSignIn} path="pages/ops-signin" />
        <Route component={Register} path="pages/register" />
        <Route path="configure-stripe-public" component={ConfigureStripe} />
        {/* default */}
        <Route component={Page404} path="404" />
        <Redirect from="*" to="404" />
    </Route>
);

AnalyticsUtils.listenForPageChanges(browserHistory);

export default () => (
    <Router history={browserHistory} routes={routes} />
);
