import format from 'sugar/number/format';
import capitalize from 'sugar/string/capitalize';
import spacify from 'sugar/string/spacify';

const currencify = amount => `$${amount}`;

const formatAmount = (amount, isCents = true) => currencify(format(
    amount / (isCents ? 100 : 1),
    2
));

const formatLast4Digits = last4 => `${'*'.repeat(12)} ${last4}`;

const toStripeURL = stripeID => `https://dashboard.stripe.com/payments/${stripeID}`;

const getFileNameFromURL = url => url.split('/').pop();

const geFileExtension = filename => filename.split('.').pop();

const humanize = string => capitalize(spacify(string), true, true);

const getQueryParam = name => new URLSearchParams(window.location.search).get(name);

export {
    currencify,
    formatAmount,
    formatLast4Digits,
    toStripeURL,
    getFileNameFromURL,
    geFileExtension,
    humanize,
    getQueryParam
};
