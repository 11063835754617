import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Components } from 'musalleen-react-theme';
import { browserHistory } from 'react-router';
import { logout, showSuccessAlert, loginViaToken } from '../../../store/actions';
import { StringUtils } from '../../../utils';

const {
    Loader
} = Components;

function OpsSignIn({ dispatch }) {
    useEffect(() => {
        loginAdmin();
    }, []);

    // Login on the behalf of the admin whose token
    // and email are given as query params.
    async function loginAdmin() {
        // Get admin token from query params.
        const token = StringUtils.getQueryParam('token');
        // Get token duration from query params.
        const duration = StringUtils.getQueryParam('duration');
        // Get admin email from query params.
        const email = StringUtils.getQueryParam('email');
        if (!(token || email || duration)) {
            // If token or email or duration is not provided, move onto main sigin screen.
            browserHistory.replace('/pages/signin');
            return;
        }
        // First, logout any exisiting admin.
        dispatch(logout());

        // Other errors like network errors, server errors etc. are
        // handled inside the action creator itself and don't need to be
        // handled by the UI.
        // And we don't need to handle CLIENT_ERROR here.
        const error = await dispatch(loginViaToken(
            token,
            duration - 0,
            email
        ));
        if (error) {
            // In case of any error, move onto main sigin screen.
            browserHistory.replace('/pages/signin');
            return;
        }
        dispatch(showSuccessAlert(`You have successfully logged inside this Masjid's dashboard via ${email}!`));
        browserHistory.replace('/');
    }

    return (
        <div className="app-wrapper">
            <div className="loader-container">
                <Loader height={200} thin />
            </div>
        </div>
    );
}

OpsSignIn.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default connect()(OpsSignIn);
