import { produce } from 'immer';
import {
    SET_MASJID,
    REFRESHING_MASJID,
    UPDATE_MAJSID,
    UPDATE_DONATION,
    UPDATE_RECURRING_DONATION,
    ADD_DONATION_CATEGORY,
    UPDATE_DONATION_CATEGORY,
    DELETE_DONATION_CATEGORY,
    KIOSK_PURCHASED,
    ADD_ADMIN,
    EDIT_ADMIN,
    DELETE_ADMIN,
    ADD_GROUP,
    EDIT_GROUP,
    REMOVE_GROUP,
    ADD_PROGRAM,
    UPDATE_PROGRAM,
    CANCEL_PROGRAM,
    UNENROLL_ATTENDEE,
    UPDATE_ATTENDEE_PAYMENT,
    REFUND_ATTENDEE,
    POS_PURCHASED,
    UPDATE_TV_DISPLAY,
    ADD_TV_DISPLAY_ANNOUNCEMENT,
    UPDATE_TV_DISPLAY_ANNOUNCEMENT,
    DELETE_TV_DISPLAY_ANNOUNCEMENT,
    ADD_TV_DISPLAY_FLYER,
    UPDATE_TV_DISPLAY_FLYER,
    DELETE_TV_DISPLAY_FLYER,
    UPDATE_TV_DISPLAY_SETTINGS,
    UPDATE_DONATION_CATEGORY_POSITION,
} from '../types';
import { ProgramUtils, EnvironmentUtils } from '../../utils';

// Time after which masjid data will go stale and
// a new API call will be made for each refreshMasjid action.
// Otherwise no API call is made. (seconds)
const REFRESH_INTERVAL = EnvironmentUtils.isRunningInDevelopmentMode() ? 10 : 30;

const INITIAL_STATE = {
    data: {},
    updatedAt: 0,
    interval: REFRESH_INTERVAL * 1000,
    refreshing: true
};

const getStateKeyFromProgramType = programType => {
    switch (programType) {
    case ProgramUtils.types.CLASS:
        return 'Classes';
    case ProgramUtils.types.EVENT:
        return 'Events';
    default:
        return null;
    }
};

/* eslint-disable no-param-reassign */
const masjid = produce((draft, { type, payload }) => {
    switch (type) {
    case SET_MASJID:
        draft.data = payload.masjid;
        draft.updatedAt = new Date().getTime();
        break;

    case REFRESHING_MASJID:
        draft.refreshing = payload.refreshing;
        break;

    case UPDATE_MAJSID:
        draft.data = {
            ...draft.data,
            ...payload.masjid
        };
        break;

    case UPDATE_DONATION:
        draft.data.Donations = draft.data.Donations.map(donation => {
            if (donation.ID === payload.donation.ID) {
                return { ...donation, ...payload.donation };
            }
            return donation;
        });
        break;

    case UPDATE_RECURRING_DONATION:
        draft.data.RecurringDonations = draft.data.RecurringDonations.map(donation => {
            if (donation.ID === payload.donation.ID) {
                return { ...donation, ...payload.donation };
            }
            return donation;
        });
        break;

    case ADD_DONATION_CATEGORY:
        draft.data.Categories = [
            ...draft.data.Categories,
            payload.category
        ];
        break;

    case UPDATE_DONATION_CATEGORY:
        draft.data.Categories = payload.category.masjids;
        break;

    case UPDATE_DONATION_CATEGORY_POSITION:
        draft.data.Categories = payload.masjids;
        break;

    case DELETE_DONATION_CATEGORY:
        draft.data.Categories = draft.data.Categories.filter(c => c.ID !== payload.category.ID);
        break;

    case KIOSK_PURCHASED:
        draft.data.Kiosks = payload.kiosks;
        draft.data.Charges = payload.charges;
        break;

    case ADD_ADMIN: {
        draft.data.Admins = payload.admins;
        break;
    }

    case EDIT_ADMIN: {
        draft.data.Admins = payload.admins;
        break;
    }

    case DELETE_ADMIN: {
        draft.data.Admins = draft.data.Admins.filter(a => a.Email !== payload.email);
        break;
    }

    case ADD_GROUP:
        draft.data.Groups = [
            ...draft.data.Groups,
            payload.group
        ];
        break;

    case EDIT_GROUP:
        draft.data.Groups = draft.data.Groups.map(group => {
            if (group.ID === payload.group.ID) {
                return { ...group, ...payload.group };
            }
            return group;
        });
        break;

    case REMOVE_GROUP:
        draft.data.Groups = draft.data.Groups.filter(g => g.ID !== payload.groupID);
        break;

    case ADD_PROGRAM: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = [...draft.data[stateKey], payload.program];
        break;
    }

    case UPDATE_PROGRAM: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = draft.data[stateKey].map(program => {
            if (program.ID === payload.program.ID) {
                return { ...program, ...payload.program };
            }
            return program;
        });
        break;
    }

    case CANCEL_PROGRAM: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = draft.data[stateKey].map(program => {
            if (program.ID === payload.program.ID) {
                return { ...program, ...payload.program };
            }
            return program;
        });
        break;
    }

    case UNENROLL_ATTENDEE: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = draft.data[stateKey].map(program => {
            if (program.ID === payload.registration.ProgramID) {
                return {
                    ...program,
                    Registrations: payload.registration.Program.Registrations
                };
            }
            return program;
        });
        break;
    }

    case REFUND_ATTENDEE: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = draft.data[stateKey].map(program => {
            if (program.ID === payload.registration.ProgramID) {
                return {
                    ...program,
                    Registrations: payload.registration.Program.Registrations
                };
            }
            return program;
        });
        break;
    }

    case UPDATE_ATTENDEE_PAYMENT: {
        const stateKey = getStateKeyFromProgramType(payload.programType);
        draft.data[stateKey] = draft.data[stateKey].map(program => {
            if (program.ID === payload.registration.ProgramID) {
                return {
                    ...program,
                    Registrations: payload.registration.Program.Registrations
                };
            }
            return program;
        });
        break;
    }

    case POS_PURCHASED:
        draft.data.POSDevices = payload.posDevices;
        draft.data.Charges = payload.charges;
        break;

    case UPDATE_TV_DISPLAY:
        draft.data.TvDisplay = payload.tvdisplay;
        break;

    case ADD_TV_DISPLAY_ANNOUNCEMENT:
        draft.data.TvDisplay.TvDisplayAnnouncements = [
            ...draft.data.TvDisplay.TvDisplayAnnouncements,
            payload.tvdisplay.TvDisplayAnnouncements
        ];
        break;

    case UPDATE_TV_DISPLAY_ANNOUNCEMENT:
        draft.data.TvDisplay.TvDisplayAnnouncements = payload.tvdisplay.TvDisplayAnnouncements;
        break;

    case DELETE_TV_DISPLAY_ANNOUNCEMENT:
        draft.data.TvDisplay.TvDisplayAnnouncements = draft.data.TvDisplay.TvDisplayAnnouncements
            .filter(announcement => announcement.ID !== payload.tvdisplay.ID);
        break;

    case ADD_TV_DISPLAY_FLYER:
        draft.data.TvDisplay.TvDisplayFlyers = [
            ...draft.data.TvDisplay.TvDisplayFlyers,
            payload.tvdisplay
        ];
        break;

    case UPDATE_TV_DISPLAY_FLYER:
        draft.data.TvDisplay.TvDisplayFlyers = draft.data
            .TvDisplay.TvDisplayFlyers.map(flyer => {
                if (flyer.ID === payload.tvdisplay.ID) {
                    return { ...flyer, ...payload.tvdisplay };
                }
                return flyer;
            });
        break;

    case DELETE_TV_DISPLAY_FLYER:
        draft.data.TvDisplay.TvDisplayFlyers = draft.data.TvDisplay.TvDisplayFlyers
            .filter(flyer => flyer.ID !== payload.tvdisplay.ID);
        break;
    case UPDATE_TV_DISPLAY_SETTINGS:
        draft.data.TvDisplay.ShowFlyer = payload.tvdisplay.ShowFlyer;
        draft.data.TvDisplay.ShowHadith = payload.tvdisplay.ShowHadith;
        draft.data.TvDisplay.ShowQuran = payload.tvdisplay.ShowQuran;
        draft.data.TvDisplay.MasjidTvDisplayLogo = payload.tvdisplay.MasjidTvDisplayLogo;
        break;
    default:
    }
}, INITIAL_STATE);
/* eslint-enable */

export default masjid;
