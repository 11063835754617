import {
    create,
    SERVER_ERROR,
    CLIENT_ERROR,
    CONNECTION_ERROR,
    NETWORK_ERROR
} from 'apisauce';
import { store } from '../store';

const url = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const musalleenApisClient = process.env.REACT_APP_MUSALLEEN_APIS_URL || 'https://apis.musalleen.com';

const reversegeoCodeUrl = process.env.REACT_APP_EXTERNAL_APIS_URL || 'https://nominatim.openstreetmap.org/';


const client = create({ baseURL: `${url}/api` });

const externalClient = create({ baseURL: `${musalleenApisClient}` });

const geoCodeClient = create({ baseURL: `${reversegeoCodeUrl}` });


client.addRequestTransform(request => {
    const { admin: { token, expires } } = store.getState();

    // If the admin is logged in, add the basic auth header.
    if (token && expires >= new Date().getTime()) {
        request.headers.authorization = `Basic ${token}`;
    }
});

const Constants = {
    SERVER_ERROR,
    CLIENT_ERROR,
    CONNECTION_ERROR,
    NETWORK_ERROR
};

const returnResponse = ({
    ok, problem, data, status, originalError
}) => ({
    error: !ok ? problem : null,
    data,
    status,
    originalError: !ok ? new Error(`${originalError}. Response - ${JSON.stringify(data)}`) : null
});

export {
    Constants,
    returnResponse,
    geoCodeClient
};

export default client;
export { externalClient };
