import React from 'react';
import { browserHistory } from 'react-router';
import { store } from '../store';
import { JSONUtils } from '../utils';

// HOC to guard our routes using the Redux store.
// states - Shapes of the redux store to match (in OR fashion)
// so as the user will get access to Component.
// This HOC is practically identical to mustBe with the exception that
// it takes an array of states to match in an OR fashion.
const mustBeOneOf = (states, fallbackRoute = '/') => Component => props => {
    const storeState = store.getState();
    for (const state of states) { // eslint-disable-line
        if (JSONUtils.matchStructure(state, storeState)) {
            return <Component {...props} />;
        }
    }
    browserHistory.replace(fallbackRoute);
    return null;
};

export default mustBeOneOf;
