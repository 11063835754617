const platforms = {
    KIOSK: 'Kiosk',
    ONLINE: 'Online',
    IOS: 'iOS',
    ANDROID: 'Android',
    POS: 'POS'
};

export {
    platforms
};
