import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import {
    InfoCard,
    PageHeader,
    FormInput,
    Button,
    DescriptionList,
    Image
} from '../../shared/components';
import {
    updateDonationCategory,
    deleteDonationCategory,
    showDangerAlert,
    showServerErrorAlert
} from '../../store/actions';
import { StringUtils, FileUtils } from '../../utils';
import { hasRecurringDonationsInPlan } from '../../store/selectors';


function Category({
    dispatch,
    allCategories,
    category: {
        ID,
        Enabled,
        RecurringEnabled,
        CategoryName,
        Description,
        AddProcessingFees,
        EligibleForTaxDeductionBenefits,
        ImageUrl,
        Position
    },
    donation: {
        Amount
    },
    HasRecurringDonationsInPlan,
    masjidID
}) {
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState(false);

    const [categoryName, setCategoryName] = useState(CategoryName);
    const [description, setDescription] = useState(Description);
    const [categoryEnabled, setCategoryEnabled] = useState(Enabled);
    const [recurringEnabled, setRecurringEnabled] = useState(RecurringEnabled);
    const [addProcessingFees, setAddProcessingFees] = useState(AddProcessingFees);
    const [eligibleForTaxDeductionBenefits, setEligibleForTaxDeductionBenefits] = useState(
        EligibleForTaxDeductionBenefits
    );
    const [imageUploading, setImageUploading] = useState(false);

    function editCategory() {
        showEditModal();
    }

    function showEditModal() {
        setEditModal(true);
    }

    function hideEditModal() {
        setEditModal(false);
    }

    function changeEnabledState() {
        setCategoryEnabled(!categoryEnabled);
    }

    function changeRecurringState() {
        setRecurringEnabled(!recurringEnabled);
    }

    function changeProcessingFeesState() {
        setAddProcessingFees(!addProcessingFees);
    }

    function changeEligibleForTaxDeductionBenefits() {
        setEligibleForTaxDeductionBenefits(!eligibleForTaxDeductionBenefits);
    }


    const getNewPosition = () => {
        const categoryWithHighestPosition = allCategories.filter(item => item.Enabled)
            .reduce((prev, current) => ((prev.Position > current.Position) ? prev : current));


        if (!categoryEnabled) {
            return null;
        }
        if (Enabled) {
            return Position;
        }
        if (!Enabled) {
            return categoryWithHighestPosition.Position + 1;
        }
    };
    async function updateCategory() {
        setLoading(true);
        const error = await dispatch(updateDonationCategory({
            ID,
            CategoryName: categoryName,
            Description: description,
            Enabled: categoryEnabled,
            RecurringEnabled: recurringEnabled,
            AddProcessingFees: addProcessingFees,
            EligibleForTaxDeductionBenefits: eligibleForTaxDeductionBenefits,
            MasjidID: masjidID,
            Position: allCategories.length >= 1 ? getNewPosition() : 1
        }));

        setLoading(false);
        if (error) {
            return;
        }
        hideEditModal();
    }

    async function deleteCategory() {
        if (Amount && Amount > 0) {
            dispatch(showDangerAlert("Category with donations can't be deleted"));
            return;
        }
        setDeletingCategory(true);
        await dispatch(deleteDonationCategory(ID));
        setDeletingCategory(false);
        browserHistory.push('/categories/list');
    }

    function onCancel() {
        hideEditModal();
    }
    function handleCategoryImageChange(files) {
        saveCategoryImage(files[0]);
    }

    async function saveCategoryImage(image) {
        setImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a cateogry and
            // the old one gets overwritten.
            new File(
                [image],
                `category_image_${ID}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            ), 'categoryImages'
        );
        if (error) {
            setImageUploading(false);
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The category image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            // Upload has failed.
            dispatch(showServerErrorAlert());
            return;
        }

        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for a category.
        const categoryImageUrl = `${finalUrl}?c=${new Date().getTime()}`;
        const updateError = await dispatch(updateDonationCategory({
            ID,
            ImageUrl: categoryImageUrl,
            CategoryName: categoryName,
            Enabled: categoryEnabled,
            RecurringEnabled: recurringEnabled,
            AddProcessingFees: addProcessingFees
        }));
        setImageUploading(false);
        if (updateError) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    return (
        <div className="view">
            <PageHeader title="Category Details" description="All the details of the donation category." />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Category Infomation"
                            subTitle="More about this category."
                            showFooter={false}
                            showButtonInHeader
                            buttonInHeaderLabel="Edit Category"
                            onClickButtonInHeader={editCategory}
                        >

                            <Row>
                                <DescriptionList
                                    list={[
                                        {
                                            label: 'Category Image',
                                            value: <Image
                                                title="Category Image"
                                                url={ImageUrl}
                                                uploading={imageUploading}
                                                onImageSelected={handleCategoryImageChange}
                                            />
                                        },
                                        { label: 'Category Name', value: CategoryName },
                                        { label: 'Description', value: Description },
                                        { label: 'Category Enabled', value: Enabled ? 'Yes' : 'No' },
                                        { label: 'Recurring Enabled', value: RecurringEnabled && HasRecurringDonationsInPlan ? 'Yes' : 'No' },
                                        { label: 'Processing Fees Added', value: AddProcessingFees ? 'Yes' : 'No' },
                                        { label: 'Eligible For Tax Deduction Benefits', value: EligibleForTaxDeductionBenefits ? 'Yes' : 'No' },
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                            <Row>
                                <div className="col-md-9">
                                    <Button loading={deletingCategory} color="danger" onClick={deleteCategory}>Delete</Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                    <Modal isOpen={editModal} toggle={showEditModal} className="default">
                        <ModalHeader toggle={hideEditModal}>Edit Category</ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className="col-md-12">
                                    <FormInput label="Category Name" row type="text" value={categoryName} onChangeValue={setCategoryName} />
                                    <FormInput label="Description" row type="textarea" value={description} onChangeValue={setDescription} maxLength={2000} />
                                    <Row>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <legend className="col-form-legend font-weight-bold">Enable</legend>
                                                <div>
                                                    <Label check>
                                                        <Input type="checkbox" onChange={changeEnabledState} checked={categoryEnabled} />
                                                        &nbsp;
                                                        Enable this category.
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </div>

                                    </Row>
                                    <Row>
                                        <div className={`col-md-12 ${!HasRecurringDonationsInPlan ? 'disabled-checkbox-group' : ''}`}>
                                            <FormGroup>
                                                <legend className="col-form-legend font-weight-bold">Enable recurring donations</legend>
                                                <div>
                                                    <Label check>
                                                        <Input type="checkbox" onChange={changeRecurringState} checked={HasRecurringDonationsInPlan && recurringEnabled} />
                                                        &nbsp;
                                                        Enable recurring donations
                                                        for this category.
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <legend className="col-form-legend font-weight-bold">Show Processing Fee</legend>
                                                <div>
                                                    <Label check>
                                                        <Input type="checkbox" onChange={changeProcessingFeesState} checked={addProcessingFees} />
                                                        &nbsp;
                                                        Show Processing Fee to
                                                        Musalleen while donating.
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <legend className="col-form-legend font-weight-bold">Eligible for Tax Deduction Benefits</legend>
                                                <div>
                                                    <Label check>
                                                        <Input type="checkbox" onChange={changeEligibleForTaxDeductionBenefits} checked={eligibleForTaxDeductionBenefits} />
                                                        &nbsp;
                                                        Whether donations made to this category
                                                        should be eligible for tax deduction
                                                        benefits.
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button loading={loading} color="primary" onClick={updateCategory}>Update</Button>
                            <Button color="secondary" onClick={onCancel}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </Row>
            </div>
        </div>
    );
}

Category.propTypes = {
    dispatch: PropTypes.func.isRequired,
    category: PropTypes.shape({}),
    donation: PropTypes.shape({}),
    HasRecurringDonationsInPlan: PropTypes.bool.isRequired,
    masjidID: PropTypes.number.isRequired,
    allCategories: PropTypes.arrayOf(PropTypes.shape({
        CategoryName: PropTypes.string.isRequired,
        ID: PropTypes.number.isRequired
    })).isRequired,
};
Category.defaultProps = {
    category: {},
    donation: {}
};

const mapStateToProps = (state, props) => ({
    category: state.masjid.data.Categories.find(c => c.ID === props.location.state),
    donation: state.masjid.data.Donations.find(c => c.CategoryID === props.location.state),
    masjidID: state.masjid.data.ID,
    HasRecurringDonationsInPlan: hasRecurringDonationsInPlan(state),
    allCategories: state.masjid.data.Categories
});


export default connect(mapStateToProps)(Category);
