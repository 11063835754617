import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
    Row,
    Card,
    CardBody
} from 'reactstrap';
import {
    PageHeader,
    Table,
    Button
} from '../../shared/components';
import { getKiosks } from '../../store/selectors';
import { DateUtils } from '../../utils';

function KiosksList({ kiosks }) {
    return (
        <div className="view">
            <PageHeader title="Kiosks" description="Here are all your purchased Kiosks." />
            <div className="view-content view-dashboard">
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Row>
                                <div className="col-md-12 text-right">
                                    <Link to="/kiosks/rent" className="mr-3">
                                        <Button onClick={() => { }}>Rent Kiosks</Button>
                                    </Link>
                                    <Link to="/kiosks/purchase">
                                        <Button onClick={() => { }}>Purchase Kiosks</Button>
                                    </Link>
                                </div>
                            </Row>
                            <Table
                                title="Kiosks Purchased"
                                columns={[
                                    { label: 'Kiosk License', key: 'kioskLicenseShort' },
                                    { label: 'Screen Size (inches)', key: 'sizeInInches' },
                                    {
                                        label: 'Purchase Date',
                                        key: 'purchasedOn',
                                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                    }
                                ]}
                                data={kiosks.filter(k => k.purchasedOn)}
                                enableFilters={false}
                                enableExports={false}
                                rowClickable={false}
                                defaultSortColumnKey="purchasedOn"
                            />
                            <Table
                                title="Kiosks Rented"
                                columns={[
                                    { label: 'Kiosk License', key: 'kioskLicenseShort' },
                                    { label: 'Screen Size (inches)', key: 'sizeInInches' },
                                    {
                                        label: 'Rented On',
                                        key: 'rentedOn',
                                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                    }
                                ]}
                                data={kiosks.filter(k => k.rentedOn)}
                                enableFilters={false}
                                enableExports={false}
                                rowClickable={false}
                                defaultSortColumnKey="purchasedOn"
                            />
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </div>
    );
}

KiosksList.propTypes = {
    kiosks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
    kiosks: getKiosks(state)
});

export default connect(mapStateToProps)(KiosksList);
