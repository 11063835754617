/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,

} from 'reactstrap';

// Please do yarn add react-beautiful-dnd before using this package
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import IconAddAlert from 'react-icons/lib/md/add-alert';


import './style.scss';

import {
    PageHeader,
    Button,
    FormInput,
    Table
} from '../../shared/components';


import {
    showDialog,
    addNewAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    showAlert
} from '../../store/actions';
import UpgradePlanAlert from '../ui/extras/UpgradePlanAlert';


const Announcements = ({
    tvDisplayAnnouncements, dispatch,
    hasTvDisplayAdvancedAddOn
}) => {
    const [loading, setLoading] = useState(false);
    const [currentlyEditingAnnouncement, setCurrentlyEditingAnnouncement] = useState({});
    const [newAnnouncementBody, setNewAnnouncementBody] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [enableaddBody, setEnableaddBody] = useState(false);
    const [invalidNameMessage, setInvalidNameMessage] = useState('');
    const [editModal, setEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);


    const handleNewAnnouncementOnChange = (value) => setNewAnnouncementBody(value);

    const handleEditAnnouncementOnChange = (value) => {
        setCurrentlyEditingAnnouncement(prevState => ({ ...prevState, Body: value }));
    };

    const handleOnSubmitAdd = async () => {
        if (!newAnnouncementBody.trim()) {
            setInvalidNameMessage('Announcement body cannot be empty.');
            return;
        }
        setLoading(true);
        const error = await dispatch(addNewAnnouncement({ Body: newAnnouncementBody, Position: tvDisplayAnnouncements.length }));
        setNewAnnouncementBody('');
        setLoading(false);
        setInvalidNameMessage('');
        setEnableaddBody(false);
        hideModal('add');
    };

    const handleOnSubmitEdit = async () => {
        if (!currentlyEditingAnnouncement.Body.trim()) {
            setInvalidNameMessage('Announcement body cannot be empty.');
            return;
        }
        setLoading(true);
        const error = await dispatch(updateAnnouncement(currentlyEditingAnnouncement));
        setIsEditable(false);
        setLoading(false);
        setCurrentlyEditingAnnouncement({ Body: '' });
        setInvalidNameMessage('');
        hideModal('edit');
    };

    const handleonDelete = async (announcement) => {
        const confirm = await dispatch(showDialog(
            'Are you sure?',
            'Are you sure you want to delete?'
        ));
        if (confirm) {
            const error = await dispatch(deleteAnnouncement(announcement.ID));
        }
    };

    function showModal(type) {
        if (type === 'edit') {
            setEditModal(true);
        } else {
            setShowAddModal(true);
        }
    }

    function hideModal(type) {
        if (type === 'edit') {
            setEditModal(false);
            setCurrentlyEditingAnnouncement({ Body: '' });
            setIsEditable(false);
        } else {
            setShowAddModal(false);
        }
    }


    return (
        <div className="view">

            <PageHeader title="Tv Display Announcements" description="View and configure your tv display announcements." />
            <div className="view-content view-dashboard">
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Row>
                                {hasTvDisplayAdvancedAddOn && (
                                    <div className="col-md-12 button-right">
                                        <Button
                                            onClick={() => {
                                                setIsEditable(false);
                                                showModal('add');
                                            }}
                                        >
                                            Push New Announcement
                                        </Button>
                                    </div>
                                )}
                            </Row>
                            <br />
                            <Table
                                title="Announcements"
                                columns={[
                                    { label: 'Body', key: 'Body' },

                                ]}
                                data={tvDisplayAnnouncements}
                                enableFilters={false}
                                enableExports={false}
                                showButtonOne
                                buttonOneLabel="Edit"
                                buttonTwoType="danger"
                                onButtonOneClick={(announcement) => {
                                    setCurrentlyEditingAnnouncement(announcement);
                                    setEditModal(true);
                                }}
                                showButtonTwo
                                buttonTwoLabel="Delete"
                                onButtonTwoClick={handleonDelete}
                            />


                        </CardBody>
                    </Card>
                    <UpgradePlanAlert description="To add more announcements" show={hasTvDisplayAdvancedAddOn} />
                </Row>
            </div>
            <br />
            <br />
            {
                tvDisplayAnnouncements.length === 3 && (
                    <div style={{ marginLeft: '20px' }}>
                        <IconAddAlert size={25} />
                        <p
                            className="mb-0 subtitle  text-black"
                            style={{ backgroundColor: '#FFFFCA', display: 'inline', padding: '5px' }}
                        >
                            You have reached your maximum number of announcements.Please delete some of your older announcements.
                        </p>
                    </div>
                )
            }

            <Modal isOpen={showAddModal} toggle={showModal} className="default modal-lg">
                <ModalHeader toggle={() => hideModal('add')}>Add New Announcement</ModalHeader>
                <ModalBody>
                    <FormInput
                        value={newAnnouncementBody}
                        onChangeValue={(value) => handleNewAnnouncementOnChange(value)}
                        label="Body"
                        type="textarea"
                        placeholder="Body"
                        maxLength={200}
                        state={invalidNameMessage ? 'danger' : 'default'}
                        message={invalidNameMessage}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleOnSubmitAdd}
                        loading={loading}
                        color="primary"
                    >
                        Add
                    </Button>
                    {' '}
                    <Button
                        onClick={() => {
                            setNewAnnouncementBody('');
                            setEnableaddBody(false);
                            setInvalidNameMessage('');
                            hideModal('add');
                        }}
                        loading={loading}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={editModal} toggle={showModal} className="default modal-lg">
                <ModalHeader toggle={() => hideModal('edit')}>Edit Announcement</ModalHeader>
                <ModalBody>
                    <Row>
                        <div className="col-md-12">
                            <FormInput
                                value={currentlyEditingAnnouncement.Body}
                                onChangeValue={(value) => handleEditAnnouncementOnChange(value)}
                                label="Body"
                                type="textarea"
                                placeholder="Body"
                                maxLength={200}
                                state={invalidNameMessage ? 'danger' : 'default'}
                                message={invalidNameMessage}
                            />
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleOnSubmitEdit}
                        loading={loading}
                        color="primary"
                    >
                        Update
                    </Button>
                    {' '}
                    <Button
                        onClick={() => hideModal('edit')}
                        loading={loading}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

Announcements.propTypes = {
    dispatch: PropTypes.func.isRequired,
    tvDisplayAnnouncements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired

};

const mapStateToProps = ({ masjid: { data: { TvDisplay, HasTvDisplayAdvancedAddOn } } }) => ({
    tvDisplayAnnouncements: TvDisplay.TvDisplayAnnouncements,
    hasTvDisplayAdvancedAddOn: HasTvDisplayAdvancedAddOn

});

export default connect(mapStateToProps)(Announcements);
