import ReactGA from 'react-ga';
import { EnvironmentUtils } from '.';

const GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'UA-131279035-2';

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const listenForPageChanges = history => {
    history.listen((location) => {
        // Track page changes when not developing locally.
        if (!EnvironmentUtils.isRunningInDevelopmentMode()) {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        }
    });
};

export {
    listenForPageChanges
};
