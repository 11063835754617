// Matches structure of two JSONs recursively.
// matchStructure({ abc : '1' }, { abc: '1', def: '2 }) would return a true.
// matchStructure({ abc: { innerAbc: '1' } }, { abc: { innerAbc: '1', innerDef: '2' } })
// would return a true again.
// matchStructure({ abc: { innerAbc: '1' } }, { abc: { innerAbc: '2' } }) would return a false
// since the value of state.abc.innerAbc != stateToMatchWith.abc.innerAbc.

const matchStructure = (state, stateToMatchWith) => {
    if (typeof state !== 'object' || typeof stateToMatchWith !== 'object') {
        return state === stateToMatchWith;
    }
    // Only interate if nested structure is JSON on both sides.
    for (const innerState in state) { // eslint-disable-line
        if (!matchStructure(state[innerState], stateToMatchWith[innerState])) {
            return false;
        }
    }
    return true;
};

export {
    matchStructure
};
