import S3Client from 'aws-s3';

const S3_CONFIG = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME || 'musalleen-dev',
    region: process.env.REACT_APP_S3_REGION || 'us-east-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY || 'AKIARLZGWX5IQ2C6ZI4J',
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY || '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',
};

// Max file upload size in MBs.
const MAX_FILE_UPLOAD_SIZE = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE || 10;

// Dynamically Load any JS script files inside the DOM.
// ~ Reference - https://stackoverflow.com/questions/5235321/how-do-i-load-a-javascript-file-dynamically
const loadJSFile = (src, id, loaded) => {
    // DOM: Create the script element
    const jsElm = document.createElement('script');
    // Set the id attribute.
    jsElm.id = id;
    // Set the type attribute
    jsElm.type = 'application/javascript';
    // Make the script element load file
    jsElm.src = src;
    // Finally insert the element to the body element in order to load the script.
    document.body.appendChild(jsElm);

    document.getElementById(id).addEventListener('load', loaded);
};

const FILE_SIZE_TOO_BIG = 'FILE_SIZE_TOO_BIG';

const uploadFile = async (file, dirName = 'masjidImages') => {
    try {
        if (file.size >= MAX_FILE_UPLOAD_SIZE * 1000 * 1000) {
            throw FILE_SIZE_TOO_BIG;
        }
        return {
            location: (await S3Client.uploadFile(
                file,
                { ...S3_CONFIG, dirName }
            )).location,
            error: null
        };
    } catch (e) {
        return { location: null, error: e };
    }
};

const Constants = {
    MAX_FILE_UPLOAD_SIZE,
    FILE_SIZE_TOO_BIG
};

export {
    loadJSFile,
    uploadFile,
    Constants
};
