import { AddressApi, Constants } from '../../api';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert
} from './alert';

export const fetchCountries = () => async dispatch => {
    const { error, data } = await AddressApi.getCountries();
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('Something went wrong'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
    }
    return data;
};

export const fetchStatesByCountries = (countryName) => async dispatch => {
    const { error, data } = await AddressApi.getStates(countryName);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('Something went wrong'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
    }
    return data;
};

export const fetchCitiesByState = (stateName) => async dispatch => {
    const { error, data } = await AddressApi.getCities(stateName);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('Something went wrong'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
    }
    return data;
};


export const handleBrowserNavigatoerError = (error) => dispatch => {
    switch (error.code) {
    case error.PERMISSION_DENIED:
        dispatch(showDangerAlert('User denied the request for Geolocation.'));
        break;
    case error.POSITION_UNAVAILABLE:
        dispatch(showDangerAlert('Location information is unavailable.'));
        break;
    case error.TIMEOUT:
        dispatch(showDangerAlert('The request to get user location timed out.'));
        break;
    case error.UNKNOWN_ERROR:
        dispatch(showServerErrorAlert());
        break;
    default:
        break;
    }
};
