const YEAR_LIMIT = 25;
const YEARS = Array(YEAR_LIMIT - 17).fill(null).map((_, index) => `20${index + 18}`);

const MONTHS_MAP = {
    0: {
        name: 'January',
        days: 31
    },
    1: {
        name: 'February',
        // We'll subtract this later if not a leap year.
        days: 29
    },
    2: {
        name: 'March',
        days: 31
    },
    3: {
        name: 'April',
        days: 30
    },
    4: {
        name: 'May',
        days: 31
    },
    5: {
        name: 'June',
        days: 30
    },
    6: {
        name: 'July',
        days: 31
    },
    7: {
        name: 'August',
        days: 31
    },
    8: {
        name: 'September',
        days: 30
    },
    9: {
        name: 'October',
        days: 31
    },
    10: {
        name: 'November',
        days: 30
    },
    11: {
        name: 'December',
        days: 31
    }
};
const MONTHS = Object.keys(MONTHS_MAP).map(value => ({ label: MONTHS_MAP[value].name, value }));

export {
    YEAR_LIMIT,
    YEARS,
    MONTHS_MAP,
    MONTHS
};
