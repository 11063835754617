import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    FormInput,
    InfoCard,
    Button
} from '../../shared/components';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    showSuccessAlert
} from '../../store/actions';
import { getGroups } from '../../store/selectors';
import { NotificationsAPI, Constants } from '../../api';

const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;

function AddAnnouncement({
    masjidID,
    groups,
    dispatch
}) {
    const [groupID, setGroupID] = useState(groups[0].value);

    const [announcement, setAnnouncement] = useState({
        title: '',
        description: '',
        action: '',
        redirectURL: '',
    });

    function setAnnouncementValue(type) {
        return function (value) {
            setAnnouncement({
                ...announcement,
                [type]: value
            });
        };
    }

    const [announcementErrors, setAnnouncementErrors] = useState({});

    function setAnnouncementError(type) {
        dispatch(showDangerAlert('Some required fields are empty. Please try again.'));
        setAnnouncementErrors({
            [type]: true
        });
    }

    const [invalidURL, setInvalidURL] = useState(false);

    function setInvalidURLError() {
        dispatch(showDangerAlert('The url for redirect url is invalid. Please try with a valid one.'));
        setInvalidURL(true);
    }

    const [actionable, setActionable] = useState(false);

    const [adding, setAdding] = useState(false);

    async function onAddAnnouncement() {
        setAnnouncementErrors({});
        setInvalidURL(false);
        if (!announcement.title.trim()) {
            setAnnouncementError('title');
            return;
        }
        if (!announcement.description.trim()) {
            setAnnouncementError('description');
            return;
        }
        if (actionable && !announcement.action.trim()) {
            setAnnouncementError('action');
            return;
        }
        if (actionable && !announcement.redirectURL.trim()) {
            setAnnouncementError('redirectURL');
            return;
        }
        if (actionable && !urlRegex.test(announcement.redirectURL.trim())) {
            setInvalidURLError();
            return;
        }

        setAdding(true);
        const { error } = await NotificationsAPI.pushAnnouncement(
            masjidID,
            {
                Title: announcement.title.trim(),
                Announcement: announcement.description.trim(),
                ButtonText: announcement.action.trim(),
                Link: announcement.redirectURL.trim()
            },
            groupID !== 'all' ? groupID : null
        );
        setAdding(false);
        if (error) {
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showDangerAlert('Could not push an announcement. Make sure you have "App" added to your plan and your subscription is still active.'));
                return;
            }
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        dispatch(showSuccessAlert('Successfully pushed a new announcement!'));
        browserHistory.push('/announcements/list');
    }

    function goBack() {
        browserHistory.push('/announcements/list');
    }

    return (
        <div className="view">
            <PageHeader title="Add Announcement" description="Push a new announcement to your users from here." />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="New Announcement"
                            subTitle="Fill up announcement details to get started."
                            showFooter={false}
                        >
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Announcement Title"
                                        placeholder="Title of the announcement"
                                        message="Announcement title cannot be empty."
                                        state={announcementErrors.title ? 'danger' : 'default'}
                                        value={announcement.title}
                                        onChangeValue={setAnnouncementValue('title')}
                                        maxLength={25}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Announcement"
                                        type="textarea"
                                        placeholder="The announcement itself"
                                        message="Announcement cannot be empty."
                                        state={announcementErrors.description ? 'danger' : 'default'}
                                        value={announcement.description}
                                        onChangeValue={setAnnouncementValue('description')}
                                        maxLength={500}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Select audience."
                                        type="select"
                                        options={groups}
                                        value={groupID}
                                        onChangeValue={setGroupID}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-12">
                                    <FormInput
                                        label="Actionable announcement.&nbsp;&nbsp;"
                                        type="checkbox"
                                        value={actionable}
                                        onChangeValue={setActionable}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Action Name"
                                        message="Action name cannot be empty."
                                        state={announcementErrors.action ? 'danger' : 'default'}
                                        disabled={!actionable}
                                        value={announcement.action}
                                        onChangeValue={setAnnouncementValue('action')}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Redirect URL"
                                        placeholder="The url to redirect the users to."
                                        message={invalidURL ? 'The url is invalid. Please try again with a valid one.' : 'Redirect url cannot be empty.'}
                                        state={announcementErrors.redirectURL || invalidURL ? 'danger' : 'default'}
                                        disabled={!actionable}
                                        value={announcement.redirectURL}
                                        onChangeValue={setAnnouncementValue('redirectURL')}
                                    />
                                </div>
                            </Row>
                            <Row className="mt-2">
                                <div className="col-md-6">
                                    <Button
                                        onClick={goBack}
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className="col-md-6 button-right">
                                    <Button
                                        onClick={onAddAnnouncement}
                                        loading={adding}
                                    >
                                        Push Announcement
                                    </Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

AddAnnouncement.propTypes = {
    masjidID: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired
};

const mapStateToProps = state => ({
    masjidID: state.masjid.data.ID,
    groups: [{ label: 'Everyone', value: 'all' }].concat(getGroups(state))
});

export default connect(mapStateToProps)(AddAnnouncement);
