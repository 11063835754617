import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Alert,
    Badge,
    Collapse
} from 'reactstrap';
import { browserHistory } from 'react-router';
import IconArrowDownward from 'react-icons/lib/md/keyboard-arrow-down';
import { connect } from 'react-redux';
import { needsToFillAccountData, hasDonationsInPlan } from '../../store/selectors/masjid';

function PerformActionsAlert({ NeedsToFillAccountData, HasDonationsInPlan, DonationsEnabled }) {
    const [isPendingActionsOpen, setIsPendingActionsOpen] = useState(false);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        const newActions = [];
        if (NeedsToFillAccountData) {
            newActions.push({
                title: 'Masjd not visible in App.',
                description: 'Since some vital information regarding your Masjid is missing, it will not show up inside our App.',
                onFix: () => {
                    browserHistory.push('/account?editMasjid=true');
                }
            });
        }
        if (!DonationsEnabled && HasDonationsInPlan) {
            newActions.push({
                title: 'Donations not configured.',
                description: 'You will not be able to receive donations without making and activating a Stripe account for your Masjid.',
                onFix: () => {
                    browserHistory.push('/configure-stripe');
                }
            });
        }
        setActions(newActions);
    }, [NeedsToFillAccountData, DonationsEnabled]);

    if (actions.length === 0) {
        return null;
    }
    return (
        <Row className="mb-2">
            <div className="col-md-12 col-sm-6">
                <Alert color="danger" className="pending-actions-alert" onClick={() => setIsPendingActionsOpen(!isPendingActionsOpen)}>
                    <div className="badge-container">
                        <Badge color="danger" pill><span className="h6">{actions.length}</span></Badge>
                    </div>
                    <Row className="align-items-center">
                        <div className="col-md-6 col-sm-3">
                            <b>YOU HAVE A FEW ITEMS THAT NEED URGENT ATTENTION.</b>
                            <br />
                            Click this notification to know more.
                        </div>
                        <div className="col-md-6 col-sm-3 arrow-container">
                            <IconArrowDownward size={25} className={`arrow ${isPendingActionsOpen ? 'open' : ''}`} />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <Collapse isOpen={isPendingActionsOpen}>
                                {actions.map(({ title, description, onFix }, index) => (
                                    <PerformActionStep
                                        index={index + 1}
                                        key={title}
                                        title={title}
                                        description={description}
                                        onFix={onFix}
                                    />
                                ))}
                            </Collapse>
                        </div>
                    </Row>
                </Alert>
            </div>
        </Row>
    );
}

PerformActionsAlert.propTypes = {
    NeedsToFillAccountData: PropTypes.bool.isRequired,
    HasDonationsInPlan: PropTypes.bool.isRequired,
    DonationsEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    NeedsToFillAccountData: needsToFillAccountData(state),
    HasDonationsInPlan: hasDonationsInPlan(state),
    DonationsEnabled: state.masjid.data.DonationsEnabled,
});

function PerformActionStep({
    index, title, description, onFix
}) {
    return (
        <Row className="mt-4">
            <div className="col-md-12">
                <b>
                    {`${index}) ${title}`}
                </b>
                {' '}
                (
                <b className="fix-text" onClick={onFix} role="button">Fix This?</b>
                )
                <br />
                {' '}
                {description}
                {' '}
            </div>
        </Row>
    );
}

PerformActionStep.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onFix: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null, null, {
    areStatesEqual: (s1, s2) => s1.masjid.data === s2.masjid.data,
})(PerformActionsAlert);
