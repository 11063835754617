
import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    DescriptionList
} from '../../shared/components';

function Announcement({
    location:
    {
        state:
        {
            title,
            announcement,
            sentTime,
            sentToAll,
            groupName,
            action,
            redirectURL
        }
    }
}) {
    function backToAnnouncements(event) {
        event.preventDefault();
        browserHistory.push('/announcements/list');
    }

    return (
        <div className="view">
            <PageHeader
                title="Announcement"
                description="Here's an annoncement that you pushed."
            />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title={title}
                            subTitle="More information about this announcement."
                            showFooter={false}
                        >
                            <DescriptionList
                                list={[
                                    { label: 'Announcement', value: announcement },
                                    { label: 'Notification Sent Time', value: sentTime },
                                    { label: 'For Group', value: !sentToAll ? 'Yes' : 'No' },
                                    { label: 'Group Name', value: groupName || '-' },
                                    { label: 'Actionable', value: action ? 'Yes' : 'No' },
                                    { label: 'Action', value: action || '-' },
                                    { label: 'Redirect URL', value: redirectURL || '-' },
                                ]}
                            />
                            <a // eslint-disable-line
                                className="mt-4"
                                href="/annoncements/list"
                                onClick={backToAnnouncements}
                            >
                                Back to Announcements
                            </a>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

Announcement.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            title: PropTypes.string.isRequired,
            announcement: PropTypes.string.isRequired,
            sentTime: PropTypes.string.isRequired,
            sentToAll: PropTypes.bool.isRequired,
            groupName: PropTypes.string.isRequired,
            action: PropTypes.string.isRequired,
            redirectURL: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default Announcement;
