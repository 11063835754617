// Action types dispatched from action creators.
export const SET_MASJID = 'SET_MASJID';
export const REFRESHING_MASJID = 'REFRESHING_MASJID';
export const UPDATE_MAJSID = 'UPDATE_MASJID';
export const UPDATE_DONATION = 'UPDATE_DONATION';
export const UPDATE_RECURRING_DONATION = 'UPDATE_RECURRING_DONATION';
export const ADD_DONATION_CATEGORY = 'ADD_DONATION_CATEGORY';
export const UPDATE_DONATION_CATEGORY = 'UPDATE_DONATION_CATEGORY';
export const UPDATE_DONATION_CATEGORY_POSITION = 'UPDATE_DONATION_CATEGORY_POSITION';
export const DELETE_DONATION_CATEGORY = 'DELETE_DONATION_CATEGORY';
export const KIOSK_PURCHASED = 'KIOSK_PURCHASED';
export const ADD_ADMIN = 'ADD_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const ADD_GROUP = 'ADD_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const CANCEL_PROGRAM = 'CANCEL_PROGRAM';
export const UNENROLL_ATTENDEE = 'UNENROLL_ATTENDEE';
export const UPDATE_ATTENDEE_PAYMENT = 'UPDATE_ATTENDEE_PAYMENT';
export const REFUND_ATTENDEE = 'REFUND_ATTENDEE';
export const POS_PURCHASED = 'POS_PURCHASED';
