import React from 'react';
import PropTypes from 'prop-types';
import { Provider as MusalleenProvider } from 'musalleen-react-theme';
import ReduxProvider from './ReduxProvider';
import ErrorBoundary from './ErrorBoundary';

function Provider({ children }) {
    return (
        <ErrorBoundary>
            <ReduxProvider>
                <MusalleenProvider>
                    {children}
                </MusalleenProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

Provider.propTypes = {
    children: PropTypes.element.isRequired
};

export default Provider;
