import mustBe from './mustBe';
import mustBeOneOf from './mustBeOneOf';

export {
    mustBe
};

export const mustBeSuperAdmin = mustBe({ admin: { role: 'SUPER_ADMIN' } });
export const mustBeTeacher = mustBe({ admin: { role: 'SUPER_ADMIN' } });
export const mustBeSuperAdminOrTeacher = mustBeOneOf([
    { admin: { role: 'SUPER_ADMIN' } },
    { admin: { role: 'TEACHER' } },
]);
export const mustHaveKioskBase = mustBe({ masjid: { data: { HasKioskBase: true } } });
export const mustHavePOSBase = mustBe({ masjid: { data: { HasPOSBase: true } } });
export const mustHaveAppBase = mustBe({ masjid: { data: { HasAppBase: true } } });
export const mustHaveWebsiteBase = mustBe({ masjid: { data: { HasWebsiteBase: true } } });
export const mustHaveTvDisplayBase = mustBe(
    { masjid: { data: { HasTvDisplayBase: true } } }
);
export const mustHaveAppDonationsInPlan = mustBe({
    masjid: {
        data: {
            HasAppDonationsAddOn: true
        }
    }
});
export const mustHaveRecurringDonationsInPlan = mustBeOneOf([
    {
        masjid: {
            data: {
                HasKioskRecurringDonationsAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasOnlineRecurringDonationsAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasPOSRecurringDonationsAddOn: true
            }
        },
    },
    {
        masjid: {
            data: {
                HasWebsiteBase: true
            }
        }
    }
]);
export const mustHaveClassRegistrationInPlan = mustBeOneOf([
    {
        masjid: {
            data: {
                HasKioskClassRegistrationAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasOnlineClassRegistrationAddOn: true
            }
        }
    },
    {
        masjid: {
            data: {
                HasWebsiteBase: true
            }
        }
    }
]);
export const mustHaveEventRegistrationInPlan = mustBeOneOf([
    {
        masjid: {
            data: {
                HasKioskEventRegistrationAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasOnlineEventRegistrationAddOn: true
            }
        }
    },
    {
        masjid: {
            data: {
                HasWebsiteBase: true
            }
        }
    }
]);
export const mustHaveDonationsInPlan = mustBeOneOf([
    {
        masjid: {
            data: {
                HasKioskBase: true
            }
        }
    }, {
        masjid: {
            data: {
                HasAppDonationsAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasOnlineDonationsAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasPOSBase: true
            }
        }
    },
    {
        masjid: {
            data: {
                HasWebsiteBase: true
            }
        }
    }
]);
export const mustHaveOnlineDonationsInPlan = mustBe({
    masjid: {
        data: {
            HasOnlineDonationsAddOn: true
        }
    },
});
export const mustHaveOnlineProgramRegistrationInPlan = mustBeOneOf([
    {
        masjid: {
            data: {
                HasOnlineClassRegistrationAddOn: true
            }
        }
    }, {
        masjid: {
            data: {
                HasOnlineEventRegistrationAddOn: true
            }
        }
    }
]);
