import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import IconInfo from 'react-icons/lib/md/info-outline';


const UpgradePlanAlert = ({ description, show }) => {
    if (show) {
        return null;
    }
    return (
        <div style={{ marginTop: '10px', }}>
            <IconInfo
                size={25}
                style={{ marginBottom: '4px' }}
            />
            {' '}
            <p className="mb-0 subtitle" style={{ display: 'inline' }}>
                {description}
                {' '}
                <Link
                    to={{
                        pathname: '/account',
                        state: { fromUpgrade: true },
                    }}
                    style={{ fontSize: '15px', fontWeight: 'bold' }}
                >
                    upgrade
                    {' '}

                </Link>
                your plan to advanced.

            </p>
        </div>
    );
};


UpgradePlanAlert.propTypes = {
    description: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
};

export default UpgradePlanAlert;
