/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconRighthand from 'react-icons/lib/fa/hand-o-right';

import { connect } from 'react-redux';
import {
    Row,
    Col,
} from 'reactstrap';
import PropTypes from 'prop-types';

import IconCheck from 'react-icons/lib/io/ios-checkmark';

import { Link } from 'react-router';
import {
    InfoCard,
    PageHeader,
    Button,
    DescriptionList,
} from '../../shared/components';

import {
    showSuccessAlert
} from '../../store/actions';

import { getTvDisplayLink } from '../../utils/website';
import UpgradePlanAlert from '../ui/extras/UpgradePlanAlert';

const TvDisplay = ({
    masjidID, dispatch, hasTvDisplayAdvancedAddOn
}) => {
    const UpgradeNow = () => {
        if (!hasTvDisplayAdvancedAddOn) {
            return (
                <Link
                    to={{
                        pathname: '/account',
                        state: { fromUpgrade: true },
                    }}
                >
                    <Button size="sm" color="secondary">Upgrade Now</Button>
                </Link>
            );
        }
        return (
            <>
                {' '}
                <IconCheck className="text-success" size={16} />
            </>
        );
    };

    return (
        <div className="view">
            <PageHeader title="Configure Your Tv Display" description="View and configure your tv display." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Your Tv Display"
                    subTitle="Your very own tv display that's managed by us."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-12">
                            Thank you for choosing our tv display!
                            We have tailor made your tv display for you.
                            <br />
                            The website is already production level
                            that is served to all the people living
                            around the globe quickly using a blazing fast CDN.
                            <br />
                            Visit it now from the link below to see how
                            it will look on your TV.
                        </div>
                    </Row>
                    <br />
                    <Row className="align-items-center">
                        <Col md="7">
                            <div>
                                <span className="h5 donation-link">
                                    <IconRighthand className="text-primary mr-2" />
                                    <a
                                        href={getTvDisplayLink(masjidID)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {getTvDisplayLink(masjidID)}
                                    </a>
                                </span>
                                &nbsp;
                                &nbsp;
                                <CopyToClipboard
                                    text="generatedWebsiteUrl"
                                    onCopy={() => {
                                        dispatch(showSuccessAlert(`Copied ${'generatedWebsiteUrl'} to clipboard!`));
                                    }}
                                >
                                    <Button color="secondary" size="sm">
                                        Copy to Clipboard
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row />
                    <Row className="mt-4">
                        <div className="col-md-12">
                            Status:
                            {' '}
                            <b>
                                <span className="text-success">
                                    <span className="fade-in-out">• </span>
                                    Live
                                </span>
                            </b>
                        </div>
                    </Row>
                    <small className="text-primary">
                        Your Tv Display is live now
                    </small>
                </InfoCard>
                <Row>
                    <div className="col-md-6">
                        <InfoCard
                            title="Advanced features"
                            subTitle="These features will be enabled when you upgrade your plan"
                            showFooter={false}
                        >

                            <DescriptionList list={[
                                {
                                    label: 'Configure upto 3 Jumahs  🕌',
                                    value: <UpgradeNow />
                                },
                                {
                                    label: 'Create more than 2 flyers  🕌',
                                    value: <UpgradeNow />

                                },
                                {
                                    label: 'Configure upto 3 Announcements  🕌',
                                    value: <UpgradeNow />
                                },
                                {
                                    label: 'Upload Masjid Logo 🕌',
                                    value: <UpgradeNow />
                                },

                            ]}
                            />
                            &nbsp;
                            <h6>Below features will be available soon in advanced plan</h6>
                            &nbsp;
                            <DescriptionList list={[
                                {
                                    label: 'Choose between Quran / Hadees',
                                    value: <Button size="sm" color="secondary" disabled>Coming Soon</Button>
                                },
                                {
                                    label: 'Automatic Adhan Timing Configuration',
                                    value: <Button size="sm" color="secondary" disabled>Coming Soon</Button>
                                },
                                {
                                    label: 'Upload yearly salah timing configuration',
                                    value: <Button size="sm" color="secondary" disabled>Coming Soon</Button>
                                }
                            ]}
                            />

                            <br />
                        </InfoCard>
                    </div>
                    <div className="col-md-6">
                        <InfoCard
                            title="Free plan"
                            subTitle="These features are already included with your Tv Display for free"
                            showFooter={false}
                        >

                            <DescriptionList list={[
                                {
                                    label: 'Editable Salah Timings 🕌',
                                    value: <IconCheck className="text-success" size={16} />
                                },
                                {
                                    label: 'Create One Jumah 🕌',
                                    value: <IconCheck className="text-success" size={16} />
                                },
                                {
                                    label: 'Create One Flyer  🕌',
                                    value: <IconCheck className="text-success" size={16} />
                                },
                                {
                                    label: 'Automatic Quran Ayat   🕌',
                                    value: <IconCheck className="text-success" size={16} />
                                },
                                {
                                    label: 'Create One Announcement  🕌',
                                    value: <IconCheck className="text-success" size={16} />
                                },
                            ]}
                            />

                            <br />
                        </InfoCard>
                    </div>
                </Row>
                <Row>
                    <div style={{ marginLeft: '10px' }}>
                        <UpgradePlanAlert description="To Configure Quran/Hadith display and update logo " show={hasTvDisplayAdvancedAddOn} />
                    </div>
                </Row>
            </div>
        </div>
    );
};
TvDisplay.propTypes = {
    masjidID: PropTypes.number.isRequired,
    hasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ masjid: { data } }) => ({
    masjidID: data.ID,
    hasTvDisplayAdvancedAddOn: data.HasTvDisplayAdvancedAddOn
});
export default connect(mapStateToProps)(TvDisplay);
