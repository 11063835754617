import React, {
    useState, useRef, Fragment, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import IconRighthand from 'react-icons/lib/fa/hand-o-right';
import IconCheck from 'react-icons/lib/io/ios-checkmark';
import IconClose from 'react-icons/lib/io/ios-close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
    PageHeader,
    InfoCard,
    Button,
    DescriptionList
} from '../../shared/components';
import { getHomePageLink, getEditPageLink } from '../../utils/website';
import {
    showBadRequestAlert,
    showDangerAlert,
    showDialog,
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert,
    updateWebsiteDomainName
} from '../../store/actions';
import './index.scss';
import { Constants, WebsiteAPI } from '../../api';

const WebsiteStatus = {
    LIVE: 'LIVE',
    DEPLOYING: 'DEPLOYING',
    DOWN: 'DOWN',
    LOADING: 'LOADING'
};

const domainRegistrars = [
    {
        name: 'GoDaddy',
        instructionsLink: 'https://www.hostgator.com/help/article/how-to-change-godaddy-name-servers'
    },
    {
        name: 'Google Domains',
        instructionsLink: 'https://support.google.com/domains/answer/6353515?hl=en'
    },
    {
        name: 'Namecheap',
        instructionsLink: 'https://www.namecheap.com/support/knowledgebase/article.aspx/767/10/how-to-change-dns-for-a-domain/'
    },
    {
        name: 'BigRock',
        instructionsLink: 'https://manage.bigrock.in/kb/node/3'
    },
];

const defaultNameServers = [
    'ns-1427.awsdns-50.org',
    'ns-1752.awsdns-27.co.uk',
    'ns-798.awsdns-35.net',
    'ns-82.awsdns-10.com'
];

function Website({
    WebsiteUserID,
    WebsiteAccessToken,
    GeneratedWebsiteUrl,
    WebsiteDomainName,
    WebsiteNameServers,
    HasWebsiteAdditionalPageAddOn,
    dispatch
}) {
    const editRef = useRef();
    const generatedWebsiteUrl = GeneratedWebsiteUrl || getHomePageLink(WebsiteUserID);
    const generatedWebsiteEditUrl = getEditPageLink(generatedWebsiteUrl, WebsiteAccessToken);
    const [websiteStatus, setWebsiteStatus] = useState(WebsiteStatus.LOADING);
    const [editingDomainName, setEditingDomainName] = useState(false);

    useEffect(() => {
        (async () => {
            const { data, error, originalError } = await WebsiteAPI.getWebsiteStatus(
                generatedWebsiteUrl
            );
            if (error) {
                if (error === Constants.CLIENT_ERROR) {
                    dispatch(showBadRequestAlert(originalError));
                    return;
                }
                if (error === Constants.SERVER_ERROR) {
                    dispatch(showServerErrorAlert());
                    return;
                }
                dispatch(showNoInternetAlert());
                return;
            }
            const { statusCode } = data;
            if (statusCode === 200) {
                setWebsiteStatus(WebsiteStatus.LIVE);
                return;
            }
            if (statusCode === 404) {
                setWebsiteStatus(WebsiteStatus.DEPLOYING);
                return;
            }
            setWebsiteStatus(WebsiteStatus.DOWN);
        })();
    }, [generatedWebsiteUrl]);


    const onEditDomainName = async () => {
        const [confirm, { domainName }] = await dispatch(showDialog(
            'Choose a Domain Name',
            'Enter the domain name you want to use for your website. Please be aware that you cannot use subdomains at this point. Also, please note that you do not need to prefix your domain name with "www".',
            'Choose',
            'Cancel',
            {
                domainName: {
                    label: 'Enter Domain Name',
                    placeholder: 'yourawesomedomain.com',
                    initialValue: WebsiteDomainName
                }
            }
        ));
        if (!confirm) {
            return;
        }
        if (!domainName || !domainName.trim()) {
            dispatch(showDangerAlert('Domain name cannot be empty.'));
            return;
        }
        if (domainName.includes('www.')) {
            dispatch(showDangerAlert('Domain cannot contain "www".'));
            return;
        }
        // ~ https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd.
        if (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domainName.trim())) {
            dispatch(showDangerAlert('Looks like an invalid domain name. Please try again with a valid one.'));
            return;
        }
        setEditingDomainName(true);
        await dispatch(updateWebsiteDomainName(domainName.trim()));
        setEditingDomainName(false);
    };

    const getWebSiteStatusDescription = () => {
        switch (websiteStatus) {
        case WebsiteStatus.LIVE:
            return 'Your website is currently live!';
        case WebsiteStatus.DEPLOYING:
            return (
                <>
                    If you have just
                    signed up, it may take us a
                    {' '}
                    <b>few mins to a couple of hours</b>
                    {' '}
                    to make your site live.
                </>
            );
        case WebsiteStatus.DOWN:
            return 'Your website is currently down. Rest assured we are working on it and are trying to fix it as soon as possible.';
        default:
            return 'Please wait...';
        }
    };

    const getWebSiteStatusColorClass = () => {
        switch (websiteStatus) {
        case WebsiteStatus.LIVE:
            return 'text-success';
        case WebsiteStatus.DEPLOYING:
            return 'text-warning';
        case WebsiteStatus.DOWN:
            return 'text-danger';
        case WebsiteStatus.LOADING:
            return 'text-muted';
        default:
            return '';
        }
    };

    return (
        <div className="view">
            <PageHeader title="Configure Your Website" description="View and configure your website." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Your Website"
                    subTitle="Your very own website that's managed by us."
                    showFooter={false}
                    showButtonInHeader
                    buttonInHeaderLabel="Edit Content"
                    onClickButtonInHeader={() => {
                        editRef.current.click();
                    }}
                >
                    <Row>
                        <div className="col-md-12">
                            Thank you for choosing our website base add on!
                            We have tailor made your website for you.
                            <br />
                            The website is already production level
                            that is served to all the people living
                            around the globe quickly using a blazing fast CDN.
                            <br />
                            Visit it now from the link below to see how
                            it will look to your visitors.
                        </div>
                    </Row>
                    <br />
                    <Row className="align-items-center">
                        <div className="ml-2 mr-2 mt-2">
                            <span className="h5 donation-link">
                                <IconRighthand className="text-primary mr-2" />
                                <a
                                    href={generatedWebsiteUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {generatedWebsiteUrl}
                                </a>
                            </span>
                        </div>
                        <div className="col mt-2">
                            <CopyToClipboard
                                text={generatedWebsiteUrl}
                                onCopy={() => {
                                    dispatch(showSuccessAlert(`Copied ${generatedWebsiteUrl} to clipboard!`));
                                }}
                            >
                                <Button color="secondary" size="sm">
                                    Copy to Clipboard
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div className="col-md-12">
                            <a ref={editRef} href={generatedWebsiteEditUrl} target="_blank" rel="noreferrer noopener">
                                Tap here to make changes to your website and add content.
                            </a>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className="col-md-12">
                            Status:
                            {' '}
                            <b>
                                <span className={getWebSiteStatusColorClass()}>
                                    <span className={websiteStatus !== WebsiteStatus.DOWN ? 'fade-in-out' : ''}>• </span>
                                    {websiteStatus}
                                </span>
                            </b>
                        </div>
                    </Row>
                    <small>
                        {getWebSiteStatusDescription()}
                    </small>
                </InfoCard>
                <Row>
                    <div className="col-md-6">
                        <InfoCard
                            title="Features of Your Website"
                            subTitle="More information about your website."
                            showFooter={false}
                        >
                            <Row>
                                <div className="col-md-12">
                                    Depending upon your current plan,
                                    your website has the following features.
                                    <br />
                                    <br />
                                    <DescriptionList list={[
                                        {
                                            label: 'Custom Landing Page 🕌',
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'Basic Widgets ♥',
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'Online Donations $',
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'PWA & Offline Support ✓',
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'Blazing Fast CDN ⚡',
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'Bring Your Own Domain 🌐',
                                            // value: HasWebsiteAdditionalPageAddOn
                                            //     ? <EnabledWebsiteFeature premiumFeature />
                                            //     : <DisabledWebsiteFeature premiumFeature />
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'HTTPS & SSL - Your Domain 🔒',
                                            // value: HasWebsiteAdditionalPageAddOn
                                            //     ? <EnabledWebsiteFeature premiumFeature />
                                            //     : <DisabledWebsiteFeature premiumFeature />,
                                            value: <EnabledWebsiteFeature />
                                        },
                                        {
                                            label: 'Additional Custom Pages 📃',
                                            value: HasWebsiteAdditionalPageAddOn
                                                ? <EnabledWebsiteFeature premiumFeature />
                                                : <DisabledWebsiteFeature premiumFeature />
                                        },
                                        {
                                            label: 'Premium Widgets 🔥',
                                            value: HasWebsiteAdditionalPageAddOn
                                                ? <EnabledWebsiteFeature premiumFeature />
                                                : <DisabledWebsiteFeature premiumFeature />
                                        },
                                    ]}
                                    />
                                    <br />
                                    <small>* Included with Website Base.</small>
                                    <br />
                                    <small>** Included with Website Premium.</small>
                                </div>
                            </Row>
                            <br />
                        </InfoCard>
                    </div>
                    <div className="col-md-6">
                        <InfoCard
                            title="Custom Domain"
                            subTitle="You can choose a custom domain name for your website here."
                            showFooter={false}
                            // locked={!HasWebsiteAdditionalPageAddOn}
                        >
                            <Row>
                                <div className="col-md-12">
                                    You can also choose a custom domain name for your website.
                                    <br />
                                    In order to set this up and hook it up with your website,
                                    there are only two simple steps that you need to do.
                                    <br />
                                    Once you finish these steps, please allow a time of
                                    maximum
                                    {' '}
                                    <b>2 days</b>
                                    {' '}
                                    for us to process these changes.
                                    (This time is however a lot less most of the time)
                                    <br />
                                    The changes will be reflected in this section after the
                                    processing is complete.
                                    <br />
                                    <br />
                                    The steps are as follows:
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </Row>
                            <h6>
                                1) Enter a domain name.
                            </h6>
                            <Row className="align-items-center">
                                <div className="ml-2 mr-2 mt-2">
                                    <span className="donation-link">
                                        {WebsiteDomainName
                                            ? (
                                                <>
                                                    <IconRighthand className="mr-2" />
                                                    You have chosen
                                                    {' '}
                                                    <b>{WebsiteDomainName}</b>
                                                    .
                                                </>
                                            )
                                            : 'No domain found. Tap on "Enter" to enter one.'}
                                    </span>
                                </div>
                                <div className="col mt-2">
                                    {generatedWebsiteUrl === `https://${WebsiteDomainName}`
                                        ? (
                                            <span className="text-success">
                                                <IconCheck className="mr-1" />
                                                Domain Verified.
                                            </span>
                                        )
                                        : (
                                            <Button
                                                color="secondary"
                                                size="sm"
                                                onClick={onEditDomainName}
                                                loading={editingDomainName}
                                                // disabled={!HasWebsiteAdditionalPageAddOn}
                                            >
                                                {WebsiteDomainName ? 'Change' : 'Enter'}
                                            </Button>
                                        )
                                    }
                                </div>
                            </Row>
                            <br />
                            <br />
                            <h6>
                                2)
                                {" Change name servers in your domain's DNS settings."}
                            </h6>
                            <span>
                                In order for us to configure your domain name so that
                                it servers your website,
                                you need to change the name servers for your domain name.
                                The exact steps that you need to follow to do this
                                will vary slightly from one domain registrar to another.
                                The name servers we need to add instead of the original ones
                                are these
                                {' '}
                                <b>four</b>
                                :
                                <br />
                                <br />
                                <br />
                                <h5>
                                    {/* eslint-disable-next-line */}
                                    {(WebsiteNameServers.length ? WebsiteNameServers : defaultNameServers)
                                        .map((nameServer) => (
                                            <Fragment key={nameServer}>
                                                <code>{nameServer}</code>
                                                <br />
                                                <br />
                                            </Fragment>
                                        ))}
                                </h5>
                                Make sure you add all of them by replacing the original ones.
                            </span>
                            <br />
                            <br />
                            If you want to know more about changing nameservers for your
                            domain registrar, you can follow these links:
                            <br />
                            <br />
                            {domainRegistrars.map(({ name, instructionsLink }) => (
                                <Fragment key={name}>
                                    <a href={instructionsLink} target="_blank" rel="noreferrer noopener">
                                        Tap here to see instructions for
                                        {' '}
                                        <b>{name}</b>
                                        .
                                    </a>
                                    <br />
                                </Fragment>
                            ))}
                            <br />
                            <a href="https://www.cloudflare.com/learning/dns/what-is-dns" target="_blank" rel="noreferrer noopener">
                                Tap here to know more about DNS name servers in general.
                            </a>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

Website.propTypes = {
    WebsiteUserID: PropTypes.number.isRequired,
    WebsiteAccessToken: PropTypes.string.isRequired,
    GeneratedWebsiteUrl: PropTypes.string,
    WebsiteDomainName: PropTypes.string,
    WebsiteNameServers: PropTypes.arrayOf(PropTypes.string).isRequired,
    HasWebsiteAdditionalPageAddOn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
};

Website.defaultProps = {
    GeneratedWebsiteUrl: null,
    WebsiteDomainName: null
};

const mapStateToProps = ({ masjid: { data } }) => ({
    WebsiteUserID: data.WebsiteUserID,
    WebsiteAccessToken: data.WebsiteAccessToken,
    GeneratedWebsiteUrl: data.GeneratedWebsiteUrl,
    WebsiteDomainName: data.WebsiteDomainName,
    WebsiteNameServers: data.WebsiteNameServers,
    HasWebsiteAdditionalPageAddOn: data.HasWebsiteAdditionalPageAddOn
});

export default connect(mapStateToProps)(Website);

function EnabledWebsiteFeature({ premiumFeature }) {
    return (
        <span>
            Enabled*
            {premiumFeature && '*'}
            {' '}
            <IconCheck className="text-success" size={16} />
        </span>
    );
}

EnabledWebsiteFeature.defaultProps = {
    premiumFeature: false
};

EnabledWebsiteFeature.propTypes = {
    premiumFeature: PropTypes.bool,
};

function DisabledWebsiteFeature({ premiumFeature }) {
    return (
        <span>
            Disabled*
            {premiumFeature && '*'}
            {' '}
            <IconClose className="text-danger" size={16} />
            {' '}
            <small>
                <Link to="/account">
                    {' '}
                    <Button size="sm" color="secondary">Enable Now</Button>
                </Link>
            </small>
        </span>
    );
}

DisabledWebsiteFeature.defaultProps = {
    premiumFeature: false
};

DisabledWebsiteFeature.propTypes = {
    premiumFeature: PropTypes.bool,
};
