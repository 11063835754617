import formatDate from 'date-fns/format';
import getTime from 'date-fns/get_time';
import isInLeapYear from 'date-fns/is_leap_year';
import isDateAFriday from 'date-fns/is_friday';
import parseDate from 'date-fns-2/parse';
import startOfDay from 'date-fns/start_of_day';
import compareDsc from 'date-fns/compare_desc';
import differenceInDays from 'date-fns/difference_in_days';
import addMinutes from 'date-fns/add_minutes';

const days = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday'
};

// [{
//       label: 'Sunday',
//       value: '1'
// }, {
//       label: 'Monday',
//       value: '2'
// }]
const daysAsLabelValuePairs = Object.entries(days).map(([value, label]) => ({ label, value: `${value}` }));

const format = (date, dateFormat) => formatDate(date, dateFormat);

const parse = (dateString, dateFormat) => parseDate(dateString, dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y'), new Date());

// This converts a date or a UNIX timestamp or a date string
// into a local date with date/time equal to the original date's ISO date/time.
const getISOVersion = date => addMinutes(date, new Date().getTimezoneOffset());

// Accepts a format YYYY-MM-DDTHH:mm.
const toMillis = dateString => getTime(dateString);

const weekDayName = day => days[day];

const isBefore = (
    dateString,
    dateString2,
    dateFormat,
    dateFormat2 = dateFormat
) => compare(dateString, dateString2, dateFormat, dateFormat2) > -1;

const isAfter = (
    dateString,
    dateString2,
    dateFormat,
    dateFormat2 = dateFormat
) => compare(dateString, dateString2, dateFormat, dateFormat2) < 1;

const compare = (
    dateString,
    dateString2,
    dateFormat,
    dateFormat2 = dateFormat
) => compareDsc(
    startOfDay(parse(dateString, dateFormat)),
    startOfDay(parse(dateString2, dateFormat2))
);

const getCurrentMonth = () => new Date().getMonth();

const getCurrentYear = () => new Date().getFullYear();

const isLeapYear = year => isInLeapYear(new Date(year, 1, 1));

const isFriday = (date, month, year) => isDateAFriday(new Date(year, month, date));

const getDaysAsLabelValuePairs = () => daysAsLabelValuePairs;

const getDaysBetweenDates = (date1, date2) => differenceInDays(date1, date2);

const getDaysBetweenDateStrings = (
    dateString1,
    dateString2,
    dateFormat1,
    dateFormat2 = dateFormat1
) => getDaysBetweenDates(parse(dateString1, dateFormat1), parse(dateString2, dateFormat2));

export {
    format,
    parse,
    getISOVersion,
    toMillis,
    isBefore,
    isAfter,
    compare,
    getCurrentMonth,
    getCurrentYear,
    isLeapYear,
    isFriday,
    weekDayName,
    getDaysAsLabelValuePairs,
    getDaysBetweenDates,
    getDaysBetweenDateStrings
};
