const PORTAL_URL = process.env.REACT_APP_PORTAL_URL || 'https://musalleen-portal-develop.herokuapp.com';


const getDonationsLink = masjidID => `${PORTAL_URL}/masjid/${masjidID}/donation`;
const getDonationLink = (masjidID, category, amount, recurring, recurringMonths) => `${PORTAL_URL}/masjid/${masjidID}/donation?category=${category}&amount=${amount}&recurring=${recurring}&recurringMonths=${recurringMonths}`;
const getDonationsLinkWithDashboardReferrer = masjidID => `${getDonationsLink(masjidID)}?from=dashboard`;
const getDonationLinkWithDashboardReferrer = (masjidID, category, amount, recurring, recurringMonths) => `${getDonationLink(masjidID, category, amount, recurring, recurringMonths)}&from=dashboard`;

const formatMasjidNameForSnippet = masjidName => masjidName.replace(/ /g, '%2520');

const getProgramsLink = masjidID => `${PORTAL_URL}/masjid/${masjidID}/programs`;
const getProgramLink = (masjidID, programID) => `${PORTAL_URL}/masjid/${masjidID}/programs/${programID}`;
const getProgramsLinkWithDashboardReferrer = masjidID => `${getProgramsLink(masjidID)}?from=dashboard`;
const getProgramLinkWithDashboardReferrer = (masjidID, programID) => `${getProgramLink(masjidID, programID)}?from=dashboard`;

export {
    getDonationsLink,
    getDonationLink,
    getDonationsLinkWithDashboardReferrer,
    getDonationLinkWithDashboardReferrer,
    formatMasjidNameForSnippet,
    getProgramsLink,
    getProgramLink,
    getProgramsLinkWithDashboardReferrer,
    getProgramLinkWithDashboardReferrer
};
