import React from 'react';
import PropTypes from 'prop-types';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { EnvironmentUtils } from '../utils';

const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'f05426638631f6ef08829f6d125ab623',
    notifyReleaseStages: ['production', 'stage', 'develop'],
    releaseStage: process.env.REACT_APP_BUGSNAG_STAGE || EnvironmentUtils.getCurrentEnvironment()
});

bugsnagClient.use(bugsnagReact, React);
const Boundary = bugsnagClient.getPlugin('react');

const setBugsnagUser = (name, email, masjidID, masjidName) => {
    bugsnagClient.user = {
        name,
        email
    };
    bugsnagClient.metaData = {
        masjid: {
            id: masjidID,
            name: masjidName
        }
    };
};

const removeBugsnagUser = () => {
    bugsnagClient.user = null;
    bugsnagClient.metaData = null;
};

function ErrorBoundary({ children }) {
    return (
        <Boundary>
            {children}
        </Boundary>
    );
}

ErrorBoundary.propTypes = {
    children: PropTypes.element.isRequired
};

export { setBugsnagUser, removeBugsnagUser };
export default ErrorBoundary;
