import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    DescriptionList
} from '../../shared/components';

function DonationRequest({
    location:
     {
         state:
           {
               title,
               description,
               amount,
               categoryName,
               sentTime,
               groupName,
               sentToAll
           }
     }
}) {
    function backToDonationRequests(event) {
        event.preventDefault();
        browserHistory.push('/donation-requests/list');
    }

    return (
        <div className="view">
            <PageHeader
                title="Donation Request"
                description="Here's a donation request that you pushed."
            />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title={title}
                            subTitle="More information about this donation request."
                            showFooter={false}
                        >
                            <DescriptionList
                                list={[
                                    { label: 'Description', value: description },
                                    { label: 'Amount', value: amount },
                                    { label: 'Notification Sent Time', value: sentTime },
                                    { label: 'Donation Category', value: categoryName },
                                    { label: 'For Group', value: !sentToAll ? 'Yes' : 'No' },
                                    { label: 'Group Name', value: groupName || '-' },
                                ]}
                            />
                            <a // eslint-disable-line
                                className="mt-4"
                                href="/donation-requests/list"
                                onClick={backToDonationRequests}
                            >
                                Back to Donation Requests
                            </a>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

DonationRequest.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired,
            sentTime: PropTypes.string.isRequired,
            categoryName: PropTypes.string.isRequired,
            groupName: PropTypes.string.isRequired,
            sentToAll: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired
};

export default DonationRequest;
