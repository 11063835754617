import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip as Tooltip
} from 'reactstrap';
import IconArrowUp from 'react-icons/lib/fa/angle-up';
import IconArrowDown from 'react-icons/lib/fa/angle-down';
import IconArrowDoubleDown from 'react-icons/lib/fa/angle-double-down';
import {
    FormInput,
    Button
} from '../../shared/components';
import { DateUtils } from '../../utils';


function EditTimingsModal({
    show,
    onClose,
    onSave,
    salats,
    timings,
    totalDaysInMonth,
    year,
    month,
    isDateValid
}) {
    const [editing, setEditing] = useState(false);

    const [prayers, setPrayers] = useState({
        // This state will eventually hold the prayer times like so.
        // {
        //   1: {
        //      Fajr: '01:01',
        //      ...rest of the salats inside the salats prop
        //   }
        //   ...rest of the valid dates
        // }
    });

    useEffect(() => {
        setPrayers({
            ...timings
        });
    }, [timings]);

    async function handleSave() {
        setEditing(true);
        // We'll be relying on the fact that this onSave returns a promise.
        await onSave(prayers);
        setEditing(false);
    }

    function handleClose() {
        onClose();
        resetModal();
    }

    function resetModal() {
        // Need to reset the timings when the modal closes.
        setPrayers({
            ...timings
        });
    }

    // Change totalDaysInMonth => [1, 2, 3, ..., totalDaysInMonth].
    // To array of dates which pass the filterFunc.
    function getDates(filterFunc) {
        return Array(totalDaysInMonth)
            .fill(null)
            .map((_, index) => index + 1)
            .filter(filterFunc);
    }

    function handleCopy(date, below, depth) {
        // Basically to copy the prayer times for
        // this chosen date to all dates below.
        return function () {
            // Get all the dates that are valid and below or above the chosen date.
            let dates = getDates(d => (
                isDateValid(d)
                && ((below && d > date)
                || (!below && d < date))));

            if (!below) {
                dates = dates.reverse();
            }
            setPrayers({
                ...prayers,
                ...dates
                    // Pick number of dates above or below depending upon depth.
                    // Pick all dates if depth is undefined.
                    .slice(0, depth)
                    // Convert them to prayer objects.
                    .reduce((acc, d) => {
                        if (prayers[date]) {
                            // If prayer times exist for the chosen date,
                            // add those to the dates below.
                            acc[d] = prayers[date];
                        }
                        return acc;
                    }, {})
            });
        };
    }

    function handleCopyAbove(date) {
        return handleCopy(date, false, 1);
    }

    function handleCopyBelow(date) {
        return handleCopy(date, true, 1);
    }

    function handleCopyBelowToAll(date) {
        return handleCopy(date, true);
    }

    function handleTimeChange(date, salat) {
        return function (value) {
            setPrayers({
                ...prayers,
                [date]: {
                    ...prayers[date],
                    [salat]: value
                }
            });
        };
    }

    return (
        <Modal
            isOpen={show}
            className="default modal-xlg salat-modal"
        >
            <ModalHeader
                toggle={handleClose}
            >
                Edit Salat Timings
                <br />
                {DateUtils.format(new Date(year, month, 1), 'MMMM YYYY')}
            </ModalHeader>
            <ModalBody>
                {getDates(isDateValid)
                    .map(date => (
                        <Row className="salat-input-container" key={`${year}${month}${date}`}>
                            <div className="col-md-12 mt-3">
                                {DateUtils.format(new Date(year, month, date), 'Do')}
                            </div>
                            {salats.map(salat => (
                                <div
                                    key={`${salat}${date}`}
                                    className="col-md"
                                >
                                    <FormInput
                                        label={salat}
                                        type="time"
                                        // If a salat time exists for this date salat pair,
                                        // use that or else just use an empty string.
                                        value={(prayers[date] && prayers[date][salat]) ? prayers[date][salat] : ''}
                                        onChangeValue={handleTimeChange(date, salat)}
                                    />
                                </div>
                            ))}
                            <div className="col-md-2 d-flex justify-content-between copy-buttons mb-2">
                                <div id={`up-button-${date}`}>
                                    <Button onClick={handleCopyAbove(date)}>
                                        <IconArrowUp size={18} />
                                    </Button>
                                </div>
                                <Tooltip
                                    placement="top"
                                    target={`up-button-${date}`}
                                    delay={{
                                        show: 500,
                                        hide: 0
                                    }}
                                >
                                    Copy timings to the previous day.
                                </Tooltip>
                                <div id={`down-button-${date}`}>
                                    <Button onClick={handleCopyBelow(date)}>
                                        <IconArrowDown size={18} />
                                    </Button>
                                </div>
                                <Tooltip
                                    placement="bottom"
                                    target={`down-button-${date}`}
                                    delay={{
                                        show: 500,
                                        hide: 0
                                    }}
                                >
                                    Copy timings to the next day.
                                </Tooltip>
                                <div id={`double-down-button-${date}`}>
                                    <Button onClick={handleCopyBelowToAll(date)}>
                                        <IconArrowDoubleDown size={18} />
                                    </Button>
                                </div>
                                <Tooltip
                                    placement="bottom"
                                    target={`double-down-button-${date}`}
                                    delay={{
                                        show: 500,
                                        hide: 0
                                    }}
                                >
                                    {'Copy timings to each and every day that\'s below.'}
                                </Tooltip>
                            </div>
                        </Row>
                    ))}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleSave}
                    loading={editing}
                    // Don't allow an edit if all fields were empty
                    // and weren't touched either.
                    disabled={!Object.keys(prayers).length}
                >
                    Save
                </Button>
                {' '}
                <Button color="secondary" onClick={handleClose} disabled={editing}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

EditTimingsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    /**
     * This should ideally return a promise so we can show an editing state.
     * */
    onSave: PropTypes.func.isRequired,
    /**
     * Salat inputs that will be rendered for each date.
     * */
    salats: PropTypes.arrayOf(PropTypes.string).isRequired,
    timings: PropTypes.shape({ }).isRequired,
    totalDaysInMonth: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    /**
     * Whether to show the date or not. It is given dateOfMonth as the first argument.
     * This will be useful in case of settin Jummah salah timings.
     * */
    isDateValid: PropTypes.func
};

EditTimingsModal.defaultProps = {
    isDateValid: () => true
};

export default EditTimingsModal;
