import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row,
} from 'reactstrap';
import IconTablet from 'react-icons/lib/fa/tablet';
import Select from 'react-select';
import {
    PageHeader,
    InfoCard,
    Button,
    PaymentWidget,
    FormInput
} from '../../shared/components';
import { StringUtils, PaymentUtils } from '../../utils';
import { MasjidAPI, Constants } from '../../api';
import {
    showDialog,
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    rentKiosks
} from '../../store/actions';


function PurchaseKiosk({
    dispatch, Last4, operatorMode
}) {
    const [inventory, setInventory] = useState({
        FourteenInchKioskRentalPrice: '',
        EighteenInchKioskRentalPrice: '',
        TwentyOneInchKioskRentalPrice: '',
        KioskShippingPrice: '',
        NumberOfFourteenInchKiosksAvailable: 0,
        NumberOfEighteenInchKiosksAvailable: 0,
        NumberOfTwentyOneInchKiosksAvailable: 0,
        KioskMinimumRentalMonths: 0,
    });
    const [inventoryLoading, setInventoryLoading] = useState(true);
    const [rentalMonths, setRentalMonths] = useState(0);
    const [invalidRentalMonths, setInvalidRentalMonths] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [showPaymentWidget, setShowPaymentWidget] = useState(false);
    const [cart, setCart] = useState({
        fourteen: 0,
        eighteen: 0,
        twentyOne: 0
    });

    function setCartValue(screenSize) {
        return function ({ value }) {
            setCart({
                ...cart,
                [screenSize]: value
            });
        };
    }

    useEffect(() => {
        loadInventory();
    }, []);

    async function loadInventory() {
        const { error, data } = await MasjidAPI.getInventory();
        if (error) {
            // Ignoring CLIENT_ERROR as we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        setInventory(data);
        setRentalMonths(data.KioskMinimumRentalMonths);
        setInventoryLoading(false);
    }

    function getQuantityOptions(range) {
        // 10 is the maximum number of kiosks (for a screen size)
        // that the Masjids can order at a time
        if (range > 10) {
            range = 10; // eslint-disable-line
        }
        return Array(range + 1).fill(null).map((_, index) => ({ value: index, label: `${index}` }));
    }

    function getTotalCost(months = rentalMonths) {
        const {
            fourteen,
            eighteen,
            twentyOne
        } = cart;
        const {
            FourteenInchKioskRentalPrice,
            EighteenInchKioskRentalPrice,
            TwentyOneInchKioskRentalPrice
        } = inventory;
        return (fourteen * months * FourteenInchKioskRentalPrice)
            + (eighteen * months * EighteenInchKioskRentalPrice)
            + (twentyOne * months * TwentyOneInchKioskRentalPrice);
    }

    function getTotalShippingCost() {
        let cost = 0;
        for (const kiosksPerSize of Object.values(cart)) {
            cost += (kiosksPerSize * inventory.KioskShippingPrice);
        }
        return cost;
    }

    async function onCheckout() {
        setInvalidRentalMonths('');
        if (!getTotalCost()) {
            dispatch(showDangerAlert('Cannot checkout with zero kiosks. Please select some and try again.'));
            return;
        }
        if (rentalMonths < inventory.KioskMinimumRentalMonths) {
            setInvalidRentalMonths(`Cannot be less than ${inventory.KioskMinimumRentalMonths} month(s)`);
            return;
        }
        const [confirm, { useExisting }] = await dispatch(showDialog(
            'Are You Sure?',
            `Are you sure you want to pay an upfront cost of
            <strong>
                ${StringUtils.formatAmount(getTotalCost() + getTotalShippingCost(), false)}?
            </strong>
            <br/>
            <br/>
            After ${rentalMonths} month(s) you will be charged ${StringUtils.formatAmount(getTotalCost(1), false)} / month.`,
            'Choose & Continue',
            'Cancel',
            {
                useExisting: {
                    label: 'Choose Payment Option',
                    type: 'select',
                    initialValue: 'true',
                    options: [{
                        label: `Existing Card (${StringUtils.formatLast4Digits(Last4)})`,
                        value: 'true'
                    }, {
                        label: 'New Card',
                        value: 'false'
                    }]
                }
            }
        ));
        if (!confirm) {
            return;
        }
        if (useExisting === 'true') {
            onConfirm(null);
            return;
        }
        setShowPaymentWidget(true);
    }

    async function onConfirm(token) {
        setProcessingPayment(true);
        let purchase = {
            stripeToken: token,
            useExistingCard: !token,
            rentalMonths: +rentalMonths,
            numberOfFourteenInch: cart.fourteen,
            numberOfEighteenInch: cart.eighteen,
            numberOfTwentyOneInch: cart.twentyOne,
        };
        if (operatorMode) {
            purchase = {
                ...purchase,
                rentalPriceForFourteenInchKiosk: +inventory.FourteenInchKioskRentalPrice,
                rentalPriceForEighteenInchKiosk: +inventory.EighteenInchKioskRentalPrice,
                rentalPriceForTwentyOneInchKiosk: +inventory.TwentyOneInchKioskRentalPrice,
                shippingPrice: +inventory.KioskShippingPrice
            };
        }
        const error = await dispatch(rentKiosks(purchase));
        setProcessingPayment(false);
        if (error) {
            // Error handling has already been taken care of in the action creator.
            return;
        }
        // Push to kiosk list screen so the admin can see the new Kiosks.
        browserHistory.push('/kiosks/list');
    }

    async function onEditPrices() {
        const [confirm, prices] = await dispatch(showDialog('Edit Prices?', 'Do you want to edit the default rental prices for Kiosks and their shipping?', 'Yes, Edit', 'Cancel', {
            FourteenInchKioskRentalPrice: {
                initialValue: inventory.FourteenInchKioskRentalPrice,
                label: 'Rental For 14" Inch Kiosk',
                type: 'number'
            },
            EighteenInchKioskRentalPrice: {
                initialValue: inventory.EighteenInchKioskRentalPrice,
                label: 'Rental For 18" Inch Kiosk',
                type: 'number'
            },
            TwentyOneInchKioskRentalPrice: {
                initialValue: inventory.TwentyOneInchKioskRentalPrice,
                label: 'Rental For 21" Inch Kiosk',
                type: 'number'
            },
            KioskShippingPrice: {
                initialValue: inventory.KioskShippingPrice,
                label: 'Shipping Price Per Kiosk',
                type: 'number'
            }
        }));
        if (!confirm) {
            return;
        }
        setInventory({ ...inventory, ...prices });
    }

    return (
        <div className="view">
            <PageHeader title="Rent Kiosks" description="You can rent more kiosks from here." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Rent Kiosks"
                            subTitle="Choose one or more kiosks that you want to rent and add to your existing kiosks by choosing a quantity from the dropdown next to each screen size."
                            showButtonInHeader={operatorMode}
                            onClickButtonInHeader={onEditPrices}
                            buttonInHeaderLabel="Edit Rental Prices"
                            showFooter={false}

                        >
                            <Row className="mt-4">
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={50} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            14"
                                        </span>
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.FourteenInchKioskRentalPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfFourteenInchKiosksAvailable
                                                - cart.fourteen}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfFourteenInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('fourteen')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={70} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            18"
                                        </span>
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.EighteenInchKioskRentalPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfEighteenInchKiosksAvailable
                                                - cart.eighteen}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfEighteenInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('eighteen')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={90} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            21"
                                        </span>
                                        {' '}
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.TwentyOneInchKioskRentalPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfTwentyOneInchKiosksAvailable
                                                - cart.twentyOne}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfTwentyOneInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('twentyOne')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-2 ml-4 tablet-container">
                                    <FormInput
                                        label={`Pay Upfront For (Min ${inventory.KioskMinimumRentalMonths} Months)`}
                                        type="number"
                                        placeholder="0"
                                        className="col-md-6 col-sm-12"
                                        isDisabled={inventoryLoading}
                                        value={rentalMonths}
                                        onChangeValue={setRentalMonths}
                                        state={invalidRentalMonths ? 'danger' : 'default'}
                                        message={invalidRentalMonths}
                                    />

                                </div>
                            </Row>
                            <div className="col-md-12">
                                <br />
                                Note:
                                <br />
                                All the kiosks shown above and come with the following items.
                                <br />
                                • Pre Installed Software.
                                <br />
                                • Card Reader.
                                <br />
                                • Wall Mount.
                                <br />
                                <br />
                            </div>
                            <Row className="col-md-12 checkout-container mt-5 mb-4">
                                <div className="col-md-6">
                                    Total:
                                    {' '}
                                    <strong>
                                        {StringUtils.formatAmount(getTotalCost(), false)}
                                    </strong>
                                    <br />
                                    Shipping:
                                    {' '}
                                    <strong>
                                        {StringUtils.formatAmount(getTotalShippingCost(), false)}
                                    </strong>
                                </div>
                                <div className="col-md-6 button-right">
                                    <Button
                                        disabled={inventoryLoading}
                                        loading={processingPayment}
                                        onClick={onCheckout}
                                    >
                                        Checkout
                                    </Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>
            <PaymentWidget
                show={showPaymentWidget}
                onClose={() => setShowPaymentWidget(false)}
                onPayment={onConfirm}
                totalAmount={StringUtils.formatAmount(
                    getTotalCost() + getTotalShippingCost(), false
                )}
                apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
            />
        </div>
    );
}

PurchaseKiosk.propTypes = {
    Last4: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    operatorMode: PropTypes.bool.isRequired
};

const mapStateToProps = ({ admin: { operatorMode }, masjid: { data: { Last4 } } }) => ({
    Last4,
    operatorMode,
});

export default connect(mapStateToProps)(PurchaseKiosk);
