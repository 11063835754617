import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Nav from './shared/components/nav';
import SiteHead from './shared/components/header';
import { refreshMasjid } from './store/actions';
import { isLoggedIn } from './store/selectors';
import { Loader } from './shared/components';
import 'musalleen-react-theme/lib/theme/theme.scss';
import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navMini: false
        };
    }

    componentWillMount = () => {
        // Refresh Masjid's data in case we need to.
        this.refresh();
        // Do it on every page change except for Signin and 404.
        this.historyUnlisten = browserHistory.listen(({ pathname }) => {
            if (!(pathname.includes('signin') || pathname.includes('404'))) {
                this.refresh();
            }
        });
    }

    componentWillUnmount() {
        // If we moved to the signin screen instead of
        // showing the dashboard, this would be undefined.
        if (this.historyUnlisten) {
            this.historyUnlisten();
        }
    }

    refresh() {
        const { isAdminLoggedIn, dispatch } = this.props;
        // Redirect to sign in if the admin isn't logged in.
        if (!isAdminLoggedIn) {
            browserHistory.replace('/pages/signin');
            return;
        }
        // Refresh Masjid's data in case we need to.
        dispatch(refreshMasjid());
    }

    toggleNav = (e) => {
        e.preventDefault();
        this.setState(({ navMini }) => ({ navMini: !navMini }));
    }

    hideNav = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ navMini: false });
    }

    renderBody = () => {
        const { navMini } = this.state;
        const { refreshingMasjid, children, operatorMode } = this.props;
        if (refreshingMasjid) {
            return (
                <div className="loader-container">
                    <Loader
                        height={200}
                        thin
                    />
                </div>
            );
        }
        return (
            <Fragment>
                <Nav mini={navMini} toggleNav={this.toggleNav} />
                <div className={`content-container ${navMini ? 'full' : ''}`}>
                    {/* dropshadow for mobile nav triggering */}
                    <div role="button" className="menu-dropshadow" style={{ display: (navMini ? 'block' : 'none') }} onClick={this.hideNav} />
                    <SiteHead toggleNav={this.toggleNav} />
                    {children}
                </div>
                {operatorMode && (
                    <div className="operator-mode-container">
                        Operator Mode
                        <div className="caption">{'You\'re viewing as an operator.'}</div>
                    </div>
                )}
            </Fragment>
        );
    };

    render() {
        return (
            <div className="app-wrapper">
                {this.renderBody()}
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    isAdminLoggedIn: PropTypes.bool.isRequired,
    operatorMode: PropTypes.bool.isRequired,
    refreshingMasjid: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAdminLoggedIn: isLoggedIn(state),
    operatorMode: state.admin.operatorMode,
    // Display a loading state only when there is no stale data
    // and the masjid data is refreshing. (i.e It is fresh page load)
    refreshingMasjid: state.masjid.refreshing && !state.masjid.updatedAt
});

export default connect(
    mapStateToProps,
    null,
    null,
    {
        areStatesEqual: (nextState, prevState) => (
            nextState.admin === prevState.admin
            && nextState.masjid.refreshing === prevState.masjid.refreshing
            && nextState.masjid.updatedAt === prevState.masjid.updatedAt
        )
    }
)(App);
