import client, { returnResponse } from './client';

const { get, post, put } = client;

const getTvDisplayItems = async (masjidId) => returnResponse(
    await get(`/tv-display/${masjidId}`)
);

const getTvDisplayFlyerItems = async (tvDisplayId) => returnResponse(
    await get(`/tv-display/${tvDisplayId}/flyers`)
);

const saveTvDisplayPrayerTimes = async (tvDisplayId, timings) => returnResponse(
    await put(`/tv-display/${tvDisplayId}/prayer-times`, timings)
);

const saveTvDisplayFlyer = async (tvDisplayId, flyer, flyerId) => returnResponse(
    await put(`/tv-display/${tvDisplayId}/flyers/${flyerId}`, flyer)
);

const addNewTvDisplayFlyer = async (tvDisplayId, flyer) => returnResponse(
    await post(`/tv-display/${tvDisplayId}/flyers`, flyer)
);

const deleteTvDisplayFlyer = async (tvDisplayId, flyerId) => returnResponse(
    await client.delete(`/tv-display/${tvDisplayId}/flyers/${flyerId}`)
);

const addNewTvDisplayAnnouncement = async (tvDisplayId, announcement) => returnResponse(
    await client.post(`/tv-display/${tvDisplayId}/announcements`, announcement)
);

const saveTvDisplayAnnouncement = async (
    tvDisplayId, announcementId, announcement
) => returnResponse(
    await put(`/tv-display/${tvDisplayId}/announcements/${announcementId}`, announcement)
);

const deleteTvDisplayAnnouncement = async (tvDisplayId, announcementId) => returnResponse(
    await client.delete(`/tv-display/${tvDisplayId}/announcements/${announcementId}`)
);

const updateTvDisplaySettings = async (tvDisplayId, settings) => returnResponse(
    await put(`/tv-display/${tvDisplayId}/settings`, settings)
);
export {
    getTvDisplayItems,
    getTvDisplayFlyerItems,
    saveTvDisplayPrayerTimes,
    saveTvDisplayFlyer,
    addNewTvDisplayFlyer,
    deleteTvDisplayFlyer,
    addNewTvDisplayAnnouncement,
    saveTvDisplayAnnouncement,
    deleteTvDisplayAnnouncement,
    updateTvDisplaySettings
};
