import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row,
} from 'reactstrap';
import IconTablet from 'react-icons/lib/fa/tablet';
import Select from 'react-select';
import {
    PageHeader,
    InfoCard,
    Button,
    PaymentWidget
} from '../../shared/components';
import { StringUtils, PaymentUtils } from '../../utils';
import { MasjidAPI, Constants } from '../../api';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    purchaseKiosks,
    showDialog,
    updateNewMasjid
} from '../../store/actions';


function PurchaseKiosk({
    dispatch, Last4, operatorMode, masjidID
}) {
    const [inventory, setInventory] = useState({
        EighteenInchKioskPrice: '',
        FourteenInchKioskPrice: '',
        TwentyOneInchKioskPrice: '',
        KioskShippingPrice: '',
        NumberOfFourteenInchKiosksAvailable: 0,
        NumberOfEighteenInchKiosksAvailable: 0,
        NumberOfTwentyOneInchKiosksAvailable: 0
    });
    const [inventoryLoading, setInventoryLoading] = useState(true);
    const [showPaymentWidget, setShowPaymentWidget] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [checkoutAfterAddCard, setCheckoutAfterAddCard] = useState(false);
    const [cart, setCart] = useState({
        fourteen: 0,
        eighteen: 0,
        twentyOne: 0
    });

    function setCartValue(screenSize) {
        return function ({ value }) {
            setCart({
                ...cart,
                [screenSize]: value
            });
        };
    }

    useEffect(() => {
        loadInventory();
    }, []);

    async function loadInventory() {
        const { error, data } = await MasjidAPI.getInventory();
        if (error) {
            // Ignoring CLIENT_ERROR as we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        setInventory(data);
        setInventoryLoading(false);
    }

    function getQuantityOptions(range) {
        // 10 is the maximum number of kiosks (for a screen size)
        // that the Masjids can order at a time
        if (range > 10) {
            range = 10; // eslint-disable-line
        }
        return Array(range + 1).fill(null).map((_, index) => ({ value: index, label: `${index}` }));
    }

    function getTotalCost() {
        const {
            fourteen,
            eighteen,
            twentyOne
        } = cart;
        const {
            FourteenInchKioskPrice,
            EighteenInchKioskPrice,
            TwentyOneInchKioskPrice
        } = inventory;
        return (fourteen * FourteenInchKioskPrice)
            + (eighteen * EighteenInchKioskPrice)
            + (twentyOne * TwentyOneInchKioskPrice);
    }

    function getTotalShippingCost() {
        let cost = 0;
        for (const kiosksPerSize of Object.values(cart)) {
            cost += (kiosksPerSize * inventory.KioskShippingPrice);
        }
        return cost;
    }

    async function onCheckout() {
        // Added since operator needs to send kiosk with zero prices.
        if (getTotalCost() === 0 && getTotalShippingCost() === 0 && operatorMode) {
            onConfirm();
            return;
        }

        if (!getTotalCost()) {
            dispatch(showDangerAlert('Cannot checkout with zero kiosks. Please select some and try again.'));
            return;
        }

        // if we have user's card details
        if (Last4) {
            const [confirm, { useExisting }] = await dispatch(showDialog(
                'Are You Sure?',
                `Are you sure you want to confirm this purchase and pay
                <strong>
                    ${StringUtils.formatAmount(getTotalCost() + getTotalShippingCost(), false)}?
                </strong>`,
                'Choose & Continue',
                'Cancel',
                {
                    useExisting: {
                        label: 'Choose Payment Option',
                        type: 'select',
                        initialValue: 'true',
                        options: [{
                            label: `Existing Card (${StringUtils.formatLast4Digits(Last4)})`,
                            value: 'true'
                        }, {
                            label: 'New Card',
                            value: 'false'
                        }]
                    }
                }
            ));
            if (!confirm) {
                return;
            }
            if (useExisting === 'true') {
                onConfirm(null);
                return;
            }
            setShowPaymentWidget(true);
        } else {
            // We ask user to add a card
            setProcessingPayment(true);
            setCheckoutAfterAddCard(true);
        }
    }

    async function onConfirm(token) {
        const isFreeKiosk = getTotalCost() === 0 && getTotalShippingCost() === 0 && operatorMode;
        if (!processingPayment) {
            setProcessingPayment(true);
        }
        let purchase = {
            stripeToken: token,
            useExistingCard: isFreeKiosk ? false : !token,
            numberOfFourteenInch: cart.fourteen,
            numberOfEighteenInch: cart.eighteen,
            numberOfTwentyOneInch: cart.twentyOne,
        };
        if (operatorMode) {
            purchase = {
                ...purchase,
                priceOfFourteenInchKiosk: +inventory.FourteenInchKioskPrice,
                priceOfEighteenInchKiosk: +inventory.EighteenInchKioskPrice,
                priceOfTwentyOneInchKiosk: +inventory.TwentyOneInchKioskPrice,
                shippingPrice: +inventory.KioskShippingPrice
            };
        }
        const error = await dispatch(purchaseKiosks(purchase));
        setProcessingPayment(false);
        if (error) {
            // Error handling has already been taken care of in the action creator.
            return;
        }
        // Push to kiosk list screen so the admin can see the new Kiosks.
        browserHistory.push('/kiosks/list');
    }

    async function onEditPrices() {
        const [confirm, prices] = await dispatch(showDialog('Edit Prices?', 'Do you want to edit the default prices for Kiosks and their shipping?', 'Yes, Edit', 'Cancel', {
            FourteenInchKioskPrice: {
                initialValue: inventory.FourteenInchKioskPrice,
                label: 'Price For 14" Inch Kiosk',
                type: 'number'
            },
            EighteenInchKioskPrice: {
                initialValue: inventory.EighteenInchKioskPrice,
                label: 'Price For 18" Inch Kiosk',
                type: 'number'
            },
            TwentyOneInchKioskPrice: {
                initialValue: inventory.TwentyOneInchKioskPrice,
                label: 'Price For 21" Inch Kiosk',
                type: 'number'
            },
            KioskShippingPrice: {
                initialValue: inventory.KioskShippingPrice,
                label: 'Shipping Price Per Kiosk',
                type: 'number'
            }
        }));
        if (!confirm) {
            return;
        }
        setInventory({ ...inventory, ...prices });
    }

    async function onAddCard(token) {
        const result = await dispatch(updateNewMasjid({
            StripeToken: token,
            ID: masjidID
        }));
        if (result === null) {
            setCheckoutAfterAddCard(false);
            const confirm = await dispatch(showDialog(
                'Are You Sure?',
                'Are you sure you want to confirm this purchase and pay',
                'Choose & Continue',
                'Cancel'
            ));
            if (!confirm) {
                return;
            }
            onConfirm();
            return;
        }
        setCheckoutAfterAddCard(false);

        onConfirm();
        // The errors and success message have already been handled in the action creator.
    }

    return (
        <div className="view">
            <PageHeader title="Purchase Kiosks" description="You can purchase more kiosks from here." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Kiosks"
                            subTitle="Choose one or more kiosks that you want to add to your existing kiosks by choosing a quantity from the dropdown next to each screen size."
                            showButtonInHeader={operatorMode}
                            onClickButtonInHeader={onEditPrices}
                            buttonInHeaderLabel="Edit Prices"
                            showFooter={false}
                        >
                            <Row className="mt-4">
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={50} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            14"
                                        </span>
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.FourteenInchKioskPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfFourteenInchKiosksAvailable
                                                - cart.fourteen}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfFourteenInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('fourteen')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={70} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            18"
                                        </span>
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.EighteenInchKioskPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfEighteenInchKiosksAvailable
                                                - cart.eighteen}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfEighteenInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('eighteen')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4 tablet-container">
                                    <div className="tablet-icon-container">
                                        <IconTablet className="text-primary" size={90} />
                                    </div>
                                    <div className="ml-4 screen-size-container">
                                        <span className="h4">
                                            21"
                                        </span>
                                        {' '}
                                        <span className="kiosk-price h6 ml-1">
                                            (
                                            {StringUtils.formatAmount(
                                                inventory.TwentyOneInchKioskPrice,
                                                false
                                            )}
                                            )
                                        </span>
                                        {' '}
                                        <span className="kiosk-price text-muted">
                                            {inventory.NumberOfTwentyOneInchKiosksAvailable
                                                - cart.twentyOne}
                                            {' '}
                                            left
                                        </span>
                                    </div>
                                    <div className="quantity-container">
                                        <Select
                                            options={getQuantityOptions(
                                                inventory.NumberOfTwentyOneInchKiosksAvailable
                                            )}
                                            isSearchable={false}
                                            placeholder="Quantity"
                                            className="col-md-6 col-sm-12"
                                            isDisabled={inventoryLoading}
                                            defaultValue={{ label: '0', value: 0 }}
                                            onChange={setCartValue('twentyOne')}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#eaeaea',
                                                    primary: '#075e55',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <div className="col-md-12">
                                <br />
                                Note:
                                <br />
                                All the kiosks shown above and come with the following items.
                                <br />
                                • Pre Installed Software.
                                <br />
                                • Card Reader.
                                <br />
                                • Wall Mount.
                                <br />
                                <br />
                            </div>
                            <Row className="checkout-container mt-5 mb-4 col-md-12">
                                <div className="col-md-6">
                                    Total:
                                    {' '}
                                    <strong>
                                        {StringUtils.formatAmount(getTotalCost(), false)}
                                    </strong>
                                    <br />
                                    Shipping:
                                    {' '}
                                    <strong>
                                        {StringUtils.formatAmount(getTotalShippingCost(), false)}
                                    </strong>
                                </div>
                                <div className="col-md-6 button-right">
                                    <Button
                                        disabled={inventoryLoading}
                                        loading={processingPayment}
                                        onClick={onCheckout}
                                    >
                                        Checkout
                                    </Button>
                                    {/* {checkoutAfterAddCard ? onCheckout() : null} */}
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>

            <PaymentWidget
                title="Fill in card details to continue."
                show={checkoutAfterAddCard}
                onClose={() => { setCheckoutAfterAddCard(false); }}
                onPayment={(onAddCard)}
                apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
            />
            )

            <PaymentWidget
                show={showPaymentWidget}
                onClose={() => setShowPaymentWidget(false)}
                onPayment={onConfirm}
                totalAmount={StringUtils.formatAmount(
                    getTotalCost() + getTotalShippingCost(), false
                )}
                apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
            />

        </div>
    );
}

PurchaseKiosk.propTypes = {
    // eslint-disable-next-line react/require-default-props
    Last4: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    operatorMode: PropTypes.bool.isRequired,
    masjidID: PropTypes.number.isRequired
};

const mapStateToProps = ({ admin: { operatorMode }, masjid: { data: { Last4, ID } } }) => ({
    Last4,
    operatorMode,
    masjidID: ID

});

export default connect(mapStateToProps)(PurchaseKiosk);
