import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';

import { browserHistory } from 'react-router';
import {
    PageHeader,
    Table,
    Button,
    FormInput
} from '../../shared/components';
import {
    addGroupAsync, removeGroupAsync, showDialog
} from '../../store/actions';

function GroupsList({ dispatch, groups }) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [invalidName, setInvalidName] = useState('');
    const [invalidGroupCode, setInvalidGroupCode] = useState('');
    const [newGroup, setnewGroup] = useState({
        Name: '',
        GroupCode: '',
        ID: '',
        Description: ''
    });

    function showModal() {
        setModal(true);
    }

    function hideModal() {
        setModal(false);
        resetState();
    }

    function resetState() {
        setnewGroup({
            Name: '',
            GroupCode: '',
            ID: '',
            Description: ''
        });
    }

    function showCreateAdminModal() {
        showModal();
    }

    function viewGroup(group) {
        browserHistory.push({ pathname: `/groups/${group.ID}`, state: group.ID });
    }

    async function saveGroup() {
        setInvalidName('');
        setInvalidGroupCode('');

        if (!newGroup.Name) {
            setInvalidName('Name can\'t be empty');
            return;
        }
        if (!newGroup.GroupCode) {
            setInvalidGroupCode('Group Code can\'t be empty');
            return;
        }
        const transformedGroup = {
            ...newGroup,
            GroupCode: newGroup.GroupCode.toUpperCase()
        };
        setLoading(true);
        const error = await dispatch(addGroupAsync(transformedGroup));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }
    async function showDeleteDialog(group) {
        const confirm = await dispatch(showDialog('Are you sure?', 'Are you sure want to delete the group ?'));
        if (confirm) {
            await deleteGroup(group);
        }
    }

    async function deleteGroup(group) {
        const error = await dispatch(removeGroupAsync(group.ID));
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }


    function handleAdminPropertyChange(column) {
        return function (value) {
            setnewGroup({ ...newGroup, [column]: value });
        };
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Groups" description="Here are all the Groups" />
                <div className="view-content">
                    <Row>
                        <Card className="col-md-12">
                            <CardBody>
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={showCreateAdminModal}
                                            >
                                                Add New Group
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Table
                                    title="Groups"
                                    columns={[
                                        { label: 'Name', key: 'Name' },
                                        { label: 'Group Code', key: 'GroupCode' },
                                        { label: 'Description', key: 'Description' }
                                    ]}
                                    data={groups.map(group => ({ ...group, id: group.ID }))}
                                    enableFilters={false}
                                    enableExports={false}
                                    onRowClick={viewGroup}
                                    showButtonOne
                                    buttonOneLabel="Delete"
                                    onButtonOneClick={showDeleteDialog}
                                />
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={modal} toggle={showModal} className="default modal-lg">
                        <ModalHeader toggle={hideModal}>Add Group Details</ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className="col-md-12">
                                    <FormInput
                                        value={newGroup.Name}
                                        onChangeValue={handleAdminPropertyChange('Name')}
                                        row
                                        label="Name"
                                        type="text"
                                        message={invalidName}
                                        state={invalidName ? 'danger' : 'default'}
                                        maxLength={25}
                                    />
                                    <FormInput
                                        value={newGroup.GroupCode}
                                        onChangeValue={handleAdminPropertyChange('GroupCode')}
                                        row
                                        label="Group Code"
                                        type="text"
                                        placeholder="SUN101"
                                        message={invalidGroupCode}
                                        state={invalidGroupCode ? 'danger' : 'default'}
                                        maxLength={10}
                                    />
                                    <FormInput
                                        value={newGroup.Description}
                                        onChangeValue={handleAdminPropertyChange('Description')}
                                        row
                                        label="Description"
                                        type="text"
                                        placeholder="Description"
                                        maxLength={160}
                                    />
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button loading={loading} color="primary" onClick={saveGroup}>Save</Button>
                            {' '}
                            <Button color="secondary" onClick={hideModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

GroupsList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string.isRequired,
        GroupCode: PropTypes.string.isRequired,
        ID: PropTypes.number.isRequired,
        Description: PropTypes.string,
    })).isRequired
};

const mapStateToProps = state => ({
    groups: state.masjid.data.Groups
});

export default connect(mapStateToProps)(GroupsList);
