import client, { returnResponse } from './client';

const {
    get,
    patch
} = client;

const updateWebsiteDomainName = async (websiteUserID, websiteDomainName) => returnResponse(
    await patch(`/website/users/${websiteUserID}`, { DomainName: websiteDomainName })
);

const getWebsiteStatus = async (websiteUrl) => returnResponse(
    await get('/website/status', { Url: websiteUrl })
);

export {
    updateWebsiteDomainName,
    getWebsiteStatus
};
