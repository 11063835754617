/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
const convertFrom12to24 = timeStr => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
};

const convertFrom24To12 = (time24) => {
    let ts = time24;
    const H = +ts.substr(0, 2);
    let h = (H % 12) || 12;
    h = (h < 10) ? (`0${h}`) : h; // leading 0 at the left for 1 digit hours
    const ampm = H < 12 ? ' AM' : ' PM';
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

export {
    convertFrom12to24,
    convertFrom24To12
};
