import createActionCreator from './create';
import {
    SET_ADMIN_TOKEN,
    SET_ADMIN_DETAILS,
    REMOVE_ADMIN
} from '../types';
import {
    showNoInternetAlert, showServerErrorAlert
} from './alert';
import { AdminAPI, Constants, MasjidAPI } from '../../api';
import { setMasjid } from './masjid';
import { removeBugsnagUser, setBugsnagUser } from '../../providers/ErrorBoundary';

const base64 = require('base-64');

export const setAdminToken = createActionCreator(SET_ADMIN_TOKEN, 'token', 'expires', 'operatorMode');
export const setAdminDetails = createActionCreator(SET_ADMIN_DETAILS, 'firstName', 'lastName', 'email', 'role');
export const removeAdmin = createActionCreator(REMOVE_ADMIN);

// This action creator will return an API error if there's any.
// The UI only needs to handle a wrong combination of username and password.
// Other errors are handled automatically inside this action creator.
export const login = (email, password) => async dispatch => {
    const { error, data } = await AdminAPI.login(
        email,
        password
    );
    if (error) {
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
        }
        if (error === Constants.NETWORK_ERROR || error === Constants.CONNECTION_ERROR) {
            dispatch(showNoInternetAlert());
        }
        // UI needs to handle CLIENT_ERROR.
        return error;
    }

    return proceedWithLogin(data.token, data.duration, email, false, dispatch);
};

// Used with logging via ops-dashboard.
export const loginViaToken = (token, duration, email) => dispatch => proceedWithLogin(
    token,
    duration,
    email,
    true,
    dispatch
);

const proceedWithLogin = async (token, duration, email, operatorMode, dispatch) => {
    // Let's save the admin token.
    dispatch(setAdminToken(base64.encode(`${token}:x`), new Date().getTime() + (duration * 1000), operatorMode));

    // Since we have the token we can make this request
    // and query the Masjid's data.
    const { error, data } = await MasjidAPI.getMasjid(email);

    if (error) {
        // Since there is an error, we need to remove the admin token that was saved earlier.
        dispatch(removeAdmin());

        // Ignoring CLIENT_ERROR since we won't get that here.
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return error;
        }
        // In case of NETWORK_ERROR or CONNECTION_ERROR.
        dispatch(showNoInternetAlert());
        return error;
    }

    const {
        FirstName,
        LastName,
        Email,
        Role,
        Masjid,
        Masjid: {
            ID,
            Name
        }
    } = data;

    // Save the rest of admin's details.
    dispatch(setAdminDetails(FirstName, LastName, Email, Role));

    // Save the masjid's details.
    dispatch(setMasjid(Masjid));

    // Set the user inside Bugsnag.
    setBugsnagUser(`${FirstName} ${LastName}`, Email, ID, Name);

    return null;
};

// This will log the user out and remove admin inside our store.
export const logout = () => {
    // Removing the user details from Bugsnag too.
    removeBugsnagUser();

    return removeAdmin();
};


// export const resetAdminPassword = async (, dispatch) => {
export const resetAdminPassword = (email, newPassword) => async (dispatch) => {
    const { error } = await AdminAPI.resetPassword(
        email, newPassword
    );
    if (error) {
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
        }
        if (error === Constants.NETWORK_ERROR || error === Constants.CONNECTION_ERROR) {
            dispatch(showNoInternetAlert());
        }
        // UI needs to handle CLIENT_ERROR.
        return error;
    }

    return null;
};
