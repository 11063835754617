import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    FormInput,
    InfoCard,
    Button
} from '../../shared/components';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    showSuccessAlert
} from '../../store/actions';
import { getGroups } from '../../store/selectors';
import { NotificationsAPI, Constants } from '../../api';
import { DateUtils } from '../../utils';

function AddEvent({
    masjidID,
    houseNumber,
    street,
    city,
    state,
    zipcode,
    groups,
    dispatch
}) {
    const [groupID, setGroupID] = useState(groups[0].value);

    const [event, setEvent] = useState({
        title: '',
        description: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        houseNumber,
        street,
        city,
        state,
        zipcode
    });

    function setEventValue(type) {
        return function (value) {
            setEvent({
                ...event,
                [type]: value
            });
        };
    }

    const [eventErrors, setEventErrors] = useState({});

    function setEventError(type) {
        dispatch(showDangerAlert('Some required fields are empty. Please try again.'));
        setEventErrors({
            [type]: true
        });
    }

    const [startTimeGreaterThanEndTime, setStartTimeGreaterThanEndTime] = useState(false);

    function showStartTimeIsGreaterThanEndTimeError() {
        dispatch(showDangerAlert('Start time is greater than end time. Please try again.'));
        setStartTimeGreaterThanEndTime(true);
    }

    const [adding, setAdding] = useState(false);

    async function onAddEvent() {
        setEventErrors({});
        setStartTimeGreaterThanEndTime(false);
        if (!event.title.trim()) {
            setEventError('title');
            return;
        }
        if (!event.startDate.trim()) {
            setEventError('startDate');
            return;
        }
        if (!event.startTime.trim()) {
            setEventError('startTime');
            return;
        }
        if (!event.endDate.trim()) {
            setEventError('endDate');
            return;
        }
        if (!event.endTime.trim()) {
            setEventError('endTime');
            return;
        }
        if (!event.description.trim()) {
            setEventError('description');
            return;
        }
        if (!event.houseNumber.trim()) {
            setEventError('houseNumber');
            return;
        }
        if (!event.street.trim()) {
            setEventError('street');
            return;
        }
        if (!event.city.trim()) {
            setEventError('city');
            return;
        }
        if (!event.state.trim()) {
            setEventError('state');
            return;
        }
        if (!event.zipcode.trim()) {
            setEventError('zipcode');
            return;
        }
        const startTimeMillis = DateUtils.toMillis(`${event.startDate}T${event.startTime}Z`);
        const endTimeMillis = DateUtils.toMillis(`${event.endDate}T${event.endTime}Z`);

        if (startTimeMillis > endTimeMillis) {
            showStartTimeIsGreaterThanEndTimeError();
            return;
        }

        setAdding(true);
        const { error } = await NotificationsAPI.pushEvent(
            masjidID,
            {
                Title: event.title.trim(),
                StartTime: startTimeMillis,
                EndTime: endTimeMillis,
                Description: event.description.trim(),
                HouseNumber: event.houseNumber.trim(),
                Street: event.street.trim(),
                City: event.city.trim(),
                State: event.state.trim(),
                ZipCode: event.zipcode.trim()
            },
            groupID !== 'all' ? groupID : null
        );
        setAdding(false);
        if (error) {
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showDangerAlert('Could not push an event. Make sure you have "App" added to your plan and your subscription is still active.'));
                return;
            }
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        dispatch(showSuccessAlert('Successfully pushed a new event!'));
        browserHistory.push('/events/list');
    }

    function goBack() {
        browserHistory.push('/events/list');
    }

    return (
        <div className="view">
            <PageHeader title="Add Event" description="Push a new event to your users from here." />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="New Event"
                            subTitle="Fill up event details to get started."
                            showFooter={false}
                        >
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Event Title"
                                        placeholder="Title of the event"
                                        message="Event title cannot be empty."
                                        state={eventErrors.title ? 'danger' : 'default'}
                                        value={event.title}
                                        onChangeValue={setEventValue('title')}
                                        maxLength={30}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Start Date"
                                        type="date"
                                        message={startTimeGreaterThanEndTime ? 'Start time is greater than end time.' : 'Start date cannot be empty.'}
                                        state={eventErrors.startDate || startTimeGreaterThanEndTime ? 'danger' : 'default'}
                                        value={event.startDate}
                                        onChangeValue={setEventValue('startDate')}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Start Time"
                                        type="time"
                                        message={startTimeGreaterThanEndTime ? 'Start time is greater than end time.' : 'Start time cannot be empty.'}
                                        state={eventErrors.startTime || startTimeGreaterThanEndTime ? 'danger' : 'default'}
                                        value={event.startTime}
                                        onChangeValue={setEventValue('startTime')}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="End Date"
                                        type="date"
                                        message={startTimeGreaterThanEndTime ? 'End time is lesser than start time.' : 'End date cannot be empty.'}
                                        state={eventErrors.endDate || startTimeGreaterThanEndTime ? 'danger' : 'default'}
                                        value={event.endDate}
                                        onChangeValue={setEventValue('endDate')}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        label="End Time"
                                        type="time"
                                        message={startTimeGreaterThanEndTime ? 'End time is lesser than start time.' : 'End time cannot be empty.'}
                                        state={eventErrors.endTime || startTimeGreaterThanEndTime ? 'danger' : 'default'}
                                        value={event.endTime}
                                        onChangeValue={setEventValue('endTime')}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Event Description"
                                        type="textarea"
                                        message="Event description cannot be empty."
                                        state={eventErrors.description ? 'danger' : 'default'}
                                        value={event.description}
                                        onChangeValue={setEventValue('description')}
                                        maxLength={200}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="House Number"
                                        message="House number cannot be empty."
                                        state={eventErrors.houseNumber ? 'danger' : 'default'}
                                        value={event.houseNumber}
                                        onChangeValue={setEventValue('houseNumber')}
                                        maxLength={10}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Street"
                                        message="Street cannot be empty."
                                        state={eventErrors.street ? 'danger' : 'default'}
                                        value={event.street}
                                        onChangeValue={setEventValue('street')}
                                        maxLength={50}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="City"
                                        message="City cannot be empty."
                                        state={eventErrors.city ? 'danger' : 'default'}
                                        value={event.city}
                                        onChangeValue={setEventValue('city')}
                                        maxLength={25}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        label="State"
                                        message="State cannot be empty."
                                        state={eventErrors.state ? 'danger' : 'default'}
                                        value={event.state}
                                        onChangeValue={setEventValue('state')}
                                        maxLength={25}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Zip Code"
                                        type="number"
                                        message="Zip Code cannot be empty."
                                        state={eventErrors.zipcode ? 'danger' : 'default'}
                                        value={event.zipcode}
                                        onChangeValue={setEventValue('zipcode')}
                                        maxLength={10}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Select audience."
                                        type="select"
                                        options={groups}
                                        value={groupID}
                                        onChangeValue={setGroupID}
                                    />
                                </div>
                            </Row>
                            <Row className="mt-2">
                                <div className="col-md-6">
                                    <Button
                                        onClick={goBack}
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className="col-md-6 button-right">
                                    <Button
                                        onClick={onAddEvent}
                                        loading={adding}
                                    >
                                        Push Event
                                    </Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

AddEvent.propTypes = {
    masjidID: PropTypes.number.isRequired,
    houseNumber: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired
};

const mapStateToProps = state => ({
    masjidID: state.masjid.data.ID,
    houseNumber: state.masjid.data.HouseNumber,
    street: state.masjid.data.Street,
    city: state.masjid.data.City,
    state: state.masjid.data.State,
    zipcode: state.masjid.data.Zipcode,
    groups: [{ label: 'Everyone', value: 'all' }].concat(getGroups(state))
});

export default connect(mapStateToProps)(AddEvent);
