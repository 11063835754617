import React from 'react';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    InfoCard,
} from '../../shared/components';

function ConfigureStripe() {
    return (
        <div className="view">
            <PageHeader title="How to Configure Donations?" description="This page is a guide on how to configure Stripe and start receiving donations." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="Introduction to Stripe"
                            subTitle="What is Stripe and why do we need it for donations?"
                            showFooter={false}
                        >
                            Musalleen is not a payment gateway and
                            does not handle payments by itself.
                            <br />
                            It relies on a third party service called
                            {' '}
                            <a href="https://stripe.com" target="_blank" rel="noopener noreferrer"><b>Stripe</b></a>
                            {' '}
                            to transfer donation amounts to your bank account.
                            Therefore, to start receiving donations you need to create
                            and activate a Stripe account for your Masjid. This page tries
                            to explain more about this process and helps you through it.
                            <br />
                            <br />
                            To configure Stripe and start receiving donations, there
                            are
                            {' '}
                            <b>three</b>
                            {' '}
                            main steps that you need to complete:
                            <br />
                            <br />
                            <a href="#stripe-account">• Create and activate a Stripe account.</a>
                            <br />
                            <br />
                            <a href="#connect-stripe-musalleen">• Transfer Stripe keys to your Musalleen account.</a>
                            <br />
                            <br />
                            <a href="#fees-waiver">
                                {'• Ask for a fee waiver in Stripe\'s processing fees.'}
                            </a>
                            <br />
                        </InfoCard>
                        <div id="stripe-account" />
                        <InfoCard
                            title="Create a Stripe account"
                            subTitle="Create and activate a Stripe account. This is divided into the following sub instructions."
                            showFooter={false}
                        >
                            1. Please go to
                            {' '}
                            <a href="https://stripe.com" target="_blank" rel="noopener noreferrer"><b>www.stripe.com</b></a>
                            {' '}
                            and create an account.
                            <br />
                            <br />
                            2.
                            {' '}
                            Once you are in,
                            {' '}
                            you will see a
                            {' '}
                            <b>START NOW</b>
                            {' '}
                            button. Click it.
                            <br />
                            <br />
                            3. Fill out this form and then tap on
                            {' '}
                            <b>Create your Stripe account</b>
                            .
                            <br />
                            <br />
                            <img src={require('../../assets/images/configure-stripe-1.png')} alt="register-for-account" />
                            <br />
                            <br />
                            4.
                            {' '}
                            Once you create the account you will be taken inside
                            {' '}
                            the Stripe dashboard.
                            {' '}
                            Before doing anything else,
                            {' '}
                            open the email given step 3 to verify your email.
                            {' '}
                            <br />
                            <br />
                            5.
                            {' After you have veriied your email, go to '}
                            <a href="https://dashboard.stripe.com/account/details"><b>this</b></a>
                            {' '}
                            {' '}
                            link and fill out your financial details.
                            {' '}
                            After filling out the form click on
                            {' '}
                            <b>Submit Application </b>
                            {' '}
                            to activate your account.
                            <br />
                            <br />
                            Alternatively, you could refresh the Stripe dashboard and tap on
                            {' '}
                            <b>Activate your account</b>
                            {' '}
                            to reach the same page.
                            <br />
                            <br />
                            <img src={require('../../assets/images/configure-stripe-2.png')} alt="activate-account" />
                            <br />
                            <br />
                            5.
                            {' This finishes your account creation on Stripe.'}
                        </InfoCard>
                        <div id="connect-stripe-musalleen" />
                        <InfoCard
                            title="Connect your Stripe and Musallen accounts"
                            subTitle="Connect both your accounts by copying Stripe keys inside this dashboard."
                            showFooter={false}
                        >
                            1. On the Stripe dashboard, using the left hand side menu go to
                            {' '}
                            <b>{'Developers -> API Keys'}</b>
                            {' '}
                            to open the API keys page.
                            <br />
                            Make sure
                            {' '}
                            <b>Viewing test data</b>
                            {' '}
                            option is unchecked as shown below.
                            <br />
                            <br />
                            <img src={require('../../assets/images/configure-stripe-3.png')} alt="get-api-keys" />
                            <br />
                            <br />
                            2.
                            {' '}
                            Once you are in,
                            {' '}
                            you will see two sets of API keys.
                            {' '}
                            The
                            {' '}
                            <b>Publishable key</b>
                            {' '}
                            and the
                            {' '}
                            <b>Secret key</b>
                            {' '}
                            button.
                            {' '}
                            <br />
                            The secret key can be revealed by clicking the
                            {' '}
                            <b>Reveal live key token</b>
                            .
                            <br />
                            <br />
                            <img src={require('../../assets/images/configure-stripe-4.png')} alt="api-keys" />
                            <br />
                            <br />
                            3.
                            {' '}
                            Now, copy these two keys inside their respective fields inside the
                            {' '}
                            <a href="/configure-donations" target="blank" rel="noopener noreferrer"><b>Configure Donations</b></a>
                            {' '}
                            page of this dashboard.
                            <br />
                            <br />
                            4.
                            {' '}
                            Press the
                            {' '}
                            <b>Update keys</b>
                            {' '}
                            button to save the keys.
                            <br />
                            <br />
                            5.
                            {' '}
                            Huray! You can now receive donations.
                            <br />
                            <br />
                            6.
                            {' Follow along to get a waiver on Stripe\'s processing fees.'}
                        </InfoCard>
                        <div id="fees-waiver" />
                        <InfoCard
                            title="Waiver Stripe's processing fees (Optional)"
                            subTitle="Ask for a waiver on processing fees by asking for a non profit status."
                            showFooter={false}
                        >
                            1. Read this more on this from here
                            {' '}
                            <a href="https://support.fundly.com/hc/en-us/articles/218102948-Stripe-Processing-Fees" target="_blank" rel="noopener noreferrer"><b>https://support.fundly.com/hc/en-us/articles/218102948-Stripe-Processing-Fees</b></a>
                            .
                            <br />
                            <br />
                            2. Email them at this email address
                            {' '}
                            <a href="mailto:sales+nonprofit@stripe.com"><b>sales+nonprofit@stripe.com</b></a>
                            {' '}
                            {'from your Masjid\'s email account and say you need non-profit pricing.'}
                            <br />
                            They would need proof of your 501c status and
                            you can send any document to them that prices that.
                            {' '}
                            They will tell you what extra info they need if any.
                            <br />
                            <br />
                            3.
                            {' '}
                            This should be all on how to configure donations.
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

ConfigureStripe.propTypes = {

};


export default ConfigureStripe;
