import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './style.scss';
import {
    showDialog, updatePlanAsync, unsubscribeAsync, updateNewMasjid
} from '../../store/actions';
import { Button, PaymentWidget, } from '../../shared/components';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert
} from '../../store/actions/alert';
import { MasjidAPI, Constants } from '../../api';
import { StringUtils, JSONUtils, PaymentUtils } from '../../utils';

function MasjidPlan({
    dispatch,
    operatorMode,
    currentPlan: {
        HasAppBase,
        HasKioskBase,
        HasOnlineBase,
        HasPOSBase,
        HasTvDisplayBase,
        HasTvDisplayAdvancedAddOn,
        HasWebsiteBase,
        HasAppDonationsAddOn,
        HasKioskRecurringDonationsAddOn,
        HasKioskClassRegistrationAddOn,
        HasKioskEventRegistrationAddOn,
        HasOnlineDonationsAddOn,
        HasOnlineRecurringDonationsAddOn,
        HasOnlineClassRegistrationAddOn,
        HasOnlineEventRegistrationAddOn,
        HasPOSRecurringDonationsAddOn,
        HasWebsiteAdditionalPageAddOn,
        PlanPurchaseAmount,
    },
    Last4,
    masjidID,
    currentPlan,
    locationState,
}) {
    const plansRef = useRef(null);
    const [masjidPlanLoading, setMasjidPlanLoading] = useState(false);
    const [unsubscribeLoading, setUnsubscribeLoading] = useState(false);

    const [updateCardModalVisible, setUpdateCardModalVisible] = useState(false);

    const [masjidPlan, setMasjidPlan] = useState({
        HasAppBase,
        HasKioskBase,
        HasOnlineBase,
        HasPOSBase,
        HasTvDisplayBase,
        HasWebsiteBase,
        HasTvDisplayAdvancedAddOn,
        HasAppDonationsAddOn,
        HasKioskRecurringDonationsAddOn,
        HasKioskClassRegistrationAddOn,
        HasKioskEventRegistrationAddOn,
        HasOnlineDonationsAddOn,
        HasOnlineRecurringDonationsAddOn,
        HasOnlineClassRegistrationAddOn,
        HasOnlineEventRegistrationAddOn,
        HasPOSRecurringDonationsAddOn,
        HasWebsiteAdditionalPageAddOn,
        PlanPurchaseAmount
    });
    const [masjidPlanPrices, setMasjidPlanPrices] = useState({
        AppBaseAddOnPrice: '',
        AppDonationsAddOnPrice: '',
        KioskBaseAddOnPrice: '',
        KioskRecurringDonationsAddOnPrice: '',
        KioskClassRegistrationAddOnPrice: '',
        KioskEventRegistrationAddOnPrice: '',
        TvDisplayBaseAddOnPrice: '',
        TvDisplayAdvancedAddOnPrice: '',
        DashboardBaseAddOnPrice: '',
        OnlineBaseAddOnPrice: '',
        OnlineDonationsAddOnPrice: '',
        OnlineRecurringDonationsAddOnPrice: '',
        OnlineClassRegistrationAddOnPrice: '',
        OnlineEventRegistrationAddOnPrice: '',
        POSBaseAddOnPrice: '',
        POSRecurringDonationsAddOnPrice: '',
        WebsiteBaseAddOnPrice: '',
        WebsiteAdditionalPageAddOnPrice: ''
    });
    async function getPlanPrices() {
        const { error, data } = await MasjidAPI.getPlanPrices();
        if (error) {
            switch (error) {
            // Ignoring CLIENT_ERROR since we won't get that here.
            case Constants.SERVER_ERROR:
                dispatch(showServerErrorAlert());
                break;
            default:
                // In case of NETWORK_ERROR or CONNECTION_ERROR.
                dispatch(showNoInternetAlert());
            }
            return;
        }
        setMasjidPlanPrices(data);
    }

    useEffect(() => {
        if (locationState) {
            plansRef.current.scrollIntoView();
        }
        getPlanPrices();
    }, []);

    function handleMajsidPlanPropertyChange(column) {
        return function () {
            const newPlan = {
                ...masjidPlan,
                PlanPurchaseAmount: null,
                [column]: !masjidPlan[column]
            };
            // Turn off the addons if base is switched off.
            if (column === 'HasKioskBase' && !newPlan.HasKioskBase) {
                newPlan.HasKioskRecurringDonationsAddOn = false;
                newPlan.HasKioskClassRegistrationAddOn = false;
                newPlan.HasKioskEventRegistrationAddOn = false;
            } else if (column === 'HasAppBase' && !newPlan.HasAppBase) {
                newPlan.HasAppDonationsAddOn = false;
            } else if (column === 'HasOnlineBase' && !newPlan.HasOnlineBase) {
                newPlan.HasOnlineDonationsAddOn = false;
                newPlan.HasOnlineRecurringDonationsAddOn = false;
                newPlan.HasOnlineClassRegistrationAddOn = false;
                newPlan.HasOnlineEventRegistrationAddOn = false;
            } else if (column === 'HasPOSBase' && !newPlan.HasPOSBase) {
                newPlan.HasPOSRecurringDonationsAddOn = false;
            } else if (column === 'HasWebsiteBase' && !newPlan.HasWebsiteBase) {
                newPlan.HasWebsiteAdditionalPageAddOn = false;
            } else if (column === 'HasTvDisplayBase' && !newPlan.HasTvDisplayBase) {
                newPlan.HasTvDisplayBase = false;
                newPlan.HasTvDisplayAdvancedAddOn = false;
            }
            // Turn off online reccuring donations if donations is switched off.
            if (column === 'HasOnlineDonationsAddOn' && !newPlan.HasOnlineDonationsAddOn) {
                newPlan.HasOnlineRecurringDonationsAddOn = false;
            }
            setMasjidPlan(newPlan);
        };
    }

    async function changeMasjidPlan() {
        // Don't allow the admin to select just the online base and not the addons.
        if (masjidPlan.HasOnlineBase
            && !(masjidPlan.HasOnlineDonationsAddOn
                || masjidPlan.HasOnlineClassRegistrationAddOn
                || masjidPlan.HasOnlineEventRegistrationAddOn)) {
            dispatch(showDangerAlert('Cannot select the online base plan without the donations or one of the program registration add ons. Please try again.'));
            return;
        }

        let message = `Are you sure want to change your currently selected plans? From now on you will be charged ${StringUtils.formatAmount(getCurrentPricing(), false)} monthly.`;
        // Adding an extra message if recurring donation addon was turned off.
        if (!masjidPlan.HasKioskRecurringDonationsAddOn && HasKioskRecurringDonationsAddOn) {
            message += '<br /><br />*Please also note that you have decided to turn off recurring donations for Kiosk. Doing so will make you loose all incoming recurring donations from Kiosk.';
        }
        if (!masjidPlan.HasOnlineRecurringDonationsAddOn && HasOnlineRecurringDonationsAddOn) {
            message += '<br /><br />*Please also note that you have decided to turn off online recurring donations. Doing so will make you loose all incoming online recurring donations.';
        }
        if (!masjidPlan.HasPOSRecurringDonationsAddOn && HasPOSRecurringDonationsAddOn) {
            message += '<br /><br />*Please also note that you have decided to turn off pos recurring donations. Doing so will make you loose all pos recurring donations.';
        }
        const confirm = await dispatch(showDialog('Are you sure?', message));
        if (!confirm) {
            return;
        }
        setMasjidPlanLoading(true);
        await dispatch(updatePlanAsync(masjidPlan));
        setMasjidPlanLoading(false);
        // The errors and success message have already been handled in the action creator.
    }

    async function unsubscribeFromAllPlans() {
        const confirm = await dispatch(showDialog('Are you sure?', 'Are you sure want to Undubscribe from the Musalleen platform?'));
        if (!confirm) {
            return;
        }
        setUnsubscribeLoading(true);
        await dispatch(unsubscribeAsync());
        setUnsubscribeLoading(false);
        // The errors and success message have already been handled in the action creator.
    }

    function getCurrentPricing() {
        // To add any new Addon Price keep it above PlanPurchaseAmount
        // because at the end we check if custom amount is passed
        let currentPrice = 0;
        if (masjidPlan.HasAppBase) {
            currentPrice += +masjidPlanPrices.AppBaseAddOnPrice;
        }
        if (masjidPlan.HasAppDonationsAddOn) {
            currentPrice += +masjidPlanPrices.AppDonationsAddOnPrice;
        }
        if (masjidPlan.HasKioskBase) {
            currentPrice += +masjidPlanPrices.KioskBaseAddOnPrice;
        }
        if (masjidPlan.HasKioskRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.KioskRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasKioskClassRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.KioskClassRegistrationAddOnPrice;
        }
        if (masjidPlan.HasKioskEventRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.KioskEventRegistrationAddOnPrice;
        }
        if (masjidPlan.HasOnlineBase) {
            currentPrice += +masjidPlanPrices.OnlineBaseAddOnPrice;
        }
        if (masjidPlan.HasOnlineDonationsAddOn) {
            currentPrice += +masjidPlanPrices.OnlineDonationsAddOnPrice;
        }
        if (masjidPlan.HasOnlineRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.OnlineRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasOnlineClassRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.OnlineClassRegistrationAddOnPrice;
        }
        if (masjidPlan.HasOnlineEventRegistrationAddOn) {
            currentPrice += +masjidPlanPrices.OnlineEventRegistrationAddOnPrice;
        }
        if (masjidPlan.HasTvDisplayBase) {
            currentPrice += +masjidPlanPrices.TvDisplayBaseAddOnPrice;
        }
        if (masjidPlan.HasPOSBase) {
            currentPrice += +masjidPlanPrices.POSBaseAddOnPrice;
        }
        if (masjidPlan.HasPOSRecurringDonationsAddOn) {
            currentPrice += +masjidPlanPrices.POSRecurringDonationsAddOnPrice;
        }
        if (masjidPlan.HasWebsiteBase) {
            currentPrice += +masjidPlanPrices.WebsiteBaseAddOnPrice;
        }
        if (masjidPlan.HasWebsiteAdditionalPageAddOn) {
            currentPrice += +masjidPlanPrices.WebsiteAdditionalPageAddOnPrice;
        }
        if (masjidPlan.HasTvDisplayAdvancedAddOn) {
            currentPrice += +masjidPlanPrices.TvDisplayAdvancedAddOnPrice;
        }
        if (masjidPlan.PlanPurchaseAmount !== null) {
            currentPrice = +masjidPlan.PlanPurchaseAmount;
        }
        return currentPrice;
    }

    async function editPlanPurchaseAmount() {
        const [confirm, { planPurchaseAmount }] = await dispatch(showDialog('Edit Plan Purchase Amount?', 'Do you want to pass a custom plan purchase amount ?', 'Yes, Edit', 'Cancel', {
            planPurchaseAmount: {
                initialValue: getCurrentPricing(),
                label: 'Plan Purchase Amount',
                placeholder: 'Custom Purchase Amount',
                type: 'number'
            }
        }));
        if (!confirm) {
            return;
        }
        setMasjidPlan({ ...masjidPlan, PlanPurchaseAmount: +planPurchaseAmount });
    }

    async function onUpdateCard(token) {
        const result = await dispatch(updateNewMasjid({
            StripeToken: token,
            ID: masjidID
        }));
        if (result === null) {
            setUpdateCardModalVisible(false);
            changeMasjidPlan();
        } else {
            hideUpdateCardModal();
        }
        // The errors and success message have already been handled in the action creator.
    }

    function hideUpdateCardModal() {
        setUpdateCardModalVisible(false);
    }
    return (
        <div>
            <section className="pricing pt-3 pb-5">
                <div className="row ">
                    <div className="col-md-3">
                        <div className="card mb-5 mb-lg-0">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Default</h5>
                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.DashboardBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />

                                <ul className="plan-items">
                                    <li>
                                        View Analytics.
                                    </li>
                                    <li>
                                        Track & Manage Donations.
                                    </li>
                                    <li>
                                        Create Donation Categories.
                                    </li>
                                    <li>
                                        Generate Reports.
                                    </li>
                                    <li>
                                        Create & Manage Admins.
                                    </li>
                                    <li>
                                        Send Push Notifications.
                                    </li>
                                    <li>
                                        Send Donation Requests.
                                    </li>
                                    <li>
                                        Manage Salat Times.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Website</h5>
                                {/*
                                    * The price information is hidden
                                    * will be visible only when in operator mode
                                */}
                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.WebsiteBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2"><strong>Selected</strong></div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasWebsiteBase}
                                            onChange={handleMajsidPlanPropertyChange('HasWebsiteBase')}
                                            disabled={!operatorMode}
                                        />

                                    </div>
                                </div>
                                <ul className="plan-items">
                                    <li>
                                        Mobile First Website for your Masjid.
                                    </li>
                                    <li>
                                        Minimal and Beautiful Design.
                                    </li>
                                    <li>
                                        Dedicated Announcements, Staff Members
                                        and Contact Information.
                                    </li>
                                    <li>
                                        Showcase Images and Videos in a Gallery.
                                    </li>
                                    <li>
                                        Prayer Times.
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasWebsiteBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                {' '}
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasWebsiteBase ? 'text-muted' : ''}`}>
                                        Seminary Page (Premium)
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.WebsiteAdditionalPageAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasWebsiteBase || !operatorMode}
                                            checked={masjidPlan.HasWebsiteAdditionalPageAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasWebsiteAdditionalPageAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card mb-5 mb-lg-0">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">App</h5>

                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.AppBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2">
                                        {' '}
                                        <strong>Selected</strong>
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasAppBase}
                                            onChange={handleMajsidPlanPropertyChange('HasAppBase')}
                                            disabled={!operatorMode}
                                        />
                                    </div>
                                </div>
                                <ul className={`plan-items ${!masjidPlan.HasAppBase ? 'text-muted' : ''}`}>
                                    <li>
                                        Push Notifications.
                                    </li>
                                    <li>
                                        Group Notifications.
                                    </li>
                                    <li>
                                        Auto-Salat Times.
                                    </li>
                                    <li>
                                        News Feed.
                                    </li>
                                    <li>
                                        Masjid Info.
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasAppBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                {' '}
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasAppBase ? 'text-muted' : ''}`}>
                                        Donations
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.AppDonationsAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasAppBase || !operatorMode}
                                            checked={masjidPlan.HasAppDonationsAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasAppDonationsAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Kiosk</h5>
                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.KioskBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2"><strong>Selected</strong></div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasKioskBase}
                                            onChange={handleMajsidPlanPropertyChange('HasKioskBase')}
                                            disabled={!operatorMode}
                                        />
                                    </div>
                                </div>
                                <ul className={`plan-items ${!masjidPlan.HasKioskBase ? 'text-muted' : ''}`}>
                                    <li>
                                        Donations.
                                    </li>
                                    <li>
                                        Receipts for Donations.
                                    </li>
                                    <li>
                                        Auto-Salat Times.
                                    </li>
                                    <li>
                                        Customize Quick Amounts.
                                    </li>
                                    <li>
                                        Use as Screen for Events.
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasKioskBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasKioskBase ? 'text-muted' : ''}`}>
                                        Recurring Donations
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.KioskRecurringDonationsAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasKioskBase || !operatorMode}
                                            checked={masjidPlan.HasKioskRecurringDonationsAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasKioskRecurringDonationsAddOn')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasKioskBase ? 'text-muted' : ''}`}>
                                        Class Registration
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.KioskClassRegistrationAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasKioskBase || !operatorMode}
                                            checked={masjidPlan.HasKioskClassRegistrationAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasKioskClassRegistrationAddOn')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasKioskBase ? 'text-muted' : ''}`}>
                                        Event Registration
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.KioskEventRegistrationAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasKioskBase || !operatorMode}
                                            checked={masjidPlan.HasKioskEventRegistrationAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasKioskEventRegistrationAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Online</h5>
                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.OnlineBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2"><strong>Selected</strong></div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasOnlineBase}
                                            onChange={handleMajsidPlanPropertyChange('HasOnlineBase')}
                                            disabled={!operatorMode}
                                        />

                                    </div>
                                </div>
                                <ul className={`plan-items ${!masjidPlan.HasOnlineBase ? 'text-muted' : ''}`}>
                                    <li>
                                        Online Portal for your Masjid.
                                    </li>
                                    <li>
                                        Free Website Widgets for
                                        Online Donations and Program Registration.
                                    </li>
                                    <li>
                                        Sharable links for Donations and Program Registration.
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasOnlineBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasOnlineBase ? 'text-muted' : ''}`}>
                                        Online Donations
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.OnlineDonationsAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasOnlineBase || !operatorMode}
                                            checked={masjidPlan.HasOnlineDonationsAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasOnlineDonationsAddOn')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className={`p-2 ${!(masjidPlan.HasOnlineBase && masjidPlan.HasOnlineDonationsAddOn) ? 'text-muted' : ''}`}>
                                        Recurring Donations
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.OnlineRecurringDonationsAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!(
                                                masjidPlan.HasOnlineBase
                                                && masjidPlan.HasOnlineDonationsAddOn
                                            ) || !operatorMode}
                                            checked={masjidPlan.HasOnlineRecurringDonationsAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasOnlineRecurringDonationsAddOn')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className={`p-2 ${!(masjidPlan.HasOnlineBase && masjidPlan.HasOnlineClassRegistrationAddOn) ? 'text-muted' : ''}`}>
                                        Online Class Registration
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.OnlineClassRegistrationAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasOnlineBase || !operatorMode}
                                            checked={masjidPlan.HasOnlineClassRegistrationAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasOnlineClassRegistrationAddOn')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className={`p-2 ${!(masjidPlan.HasOnlineBase && masjidPlan.HasOnlineEventRegistrationAddOn) ? 'text-muted' : ''}`}>
                                        Online Event Registration
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.OnlineEventRegistrationAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasOnlineBase || !operatorMode}
                                            checked={masjidPlan.HasOnlineEventRegistrationAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasOnlineEventRegistrationAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">POS</h5>
                                {operatorMode && (
                                    <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.POSBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>
                                )}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2"><strong>Selected</strong></div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasPOSBase}
                                            onChange={handleMajsidPlanPropertyChange('HasPOSBase')}
                                            disabled={!operatorMode}
                                        />

                                    </div>
                                </div>
                                <ul className={`plan-items ${!masjidPlan.HasPOSBase ? 'text-muted' : ''}`}>
                                    <li>
                                        POS Donations.
                                    </li>
                                    <li>
                                        Receipts for Donations.
                                    </li>
                                    <li>
                                        Carry POS device to events like fundraisers or Jumuah.
                                    </li>
                                    <li>
                                        Get donations quicker.
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasPOSBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasPOSBase ? 'text-muted' : ''}`}>
                                        Recurring Donations
                                        {operatorMode && `: ${StringUtils.formatAmount(masjidPlanPrices.POSRecurringDonationsAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasPOSBase || !operatorMode}
                                            checked={masjidPlan.HasPOSRecurringDonationsAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasPOSRecurringDonationsAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  eslint-disable */}
                    <div className="col-md-3" ref={plansRef}>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title text-muted text-uppercase text-center">Tv Display</h5>
                                {operatorMode &&   <h6 className="card-price text-center">
                                        {`${StringUtils.formatAmount(masjidPlanPrices.TvDisplayBaseAddOnPrice, false)}`}
                                        <span className="period">/month</span>
                                    </h6>}
                                <hr />
                                <div className="d-flex">
                                    <div className="p-2"><strong>Selected</strong></div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            checked={masjidPlan.HasTvDisplayBase}
                                            onChange={handleMajsidPlanPropertyChange('HasTvDisplayBase')}
                                            disabled={!operatorMode}
                                        />

                                    </div>
                                </div>
                                <ul className={`plan-items ${!masjidPlan.HasTvDisplayBase ? 'text-muted' : ''}`}>
                                    <li>
                                        Web Site To Display on TV
                                    </li>
                                    <li>
                                        Auto-Salat Times
                                    </li>
                                    <li>
                                        Beautiful Design
                                    </li>
                                </ul>
                                <h6 className={`m-2 ${!masjidPlan.HasTvDisplayBase ? 'text-muted' : ''}`}>
                                    Addons
                                </h6>
                                <div className="d-flex">
                                    <div className={`p-2 ${!masjidPlan.HasTvDisplayBase ? 'text-muted' : ''}`}>
                                        Advanced Features
                                        {operatorMode && ` ${StringUtils.formatAmount(masjidPlanPrices.TvDisplayAdvancedAddOnPrice, false)}`}
                                    </div>
                                    <div className="ml-auto p-2">
                                        {' '}
                                        <Toggle
                                            icons={false}
                                            disabled={!masjidPlan.HasTvDisplayBase || !operatorMode}
                                            checked={masjidPlan.HasTvDisplayAdvancedAddOn}
                                            onChange={handleMajsidPlanPropertyChange('HasTvDisplayAdvancedAddOn')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  eslint-enable */}
                </div>
            </section>
            <div className="row">
                <div className="col-md-6">
                    <div className="d-flex justify-content-start">
                        <div className="p-2 ">
                            <Button loading={unsubscribeLoading} color="danger" onClick={unsubscribeFromAllPlans}>
                                Unsubscribe
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="p-2">
                            <h5>
                                Total:
                                {` ${StringUtils.formatAmount(getCurrentPricing(), false)} / month`}
                            </h5>
                        </div>
                        <div className="p-2">
                            <Button
                                loading={masjidPlanLoading}
                                onClick={() => {
                                    if (!Last4 && getCurrentPricing() > 0) {
                                        setUpdateCardModalVisible(true);
                                    } else {
                                        changeMasjidPlan();
                                    }
                                }}
                                disabled={JSONUtils.matchStructure(
                                    masjidPlan,
                                    currentPlan
                                )}
                            >
                                Save Changes
                            </Button>
                        </div>
                        {operatorMode && (
                            <div className="p-2">
                                <Button
                                    disabled={masjidPlanLoading}
                                    onClick={editPlanPurchaseAmount}
                                >
                                    Edit Plan Purchase Amount
                                </Button>
                            </div>
                        )}
                    </div>
                    <PaymentWidget
                        title="Fill in card details to continue."
                        show={updateCardModalVisible}
                        onClose={hideUpdateCardModal}
                        onPayment={onUpdateCard}
                        apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
                    />
                </div>
            </div>
        </div>
    );
}

MasjidPlan.propTypes = {
    dispatch: PropTypes.func.isRequired,
    operatorMode: PropTypes.bool.isRequired,
    currentPlan: PropTypes.shape({
        HasAppBase: PropTypes.bool.isRequired,
        HasKioskBase: PropTypes.bool.isRequired,
        HasOnlineBase: PropTypes.bool.isRequired,
        HasTvDisplayBase: PropTypes.bool.isRequired,
        HasPOSBase: PropTypes.bool.isRequired,
        HasAppDonationsAddOn: PropTypes.bool.isRequired,
        HasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired,
        HasKioskRecurringDonationsAddOn: PropTypes.bool.isRequired,
        HasKioskClassRegistrationAddOn: PropTypes.bool.isRequired,
        HasKioskEventRegistrationAddOn: PropTypes.bool.isRequired,
        HasOnlineDonationsAddOn: PropTypes.bool.isRequired,
        HasOnlineRecurringDonationsAddOn: PropTypes.bool.isRequired,
        HasOnlineClassRegistrationAddOn: PropTypes.bool.isRequired,
        HasOnlineEventRegistrationAddOn: PropTypes.bool.isRequired,
        HasPOSRecurringDonationsAddOn: PropTypes.bool.isRequired,
        HasWebsiteAdditionalPageAddOn: PropTypes.bool.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/require-default-props
    Last4: PropTypes.string,
    masjidID: PropTypes.number.isRequired,
    // eslint-disable-next-line react/require-default-props
    locationState: PropTypes.any,
};

const mapStateToProps = ({ admin: { operatorMode }, masjid: { data } }) => ({
    operatorMode,
    currentPlan: {
        HasAppBase: data.HasAppBase,
        HasKioskBase: data.HasKioskBase,
        HasOnlineBase: data.HasOnlineBase,
        HasTvDisplayBase: data.HasTvDisplayBase,
        HasPOSBase: data.HasPOSBase,
        HasWebsiteBase: data.HasWebsiteBase,
        HasTvDisplayAdvancedAddOn: data.HasTvDisplayAdvancedAddOn,
        HasAppDonationsAddOn: data.HasAppDonationsAddOn,
        HasKioskRecurringDonationsAddOn: data.HasKioskRecurringDonationsAddOn,
        HasKioskClassRegistrationAddOn: data.HasKioskClassRegistrationAddOn,
        HasKioskEventRegistrationAddOn: data.HasKioskEventRegistrationAddOn,
        HasOnlineDonationsAddOn: data.HasOnlineDonationsAddOn,
        HasOnlineRecurringDonationsAddOn: data.HasOnlineRecurringDonationsAddOn,
        HasOnlineClassRegistrationAddOn: data.HasOnlineClassRegistrationAddOn,
        HasOnlineEventRegistrationAddOn: data.HasOnlineEventRegistrationAddOn,
        HasPOSRecurringDonationsAddOn: data.HasPOSRecurringDonationsAddOn,
        HasWebsiteAdditionalPageAddOn: data.HasWebsiteAdditionalPageAddOn,
        PlanPurchaseAmount: data.PlanPurchaseAmount,
    },
    Last4: data.Last4,
    masjidID: data.ID
});
export default connect(mapStateToProps)(MasjidPlan);
