import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,

} from 'reactstrap';
import IconOpenEye from 'react-icons/lib/fa/eye';
import IconCloseEye from 'react-icons/lib/fa/eye-slash';

import {
    PageHeader,
    InfoCard,
    DescriptionList,
    Button,
    FormInput
} from '../../shared/components';
import {
    resetAdminPassword, showBadRequestAlert, showSuccessAlert, updateAdminAsync
} from '../../store/actions';

function Admin({
    dispatch, admin: {
        FirstName, LastName, Role, Email, PhoneNumber
    }
}) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [invalidFirstName, setInvalidFirstName] = useState('');
    const [invalidPassword, setInvalidPassword] = useState('');
    const [invalidLastName, setInvalidLastName] = useState('');
    const [newAdminPassword, setNewAdminPassword] = useState('');
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const [currentAdmin, setCurrentAdmin] = useState({
        FirstName,
        LastName,
        Role,
        Email,
        PhoneNumber
    });

    function showModal() {
        setModal(true);
    }

    function hideModal() {
        setModal(false);
    }

    function showUpdateAdminModal() {
        showModal();
    }

    function handleAdminPropertyChange(column) {
        return function (value) {
            setCurrentAdmin({ ...currentAdmin, [column]: value });
        };
    }

    async function updateAdmin() {
        setInvalidFirstName('');
        setInvalidLastName('');

        if (!currentAdmin.FirstName) {
            setInvalidFirstName('First Name can\'t be empty.');
            return;
        }
        if (!currentAdmin.LastName) {
            setInvalidLastName('Last Name can\'t be empty.');
            return;
        }
        setLoading(true);
        const error = await dispatch(updateAdminAsync(currentAdmin));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideModal();
    }
    async function handleResetPassword() {
        if (!newAdminPassword.trim()) {
            setInvalidPassword('New password can not be empty');
            return;
        }
        setLoading(true);
        const error = await dispatch(resetAdminPassword(Email, newAdminPassword));
        setLoading(false);
        if (error) {
            // Handling the CLIENT_ERROR.
            dispatch(showBadRequestAlert(error));
        } else {
            dispatch(showSuccessAlert('Password reset successul!'));
            hideResetPasswordModal();
            setNewAdminPassword('');
        }
    }

    function hideResetPasswordModal() {
        setResetPasswordModal(false);
        setNewAdminPassword('');
    }

    function showResetPasswordModal() {
        setResetPasswordModal(true);
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Admin" description="Here are all the details for this admin." />
                <div className="view-content">
                    <Row>
                        <div className="col-md-12">
                            <InfoCard
                                title="Admin Infomation"
                                subTitle="More about this admin."
                                showFooter={false}
                                showButtonInHeader
                                buttonInHeaderLabel="Edit Admin"
                                onClickButtonInHeader={showUpdateAdminModal}
                            >
                                <Row>
                                    <DescriptionList
                                        list={[
                                            { label: 'First Name', value: FirstName },
                                            { label: 'Last Name', value: LastName },
                                            { label: 'Email', value: Email },
                                            { label: 'Phone Number', value: PhoneNumber || '-' },
                                            { label: 'Role', value: Role },
                                        ]}
                                        containerClassName="col-md-12"
                                    />
                                </Row>
                                { Role === 'SUPER_ADMIN' && <Button onClick={showResetPasswordModal}>Reset Password </Button>}
                            </InfoCard>
                        </div>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={showModal} className="default modal-lg">
                    <ModalHeader toggle={hideModal}>Edit Admin Details</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-md-12">
                                <FormInput
                                    value={currentAdmin.FirstName}
                                    onChangeValue={handleAdminPropertyChange('FirstName')}
                                    row
                                    label="First Name"
                                    type="text"
                                    placeholder="First Name"
                                    message={invalidFirstName}
                                    state={invalidFirstName ? 'danger' : 'default'}
                                    maxLength={25}
                                />
                                <FormInput
                                    value={currentAdmin.LastName}
                                    onChangeValue={handleAdminPropertyChange('LastName')}
                                    row
                                    label="Last Name"
                                    type="text"
                                    placeholder="Last Name"
                                    message={invalidLastName}
                                    state={invalidLastName ? 'danger' : 'default'}
                                    maxLength={25}
                                />
                                <FormInput
                                    value={currentAdmin.PhoneNumber}
                                    onChangeValue={handleAdminPropertyChange('PhoneNumber')}
                                    row
                                    label="Phone Number"
                                    type="text"
                                    maxLength={20}
                                />
                                <FormInput
                                    value={currentAdmin.Role}
                                    onChangeValue={handleAdminPropertyChange('Role')}
                                    row
                                    label="Role"
                                    type="select"
                                    placeholder="Role"
                                    options={[
                                        { key: 'SUPER_ADMIN', value: 'SUPER_ADMIN', label: 'Super Admin' },
                                        { key: 'ADMIN', value: 'ADMIN', label: 'Admin' },
                                        { key: 'TEACHER', value: 'TEACHER', label: 'Teacher' }
                                    ]}
                                />
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="primary" onClick={updateAdmin}>Save</Button>
                        {' '}
                        <Button color="secondary" onClick={hideModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={resetPasswordModal} toggle={showResetPasswordModal} className="default modal-lg">
                    <ModalHeader toggle={hideResetPasswordModal}>Reset Password</ModalHeader>
                    <ModalBody>

                        <div className="d-flex">
                            <div className="col-md-10">
                                <FormInput
                                    value={newAdminPassword}
                                    onChangeValue={setNewAdminPassword}
                                    row
                                    label="New Password"
                                    type={passwordShown ? 'text' : 'password'}
                                    placeholder="New Password"
                                    message={invalidPassword}
                                    state={invalidPassword ? 'danger' : 'default'}
                                />
                            </div>

                            <div className="col-md-2">
                                {!passwordShown
                                && <IconCloseEye onClick={() => setPasswordShown(true)} />}
                                {passwordShown
                                 && <IconOpenEye onClick={() => setPasswordShown(false)} />}
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="primary" onClick={handleResetPassword}>Save</Button>

                        <Button color="secondary" onClick={hideResetPasswordModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}
Admin.propTypes = {
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired
};

const mapStateToProps = (state, props) => ({
    admin: state.masjid.data.Admins.find(a => a.Email === props.location.state)
});
export default connect(mapStateToProps)(Admin);
