import React from 'react';
import ViewContent from './view-content';

import { PageHeader } from '../../shared/components';
// styling
import './style.scss';


export default () => (
    <div className="view">
        <PageHeader title="Dashboard" description="Summary" />
        <ViewContent />
    </div>
);
