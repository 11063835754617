import { store } from 'musalleen-react-theme';

// Keeping this file to maintain integrity of imports
// that were in place before we moved out a lot of
// common stuff into "musalleen-react-theme".
const {
    hideAlert,
    showAlert,
    showNoInternetAlert,
    showBadRequestAlert,
    showServerErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    showDangerAlert,
} = store.actions;

export {
    hideAlert,
    showAlert,
    showNoInternetAlert,
    showBadRequestAlert,
    showServerErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    showDangerAlert
};
