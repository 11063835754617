import client, { returnResponse } from './client';

const base64 = require('base-64');

const { get, put } = client;

const login = async (email, password) => returnResponse(
    await get('/admins/token', null, { headers: { Authorization: `Basic ${base64.encode(`${email}:${password}`)}` } })
);

const changePassword = async (email, currentPassword, newPassword) => returnResponse(
    await put(`/admins/${email}/changePassword`, { CurrentPassword: currentPassword, NewPassword: newPassword })
);

const emailExists = async email => returnResponse(
    await get(`/admins/${email}/exists`)
);

const resetPassword = async (email, newPassword) => returnResponse(
    await put(`/admins/${email}/resetPassword`, { NewPassword: newPassword })

);
export {
    login,
    changePassword,
    emailExists,
    resetPassword
};
