import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import {
    Row,
    Card,
    CardBody
} from 'reactstrap';
import {
    PageHeader,
    Table,
    Button
} from '../../shared/components';
import { NotificationsAPI, Constants } from '../../api';
import { showNoInternetAlert, showServerErrorAlert } from '../../store/actions';
import { DateUtils, StringUtils } from '../../utils';
import { getCategories, getGroups } from '../../store/selectors';

function DonationRequestsList({
    masjidID,
    categories,
    groups,
    dispatch
}) {
    const [fetching, setFetching] = useState(true);
    const [donationRequests, setDonationRequests] = useState([]);

    useEffect(() => {
        loadData();
    }, [masjidID]);

    async function loadData() {
        const { error, data } = await NotificationsAPI.getDonationsRequests(masjidID);
        if (error) {
            // We probably won't get a CLIENT_ERROR here so
            // not handling it.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        setFetching(false);
        setDonationRequests(data['Donation Requests Log'].map(({
            id,
            title,
            description,
            amount,
            categoryId,
            categoryName,
            sentTime,
            buttonText,
            link,
            groupId,
            groupName,
        }) => ({
            id,
            title,
            description,
            amount: StringUtils.formatAmount(amount, false),
            categoryId,
            categoryName,
            actionable: buttonText ? 'Yes' : 'No',
            action: buttonText,
            redirectURL: link,
            sentTime: DateUtils.format(sentTime, 'YYYY MMM DD hh:mm A'),
            sentToAll: !groupName,
            groupId,
            groupName: groupName || '-'
        })));
    }

    function onDonationRequestClick(donationRequest) {
        browserHistory.push({ pathname: `/donation-requests/${donationRequest.id}`, state: donationRequest });
    }

    return (
        <div className="view">
            <PageHeader title="Donation Requests" description="Here are all your donation requests that you have pushed to app users so far." />
            <div className="view-content view-dashboard">
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Row>
                                <div className="col-md-12 button-right">
                                    <Link to="/donation-requests/add">
                                        <Button
                                            onClick={() => {}}
                                            disabled={fetching}
                                        >
                                            Push New Donation Request
                                        </Button>
                                    </Link>
                                </div>
                            </Row>
                            <Table
                                title="Donation Requests"
                                columns={[
                                    { label: 'Title', key: 'title' },
                                    { label: 'Amount Requested', key: 'amount' },
                                    { label: 'Group', key: 'groupName' },
                                    { label: 'Category', key: 'categoryName' },
                                    { label: 'Time Sent', key: 'sentTime' }
                                ]}
                                data={donationRequests}
                                filters={[
                                    { label: 'Title', key: 'title' },
                                    {
                                        label: 'Donation Category',
                                        key: 'categoryId',
                                        filterInputType: 'select',
                                        filterSelectOptions: categories,
                                        filterFunc(categoryId, chosenCategoryId) {
                                            return chosenCategoryId === 'all' || `${categoryId}` === chosenCategoryId;
                                        }
                                    },
                                    {
                                        label: 'Group',
                                        key: 'groupId',
                                        filterInputType: 'select',
                                        filterSelectOptions: groups,
                                        filterFunc(groupId, chosenGroupId) {
                                            return chosenGroupId === 'all' || `${groupId}` === chosenGroupId;
                                        }
                                    }
                                ]}
                                enableExports={false}
                                loading={fetching}
                                onRowClick={onDonationRequestClick}
                                defaultSortColumnKey="sentTime"
                            />
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </div>
    );
}

DonationRequestsList.propTypes = {
    masjidID: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    masjidID: state.masjid.data.ID,
    categories: [{ label: 'All', value: 'all' }].concat(getCategories(state)),
    groups: [{ label: 'All', value: 'all' }].concat(getGroups(state))
});

export default connect(mapStateToProps)(DonationRequestsList);
