import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import {
    Row,
    Card,
    CardBody
} from 'reactstrap';
import {
    PageHeader,
    Table,
    Button
} from '../../shared/components';
import { NotificationsAPI, Constants } from '../../api';
import { showNoInternetAlert, showServerErrorAlert } from '../../store/actions';
import { DateUtils } from '../../utils';
import { getGroups } from '../../store/selectors';

function EventsList({ masjidID, groups, dispatch }) {
    const [fetching, setFetching] = useState(true);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        loadData();
    }, [masjidID]);

    async function loadData() {
        const { error, data } = await NotificationsAPI.getEvents(masjidID);
        if (error) {
            // We probably won't get a CLIENT_ERROR here so
            // not handling it.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        setFetching(false);
        setEvents(data['Events Log'].map(({
            id,
            title,
            description,
            houseNumber,
            street,
            city,
            state,
            zipCode,
            startTime,
            endTime,
            sentTime,
            groupId,
            groupName
        }) => ({
            id,
            title,
            description,
            houseNumber,
            street,
            city,
            state,
            zipCode,
            startTime: DateUtils.format(DateUtils.getISOVersion(startTime), 'YYYY MMM DD hh:mm A'),
            endTime: DateUtils.format(DateUtils.getISOVersion(endTime), 'YYYY MMM DD hh:mm A'),
            sentTime: DateUtils.format(sentTime, 'YYYY MMM DD hh:mm A'),
            sentToAll: !groupName,
            groupId,
            groupName: groupName || '-',
            address: `${houseNumber}, ${street}, ${city}, ${state} - ${zipCode}`
        })));
    }

    function onEventClick(event) {
        browserHistory.push({ pathname: `/events/${event.id}`, state: event });
    }

    return (
        <div className="view">
            <PageHeader title="Events" description="Here are all your events that you have pushed to app users so far." />
            <div className="view-content view-dashboard">
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Row>
                                <div className="col-md-12 button-right">
                                    <Link to="/events/add">
                                        <Button
                                            onClick={() => {}}
                                            disabled={fetching}
                                        >
                                            Push New Event
                                        </Button>
                                    </Link>
                                </div>
                            </Row>
                            <Table
                                title="Events"
                                columns={[
                                    { label: 'Title', key: 'title' },
                                    { label: 'Start Time', key: 'startTime' },
                                    { label: 'End Time', key: 'endTime' },
                                    { label: 'Address', key: 'address' },
                                    { label: 'Group', key: 'groupName' },
                                    { label: 'Time Sent', key: 'sentTime' }
                                ]}
                                data={events}
                                filters={[
                                    { label: 'Title', key: 'title' },
                                    { label: 'Address', key: 'address' },
                                    {
                                        label: 'Group',
                                        key: 'groupId',
                                        filterInputType: 'select',
                                        filterSelectOptions: groups,
                                        filterFunc(groupId, chosenGroupId) {
                                            return chosenGroupId === 'all' || `${groupId}` === chosenGroupId;
                                        }
                                    }
                                ]}
                                enableExports={false}
                                loading={fetching}
                                onRowClick={onEventClick}
                                defaultSortColumnKey="sentTime"
                            />
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </div>
    );
}

EventsList.propTypes = {
    masjidID: PropTypes.number.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    masjidID: state.masjid.data.ID,
    groups: [{ label: 'All', value: 'all' }].concat(getGroups(state))
});

export default connect(mapStateToProps)(EventsList);
