/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { useState } from 'react';

import { connect } from 'react-redux';
import {
    Row,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';


import {
    InfoCard,
    PageHeader,
    FormInput,
    Button,
    Image
} from '../../shared/components';


import {
    showDangerAlert,
    showSuccessAlert, showServerErrorAlert,
    showDialog, addFlyer, updateFlyer, deleteFlyer
} from '../../store/actions';
import { FileUtils, StringUtils } from '../../utils';
import UpgradePlanAlert from '../ui/extras/UpgradePlanAlert';


const Flyer = ({
    tvDisplayFlyer,
    hasTvDisplayAdvancedAddOn, dispatch
}) => {
    const [loading, setLoading] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [currentlyEditingFlyer, setCurrentlyEditingFlyer] = useState({});
    const [imageUploading, setImageUploading] = useState(false);
    const [newFlyerData, setNewFlyerData] = useState({
        Description: '',
        ID: '',
        ImageUrl: '',
        Title: ''
    });

    const showEditModal = (flyer) => {
        setCurrentlyEditingFlyer(flyer);
        setEditModal(true);
    };

    const hideEditModal = () => {
        setCurrentlyEditingFlyer({});
        setEditModal(false);
    };

    const showAddModal = () => {
        setNewFlyerData({
            Description: '',
            ID: '',
            ImageUrl: '',
            Title: ''
        });
        setAddModal(true);
    };

    const hideAddModal = () => {
        setNewFlyerData({
            Description: '',
            ID: '',
            ImageUrl: '',
            Title: ''
        });
        setAddModal(false);
    };

    const uploadFlyerImage = async (image) => {
        setImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a announcement and
            // the old one gets overwritten.
            new File(
                [image],
                `flyer_image${Math.floor(Math.random() * (100 - 1 + 1)) + 1}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            ), 'TvDisplayFlyers'
        );
        if (error) {
            setImageUploading(false);
            // Upload has failed.
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The Slide image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            dispatch(showServerErrorAlert());
            return;
        }
        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for an Image.
        const flyerImageUrl = `${finalUrl}?c=${new Date().getTime()}`;

        // Edit Flyer
        if (editModal) {
            setCurrentlyEditingFlyer(prevState => ({ ...prevState, ImageUrl: flyerImageUrl }));
        }
        // New Flyer
        if (!editModal) {
            setNewFlyerData(prevState => ({ ...prevState, ImageUrl: flyerImageUrl }));
        }


        setImageUploading(false);
    };

    const handleflyerImageChange = (files) => {
        uploadFlyerImage(files[0]);
    };

    const onSubmitEdit = async () => {
        setLoading(true);
        const error = await dispatch(updateFlyer(currentlyEditingFlyer));
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        setLoading(false);
        dispatch(showSuccessAlert('Successfully updated the slide!'));
        hideEditModal();
    };

    const onSubmitAdd = async () => {
        setLoading(true);
        const error = await dispatch(addFlyer({
            Title: newFlyerData.Title,
            Description: newFlyerData.Description,
            ImageUrl: newFlyerData.ImageUrl
        }));
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
        setLoading(false);
        setNewFlyerData({
            Description: '',
            ID: '',
            ImageUrl: '',
            Title: ''
        });
        hideAddModal();
    };

    const onSubmitDelete = async (id) => {
        const confirm = await dispatch(showDialog(
            'Are you sure?',
            'Are you sure you want to delete?'
        ));
        if (confirm) {
            const error = await dispatch(deleteFlyer(id));
            if (error) {
                // The errors and success message have already been handled in the action creator.
            }
        }
    };

    const handleEditModalTitle = (value) => {
        setCurrentlyEditingFlyer(prevState => ({ ...prevState, Title: value }));
    };

    const handleEditModalDescription = (value) => {
        setCurrentlyEditingFlyer(prevState => ({ ...prevState, Description: value }));
    };

    const handleAddModalTitle = (value) => {
        setNewFlyerData(prevState => ({ ...prevState, Title: value }));
    };

    const handleAddModalDescription = (value) => {
        setNewFlyerData(prevState => ({ ...prevState, Description: value }));
    };

    const renderFlyer = () => {
        if (tvDisplayFlyer.length === 0) {
            return (
                <>
                    <Card className="col-md-12">
                        <CardBody>
                            {hasTvDisplayAdvancedAddOn && (
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={showAddModal}
                                            >
                                                Add New Slide
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            )}
                            <p style={{ color: '#9e9e9e' }}>They are currently no slides.</p>

                        </CardBody>
                    </Card>
                    <UpgradePlanAlert description="To add more slides " show={hasTvDisplayAdvancedAddOn} />
                </>
            );
        }
        return (
            <>
                <Row className="mb-4">
                    <Card className="col-md-12">
                        <CardBody>
                            {hasTvDisplayAdvancedAddOn && (
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={showAddModal}
                                            >
                                                Add New Slide
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                            )}
                            <Row>
                                {tvDisplayFlyer && tvDisplayFlyer.map((flyer) => (

                                    <div className="col-sm-6 col-md-3" key={flyer.ID}>
                                        <InfoCard
                                            title={flyer.Title}
                                            subTitle={flyer.Description.length < 18 ? flyer.Description : `${flyer.Description.substring(0, 22)}...`}
                                            footerContent={null}
                                            footerIsLink={false}
                                            showButtonInHeader
                                            buttonInHeaderLabel="Edit"
                                            onClickButtonInHeader={() => showEditModal(flyer)}
                                        >
                                            <img src={flyer.ImageUrl} alt="avataar" style={{ height: '180px' }} />
                                            <br />
                                            <br />

                                            {hasTvDisplayAdvancedAddOn && (
                                                <Button
                                                    className=""
                                                    color="danger"
                                                    onClick={() => onSubmitDelete(flyer.ID)}
                                                    disabled={!hasTvDisplayAdvancedAddOn}
                                                >
                                                    Delete Slide
                                                </Button>
                                            )}

                                        </InfoCard>
                                        {/* {isSuperAdmin && renderSalatCards()} */}
                                    </div>
                                ))}

                            </Row>
                        </CardBody>
                    </Card>
                    <UpgradePlanAlert description="To add more slides " show={hasTvDisplayAdvancedAddOn} />
                </Row>
            </>
        );
    };

    return (
        <div className="view">
            <PageHeader title="Configure your tv display" description="Here are the slides of your tv display." />
            <div className="view-content">
                {renderFlyer()}

                <Modal isOpen={editModal} toggle={showEditModal} className="default">
                    <ModalHeader toggle={hideEditModal}>Edit Slide</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-md-12">
                                <FormInput
                                    label="Title"
                                    type="text"
                                    value={currentlyEditingFlyer.Title}
                                    onChangeValue={(value) => handleEditModalTitle(value)}
                                    maxLength={100}
                                />
                                <FormInput
                                    label="Description"
                                    type="textarea"
                                    value={currentlyEditingFlyer.Description}
                                    onChangeValue={(value) => handleEditModalDescription(value)}
                                    maxLength={200}
                                />
                                <div className="float-right">
                                    <Image
                                        title="Masjid Image"
                                        url={currentlyEditingFlyer.ImageUrl}
                                        uploading={false}
                                        onImageSelected={handleflyerImageChange}
                                        label="Image"
                                    />
                                </div>
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={imageUploading || loading} color="primary" onClick={onSubmitEdit}>Update</Button>
                        <Button color="secondary" onClick={hideEditModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={addModal} toggle={showAddModal} className="default">
                    <ModalHeader toggle={hideAddModal}>Add Image</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-md-12">
                                <FormInput
                                    label="Title"
                                    row
                                    type="text"
                                    value={newFlyerData.Title}
                                    onChangeValue={(value) => handleAddModalTitle(value)}
                                    maxLength={100}
                                />
                                <FormInput
                                    label="Description"
                                    row
                                    type="textarea"
                                    value={newFlyerData.Description}
                                    onChangeValue={(value) => handleAddModalDescription(value)}
                                    maxLength={200}
                                />
                                {newFlyerData.ImageUrl && (
                                    <div className="float-right">
                                        <Image
                                            title="Masjid Image"
                                            url={newFlyerData.ImageUrl}
                                            uploading={false}
                                            onImageSelected={handleflyerImageChange}
                                            label="Image"
                                        />
                                    </div>
                                )}

                                {!newFlyerData.ImageUrl && (
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="col-3 text-center" style={{ marginLeft: '-27px' }}>
                                            <p>Image Url</p>
                                        </div>
                                        <div className="col-10" style={{ paddingLeft: '35px' }}>
                                            <FormInput
                                                value=""
                                                onChangeValue={handleflyerImageChange}
                                                type="file"
                                                row
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                )}


                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={imageUploading || loading} color="primary" onClick={onSubmitAdd}>Add</Button>
                        <Button color="secondary" onClick={hideAddModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
};


Flyer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    tvDisplayFlyer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasTvDisplayAdvancedAddOn: PropTypes.bool.isRequired
};

const mapStateToProps = ({ masjid: { data: { TvDisplay, HasTvDisplayAdvancedAddOn } } }) => ({
    tvDisplayFlyer: TvDisplay.TvDisplayFlyers,
    hasTvDisplayAdvancedAddOn: HasTvDisplayAdvancedAddOn

});
export default connect(mapStateToProps)(Flyer);
