const getCurrentEnvironment = () => {
    switch (window.location.host) {
    case 'musalleen-dashboard-develop-n.herokuapp.com':
        return 'develop';
    case 'musalleen-dashboard-stage-n.herokuapp.com':
        return 'stage';
    case 'dashboard.musalleen.com':
        return 'production';
    default:
        return 'development';
    }
};

const isRunningInDevelopmentMode = () => getCurrentEnvironment() === 'development';

const isRunningInProductionMode = () => getCurrentEnvironment() === 'production';


export {
    getCurrentEnvironment,
    isRunningInDevelopmentMode,
    isRunningInProductionMode
};
