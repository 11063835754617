import { Components } from 'musalleen-react-theme';
import Header from './header';
import PageHeader from './pageHeader';
import Nav from './nav';


export {
    Header,
    PageHeader,
    Nav
};

// Exporting components from here to maintain integrity of imports
// that were in place before we moved out a lot of
// common components into "musalleen-react-theme".
const {
    Button,
    Loader,
    Logo,
    FormInput,
    Table,
    InfoCard,
    DescriptionList,
    ImageModal,
    ImageView,
    PaymentWidget
} = Components;

export {
    Button,
    Loader,
    Logo,
    FormInput,
    Table,
    InfoCard,
    DescriptionList,
    ImageModal,
    ImageView as Image,
    PaymentWidget
};
