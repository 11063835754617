import React from 'react';
import { browserHistory } from 'react-router';
import { store } from '../store';
import { JSONUtils } from '../utils';

// HOC to guard our routes using the Redux store.
// state - Shape of the redux store to match so as the user will get access to Component.
// For example to check for super admin
// state = { admin: { role: 'SUPER_ADMIN' } }
// or to check if Masjid has kiosk base.
// state = { masjid: { data: { HasKioskBase: true } } }
const mustBe = (state, fallbackRoute = '/') => Component => props => {
    if (!JSONUtils.matchStructure(state, store.getState())) {
        browserHistory.replace(fallbackRoute);
        return null;
    }
    return <Component {...props} />;
};

export default mustBe;
