import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Row
} from 'reactstrap';
import {
    PageHeader,
    FormInput,
    InfoCard,
    Button
} from '../../shared/components';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showDangerAlert,
    showSuccessAlert
} from '../../store/actions';
import { getGroups, getCategories } from '../../store/selectors';
import { NotificationsAPI, Constants } from '../../api';

function AddDonationRequest({
    masjidID,
    groups,
    categories,
    dispatch
}) {
    const [groupID, setGroupID] = useState(groups[0].value);

    const [event, setEvent] = useState({
        title: '',
        description: '',
        categoryID: categories.length ? categories[0].value : '0',
        amount: ''
    });

    function setDonationRequestValue(type) {
        return function (value) {
            setEvent({
                ...event,
                [type]: value
            });
        };
    }

    const [donationRequestErrors, setDonationRequestErrors] = useState({});

    function setDonationRequestError(type) {
        dispatch(showDangerAlert('Some required fields are empty. Please try again.'));
        setDonationRequestErrors({
            [type]: true
        });
    }

    const [adding, setAdding] = useState(false);

    async function onAddDonationRequest() {
        if (!event.title.trim()) {
            setDonationRequestError('title');
            return;
        }
        if (!event.description.trim()) {
            setDonationRequestError('description');
            return;
        }
        if (!event.amount.trim()) {
            setDonationRequestError('amount');
            return;
        }

        // If there are no donation categories available.
        if (!categories.length) {
            dispatch(showDangerAlert('You don\'t have any donation categories. Add a donation category and try again.'));
            return;
        }

        setAdding(true);
        const { error } = await NotificationsAPI.pushDonationRequest(
            masjidID,
            {
                Title: event.title.trim(),
                Description: event.description.trim(),
                Amount: event.amount - 0,
                CategoryID: event.categoryID - 0
            },
            groupID !== 'all' ? groupID : null
        );
        setAdding(false);
        if (error) {
            if (error === Constants.CLIENT_ERROR) {
                dispatch(showDangerAlert('Could not push a donation request. Make sure you have "App" added to your plan with a "donations" addon and your subscription is still active.'));
                return;
            }
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        dispatch(showSuccessAlert('Successfully pushed a new donation request!'));
        browserHistory.push('/donation-requests/list');
    }

    function goBack() {
        browserHistory.push('/donation-requests/list');
    }

    return (
        <div className="view">
            <PageHeader title="Add Donation Request" description="Push a new donation request to your users from here." />
            <div className="view-content">
                <Row>
                    <div className="col-md-12">
                        <InfoCard
                            title="New Donation Request"
                            subTitle="Fill up donation request details to get started."
                            showFooter={false}
                        >
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Title"
                                        placeholder="Title of the donation request"
                                        message="Title cannot be empty."
                                        state={donationRequestErrors.title ? 'danger' : 'default'}
                                        value={event.title}
                                        onChangeValue={setDonationRequestValue('title')}
                                        maxLength={25}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-8">
                                    <FormInput
                                        label="Description"
                                        type="textarea"
                                        message="Description cannot be empty."
                                        placeholder="For example: 2 A/C units broke down last night. If everyone donates $30, the entire expense will be covered. May Allah Reward you."
                                        state={donationRequestErrors.description ? 'danger' : 'default'}
                                        value={event.description}
                                        onChangeValue={setDonationRequestValue('description')}
                                        maxLength={200}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Amount ($)"
                                        placeholder="Amount requested from each member"
                                        type="number"
                                        message="Amount cannot be empty."
                                        maxLength={4}
                                        state={donationRequestErrors.amount ? 'danger' : 'default'}
                                        value={event.amount}
                                        onChangeValue={setDonationRequestValue('amount')}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-12">
                                    <FormInput
                                        label="Donation Category"
                                        type="select"
                                        options={categories.length ? categories : ['Sadly, you have no categories']}
                                        value={event.categoryID}
                                        onChangeValue={setDonationRequestValue('categoryID')}
                                        disabled={!categories.length}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        label="Select audience."
                                        type="select"
                                        options={groups}
                                        value={groupID}
                                        onChangeValue={setGroupID}
                                    />
                                </div>
                            </Row>
                            <Row className="mt-2">
                                <div className="col-md-6">
                                    <Button
                                        onClick={goBack}
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className="col-md-6 button-right">
                                    <Button
                                        onClick={onAddDonationRequest}
                                        loading={adding}
                                    >
                                        Push Request
                                    </Button>
                                </div>
                            </Row>
                        </InfoCard>
                    </div>
                </Row>
            </div>
        </div>
    );
}

AddDonationRequest.propTypes = {
    masjidID: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired
};

const mapStateToProps = state => ({
    masjidID: state.masjid.data.ID,
    groups: [{ label: 'Everyone', value: 'all' }].concat(getGroups(state)),
    categories: getCategories(state)
});

export default connect(mapStateToProps)(AddDonationRequest);
