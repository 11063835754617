import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    Row
} from 'reactstrap';
import IconClose from 'react-icons/lib/md/close';
import {
    PageHeader,
    InfoCard,
    FormInput,
    Button
} from '../../shared/components';
import { DateUtils, FileUtils } from '../../utils';
import {
    showServerErrorAlert,
    addEventAsync,
    showDangerAlert
} from '../../store/actions';

function AddEvent({ masjid, dispatch }) {
    const [eventImage, setEventImage] = useState(null);
    const [eventImageName, setEventImageName] = useState('');
    const [loading, setLoading] = useState(false);
    const [fathersNameApplicable, setFathersNameApplicable] = useState(false);
    const [mothersNameApplicable, setMothersNameApplicable] = useState(false);
    const [fathersNameRequired, setFathersNameRequired] = useState(false);
    const [mothersNameRequired, setMothersNameRequired] = useState(false);
    const [emergencyContactNameApplicable, setEmergencyContactNameApplicable] = useState(false);
    const [emergencyContactNumberApplicable, setEmergencyContactNumberApplicable] = useState(false);
    const [emergencyContactNumberRequired, setEmergencyContactNumberRequired] = useState(false);
    const [emergencyContactNameRequired, setEmergencyContactNameRequired] = useState(false);
    const emptyEvent = {
        Title: '',
        Description: '',
        Cost: '',
        RegistrationDeadline: '',
        TotalCapacity: '',
        StartDate: '',
        EndDate: '',
        HouseNumber: '',
        Street: '',
        City: '',
        Zipcode: '',
        State: '',
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: ''
        }]
    };
    const [invalidEvent, setInvalidEvent] = useState({
        ...emptyEvent
    });
    const [newEvent, setNewEvent] = useState({
        ...emptyEvent,
        Street: masjid.Street,
        City: masjid.City,
        Zipcode: masjid.Zipcode,
        State: masjid.State,
        HouseNumber: masjid.HouseNumber,
        Days: [{
            StartTime: '',
            EndTime: '',
            Day: '1'
        }]
    });

    function goBack() {
        browserHistory.push('/events/list');
    }

    async function saveEvent() {
        let validationError = false;
        const newInvalidEvent = {
            ...emptyEvent,
            Days: Array(newEvent.Days.length).fill({
                StartTime: '',
                EndTime: '',
                Day: ''
            })
        };

        for (const i in newEvent.Days) {
            const day = newEvent.Days[i];
            for (const [dayKey, dayValue] of Object.entries(day)) {
                if (!dayValue) {
                    newInvalidEvent.Days = newInvalidEvent.Days.map((invalidDay, index) => { //eslint-disable-line
                        if (+i === index) {
                            validationError = true;
                            return {
                                ...invalidDay,
                                [dayKey]: `${dayKey} can't be empty`
                            };
                        }
                        return day;
                    });
                }
            }
        }

        for (const key of Object.keys(emptyEvent)) {
            if (!newEvent[key]) {
                validationError = true;
                newInvalidEvent[key] = `${key} can't be empty`;
            }
        }

        if (newEvent.Cost > 0 && newEvent.Cost <= 0.5) {
            validationError = true;
            newInvalidEvent.Cost = 'Event cost cannot be less than 0.5. It can however be 0 in case of a free event.';
        }

        if (DateUtils.isBefore(newEvent.StartDate, newEvent.RegistrationDeadline, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidEvent.StartDate = 'Start date cannot be equal to or before registration deadline.';
        }

        if (newEvent.StartDate !== newEvent.EndDate && DateUtils.isAfter(newEvent.StartDate, newEvent.EndDate, 'YYYY-MM-DD')) {
            validationError = true;
            newInvalidEvent.EndDate = 'Start date cannot be after end date.';
        }

        if (DateUtils.getDaysBetweenDateStrings(newEvent.EndDate, newEvent.StartDate, 'YYYY-MM-DD') > 6) {
            validationError = true;
            newInvalidEvent.EndDate = 'An event cannot span for more than one week.';
        }

        setInvalidEvent(newInvalidEvent);
        if (validationError) {
            dispatch(showDangerAlert('You have errors in the form above that need to be fixed before you can add a new event.'));
            return;
        }

        setLoading(true);
        let imageUrl = null;
        if (eventImage) {
            // User has chosen an image.
            const { location, error } = await FileUtils.uploadFile(eventImage, 'eventImages');
            if (error) {
                setLoading(false);
                if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                    dispatch(showDangerAlert(`The event image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                    return;
                }
                // Upload has failed.
                dispatch(showServerErrorAlert());
                return;
            }
            const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
            imageUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        }

        const newEventWithTimeInMillis = {
            ...newEvent,
            StartDate: DateUtils.format(newEvent.StartDate, 'YYYY/MM/DD'),
            EndDate: DateUtils.format(newEvent.EndDate, 'YYYY/MM/DD'),
            RegistrationDeadline: DateUtils.format(newEvent.RegistrationDeadline, 'YYYY/MM/DD'),
            ImageUrl: imageUrl,
            FathersNameRequired: fathersNameRequired,
            MothersNameRequired: mothersNameRequired,
            FathersNameApplicable: fathersNameApplicable,
            MothersNameApplicable: mothersNameApplicable,
            EmergencyContactNameRequired: emergencyContactNameRequired,
            EmergencyContactNumberRequired: emergencyContactNumberRequired,
            EmergencyContactNameApplicable: emergencyContactNameApplicable,
            EmergencyContactNumberApplicable: emergencyContactNumberApplicable
        };

        const error = await dispatch(addEventAsync(newEventWithTimeInMillis));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        browserHistory.push('/events/list');
    }

    function handleClassPropertyChange(column) {
        return function (value) {
            setNewEvent({ ...newEvent, [column]: value });
        };
    }

    function handleDayPropertyChange(column, index) {
        return function (value) {
            setNewEvent({
                ...newEvent,
                Days: newEvent.Days.map((day, dayIndex) => {
                    if (dayIndex === index) {
                        return { ...day, [column]: value };
                    }
                    return day;
                })
            });
        };
    }

    function addDayToEve() {
        setNewEvent({
            ...newEvent,
            // Fill it with the next available label.
            Days: [...newEvent.Days, { StartTime: newEvent.Days[0].StartTime, EndTime: newEvent.Days[0].EndTime, Day: getDayLabels(null)[0].value }]
        });
        setInvalidEvent({
            ...invalidEvent,
            Days: [...invalidEvent.Days, { StartTime: '', EndTime: '', Day: '' }]
        });
    }

    function handleEventImageChange() {
        return function (files) {
            setEventImageName(files[0].name);
            setEventImage(files[0]);
        };
    }

    function removeDay(index) {
        return function () {
            if (index === 0) {
                // Don't let the user remove the first day.
                dispatch(showDangerAlert('An event has to be for atleast a single day.'));
                return;
            }
            setNewEvent({
                ...newEvent,
                Days: newEvent.Days.filter((_, i) => i !== index)
            });
            setInvalidEvent({
                ...invalidEvent,
                Days: newEvent.Days.filter((_, i) => i !== index)
            });
        };
    }

    // Get dropdown options for days excluding the ones that have been set currently.
    // Deal in strings only.
    function getDayLabels(currentDay) {
        const daysInClass = newEvent.Days.map(({ Day }) => `${Day}`);
        return DateUtils.getDaysAsLabelValuePairs()
            .filter(({ value }) => value === `${currentDay}` || !daysInClass.includes(value));
    }

    function canAddAnotherDay() {
        return newEvent.Days.length < 7;
    }
    function setFathersNameApplicability(value) {
        setFathersNameApplicable(value);
        if (value === false) {
            setFathersNameRequired(false);
        }
    }

    function setMothersNameApplicability(value) {
        setMothersNameApplicable(value);
        if (value === false) {
            setMothersNameRequired(false);
        }
    }

    function setEmergencyContactNameApplicability(value) {
        setEmergencyContactNameApplicable(value);
        if (value === false) {
            setEmergencyContactNameRequired(false);
        }
    }

    function setEmergencyContactNumberApplicability(value) {
        setEmergencyContactNumberApplicable(value);
        if (value === false) {
            setEmergencyContactNumberRequired(false);
        }
    }

    return (
        <div className="view">
            <PageHeader title="Add New Event" description="Let's add a new event which people can sign up for." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Event Information"
                    subTitle="Add event details here."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-6 col-offset-md-6">
                            <FormInput
                                value={newEvent.Title}
                                onChangeValue={handleClassPropertyChange('Title')}
                                label="Title"
                                message={invalidEvent.Title}
                                state={!invalidEvent.Title ? 'default' : 'danger'}
                                maxLength={30}
                            />
                            <FormInput
                                value={newEvent.Description}
                                onChangeValue={handleClassPropertyChange('Description')}
                                label="Description"
                                type="textarea"
                                maxLength={200}
                                message={invalidEvent.Description}
                                state={!invalidEvent.Description ? 'default' : 'danger'}
                            />
                            <FormInput
                                value={newEvent.Cost}
                                onChangeValue={handleClassPropertyChange('Cost')}
                                label="Cost"
                                type="number"
                                min={0}
                                message={invalidEvent.Cost}
                                state={!invalidEvent.Cost ? 'default' : 'danger'}
                            />
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newEvent.RegistrationDeadline}
                                        onChangeValue={handleClassPropertyChange('RegistrationDeadline')}
                                        label="Registration Deadline"
                                        type="date"
                                        message={invalidEvent.RegistrationDeadline}
                                        state={!invalidEvent.RegistrationDeadline ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newEvent.StartDate}
                                        onChangeValue={handleClassPropertyChange('StartDate')}
                                        label="Start Date"
                                        type="date"
                                        message={invalidEvent.StartDate}
                                        state={!invalidEvent.StartDate ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-4">
                                    <FormInput
                                        value={newEvent.EndDate}
                                        onChangeValue={handleClassPropertyChange('EndDate')}
                                        label="End Date"
                                        type="date"
                                        message={invalidEvent.EndDate}
                                        state={!invalidEvent.EndDate ? 'default' : 'danger'}
                                    />
                                </div>
                            </Row>
                            <FormInput
                                value={newEvent.HouseNumber}
                                onChangeValue={handleClassPropertyChange('HouseNumber')}
                                label="House No"
                                placeholder="HouseNumber"
                                message={invalidEvent.HouseNumber}
                                state={!invalidEvent.HouseNumber ? 'default' : 'danger'}
                                maxLength={10}
                            />
                            <FormInput
                                value={newEvent.Street}
                                onChangeValue={handleClassPropertyChange('Street')}
                                label="Street"
                                placeholder="Street"
                                message={invalidEvent.Street}
                                state={!invalidEvent.Street ? 'default' : 'danger'}
                                maxLength={50}
                            />
                            <FormInput
                                value={newEvent.City}
                                onChangeValue={handleClassPropertyChange('City')}
                                label="City"
                                placeholder="City"
                                message={invalidEvent.City}
                                state={!invalidEvent.City ? 'default' : 'danger'}
                                maxLength={25}
                            />
                            <FormInput
                                value={newEvent.State}
                                onChangeValue={handleClassPropertyChange('State')}
                                label="State"
                                placeholder="State"
                                message={invalidEvent.State}
                                state={!invalidEvent.State ? 'default' : 'danger'}
                                maxLength={25}
                            />
                            <FormInput
                                value={newEvent.Zipcode}
                                onChangeValue={handleClassPropertyChange('Zipcode')}
                                label="Zipcode"
                                placeholder="Zipcode"
                                message={invalidEvent.Zipcode}
                                state={!invalidEvent.Zipcode ? 'default' : 'danger'}
                                maxLength={10}
                            />
                            <FormInput
                                value={newEvent.TotalCapacity}
                                onChangeValue={handleClassPropertyChange('TotalCapacity')}
                                label="Capacity"
                                type="number"
                                message={invalidEvent.TotalCapacity}
                                state={!invalidEvent.TotalCapacity ? 'default' : 'danger'}
                                placeholder="Total Capacity"
                            />
                            <FormInput
                                value=""
                                onChangeValue={handleEventImageChange()}
                                label="Image Url"
                                type="file"
                                size="sm"
                                placeholder={`${eventImageName}`}
                            />
                        </div>
                    </Row>
                </InfoCard>
                <InfoCard
                    title="Attendee Information"
                    subTitle="Control what information an attendee should provide."
                    showFooter={false}
                >

                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Father's Name&nbsp;&nbsp;"
                                type="checkbox"
                                value={fathersNameApplicable}
                                onChangeValue={setFathersNameApplicability}
                            />
                        </div>
                        {fathersNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Father's Name is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!fathersNameApplicable}
                                    value={fathersNameRequired}
                                    onChangeValue={setFathersNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Mother's Name .&nbsp;&nbsp;"
                                type="checkbox"
                                value={mothersNameApplicable}
                                onChangeValue={setMothersNameApplicability}
                            />
                        </div>
                        {mothersNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Mother's Name is  Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!mothersNameApplicable}
                                    value={mothersNameRequired}
                                    onChangeValue={setMothersNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Emergency Contact Name .&nbsp;&nbsp;"
                                type="checkbox"
                                value={emergencyContactNameApplicable}
                                onChangeValue={setEmergencyContactNameApplicability}
                            />
                        </div>
                        {emergencyContactNameApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Emergency Contact Name is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!emergencyContactNameApplicable}
                                    value={emergencyContactNameRequired}
                                    onChangeValue={setEmergencyContactNameRequired}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <FormInput
                                label="Emergency Contact Number&nbsp;&nbsp;"
                                type="checkbox"
                                value={emergencyContactNumberApplicable}
                                onChangeValue={setEmergencyContactNumberApplicability}
                            />
                        </div>
                        {emergencyContactNumberApplicable && (
                            <div className="col-md-6">
                                <FormInput
                                    label="Emergency Contact Number is Required&nbsp;&nbsp;"
                                    type="checkbox"
                                    disabled={!emergencyContactNumberApplicable}
                                    value={emergencyContactNumberRequired}
                                    onChangeValue={setEmergencyContactNumberRequired}
                                />
                            </div>
                        )}
                    </Row>
                </InfoCard>
                <InfoCard
                    title="Days"
                    subTitle="Fill in days of the week this event will take place."
                    showFooter={false}
                >
                    <Row>
                        <div className="col-md-6 col-offset-md-6">
                            {newEvent.Days.map((day, index) => (
                                <div key={`${day.Day}`}>
                                    <Row>
                                        <div className="col-md-12">
                                            <span className="text-primary">
                                                {' '}
                                                <IconClose
                                                    size={20}
                                                    className="my-3 float-right"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={removeDay(index)}
                                                />
                                                {' '}
                                            </span>
                                        </div>
                                    </Row>
                                    <FormInput
                                        value={day.Day}
                                        onChangeValue={handleDayPropertyChange('Day', index)}
                                        row
                                        label="Day"
                                        options={getDayLabels(day.Day)}
                                        type="select"
                                    />
                                    <FormInput
                                        value={day.StartTime}
                                        onChangeValue={handleDayPropertyChange('StartTime', index)}
                                        row
                                        label="Start Time"
                                        type="time"
                                        message={invalidEvent.Days[index] ? invalidEvent.Days[index].StartTime : ''}
                                        state={!(invalidEvent.Days[index] && invalidEvent.Days[index].StartTime) ? 'default' : 'danger'}
                                    />
                                    <FormInput
                                        value={day.EndTime}
                                        onChangeValue={handleDayPropertyChange('EndTime', index)}
                                        row
                                        label="End Time"
                                        type="time"
                                        message={invalidEvent.Days[index] ? invalidEvent.Days[index].EndTime : ''}
                                        state={!(invalidEvent.Days[index] && invalidEvent.Days[index].EndTime) ? 'default' : 'danger'}
                                    />
                                    <hr />
                                </div>
                            ))}
                            <Button
                                color="primary"
                                onClick={addDayToEve}
                                disabled={!canAddAnotherDay()}
                            >
                                Add Another Day
                            </Button>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className="col-md-12 d-flex justify-content-between">
                            <Button color="secondary" disabled={loading} onClick={goBack}>Cancel</Button>
                            <Button loading={loading} color="primary" onClick={saveEvent}>Add Event</Button>
                        </div>
                    </Row>
                </InfoCard>
            </div>
        </div>
    );
}

AddEvent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    masjid: PropTypes.shape({}).isRequired
};
const mapStateToProps = ({ masjid: { data } }) => ({
    masjid: data
});

export default connect(mapStateToProps)(AddEvent);
