import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Row
} from 'reactstrap';
import pad from 'sugar/number/pad';
import {
    PageHeader,
    FormInput,
    Button,
    Table
} from '../../shared/components';
import { DateUtils } from '../../utils';
import { SalatAPI, Constants } from '../../api';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert
} from '../../store/actions';
import EditTimingsModal from './EditTimingsModal';
import {
    YEARS,
    MONTHS_MAP,
    MONTHS
} from './constants';

function DailySalat({ masjidID, dispatch }) {
    const [year, setYear] = useState(`${DateUtils.getCurrentYear()}`);

    const [month, setMonth] = useState(`${DateUtils.getCurrentMonth()}`);

    const [editModalVisible, setEditModalVisible] = useState(false);

    const [timings, setTimings] = useState({});

    const [timingList, setTimingList] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadExistingSalatTimes();
    }, [month, year]);

    async function loadExistingSalatTimes() {
        setLoading(true);
        const { error, data } = await SalatAPI.getSalatTimesForMonth(masjidID, +month + 1, year);
        if (error) {
            // Ignoring CLIENT_ERROR since we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        // For the edit modal.
        setTimings(generateTimingsMap(data.PrayerTimesMap));
        // For the list.
        setTimingList(generateTimingsList(data.PrayerTimesMap));
        setLoading(false);
    }

    // This state will return prayer times as so.
    // From PrayerTimesArray.
    // input: {
    //     01/01/2018: {
    //          Day: 1,
    //          Jumuah: 12313123
    //          ...rest of the salats
    //     },
    //     ...rest of the days of the month.
    // }
    //
    //
    // output: {
    //   1: {
    //      Fajr: '01:01',
    //      ...rest of the salats inside the salats prop
    //   }
    //   ...rest of the dates of the month.
    // }
    function generateTimingsMap(prayerMap) {
        function formatToHHmm(timing) {
            return DateUtils.format(DateUtils.getISOVersion(timing), 'HH:mm');
        }

        return Object.values(prayerMap).reduce((timingsMap, timing) => {
            /* eslint-disable no-param-reassign */
            if (timing.Fajr) {
                if (!timingsMap[timing.Day]) {
                    timingsMap[timing.Day] = {};
                }
                timingsMap[timing.Day].Fajr = formatToHHmm(timing.Fajr);
            }
            if (timing.Zuhr) {
                if (!timingsMap[timing.Day]) {
                    timingsMap[timing.Day] = {};
                }
                timingsMap[timing.Day].Zuhr = formatToHHmm(timing.Zuhr);
            }
            if (timing.Asr) {
                if (!timingsMap[timing.Day]) {
                    timingsMap[timing.Day] = {};
                }
                timingsMap[timing.Day].Asr = formatToHHmm(timing.Asr);
            }
            if (timing.Maghrib) {
                if (!timingsMap[timing.Day]) {
                    timingsMap[timing.Day] = {};
                }
                timingsMap[timing.Day].Maghrib = formatToHHmm(timing.Maghrib);
            }
            if (timing.Isha) {
                if (!timingsMap[timing.Day]) {
                    timingsMap[timing.Day] = {};
                }
                timingsMap[timing.Day].Isha = formatToHHmm(timing.Isha);
            }
            /* eslint-enable */
            return timingsMap;
        }, { });
    }

    // This will return prayer times as a list for the table.
    // From PrayerTimesArray.
    // input: {
    //     01/01/2018: {
    //          Day: 1,
    //          Fajr: 12313123
    //          ...rest of the salats
    //     },
    //     ...rest of the days of the month.
    // }
    //
    // output: [{
    //      Day: '1st',
    //      Fajr: '01:01',
    //      ...rest of the salats inside the salats prop
    //   }
    //   ...rest of the dates of the month.
    // ]
    function generateTimingsList(prayersMap) {
        function formatToHHmmA(timing) {
            if (!timing) {
                return '-';
            }
            return DateUtils.format(DateUtils.getISOVersion(timing), 'hh:mm A');
        }
        function formatDay(day) {
            return DateUtils.format(new Date(year, month, day), 'Do');
        }

        return Array(getTotalDays())
            .fill(null)
            .map((_, index) => {
                const date = index + 1;
                const key = `${+month + 1}/${date}/${year}`;
                if (prayersMap[key]) {
                    const timing = prayersMap[key];
                    return {
                        id: formatDay(date),
                        Day: formatDay(date),
                        Fajr: formatToHHmmA(timing.Fajr),
                        Zuhr: formatToHHmmA(timing.Zuhr),
                        Asr: formatToHHmmA(timing.Asr),
                        Maghrib: formatToHHmmA(timing.Maghrib),
                        Isha: formatToHHmmA(timing.Isha)
                    };
                }
                return {
                    id: formatDay(date),
                    Day: formatDay(date),
                    Fajr: '-',
                    Zuhr: '-',
                    Asr: '-',
                    Maghrib: '-',
                    Isha: '-'
                };
            });
    }

    function hideEditModal() {
        setEditModalVisible(false);
    }

    function showEditModal() {
        setEditModalVisible(true);
    }

    async function onEdit(newTimings) {
        function convertToUNIXOrNull(day, time) {
            if (!time) {
                return null;
            }
            return DateUtils.toMillis(`${year}-${pad(+month + 1, 2)}-${pad(day, 2)}T${time}Z`);
        }

        const newTimingListForAPI = Object
            .entries(newTimings)
            .map(([day, timingsForSingleDay]) => ({
                Day: day,
                Month: +month + 1,
                Year: year,
                // Converting to UNIX timestamps.
                // The input should be exactly YYYY-MM-DDT:HH:mm.
                Fajr: convertToUNIXOrNull(day, timingsForSingleDay.Fajr),
                Zuhr: convertToUNIXOrNull(day, timingsForSingleDay.Zuhr),
                Asr: convertToUNIXOrNull(day, timingsForSingleDay.Asr),
                Maghrib: convertToUNIXOrNull(day, timingsForSingleDay.Maghrib),
                Isha: convertToUNIXOrNull(day, timingsForSingleDay.Isha)
            }));
        const { error } = await SalatAPI.saveSalatTimesForMonth(masjidID, newTimingListForAPI);
        if (error) {
            // Ignoring CLIENT_ERROR since we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        hideEditModal();
        // Changing the values for Edit Modal and the Table.
        // We're just making another API call to save complex merging of salat data.
        await loadExistingSalatTimes();
        dispatch(showSuccessAlert('Successfully updated salat times for this month!'));
    }

    function getTotalDays() {
        if (month === '1' && !DateUtils.isLeapYear(year)) {
            // If this is not the lear year AND
            // is the month of feb. Let's take out the 29th day.
            return MONTHS_MAP[month].days - 1;
        }
        return MONTHS_MAP[month].days;
    }

    return (
        <div className="view">
            <PageHeader title="Daily Salat" description="Here are your masjid's daily salah timings." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <Card>
                            <CardBody>
                                <div className="h5">Select Year and Month</div>
                                <Row className="mt-4">
                                    <div className="col-md-2">
                                        <FormInput
                                            label="Year"
                                            type="select"
                                            options={YEARS}
                                            value={year}
                                            onChangeValue={setYear}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <FormInput
                                            label="Month"
                                            type="select"
                                            options={MONTHS}
                                            value={month}
                                            onChangeValue={setMonth}
                                        />
                                    </div>
                                </Row>
                                <Row className="mt-4">
                                    <div className="h5 col-md-12">
                                        {MONTHS_MAP[month].name}
                                        {' '}
                                        {year}
                                    </div>
                                </Row>
                                <Row className="mt-4">
                                    <div className="col-md-6">
                                        <div className="h5">
                                            Salat Timings
                                        </div>
                                    </div>
                                    <div className="col-md-6 button-right">
                                        <Button onClick={showEditModal} disabled={loading}>
                                            Edit Salat Timings
                                        </Button>
                                    </div>

                                </Row>
                                <EditTimingsModal
                                    show={editModalVisible}
                                    onClose={hideEditModal}
                                    onSave={onEdit}
                                    totalDaysInMonth={getTotalDays()}
                                    month={+month}
                                    year={+year}
                                    salats={['Fajr', 'Zuhr', 'Asr', 'Maghrib', 'Isha']}
                                    timings={timings}
                                />
                                <Row className="mt-4">
                                    <div className="col-md-12">
                                        <Table
                                            columns={[
                                                { label: 'Day', key: 'Day' },
                                                { label: 'Fajr', key: 'Fajr' },
                                                { label: 'Zuhr', key: 'Zuhr' },
                                                { label: 'Asr', key: 'Asr' },
                                                { label: 'Maghrib', key: 'Maghrib' },
                                                { label: 'Isha', key: 'Isha' },
                                            ]}
                                            sortable={false}
                                            data={timingList}
                                            itemsPerPage={getTotalDays()}
                                            loading={loading}
                                            rowClickable={false}
                                            enableFilters={false}
                                            enableExports={false}
                                        />
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </div>
    );
}

DailySalat.propTypes = {
    masjidID: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired
};


const mapStateToProps = ({ masjid: { data: { ID } } }) => ({
    masjidID: ID
});

export default connect(mapStateToProps)(DailySalat);
