import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import {
    Row,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import {
    PageHeader,
    InfoCard,
    DescriptionList,
    Button,
    FormInput,
    Table,
} from '../../shared/components';
import { getParticipant } from '../../store/selectors';
import { unenrollParticipantAsync, updateParticipantPaymentAsync } from '../../store/actions';
import { DateUtils, StringUtils } from '../../utils';

function Participant({
    dispatch,
    registration: {
        ProgramID,
        AttendeeID,
        RegistrationPlatform,
        Attendee: {
            FirstName,
            LastName,
            PhoneNumber,
            Email,
            Age,
            Gender,
            EmergencyContactName,
            EmergencyContactNumber,
            FathersName,
            MothersName
        },
        UnenrolledFormatted,
        Unenrolled,
        UnenrolledAt,
        EnrolledAt,
        Cost,
        Cancelled,
        RegistrationState,
        Charges
    }
}) {
    const amountPaid = Charges.map(c => c.AmountPaid).reduce((prev, current) => prev + current, 0);
    const paidInFull = amountPaid >= Cost;
    const [unenrollModal, setUnenrollModal] = useState(false);
    const [manualPaymentModal, setManualPaymentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({
        Amount: '',
    });
    const [currentRegistration, setCurrentRegistration] = useState({
        ProgramID,
        AttendeeID,
        WithRefund: false,
        Amount: Cost - amountPaid,
        Unenroll: true
    });
    function getHeaderTitle() {
        return `${FirstName} ${LastName} - ${StringUtils.humanize(RegistrationState)}`;
    }
    async function unenrollParticipant() {
        setLoading(true);
        const error = await dispatch(unenrollParticipantAsync(currentRegistration));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideUnenrollModal();
    }
    function viewProgramCharge({ ID }) {
        browserHistory.push({
            pathname: `/events/${ProgramID}/participants/${AttendeeID}/charge/${ID}`,
            state: {
                attendeeID: AttendeeID,
                programID: ProgramID,
                chargeID: ID
            }
        });
    }
    async function updateParticipantPayment() {
        if (!currentRegistration.Amount) {
            setValidation({
                Amount: 'Amount can\'t be empty.'
            });
            return;
        }
        setLoading(true);
        const error = await dispatch(updateParticipantPaymentAsync(currentRegistration));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideManualPaymentModal();
    }
    function hideManualPaymentModal() {
        setManualPaymentModal(false);
    }

    function showManualPaymentModal() {
        setManualPaymentModal(true);
    }
    function changePaymentAmount(value) {
        setCurrentRegistration({
            ...currentRegistration,
            Amount: value,
        });
    }
    function changeRefund() {
        setCurrentRegistration({
            ...currentRegistration,
            WithRefund: !currentRegistration.WithRefund,
        });
    }
    function hideUnenrollModal() {
        setUnenrollModal(false);
    }

    function showUnenrollModal() {
        setUnenrollModal(true);
    }


    return (
        <div className="view">
            <PageHeader title={getHeaderTitle()} description="All the details about this participant." />
            <div className="view-content view-dashboard">
                <InfoCard
                    title="Participant Information"
                    subTitle="Information about the participant and his/her registration."
                    showFooter={false}
                >
                    <Row>
                        <DescriptionList
                            list={[
                                { label: 'First Name', value: FirstName },
                                { label: 'Last Name', value: LastName },
                                { label: 'Emergency Contact Name', value: EmergencyContactName || '-' },
                                { label: 'Emergency Contact Number', value: EmergencyContactNumber || '-' },
                                { label: 'Father\'s Name', value: FathersName || '-' },
                                { label: 'Mother\'s Name', value: MothersName || '-' },
                                { label: 'Email', value: Email },
                                { label: 'Phone Number', value: PhoneNumber },
                                { label: 'Age', value: Age },
                                { label: 'Gender', value: Gender },
                                { label: 'Registration Platform', value: RegistrationPlatform },
                                { label: 'EnrolledAt', value: EnrolledAt },
                                { label: 'Unenrolled', value: UnenrolledFormatted },
                                { label: 'UnenrolledAt', value: UnenrolledAt || '-' },
                                {
                                    label: 'Manual Payment',
                                    value: (
                                        <Button
                                            color="secondary"
                                            onClick={showManualPaymentModal}
                                            disabled={paidInFull || Cancelled || Unenrolled}
                                        >
                                            {paidInFull ? 'Already Fully Paid' : 'Process Manual Payment'}
                                        </Button>
                                    )
                                },
                                {
                                    label: 'Unenroll',
                                    value: (
                                        <Button
                                            color="danger"
                                            onClick={showUnenrollModal}
                                            disabled={Unenrolled}
                                        >
                                            {Unenrolled ? 'Already Unenrolled' : 'Unenroll Student'}
                                        </Button>
                                    )
                                }
                            ]}
                            containerClassName="col-md-12"
                        />
                    </Row>
                </InfoCard>
                <Row>
                    <InfoCard
                        title="Charges"
                        subTitle="All the charges made for this event by this user."
                        showFooter={false}
                        containerClassName="col-md-12"
                    >
                        <Table
                            title=""
                            columns={[
                                {
                                    label: 'Charged At',
                                    key: 'CreatedAt',
                                    sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                },
                                { label: 'Amount Paid', key: 'AmountPaidFormatted' },
                                { label: 'Amount Paid Minus Fees', key: 'AmountPaidMinusFees' },
                                { label: 'Refunded Amount', key: 'RefundedAmount' },
                                { label: 'Refunded', key: 'Refunded' },
                            ]}
                            data={Charges}
                            enableFilters={false}
                            enableExports
                            defaultSortColumnKey="CreatedAt"
                            onRowClick={viewProgramCharge}
                            containerClassName="mt-2"
                        />
                    </InfoCard>
                </Row>
                <Modal isOpen={manualPaymentModal} toggle={showManualPaymentModal} className="default">
                    <ModalHeader toggle={hideManualPaymentModal}>Manual Payment</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div>
                                <FormInput
                                    value={currentRegistration.Amount}
                                    onChangeValue={changePaymentAmount}
                                    row
                                    label="Amount"
                                    type="number"
                                    placeholder="Amount"
                                    message={validation.Amount}
                                    state={!validation.Amount ? 'default' : 'danger'}
                                    max={Cost}
                                    min={0}
                                />
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="primary" onClick={updateParticipantPayment}>Update</Button>
                        {' '}
                        <Button color="secondary" onClick={hideManualPaymentModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={unenrollModal} toggle={showUnenrollModal} className="default">
                    <ModalHeader toggle={hideUnenrollModal}>Unenroll Participant</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <legend className="col-form-legend font-weight-bold">
                                Are you sure you want to Unenroll the Participant?
                            </legend>
                            <div>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onChange={changeRefund}
                                        checked={currentRegistration.WithRefund}
                                        disabled={Unenrolled}
                                    />
                                    &nbsp;
                                    Refund Fee of the Participant
                                </Label>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            loading={loading}
                            color="danger"
                            onClick={unenrollParticipant}
                        >
                            Unenroll
                        </Button>
                        {' '}
                        <Button color="secondary" onClick={hideUnenrollModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

Participant.propTypes = {
    dispatch: PropTypes.func.isRequired,
    registration: PropTypes.shape({}).isRequired
};


const mapStateToProps = (state, props) => ({
    registration: getParticipant(state, props)
});

export default connect(mapStateToProps)(Participant);
