import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Row
} from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import IconPlus from 'react-icons/lib/md/add';

import {
    PageHeader,
    InfoCard,
    Table
} from '../../shared/components';
import { getCategoryWiseDonations, getDonations } from '../../store/selectors';
import { showWarningAlert } from '../../store/actions';
import { StringUtils, DateUtils } from '../../utils';

function DonationsList({
    categories,
    categoryWiseDonations,
    donations,
    dispatch
}) {
    function viewDonation(donation) {
        browserHistory.push({ pathname: `/donations/${donation.id}`, state: donation.id });
    }

    function viewInStripe({ stripeID, successful }) {
        if (!successful) {
            dispatch(showWarningAlert('You cannot view a payment that failed, sadly.'));
            return;
        }
        window.open(StringUtils.toStripeURL(stripeID), '_blank');
    }

    return (
        <div className="view">
            <PageHeader title="Donations" description="Here are all your donations." />
            <div className="view-content">
                <Row className="mb-4">
                    <Card className="col-md-12">
                        <CardBody>
                            <div className="h5 mb-4">Category Wise Donations</div>
                            <Row>
                                {categories.map(({ ID, CategoryName }) => (
                                    <InfoCard
                                        key={ID}
                                        title={CategoryName}
                                        subTitle={StringUtils.formatAmount(
                                            categoryWiseDonations[ID] || 0
                                        )}
                                        containerClassName="col-md-3"
                                        footerContent="View More"
                                        footerHref={`/categories/${ID}`}
                                        footerHrefState={ID}
                                    />
                                ))}
                                <div className="col-md-3 text-center">
                                    <Link to="/categories/list">
                                        <Card className="add-new-category">
                                            <CardBody>
                                                <br />
                                                <IconPlus color="green" size={55} />
                                                <br />
                                                <span className="h5" style={{ fontSize: 13 }}>Add New Category</span>
                                                <br />
                                                <br />
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <Row>
                    <Card className="col-md-12">
                        <CardBody>
                            <Table
                                title="Donations"
                                columns={[
                                    {
                                        label: 'Date',
                                        key: 'date',
                                        sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                    },
                                    { label: 'Name', key: 'name' },
                                    { label: 'Amount', key: 'amount' },
                                    { label: 'Category', key: 'categoryName' },
                                    { label: 'Gross Amount', key: 'amountWithFees' },
                                    { label: 'Fees', key: 'fees' },
                                    { label: 'Refunded', key: 'refunded' },
                                    { label: 'Email', key: 'email' },

                                ]}
                                data={donations}
                                filters={[
                                    {
                                        label: 'Name',
                                        key: 'name'
                                    },
                                    {
                                        label: 'Email',
                                        key: 'email',
                                    },
                                    {
                                        label: 'Category',
                                        key: 'categoryName',
                                        filterInputType: 'select',
                                        filterSelectOptions: ['All'].concat(categories.map(c => c.CategoryName)),
                                        filterFunc(categoryName, chosenCategoryName) {
                                            return chosenCategoryName === 'All' || chosenCategoryName === categoryName;
                                        }
                                    },
                                    {
                                        label: 'Refunded',
                                        key: 'refunded',
                                        filterInputType: 'select',
                                        filterSelectOptions: ['Both', 'Yes', 'No'],
                                        filterFunc(refunded, chosenRefundedOption) {
                                            return chosenRefundedOption === 'Both' || refunded === chosenRefundedOption;
                                        }
                                    },
                                    {
                                        label: 'From',
                                        key: 'date',
                                        filterInputType: 'date',
                                        filterFunc(date, chosenDate) {
                                            return DateUtils.isBefore(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                        }
                                    },
                                    {
                                        label: 'To',
                                        key: 'date',
                                        filterInputType: 'date',
                                        filterFunc(date, chosenDate) {
                                            return DateUtils.isAfter(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                        }
                                    },
                                    {
                                        label: 'Has Email',
                                        key: 'email',
                                        filterInputType: 'select',
                                        filterSelectOptions: ['Both', 'Has Email', 'Doesn\'t Have Email'],
                                        filterFunc(email, hasEmail) {
                                            if (hasEmail === 'Both') {
                                                return true;
                                            }
                                            if (hasEmail === 'Has Email') {
                                                return !!email;
                                            }
                                            return !email;
                                        }
                                    },
                                ]}
                                itemsPerPage={50}
                                onRowClick={viewDonation}
                                showButtonOne
                                buttonOneLabel="View in Stripe"
                                onButtonOneClick={viewInStripe}
                                defaultSortColumnKey="date"
                            />
                        </CardBody>
                    </Card>
                </Row>
            </div>
        </div>
    );
}

DonationsList.propTypes = {
    categoryWiseDonations: PropTypes.shape({}).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        CategoryName: PropTypes.string.isRequired,
        ID: PropTypes.number.isRequired
    })).isRequired,
    donations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        categoryName: PropTypes.string.isRequired,
        stripeID: PropTypes.string,
        date: PropTypes.string.isRequired
    })).isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    categoryWiseDonations: getCategoryWiseDonations(state),
    categories: state.masjid.data.Categories,
    donations: getDonations(state)
});

export default connect(mapStateToProps)(DonationsList);
