/* eslint-disable no-param-reassign */

/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
import React, { Fragment, useLayoutEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DraggableList = ({
    keyExtractor,
    renderSeparator,
    renderItem,
    onDragEnd,
    ...props
}) => {
    // Need to create an internal state so can see the dragged state immediately without relying on "onDragEnd".
    const [data, setData] = useState([]);
    useLayoutEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <DragDropContext
            onDragEnd={({ source, destination }) => {
                if (destination) {
                    setData((data) => {
                        const updatedData = [...data];
                        updatedData.splice(source.index, 1);
                        updatedData.splice(destination.index, 0, data[source.index]);
                        return updatedData;
                    });
                    // Starting Position from 1 hence sending destination as index+1
                    onDragEnd(data[source.index], destination.index + 1, source.index);
                }
            }}
        >
            <Droppable droppableId="droppable" style={{ transform: 'none' }}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {data.map((item, index) => (
                            <Fragment key={keyExtractor(item, index)}>
                                <Draggable draggableId={keyExtractor(item, index)} index={index}>
                                    {(provided, snapshot) => {
                                        if (snapshot.isDragging) {
                                            const offset = { x: 10, y: 0 }; //  fixed container left/top position
                                            const x = provided.draggableProps.style.left - offset.x;
                                            const y = provided.draggableProps.style.top - offset.y;
                                            provided.draggableProps.style.left = x;
                                            provided.draggableProps.style.top = y;
                                        }
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {renderItem(item, index, snapshot.isDragging)}
                                            </div>);
                                    }}
                                </Draggable>
                                {renderSeparator && index !== data.length - 1 && renderSeparator(item)}
                            </Fragment>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;
