import { useEffect, useState } from 'react';
import { getGeoCodeAddress } from '../../api/address';

const useLatLng = () => {
    const [position, setPosition] = useState(null);
    const [geoAddress, setGeoAddress] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((location) => {
            setPosition({
                latitude: location.coords.latitude.toFixed(6),
                longitude: location.coords.longitude.toFixed(6)
            });
        }, () => console.error("Couln't get you lat ongs from browser"), []);
    }, []);

    useEffect(() => {
        if (position) {
            getAddressFromLatLng(position.latitude, position.longitude);
        }
    }, [position]);

    const getAddressFromLatLng = async (latitude, longitude) => {
        const address = await getGeoCodeAddress(latitude, longitude);
        setGeoAddress(address.data.address);
    };


    return position === null ? []
        : [position, geoAddress];
};

export default useLatLng;
