import { createSelector } from 'reselect';

const selectAdmin = ({ admin }) => admin;

// Let's not memoize this function intentionally since we need
// it to calculate token expiry from new Date() each time.
export const isLoggedIn = ({
    admin: {
        token,
        expires
    }
}) => !!token && (expires >= new Date().getTime());


export const hasSuperAdminRole = createSelector(
    selectAdmin,
    ({ role }) => role === 'SUPER_ADMIN'
);


export const hasTeacherRole = createSelector(
    selectAdmin,
    ({ role }) => role === 'TEACHER'
);
