import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Row
} from 'reactstrap';
import pad from 'sugar/number/pad';
import {
    PageHeader,
    FormInput,
    Button,
    Table
} from '../../shared/components';
import { DateUtils } from '../../utils';
import { SalatAPI, Constants } from '../../api';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert
} from '../../store/actions';
import EditTimingsModal from './EditTimingsModal';
import {
    YEARS,
    MONTHS_MAP,
    MONTHS
} from './constants';

function JummahSalat({ masjidID, dispatch }) {
    const [year, setYear] = useState(`${DateUtils.getCurrentYear()}`);

    const [month, setMonth] = useState(`${DateUtils.getCurrentMonth()}`);

    const [editModalVisible, setEditModalVisible] = useState(false);

    const [timings, setTimings] = useState({});

    const [timingList, setTimingList] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadExistingSalatTimes();
    }, [month, year]);

    // Is the date a Friday?
    function isDateValid(date) {
        return DateUtils.isFriday(date, month, year);
    }

    async function loadExistingSalatTimes() {
        setLoading(true);
        const { error, data } = await SalatAPI.getSalatTimesForMonth(masjidID, +month + 1, year);
        if (error) {
            // Ignoring CLIENT_ERROR since we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        // For the edit modal.
        setTimings(generateTimingsMap(data.PrayerTimesMap));
        // For the list.
        setTimingList(generateTimingsList(data.PrayerTimesMap));
        setLoading(false);
    }

    // This state will return prayer times as so.
    // From PrayerTimesArray.
    // input: {
    //     01/01/2018: {
    //          Day: 1,
    //          Jumuah: 12313123
    //          ...rest of the salats
    //     },
    //     ...rest of the days of the month.
    // }
    //
    // output: {
    //   1: {
    //      Jumuah: '01:01',
    //      ...rest of the salats inside the salats prop
    //   }
    //   ...rest of the dates of the month.
    // }
    function generateTimingsMap(prayerMap) {
        function formatToHHmm(timing) {
            return DateUtils.format(DateUtils.getISOVersion(timing), 'HH:mm');
        }

        return Object
            .values(prayerMap)
            .filter(({ Day }) => isDateValid(Day))
            .reduce((timingsMap, timing) => {
            /* eslint-disable no-param-reassign */
                if (timing.Jumuah) {
                    if (!timingsMap[timing.Day]) {
                        timingsMap[timing.Day] = {};
                    }
                    timingsMap[timing.Day].Jumuah = formatToHHmm(timing.Jumuah);
                }
                if (timing.SecondJumuah) {
                    if (!timingsMap[timing.Day]) {
                        timingsMap[timing.Day] = {};
                    }
                    timingsMap[timing.Day].SecondJumuah = formatToHHmm(timing.SecondJumuah);
                }
                if (timing.ThirdJumuah) {
                    if (!timingsMap[timing.Day]) {
                        timingsMap[timing.Day] = {};
                    }
                    timingsMap[timing.Day].ThirdJumuah = formatToHHmm(timing.ThirdJumuah);
                }
                /* eslint-enable */
                return timingsMap;
            }, { });
    }

    // This will return prayer times as a list for the table.
    // From PrayerTimesArray.
    // input: {
    //     01/01/2018: {
    //          Day: 1,
    //          Jumuah: 12313123
    //          ...rest of the salats
    //     },
    //     ...rest of the days of the month.
    // }
    //
    // output: [{
    //      Day: '1st',
    //      Jumuah: '01:01',
    //      ...rest of the salats inside the salats prop
    //   }
    //   ...rest of the dates of the month.
    // ]
    function generateTimingsList(prayersMap) {
        function formatToHHmmA(timing) {
            if (!timing) {
                return '-';
            }
            return DateUtils.format(DateUtils.getISOVersion(timing), 'hh:mm A');
        }
        function formatDay(day) {
            return DateUtils.format(new Date(year, month, day), 'Do');
        }

        return Array(getTotalDays())
            .fill(null)
            .map((_, index) => index + 1)
            .filter(isDateValid)
            .map(date => {
                const key = `${+month + 1}/${date}/${year}`;
                if (prayersMap[key]) {
                    const timing = prayersMap[key];
                    return {
                        id: formatDay(date),
                        Day: formatDay(date),
                        Jumuah: formatToHHmmA(timing.Jumuah),
                        SecondJumuah: formatToHHmmA(timing.SecondJumuah),
                        ThirdJumuah: formatToHHmmA(timing.ThirdJumuah)
                    };
                }
                return {
                    id: formatDay(date),
                    Day: formatDay(date),
                    Jumuah: '-',
                    SecondJumuah: '-',
                    ThirdJumuah: '-'
                };
            });
    }

    function hideEditModal() {
        setEditModalVisible(false);
    }

    function showEditModal() {
        setEditModalVisible(true);
    }

    async function onEdit(newTimings) {
        function convertToUNIXOrNull(day, time) {
            if (!time) {
                return null;
            }
            return DateUtils.toMillis(`${year}-${pad(+month + 1, 2)}-${pad(day, 2)}T${time}Z`);
        }

        const newTimingListForAPI = Object
            .entries(newTimings)
            .map(([day, timingsForSingleDay]) => ({
                Day: day,
                Month: +month + 1,
                Year: year,
                // Converting to UNIX timestamps.
                // The input should be exactly YYYY-MM-DDT:HH:mm.
                Jumuah: convertToUNIXOrNull(day, timingsForSingleDay.Jumuah),
                SecondJumuah: convertToUNIXOrNull(day, timingsForSingleDay.SecondJumuah),
                ThirdJumuah: convertToUNIXOrNull(day, timingsForSingleDay.ThirdJumuah)
            }));

        const { error } = await SalatAPI.saveJumuahSalatTimesForMonth(
            masjidID,
            newTimingListForAPI
        );
        if (error) {
            // Ignoring CLIENT_ERROR since we won't get that here.
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
                return;
            }
            dispatch(showNoInternetAlert());
            return;
        }
        hideEditModal();
        // Changing the values for Edit Modal and the Table.
        // We're just making another API call to save complex merging of salat data.
        await loadExistingSalatTimes();
        dispatch(showSuccessAlert('Successfully updated jummah salat times for this month!'));
    }

    function getTotalDays() {
        if (month === '1' && !DateUtils.isLeapYear(year)) {
            // If this is not the lear year AND
            // is the month of feb. Let's take out the 29th day.
            return MONTHS_MAP[month].days - 1;
        }
        return MONTHS_MAP[month].days;
    }

    return (
        <div className="view">
            <PageHeader title="Jummah Salat" description="Here are your masjid's weekly jummah timings." />
            <div className="view-content view-dashboard">
                <Row>
                    <div className="col-md-12">
                        <Card>
                            <CardBody>
                                <div className="h5">Select Year and Month</div>
                                <Row className="mt-4">
                                    <div className="col-md-2">
                                        <FormInput
                                            label="Year"
                                            type="select"
                                            options={YEARS}
                                            value={year}
                                            onChangeValue={setYear}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <FormInput
                                            label="Month"
                                            type="select"
                                            options={MONTHS}
                                            value={month}
                                            onChangeValue={setMonth}
                                        />
                                    </div>
                                </Row>
                                <Row className="mt-4">
                                    <div className="h5 col-md-12">
                                        {MONTHS_MAP[month].name}
                                        {' '}
                                        {year}
                                    </div>
                                </Row>
                                <Row className="mt-4">
                                    <div className="col-md-6">
                                        <div className="h5">
                                            Jummah Salat Timings
                                        </div>
                                    </div>
                                    <div className="col-md-6 button-right">
                                        <Button onClick={showEditModal} disabled={loading}>
                                            Edit Salat Timings
                                        </Button>
                                    </div>

                                </Row>
                                <EditTimingsModal
                                    show={editModalVisible}
                                    onClose={hideEditModal}
                                    onSave={onEdit}
                                    totalDaysInMonth={getTotalDays()}
                                    month={+month}
                                    year={+year}
                                    salats={['Jumuah', 'SecondJumuah', 'ThirdJumuah']}
                                    timings={timings}
                                    isDateValid={isDateValid}
                                />
                                <Row className="mt-4">
                                    <div className="col-md-12">
                                        <Table
                                            columns={[
                                                { label: 'Day', key: 'Day' },
                                                { label: 'Jummah', key: 'Jumuah' },
                                                { label: 'Second Jummah', key: 'SecondJumuah' },
                                                { label: 'Third Jummah', key: 'ThirdJumuah' }
                                            ]}
                                            sortable={false}
                                            data={timingList}
                                            itemsPerPage={getTotalDays()}
                                            loading={loading}
                                            rowClickable={false}
                                            enableFilters={false}
                                            enableExports={false}
                                        />
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </div>
        </div>
    );
}

JummahSalat.propTypes = {
    masjidID: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired
};


const mapStateToProps = ({ masjid: { data: { ID } } }) => ({
    masjidID: ID
});

export default connect(mapStateToProps)(JummahSalat);
