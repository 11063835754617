import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import screenfull from 'screenfull';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';

// icons
import IconFullScreen from 'react-icons/lib/md/crop-free';
import IconAccountCircle from 'react-icons/lib/md/account-circle';
import IconLogout from 'react-icons/lib/md/power-settings-new';
import IconMenu from 'react-icons/lib/md/menu';

import { logout } from '../../../store/actions';
// style
import './style.scss';

function Header({
    toggleNav,
    dispatch,
    admin,
    masjid
}) {
    function fullScreenToggle() {
        screenfull.toggle();
    }

    function handleLogout() {
        dispatch(logout());
        browserHistory.replace('/pages/signin');
    }

    return (
        <header className="site-head d-flex align-items-center justify-content-between">
            <div className="wrap mr-4">
                <IconMenu size="24" color="#fff" onClick={toggleNav} style={{ cursor: 'pointer' }} />
            </div>
            <div className="masjid-name">
                <strong className="h3 text-uppercase">
                    {masjid}
                </strong>
            </div>
            <div className="right-elems ml-auto d-flex">
                <div className="wrap hidden-sm-down">
                    <IconFullScreen size="22" color="#fff" onClick={fullScreenToggle} />
                </div>

                <div className="wrap profile">
                    <UncontrolledDropdown>
                        <DropdownToggle tag="div">
                            <img
                                src={require('../../../assets/images/configure-stripe-1.png')}
                                alt="avatar"
                            />
                        </DropdownToggle>
                        <DropdownMenu right style={{ minWidth: '12rem' }}>
                            <DropdownItem header>
                                {admin}
                            </DropdownItem>
                            <DropdownItem divider />
                            <Link to="/account">
                                <DropdownItem>
                                    <IconAccountCircle size="16" />
                                    &emsp;
                                    Account
                                </DropdownItem>

                            </Link>
                            <div className="text-right ml-3 mr-3 mt-2">
                                <Button
                                    block
                                    color="primary"
                                    size="sm"
                                    onClick={handleLogout}
                                >
                                    <IconLogout size="15" />
                                    &emsp;Logout
                                </Button>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        </header>
    );
}

Header.propTypes = {
    toggleNav: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    admin: PropTypes.string.isRequired,
    masjid: PropTypes.string.isRequired
};

const mapStateToProps = ({ admin: { firstName, lastName }, masjid: { data: { Name } } }) => ({
    admin: `${firstName} ${lastName}`,
    masjid: Name || ''
});

export default connect(mapStateToProps)(Header);
