/* eslint-disable no-shadow */
import { createSelector } from 'reselect';
import {
    StringUtils,
    DateUtils,
    ProgramUtils,
    DonationUtils
} from '../../utils';

const selectMasjid = ({ masjid: { data } }) => data;
const selectDonations = ({ masjid: { data: { Donations } } }) => Donations;
const selectRecurringDonations = ({ masjid: { data: { RecurringDonations } } }) => RecurringDonations; // eslint-disable-line
const selectKiosks = ({ masjid: { data: { Kiosks } } }) => Kiosks;
const selectPOS = ({ masjid: { data: { POSDevices } } }) => POSDevices;
const selectGroups = ({ masjid: { data: { Groups } } }) => Groups;
const selectCategories = ({ masjid: { data: { Categories } } }) => Categories;
const selectClasses = ({ masjid: { data: { Classes } } }) => Classes;
const selectEvents = ({ masjid: { data: { Events } } }) => Events;
const selectStateFromLocation = (_, { location }) => (location ? location.state : null);
const hasOnlineClassRegistrationAddOn = ({
    masjid: {
        data: { HasOnlineClassRegistrationAddOn }
    }
}) => HasOnlineClassRegistrationAddOn;
const hasOnlineEventRegistrationAddOn = ({
    masjid: {
        data: {
            HasOnlineEventRegistrationAddOn
        }
    }
}) => HasOnlineEventRegistrationAddOn;

export const hasDonationsInPlan = createSelector(
    selectMasjid,
    ({
        HasKioskBase,
        HasAppDonationsAddOn,
        HasOnlineDonationsAddOn,
        HasPOSBase,
        HasWebsiteBase
    }) => HasKioskBase || HasAppDonationsAddOn || HasOnlineDonationsAddOn
        || HasPOSBase || HasWebsiteBase
);

export const hasRecurringDonationsInPlan = createSelector(
    selectMasjid,
    ({
        HasKioskRecurringDonationsAddOn,
        HasOnlineRecurringDonationsAddOn,
        HasPOSRecurringDonationsAddOn,
        HasWebsiteBase
    }) => HasKioskRecurringDonationsAddOn
    || HasOnlineRecurringDonationsAddOn
        || HasPOSRecurringDonationsAddOn
        || HasWebsiteBase
);

export const hasClassRegistrationInPlan = createSelector(
    selectMasjid,
    ({
        HasKioskClassRegistrationAddOn,
        HasOnlineClassRegistrationAddOn,
        HasWebsiteBase
    }) => HasKioskClassRegistrationAddOn || HasOnlineClassRegistrationAddOn || HasWebsiteBase
);

export const hasEventRegistrationInPlan = createSelector(
    selectMasjid,
    ({
        HasKioskEventRegistrationAddOn,
        HasOnlineEventRegistrationAddOn,
        HasWebsiteBase
    }) => HasKioskEventRegistrationAddOn || HasOnlineEventRegistrationAddOn || HasWebsiteBase
);

export const hasOnlineProgramRegistrationInPlan = createSelector(
    selectMasjid,
    ({
        HasOnlineClassRegistrationAddOn,
        HasOnlineEventRegistrationAddOn
    }) => HasOnlineClassRegistrationAddOn || HasOnlineEventRegistrationAddOn
);

export const needsToFillAccountData = createSelector(
    selectMasjid, ({
        HasAppBase,
        Name,
        HouseNumber,
        Street,
        City,
        Zipcode,
        State
    }) => HasAppBase && !(Name && HouseNumber && Street && City && Zipcode && State)
);

export const getDonations = createSelector(
    selectDonations,
    donations => donations.map(({
        FirstName,
        LastName,
        StripeID,
        ID,
        CategoryName,
        Amount,
        AmountWithFees,
        CreatedAt,
        Refunded,
        Email,
        Successful
    }) => ({
        id: ID,
        name: `${FirstName} ${LastName}`,
        amount: StringUtils.formatAmount(Amount),
        amountWithFees: StringUtils.formatAmount(AmountWithFees),
        fees: StringUtils.formatAmount(AmountWithFees - Amount),
        categoryName: CategoryName,
        stripeID: StripeID,
        date: DateUtils.format(CreatedAt, 'MM/DD/YYYY'),
        refunded: Refunded ? 'Yes' : 'No',
        email: Email,
        successful: Successful
    }))
);

export const getLatestDonations = createSelector(
    selectDonations,
    donations => [...donations]
        .sort((d1, d2) => d2.CreatedAt - d1.CreatedAt)
        .slice(0, 6)
        .map(({
            ID,
            FirstName,
            LastName,
            Amount,
            CreatedAt,
            PaymentByKiosk,
            DonationPlatform
        }) => ({
            id: ID,
            name: `${FirstName} ${LastName}`,
            amount: StringUtils.formatAmount(Amount),
            date: DateUtils.format(new Date(CreatedAt), 'Do MMM YY'),
            donationPlatform: PaymentByKiosk ? DonationUtils.platforms.KIOSK : DonationPlatform || 'App'
        }))
);

export const getDonation = createSelector(
    [selectDonations, selectStateFromLocation],
    (donations, donationID) => donations.find(({ ID }) => ID === donationID)
);

/* eslint-disable no-param-reassign */
export const getCategoryWiseDonations = createSelector(
    selectDonations,
    donations => donations.reduce((result, donation) => {
        if (!donation.Refunded) {
            if (!result[donation.CategoryID]) {
                result[donation.CategoryID] = 0;
            }
            result[donation.CategoryID] += donation.Amount;
        }
        return result;
    }, {})
);
/* eslint-enable no-param-reassign */

export const getRecurringDonations = createSelector(
    selectRecurringDonations,
    recurringDonations => recurringDonations.map(({
        FirstName,
        LastName,
        ID,
        CategoryName,
        Amount,
        Email,
        CreatedAt,
        MonthsRecurring,
        RecurringCancelled,
    }) => ({
        id: ID,
        name: `${FirstName} ${LastName}`,
        amount: `${StringUtils.formatAmount(Amount)} / Month`,
        categoryName: CategoryName,
        date: DateUtils.format(CreatedAt, 'MM/DD/YYYY'),
        email: Email,
        monthsRecurring: `${MonthsRecurring}`,
        recurringCancelled: RecurringCancelled
    }))
);

export const getRecurringDonation = createSelector(
    [selectRecurringDonations, selectStateFromLocation],
    (recurringDonations, donationID) => recurringDonations.find(({ ID }) => ID === donationID)
);

export const getKiosks = createSelector(
    selectKiosks,
    kiosks => kiosks.map(({
        KioskLicense,
        KioskLicenseShort,
        SizeInInches,
        PurchasedOn,
        RentedOn,
        RentalExpiresOn,
        RentalCancelled,
    }) => ({
        id: KioskLicense,
        kioskLicense: KioskLicense,
        kioskLicenseShort: KioskLicenseShort,
        sizeInInches: SizeInInches,
        purchasedOn: PurchasedOn,
        rentedOn: RentedOn,
        rentalCancelled: RentalCancelled,
        rentalExpiresOn: RentalExpiresOn
    }))
);
export const getPOS = createSelector(
    selectPOS,
    pos => pos.map(({
        POSLicense,
        PurchasedOn
    }) => ({
        id: POSLicense,
        POSLicense,
        PurchasedOn
    }))
);
export const getGroups = createSelector(
    selectGroups,
    groups => groups.map(({ Name, ID }) => ({
        label: Name,
        value: `${ID}`
    }))
);

export const getCategories = createSelector(
    selectCategories,
    categories => categories.filter(categorie => categorie.Enabled)
        .map(({ CategoryName, ID, RecurringEnabled }) => ({
            label: CategoryName,
            value: `${ID}`,
            recurringEnabled: RecurringEnabled
        }))
);

const mapPrograms = programs => programs.map(({
    ID,
    Title,
    RegistrationDeadline,
    StartDate,
    ChargeFrequency,
    EndDate,
    RegisteredAttendeeCount,
    IsEditable,
    ShownOnKioskAndPortal,
    CancelledAt
}) => ({
    id: ID,
    ID,
    Title,
    ChargeFrequency,
    StartDate: DateUtils.format(StartDate, 'MM/DD/YYYY'),
    EndDate: DateUtils.format(EndDate, 'MM/DD/YYYY'),
    RegistrationDeadline: DateUtils.format(RegistrationDeadline, 'MM/DD/YYYY'),
    ShownOnKioskAndPortal: ShownOnKioskAndPortal ? 'Yes' : 'No',
    RegisteredAttendeeCount,
    IsEditable,
    Cancelled: !!CancelledAt
}));

const getProgramFromID = (programs, programID) => {
    const {
        ID,
        Title,
        Cost,
        RegistrationDeadline,
        StartDate,
        EndDate,
        Description,
        HouseNumber,
        Street,
        City,
        Zipcode,
        ChargeFrequency,
        State,
        TotalCapacity,
        ImageUrl,
        CancelledAt,
        ShownOnKioskAndPortal,
        RegisteredAttendeeCount,
        Registrations,
        IsEditable,
        Days,
        ProgramState,
        FathersNameRequired,
        MothersNameRequired,
        FathersNameApplicable,
        MothersNameApplicable,
        EmergencyContactNameRequired,
        EmergencyContactNumberRequired,
        EmergencyContactNameApplicable,
        EmergencyContactNumberApplicable
    } = programs.find(program => program.ID === programID);
    return {
        id: ID,
        ID,
        Title,
        Cost,
        CostFormatted: `${StringUtils.formatAmount(Cost, false)}`,
        StartDate,
        EndDate,
        ChargeFrequency,
        RegistrationDeadline,
        StartDateFormatted: DateUtils.format(StartDate, 'MM/DD/YYYY'),
        EndDateFormatted: DateUtils.format(EndDate, 'MM/DD/YYYY'),
        RegistrationDeadlineFormatted: DateUtils.format(RegistrationDeadline, 'MM/DD/YYYY'),
        CancelledAt: CancelledAt ? DateUtils.format(CancelledAt, 'MM/DD/YYYY') : '-',
        CancelledFormatted: CancelledAt ? 'Yes' : 'No',
        Cancelled: !!CancelledAt,
        ProgramState,
        Description,
        IsEditable: IsEditable ? 'Yes' : 'No',
        IsEditableBool: IsEditable,
        ShownOnKioskAndPortal: ShownOnKioskAndPortal ? 'Yes' : 'No',
        HouseNumber,
        Street,
        City,
        Zipcode,
        State,
        Days,
        TotalCapacity,
        ImageUrl,
        FathersNameRequired,
        MothersNameRequired,
        FathersNameApplicable,
        MothersNameApplicable,
        EmergencyContactNameRequired,
        EmergencyContactNumberRequired,
        EmergencyContactNameApplicable,
        EmergencyContactNumberApplicable,
        RegisteredAttendeeCount,
        FathersNameIsRequired: FathersNameRequired ? 'Yes' : 'No',
        MothersNameIsRequired: MothersNameRequired ? 'Yes' : 'No',
        FathersNameIsApplicable: FathersNameApplicable ? 'Yes' : 'No',
        MothersNameIsApplicable: MothersNameApplicable ? 'Yes' : 'No',
        EmergencyContactNameIsRequired: EmergencyContactNameRequired ? 'Yes' : 'No',
        EmergencyContactNumberIsRequired: EmergencyContactNumberRequired ? 'Yes' : 'No',
        EmergencyContactNameIsApplicable: EmergencyContactNameApplicable ? 'Yes' : 'No',
        EmergencyContactNumberIsApplicable: EmergencyContactNumberApplicable ? 'Yes' : 'No',
        Registrations: Registrations.map(({
            ProgramID,
            DiscountAmount,
            RegistrationState,
            RegistrationPlatform,
            Attendee,
            AttendeeID,
            Unenrolled,
            EnrolledAt,
            Charges
        }) => ({
            id: AttendeeID,
            AttendeeID,
            Name: `${Attendee.FirstName} ${Attendee.LastName}`,
            Email: Attendee.Email,
            Attendee,
            EnrolledAt: DateUtils.format(EnrolledAt, 'MM/DD/YYYY'),
            RegistrationPlatform,
            PaidInFull: ProgramUtils.hasPaidInFull(RegistrationState) ? 'Yes' : 'No',
            PaidInFullFormatted: ProgramUtils.hasPaidInFull(RegistrationState) ? 'Yes' : 'No',
            Unenrolled: Unenrolled ? 'Yes' : 'No',
            ProgramID,
            Charges: Charges.map(({
                ID, CreatedAt, AmountPaid, RefundedAt,
                AmountPaidMinusFees, IsRecurring, Refunded, Successful,
                StripeID, RefundStripeID, ProgramID
            }) => ({
                ID,
                AmountPaid,
                AmountPaidFormatted: StringUtils.currencify(AmountPaid, false),
                AmountMinusFees: StringUtils.currencify(AmountPaidMinusFees, false),
                Refunded: Refunded ? 'Yes' : 'No',
                IsRecurring: IsRecurring ? 'Yes' : 'No',
                Successful: Successful ? 'Yes' : 'No',
                StripeID,
                RefundedAt: RefundedAt ? DateUtils.format(RefundedAt, 'MM/DD/YYYY') : '-',
                ProgramID,
                RefundStripeID,
                CreatedAt: DateUtils.format(CreatedAt, 'MM/DD/YYYY')
            })),
            DiscountAmount: StringUtils.currencify(DiscountAmount, false)
        }))
    };
};

const getAttendeeFromProgramIDAndAttendeeID = (programs, { attendeeID, programID }) => {
    const {
        Registrations,
        Cost,
        CancelledAt,
        ProgramState
    } = programs.find(program => program.ID === programID);
    const {
        ProgramID,
        DiscountAmount,
        RegistrationPlatform,
        Attendee,
        AttendeeID,
        Unenrolled,
        UnenrolledAt,
        EnrolledAt,
        RegistrationState,
        Charges,
    } = Registrations.find(registration => registration.AttendeeID === attendeeID);

    return {
        // We send in a "Formatted" value since we need the original value too.
        id: AttendeeID,
        AttendeeID,
        Unenrolled,
        UnenrolledAt: UnenrolledAt ? DateUtils.format(UnenrolledAt, 'MM/DD/YYYY') : '-',
        EnrolledAt: EnrolledAt ? DateUtils.format(EnrolledAt, 'MM/DD/YYYY') : '-',
        UnenrolledFormatted: Unenrolled ? 'Yes' : 'No',
        PaidInFull: ProgramUtils.hasPaidInFull(RegistrationState),
        PaidInFullFormatted: ProgramUtils.hasPaidInFull(RegistrationState) ? 'Yes' : 'No',
        RegistrationPlatform,
        ProgramID,
        DiscountAmount: StringUtils.currencify(DiscountAmount, false),
        Attendee,
        Cost,
        Cancelled: !!CancelledAt,
        RegistrationState,
        ProgramState,
        Charges: Charges.map(({
            ID, CreatedAt, AmountPaid, RefundedAmount, RefundedAt,
            AmountPaidMinusFees, IsRecurring, Refunded, Successful,
            StripeID, RefundStripeID, ProgramID
        }) => ({
            id: ID,
            ID,
            AmountPaid,
            AmountPaidFormatted: StringUtils.formatAmount(AmountPaid, false),
            AmountPaidMinusFees: StringUtils.formatAmount(AmountPaidMinusFees, false),
            RefundedAmount: RefundedAmount ? StringUtils.formatAmount(RefundedAmount, false) : '-',
            Refunded,
            IsRecurring: IsRecurring ? 'Yes' : 'No',
            StripeIDFormatted: StripeID || '-',
            StripeID,
            Successful,
            ProgramID,
            RefundedAt: RefundedAt ? DateUtils.format(RefundedAt, 'MM/DD/YYYY') : '-',
            RefundStripeID: RefundStripeID || '-',
            RefundedFormatted: Refunded ? 'Yes' : 'No',
            CreatedAt: DateUtils.format(CreatedAt, 'MM/DD/YYYY')
        }))
    };
};

// Selectors for classes.

export const getClasses = createSelector(
    selectClasses,
    mapPrograms
);

export const getClass = createSelector(
    [selectClasses, selectStateFromLocation],
    getProgramFromID
);

export const getStudent = createSelector(
    [selectClasses, selectStateFromLocation],
    getAttendeeFromProgramIDAndAttendeeID
);

// Selectors for events.

export const getEvents = createSelector(
    [selectEvents],
    mapPrograms
);


export const getEvent = createSelector(
    [selectEvents, selectStateFromLocation],
    getProgramFromID
);

export const getParticipant = createSelector(
    [selectEvents, selectStateFromLocation],
    getAttendeeFromProgramIDAndAttendeeID
);

const selectSubscribedOnlinePrograms = (
    classes,
    events,
    hasOnlineClassesAddOn,
    hasOnlineEventsAddOn
) => {
    let programs = [];
    if (hasOnlineClassesAddOn) {
        programs = [...classes];
    }
    if (hasOnlineEventsAddOn) {
        programs = [...programs, ...events];
    }
    return programs.map(({ Title, ID }) => ({
        label: Title,
        value: `${ID}`
    }));
};

export const getPrograms = createSelector(
    [selectClasses, selectEvents, hasOnlineClassRegistrationAddOn, hasOnlineEventRegistrationAddOn],
    selectSubscribedOnlinePrograms
);
