import { store } from 'musalleen-react-theme';
import masjid from './masjid';
import admin from './admin';

const reducers = store.combineReducers({
    admin,
    masjid
});

export default reducers;
