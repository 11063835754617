/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconCheck from 'react-icons/lib/md/check';
import IconClose from 'react-icons/lib/md/close';
import IconInfo from 'react-icons/lib/md/info-outline';


import {
    Row,
    Modal,
    Card,
    CardBody,
    ModalHeader,
    ModalFooter,
    ModalBody,
    FormGroup,
    Label,
    Input,
    CardTitle,
} from 'reactstrap';
import {
    PageHeader,
    FormInput,
    Button,
} from '../../shared/components';
import {
    addNewDonationCategory,
    showDangerAlert,
    showServerErrorAlert,
    updateDonationCategory,
} from '../../store/actions';
import { FileUtils } from '../../utils';
import { hasRecurringDonationsInPlan } from '../../store/selectors';
import DraggableList from '../../utils/DraggableList';
import UpgradePlanAlert from '../ui/extras/UpgradePlanAlert';

function CategoryList({
    dispatch, categories, HasRecurringDonationsInPlan
}) {
    const [createModal, setCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [categoryName, setCategoryName] = useState('');
    const [description, setDescription] = useState('');
    const [categoryEnabled, setCategoryEnabled] = useState(true);
    const [recurringEnabled, setRecurringEnabled] = useState(false);
    const [addProcessingFees, setAddProcessingFees] = useState(false);
    const [eligibleForTaxDeductionBenefits, setEligibleForTaxDeductionBenefits] = useState(false);
    const [categoryImage, setCategoryImage] = useState(null);
    const [categoryImageName, setCategoryImageName] = useState('');

    function showCreateModal() {
        setCreateModal(true);
    }

    function hideCreateModal() {
        setCreateModal(false);
        resestState();
    }

    function viewCategory(id) {
        return function () {
            browserHistory.push({ pathname: `/categories/${id}`, state: id });
        };
    }

    function changeEnabledState() {
        setCategoryEnabled(!categoryEnabled);
    }

    function changeRecurringState() {
        setRecurringEnabled(!recurringEnabled);
    }

    function changeProcessingFeesState() {
        setAddProcessingFees(!addProcessingFees);
    }


    function changeEligibleForTaxDeductionBenefits() {
        setEligibleForTaxDeductionBenefits(!eligibleForTaxDeductionBenefits);
    }

    async function saveNewCategory() {
        setLoading(true);
        let imageUrl = null;
        if (categoryImage) {
            // User has chosen an image.
            const { location, error } = await FileUtils.uploadFile(categoryImage, 'categoryImages');
            if (error) {
                setLoading(false);
                if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                    dispatch(showDangerAlert(`The category image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                    return;
                }
                // Upload has failed.
                dispatch(showServerErrorAlert());
                return;
            }
            const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
            imageUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        }
        const enabledCategories = categories.filter(item => item.Enabled);

        const error = await dispatch(addNewDonationCategory({
            CategoryName: categoryName,
            Description: description,
            Enabled: categoryEnabled,
            RecurringEnabled: recurringEnabled,
            AddProcessingFees: addProcessingFees,
            EligibleForTaxDeductionBenefits: eligibleForTaxDeductionBenefits,
            ImageUrl: imageUrl,
            // TODO  Need to check again
            Position: categoryEnabled ? enabledCategories.length + 1 : null
        }));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideCreateModal();
    }


    function onCancel() {
        setLoading(false);
        hideCreateModal();
        resestState();
    }

    function resestState() {
        setCategoryName('');
        setDescription('');
        setCategoryEnabled(true);
        setRecurringEnabled(false);
        setAddProcessingFees(false);
    }
    function handleCategoryImageChange() {
        return function (files) {
            setCategoryImageName(files[0].name);
            setCategoryImage(files[0]);
        };
    }

    const onDragEnd = async (item, newPosition, lastPosition) => {
        const {
            ID, CategoryName, Description, Enabled, RecurringEnabled,
            AddProcessingFees, EligibleForTaxDeductionBenefits, MasjidID
        } = item;

        const error = await dispatch(updateDonationCategory({
            ID,
            CategoryName,
            Description,
            Enabled,
            RecurringEnabled,
            AddProcessingFees,
            EligibleForTaxDeductionBenefits,
            MasjidID,
            Position: newPosition
        }));
        setLoading(false);
        // eslint-disable-next-line no-empty
        if (error) {

        }
    };

    return (
        <div className="view">
            <PageHeader title="Categories" description="Here are all your donation categories." />
            <div className="view-content">
                <Card>
                    <CardBody>
                        <Row className="mb-4">
                            <div className="col-md-10" />
                            <div className="col-md-2">
                                <div className="float-right">
                                    <Button
                                        className=""
                                        onClick={showCreateModal}
                                    >
                                            Add New Category
                                    </Button>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div style={{ marginLeft: '10px', marginBottom: '5px' }}>
                                <IconInfo
                                    size={20}
                                    style={{ marginBottom: '4px' }}
                                />
                                <p className="mb-0 subtitle" style={{ display: 'inline', paddingLeft: '5px', }}>
                                    {'Drag and Drop Enabled Categories To Change The Order'}
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-md-6">
                                <Card>
                                    <CardTitle style={{ padding: '10px' }}>Enabled Categories</CardTitle>
                                    <CardBody>
                                        <DraggableList
                                            keyExtractor={(item) => `${item.ID}`}
                                            data={categories.filter(val => val.Enabled).sort((a, b) => parseFloat(a.Position) - parseFloat(b.Position))}
                                            onDragEnd={onDragEnd}
                                            renderItem={(item, _, isBeingDragged) => (
                                                <Row>
                                                    <div key={item.ID} className="mb-4 col-md-12">
                                                        <Card
                                                            onClick={viewCategory(item.ID)}
                                                            className="table-row-clickable"
                                                        >
                                                            <CardBody>

                                                                <div className="col-md-12 h5">{item.CategoryName}</div>

                                                                {/* <Row>
                                                                <div className="col-md-6">
                                                            Enabled
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {item.Enabled ? <span className="text-primary"><IconCheck size={15} /></span>
                                                                        : <span className="text-danger"><IconClose size={15} /></span>}
                                                                </div>

                                                            </Row>
                                                            <Row className={!HasRecurringDonationsInPlan ? 'disabled' : ''}>
                                                                <div className="col-md-6">
                                                            Recurring
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {item.RecurringEnabled
                                                                    && HasRecurringDonationsInPlan
                                                                        ? <span className="text-primary"><IconCheck size={15} /></span>
                                                                        : <span className="text-danger"><IconClose size={15} /></span>
                                                                    }
                                                                </div>
                                                            </Row> */}
                                                                <div style={{ display: 'flex' }}>


                                                                    <div className="col-md-6">
                                                            Recurring
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {item.RecurringEnabled
                                                                    && HasRecurringDonationsInPlan
                                                                            ? <span className="text-primary"><IconCheck size={15} /></span>
                                                                            : <span className="text-danger"><IconClose size={15} /></span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </Row>
                                            )}
                                        />
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="col-md-6">
                                <Card>
                                    <CardTitle style={{ padding: '10px' }}>Disabled Categories</CardTitle>
                                    <CardBody>
                                        <Row>
                                            {
                                                categories.filter(val => !val.Enabled).map(item => (
                                                    <div key={item.ID} className="mb-4 col-md-12">
                                                        <Card
                                                            onClick={viewCategory(item.ID)}
                                                            className="table-row-clickable"
                                                        >
                                                            <CardBody>
                                                                <Row>
                                                                    <div className="col-md-12 h5">{item.CategoryName}</div>
                                                                </Row>

                                                                {/* <Row>
                                                                    <div className="col-md-6">
                                                            Enabled
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {item.Enabled ? <span className="text-primary"><IconCheck size={15} /></span>
                                                                            : <span className="text-danger"><IconClose size={15} /></span>}
                                                                    </div>

                                                                </Row> */}
                                                                <Row className={!HasRecurringDonationsInPlan ? 'disabled' : ''}>
                                                                    <div className="col-md-6">
                                                            Recurring
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        {item.RecurringEnabled
                                                                    && HasRecurringDonationsInPlan
                                                                            ? <span className="text-primary"><IconCheck size={15} /></span>
                                                                            : <span className="text-danger"><IconClose size={15} /></span>
                                                                        }
                                                                    </div>
                                                                </Row>
                                                            </CardBody>

                                                        </Card>
                                                    </div>
                                                ))
                                            }
                                            {categories.filter(val => !val.Enabled).length === 0 && (
                                                <p>
They are currently no items in this list.
                                                </p>
                                            ) }
                                        </Row>
                                    </CardBody>
                                </Card>

                            </div>
                        </Row>

                    </CardBody>
                </Card>
                <Modal isOpen={createModal} toggle={showCreateModal} className="default">
                    <ModalHeader toggle={hideCreateModal}>Add New Category</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-md-12">
                                <FormInput label="Category Name" row type="text" value={categoryName} onChangeValue={setCategoryName} />
                                <FormInput label="Description" row type="textarea" value={description} onChangeValue={setDescription} maxLength={2000} />
                                <FormInput
                                    value=""
                                    onChangeValue={handleCategoryImageChange()}
                                    label="Category Image"
                                    type="file"
                                    size="sm"
                                    placeholder={`${categoryImageName}`}
                                />
                                <Row>

                                    <div className="col-md-12">
                                        <FormGroup>
                                            <legend className="col-form-legend font-weight-bold">Enable</legend>
                                            <div>
                                                <Label check>
                                                    <Input type="checkbox" onChange={changeEnabledState} checked={categoryEnabled} />
                                                    &nbsp;
                                                    Enable this category.
                                                </Label>
                                            </div>
                                        </FormGroup>

                                    </div>

                                </Row>
                                <Row>
                                    <div className={`col-md-12 ${!HasRecurringDonationsInPlan ? 'disabled-checkbox-group' : ''}`}>
                                        <FormGroup>
                                            <legend className="col-form-legend font-weight-bold">Enable recurring donations</legend>
                                            <div>
                                                <Label check>
                                                    <Input type="checkbox" onChange={changeRecurringState} checked={recurringEnabled} />
                                                    &nbsp;
                                                    Enable recurring donations for this category.
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <legend className="col-form-legend font-weight-bold">Show Processing Fee</legend>
                                            <div>
                                                <Label check>
                                                    <Input type="checkbox" onChange={changeProcessingFeesState} checked={addProcessingFees} />
                                                    &nbsp;
                                                    Show Processing Fee to Musalleen while donating.
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <legend className="col-form-legend font-weight-bold">Eligible for Tax Deduction Benefits</legend>
                                            <div>
                                                <Label check>
                                                    <Input type="checkbox" onChange={changeEligibleForTaxDeductionBenefits} checked={eligibleForTaxDeductionBenefits} />
                                                    &nbsp;
                                                    Whether donations made to this category
                                                    should be eligible for tax deduction
                                                    benefits.
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loading} color="primary" onClick={saveNewCategory}>Add</Button>
                        <Button color="secondary" onClick={onCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        </div>
    );
}

CategoryList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        CategoryName: PropTypes.string.isRequired,
        ID: PropTypes.number.isRequired
    })).isRequired,
    HasRecurringDonationsInPlan: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    categories: state.masjid.data.Categories,
    HasRecurringDonationsInPlan: hasRecurringDonationsInPlan(state)
});

export default connect(mapStateToProps)(CategoryList);
