import React, {
    useState, Fragment, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Row,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from 'reactstrap';
import { FileUtils, StringUtils, PaymentUtils } from '../../utils';
import {
    InfoCard,
    PageHeader,
    FormInput,
    Button,
    DescriptionList,
    PaymentWidget,
    Image
} from '../../shared/components';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert,
    updateNewMasjid,
    showDangerAlert
} from '../../store/actions';
import { Constants, AdminAPI } from '../../api';
import MasjidPlan from './MajidPlan';
import './style.scss';
import { emailRegex, findLatLng, requiredLabel } from '../pages/register';
import {
    fetchCitiesByState, fetchCountries, fetchStatesByCountries,
} from '../../store/actions/address';


const editState = 'State';
const editCountry = 'Country';
const editCity = 'City';
function Account({
    dispatch, masjid, admin, location: { query: { editMasjid }, state },
}) {
    const {
        Name, AptNumber, Email, City, Country, Street,
        HouseNumber, Zipcode, Latitude, Longitude, PhoneNumber, ID,
        State, WebsiteUrl, MasjidIconUrl, Last4, HasAppBase
    } = masjid;
    const { email } = admin;


    useEffect(() => {
        getCountries();
        if (Country !== null) {
            getStatesByCountries(Country);
            getCitiesByState(State);
        }
    }, [countries, statesByCountries, cities]);

    const [countries, setCountries] = useState([]);
    const [statesByCountries, setStatesByCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [passwordChangeModal, setPasswordChangeModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [invalidCurrentPasswordMessage, setInvalidCurrentPasswordMessage] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [invalidNewPasswordMessage, setInvalidNewPasswordMessage] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [invalidConfirmPasswordMessage, setInvalidConfirmPasswordMessage] = useState('');

    const [updateCardModalVisible, setUpdateCardModalVisible] = useState(false);
    const [updatingCard, setUpdatingCard] = useState(false);

    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [updatingMasjid, setUpdatingMasjid] = useState(false);

    const [editMasjidModal, setEditMasjidModal] = useState(!!editMasjid);
    const [masjidImageUploading, setMasjidImageUploading] = useState(false);

    const [currentMasjid, setCurrentMasjid] = useState({
        Name: masjid.Name,
        AptNumber: AptNumber || '',
        Email,
        City: City || '',
        Country: Country || '',
        Street: Street || '',
        HouseNumber: HouseNumber || '',
        Zipcode: Zipcode || '',
        PhoneNumber: PhoneNumber || '',
        ID,
        State: State || '',
        WebsiteUrl: WebsiteUrl || '',
        Latitude,
        Longitude

    });

    const [invalidAddressMessages, setInvalidAddressMessages] = useState({
        masjidName: '',
        country: '',
        houseNumber: '',
        street: '',
        zipcode: '',
        state: '',
        city: '',
        email: '',
    });


    const getCountries = async () => {
        const data = await dispatch(fetchCountries());
        // data.find(country => country === Country);
        setCountries(data);
    };
    const getStatesByCountries = async (countryName) => {
        const data = await dispatch(fetchStatesByCountries(countryName));
        setStatesByCountries(data);
    };

    const getCitiesByState = async (stateName) => {
        const data = await dispatch(fetchCitiesByState(stateName));
        setCities(data);
    };

    function handleMasjidPropertyChange(column) {
        return function (value) {
            if (column === editCountry) {
                getStatesByCountries(value);
            }
            if (column === editState) {
                getCitiesByState(value);
            }
            setCurrentMasjid({ ...currentMasjid, [column]: value });
        };
    }

    function handleMasjidImageChange(files) {
        uploadMasjidImage(files[0]);
    }

    function showChangePasswordModal() {
        setPasswordChangeModal(true);
    }

    function hideChangePasswordModal() {
        setPasswordChangeModal(false);
        resetPasswordChangeModalState();
    }

    function resetPasswordChangeModalState() {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setInvalidCurrentPasswordMessage('');
        setInvalidNewPasswordMessage('');
        setInvalidConfirmPasswordMessage('');
    }

    async function saveNewPassword() {
        setInvalidCurrentPasswordMessage('');
        setInvalidNewPasswordMessage('');
        setInvalidConfirmPasswordMessage('');
        if (!currentPassword) {
            setInvalidCurrentPasswordMessage('Current password can\'t be empty');
            return;
        }
        if (!newPassword) {
            setInvalidNewPasswordMessage('New Password can\'t be empty.');
            return;
        }
        if (!confirmPassword) {
            setInvalidConfirmPasswordMessage('Confirm Password can\'t be empty.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setInvalidConfirmPasswordMessage('Confirm Password and New Password don\'t match.');
            return;
        }

        setLoadingChangePassword(true);
        const { error } = await AdminAPI.changePassword(email, currentPassword, newPassword);
        setLoadingChangePassword(false);
        if (error) {
            if (error === Constants.SERVER_ERROR) {
                dispatch(showServerErrorAlert());
            }
            if (error === Constants.CLIENT_ERROR) {
                setInvalidCurrentPasswordMessage('Current password is not correct.');
            }
            dispatch(showNoInternetAlert());
            return;
        }

        hideChangePasswordModal();
        dispatch(showSuccessAlert('Password successfully updated!'));
    }

    function showUpdateCardModal() {
        setUpdateCardModalVisible(true);
    }

    function hideUpdateCardModal() {
        setUpdateCardModalVisible(false);
    }

    async function onUpdateCard(token) {
        setUpdatingCard(true);
        await dispatch(updateNewMasjid({
            StripeToken: token,
            ID
        }));
        setUpdatingCard(false);
        // The errors and success message have already been handled in the action creator.
    }

    function onCancelEditMasjid() {
        hideEditMasjidModal();
    }

    function showEditMasjidModal() {
        setEditMasjidModal(true);
    }

    function hideEditMasjidModal() {
        setEditMasjidModal(false);
    }

    async function uploadMasjidImage(image) {
        setMasjidImageUploading(true);
        const { location, error } = await FileUtils.uploadFile(
            // Changing the name of the image so its unique for a masjid and
            // the old one gets overwritten.
            new File(
                [image],
                `masjid_image_${ID}.${StringUtils.geFileExtension(image.name)}`,
                { type: image.type }
            )
        );
        if (error) {
            setMasjidImageUploading(false);
            if (error === FileUtils.Constants.FILE_SIZE_TOO_BIG) {
                dispatch(showDangerAlert(`The masjid image you have chosen is too big. Please try again with a smaller one whose size is less than ${FileUtils.Constants.MAX_FILE_UPLOAD_SIZE} MB.`));
                return;
            }
            // Upload has failed.
            dispatch(showNoInternetAlert());
            return;
        }
        // Appending a useless timestamp so the image gets re downloaded
        // in the img tag and anywhere else.
        // This is because we're using the same filename uniquely for a masjid.
        const URLComponents = location.split('.com'); // Temporary fix till the package issue gets resolved
        const finalUrl = `${URLComponents[0]}.com/${URLComponents[1]}`;
        const masjidIconUrl = `${finalUrl}?c=${new Date().getTime()}`;
        const updateError = await dispatch(updateNewMasjid({
            ID,
            MasjidIconUrl: masjidIconUrl
        }));
        setMasjidImageUploading(false);
        if (updateError) {
            // The errors and success message have already been handled in the action creator.
        }
    }

    async function saveCurrentMasjid() {
        setInvalidAddressMessages({
            masjidName: '',
            country: '',
            houseNumber: '',
            street: '',
            zipcode: '',
            state: '',
            city: '',
            email: '',
        });
        if (!currentMasjid.Name || !currentMasjid.Name.trim()) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, masjidName: 'Name cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.Country || !currentMasjid.Country) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, country: 'Country cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.HouseNumber || !currentMasjid.HouseNumber) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, houseNumber: 'House Number cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.Street || !currentMasjid.Street) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, street: 'Street cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.Zipcode || !currentMasjid.Zipcode) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, zipcode: 'Zip Code cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.State || !currentMasjid.State) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, state: 'State cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.City || !currentMasjid.City) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, city: 'City cannot be empty.'
            });
            return;
        }
        if (!currentMasjid.Email || !currentMasjid.Email.trim()) {
            setInvalidAddressMessages({ ...invalidAddressMessages, email: 'Masjid email cannot be empty.' });
            return;
        }
        if (!currentMasjid.Email || !emailRegex.test(currentMasjid.Email.trim())) {
            setInvalidAddressMessages({ ...invalidAddressMessages, email: 'Admin email is not valid.' });
            return;
        }

        const newAddress = currentMasjid.Zipcode.concat(
            ',',
            currentMasjid.HouseNumber,
            ',',
            currentMasjid.Street,
            ',',
            currentMasjid.City,
            ',',
            currentMasjid.State,
            ',',
            currentMasjid.Country,
        );

        const results = await findLatLng(newAddress);

        setUpdatingMasjid(true);
        const error = await dispatch(updateNewMasjid({
            ...currentMasjid,
            Latitude: results[0] || null,
            Longitude: results[1] || null
        }));
        setUpdatingMasjid(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
            return;
        }
        hideEditMasjidModal();
    }

    function willMasjidNotShowInApp() {
        return !(Name && HouseNumber && Street && City && Zipcode && State);
    }

    function renderMasjidWontShowInAppWarning() {
        // Show this warning only if the Masjid has app base.
        if (HasAppBase && willMasjidNotShowInApp()) {
            return (
                <div className="col-md-12 mb-2 red wont-show-in-app-message">
                    Masjid will not show in the app unless
                    {' '}
                    <b>Name</b>
                    {', '}
                    <b>House Number</b>
                    {', '}
                    <b>Street</b>
                    {', '}
                    <b>City</b>
                    {', '}
                    <b>Zip Code</b>
                    {' '}
                    and
                    {' '}
                    <b>State</b>
                    {' '}
                    are all filled.
                </div>
            );
        }
        return null;
    }

    return (
        <div className="view">
            <PageHeader title="Account" description="This is my account." />
            <div className="view-content">
                <Row className="mb-4">
                    <div className="col-sm-3 col-md-12">
                        <InfoCard
                            title="Masjid Information"
                            subTitle="More information about the masjid."
                            showFooter={false}
                            showButtonInHeader
                            buttonInHeaderLabel="Edit"
                            onClickButtonInHeader={showEditMasjidModal}
                        >
                            <Row>
                                {renderMasjidWontShowInAppWarning()}
                                <DescriptionList
                                    list={[
                                        {
                                            label: 'Masid Image',
                                            value: (
                                                <Image
                                                    title="Masjid Image"
                                                    url={MasjidIconUrl}
                                                    uploading={masjidImageUploading}
                                                    onImageSelected={handleMasjidImageChange}
                                                />
                                            )
                                        },
                                        { label: 'Masjid Name', value: Name },
                                        { label: 'Apartment Number', value: AptNumber },
                                        { label: 'House Number', value: HouseNumber },
                                        { label: 'Street', value: Street },
                                        { label: 'City', value: City },
                                        { label: 'State', value: State },
                                        { label: 'Zip Code', value: Zipcode },
                                        { label: 'Country', value: Country },
                                        { label: 'Phone Number', value: PhoneNumber },
                                        { label: 'Masjid Email', value: Email },
                                        { label: 'Website URL', value: WebsiteUrl },
                                        { label: 'Change Password', value: <Button color="secondary" onClick={showChangePasswordModal}>Change Password</Button> },
                                        {
                                            label: 'Card Details',
                                            value: (
                                                <Fragment>
                                                    {Last4 && <span className="mr-2">{StringUtils.formatLast4Digits(Last4)}</span>}
                                                    <Button color="secondary" onClick={showUpdateCardModal} loading={updatingCard}>{Last4 ? ' Update Card' : 'Add card'}</Button>
                                                </Fragment>
                                            )
                                        }
                                    ]}
                                    containerClassName="col-md-12"
                                />
                            </Row>
                        </InfoCard>
                        <Modal isOpen={editMasjidModal} toggle={showEditMasjidModal} className="default account-modal">
                            <ModalHeader toggle={hideEditMasjidModal}>
                                Edit Masjid Information
                            </ModalHeader>
                            <ModalBody>
                                <p style={{ color: 'red' }}>
                                    <sup> *  Required</sup>
                                </p>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.Name}
                                            label={requiredLabel('Masjid name')}
                                            onChangeValue={handleMasjidPropertyChange('Name')}
                                            placeholder="Masjid Name"
                                            type="text"
                                            maxLength={50}
                                            state={invalidAddressMessages.masjidName ? 'danger' : 'default'}
                                            message={invalidAddressMessages.masjidName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.Country}
                                            onChangeValue={handleMasjidPropertyChange(editCountry)}
                                            type="select"
                                            label={requiredLabel('Country/Region')}
                                            placeholder="Country"
                                            name="adminCountry"
                                            options={countries && countries.map(country => ({
                                                key: country,
                                                value: country,
                                                label: country
                                            }))}
                                            state={invalidAddressMessages.country ? 'danger' : 'default'}
                                            message={invalidAddressMessages.country}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.AptNumber}
                                            onChangeValue={handleMasjidPropertyChange('AptNumber')}
                                            label="Apartment Number"
                                            placeholder="Apartment number"
                                            maxLength={10}
                                        />

                                    </div>
                                    <div className="col-md-6">

                                        <FormInput
                                            value={currentMasjid.HouseNumber}
                                            onChangeValue={handleMasjidPropertyChange('HouseNumber')}
                                            label={requiredLabel('House Number')}
                                            placeholder="House Number"
                                            maxLength={10}
                                            state={invalidAddressMessages.houseNumber ? 'danger' : 'default'}
                                            message={invalidAddressMessages.houseNumber}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.Street}
                                            onChangeValue={handleMasjidPropertyChange('Street')}
                                            label={requiredLabel('Street')}
                                            type="text"
                                            placeholder="Street"
                                            maxLength={50}
                                            state={invalidAddressMessages.street ? 'danger' : 'default'}
                                            message={invalidAddressMessages.street}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.Zipcode}
                                            onChangeValue={handleMasjidPropertyChange('Zipcode')}
                                            label={requiredLabel('Zipcode')}
                                            type="text"
                                            placeholder="Zip Code"
                                            maxLength={10}
                                            state={invalidAddressMessages.zipcode ? 'danger' : 'default'}
                                            message={invalidAddressMessages.zipcode}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.State}
                                            onChangeValue={handleMasjidPropertyChange(editState)}
                                            label={requiredLabel('State')}
                                            type="select"
                                            placeholder="State"
                                            maxLength={25}
                                            options={statesByCountries && statesByCountries
                                                .map(st => ({
                                                    key: st,
                                                    value: st,
                                                    label: st
                                                }))}
                                            state={invalidAddressMessages.state ? 'danger' : 'default'}
                                            message={invalidAddressMessages.state}
                                            disabled={statesByCountries && statesByCountries.length === 0}
                                            extraMessage={statesByCountries && statesByCountries.length === 0 && <p style={{ color: 'red' }}>Please Select Country First</p>}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.City}
                                            onChangeValue={handleMasjidPropertyChange(editCity)}
                                            label={requiredLabel('City')}
                                            type="select"
                                            placeholder="City"
                                            maxLength={25}
                                            options={cities && cities.map(city => ({
                                                key: city,
                                                value: city,
                                                label: city
                                            }))}
                                            state={invalidAddressMessages.city ? 'danger' : 'default'}
                                            message={invalidAddressMessages.city}
                                            disabled={statesByCountries && statesByCountries.length === 0}
                                            extraMessage={statesByCountries && statesByCountries.length === 0 && <p style={{ color: 'red' }}>Please Select Country First</p>}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.PhoneNumber}
                                            onChangeValue={handleMasjidPropertyChange('PhoneNumber')}
                                            label="Phone Number"
                                            type="number"
                                            maxLength={45}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.Email}
                                            onChangeValue={handleMasjidPropertyChange('Email')}
                                            label={requiredLabel('Masjid Email')}
                                            type="email"
                                            placeholder="email"
                                            maxLength={75}
                                            state={invalidAddressMessages.email ? 'danger' : 'default'}
                                            message={invalidAddressMessages.email}
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <FormInput
                                            value={currentMasjid.WebsiteUrl}
                                            onChangeValue={handleMasjidPropertyChange('WebsiteUrl')}
                                            label="Website Url"
                                            type="text"
                                            placeholder="https://www.example.com"
                                        />
                                    </div>
                                </Row>
                            </ModalBody>

                            <ModalFooter>
                                <Button loading={updatingMasjid} color="primary" onClick={saveCurrentMasjid}>Update</Button>
                                {' '}
                                <Button color="secondary" onClick={onCancelEditMasjid} disabled={updatingMasjid}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </Row>

                <Row className="mb-4">
                    <div className="col-sm-3 col-md-12">
                        <InfoCard
                            title="Plan Information"
                            subTitle="Your currently selected plans for Musalleen platform."
                            showFooter={false}
                        >
                            <MasjidPlan locationState={state} />
                        </InfoCard>
                    </div>
                </Row>
                <Modal isOpen={passwordChangeModal} toggle={showChangePasswordModal} className="default">
                    <ModalHeader toggle={hideChangePasswordModal}>Change Password</ModalHeader>
                    <ModalBody>
                        <Row className="mb-4">
                            <div className="col-md-12">
                                <FormInput
                                    value={currentPassword}
                                    onChangeValue={setCurrentPassword}
                                    state={invalidCurrentPasswordMessage ? 'danger' : 'default'}
                                    message={invalidCurrentPasswordMessage}
                                    label="Current Password"
                                    type="password"
                                    placeholder="Password"
                                />
                                <FormInput
                                    value={newPassword}
                                    onChangeValue={setNewPassword}
                                    state={invalidNewPasswordMessage ? 'danger' : 'default'}
                                    message={invalidNewPasswordMessage}
                                    label="New Password"
                                    type="password"
                                    placeholder="New Password"
                                />
                                <FormInput
                                    value={confirmPassword}
                                    message={invalidConfirmPasswordMessage}
                                    state={invalidConfirmPasswordMessage ? 'danger' : 'default'}
                                    onChangeValue={setConfirmPassword}
                                    label="Confirm New Password"
                                    type="password"
                                    placeholder="Confirm New Password"
                                />
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button loading={loadingChangePassword} color="primary" onClick={saveNewPassword}>Save</Button>
                        <Button color="secondary" onClick={hideChangePasswordModal} disabled={loadingChangePassword}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <PaymentWidget
                    title={`Fill in information to ${Last4 ? 'update' : 'add'} your card.`}
                    show={updateCardModalVisible}
                    onClose={hideUpdateCardModal}
                    onPayment={onUpdateCard}
                    apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
                />
            </div>
        </div>
    );
}
Account.propTypes = {
    dispatch: PropTypes.func.isRequired,
    masjid: PropTypes.shape({}).isRequired,
    admin: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({
        query: PropTypes.shape({
            editMasjid: PropTypes.string,
        }).isRequired,
    }).isRequired
};
const mapStateToProps = ({ masjid: { data }, admin }) => ({
    masjid: data,
    admin
});


export default connect(mapStateToProps)(Account);
