import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Row, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup,
    Input, Label
} from 'reactstrap';
import { browserHistory } from 'react-router';
import { getClasses } from '../../store/selectors';
import {
    PageHeader,
    Table,
    Button
} from '../../shared/components';
import {
    cancelClassAsync,
    showDangerAlert
} from '../../store/actions';
import { DateUtils } from '../../utils';

function IslamicClassList({ dispatch, classes }) {
    const [loading, setLoading] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [refundClass, setRefundClass] = useState({ RefundAll: false });

    function viewClass(islamicClass) {
        browserHistory.push({ pathname: `/classes/${islamicClass.ID}`, state: islamicClass.ID });
    }

    function addNewClass() {
        browserHistory.push('/classes/add');
    }

    function showCancelClassModal(islamicClass) {
        if (islamicClass.Cancelled) {
            dispatch(showDangerAlert('Cannot cancel an already cancelled class.'));
            return;
        }
        setRefundClass({ ...refundClass, ID: islamicClass.ID });
        setCancelModal(true);
    }

    function hideCancelClassModal() {
        setCancelModal(false);
    }

    async function cancelClass() {
        setLoading(true);
        const error = await dispatch(cancelClassAsync(refundClass));
        setLoading(false);
        if (error) {
            // The errors and success message have already been handled in the action creator.
        }
        hideCancelClassModal();
    }

    function changeRefundAll() {
        setRefundClass({ ...refundClass, RefundAll: !refundClass.RefundAll });
    }

    return (
        <div>
            <div className="view">
                <PageHeader title="Classes" description="Here are all the Classes" />
                <div className="view-content">
                    <Row>
                        <Card className="col-md-12">
                            <CardBody>
                                <Row className="mb-4">
                                    <div className="col-md-10" />
                                    <div className="col-md-2">
                                        <div className="float-right">
                                            <Button
                                                className=""
                                                onClick={addNewClass}
                                            >
                                                Add New Class
                                            </Button>
                                        </div>
                                    </div>
                                </Row>
                                <Table
                                    title="Classes"
                                    filters={[
                                        {
                                            label: 'Title',
                                            key: 'Title'
                                        },
                                        {
                                            label: 'Classes',
                                            key: 'IsEditable',
                                            filterInputType: 'select',
                                            filterSelectOptions: ['All'].concat(['Editable']),
                                            filterFunc(editable, chosenOption) {
                                                return chosenOption === 'All' || editable;
                                            }
                                        },
                                        {
                                            label: 'Classes From',
                                            key: 'StartDate',
                                            filterInputType: 'date',
                                            filterFunc(date, chosenDate) {
                                                return DateUtils.isBefore(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                            }
                                        },
                                        {
                                            label: 'Classes To',
                                            key: 'EndDate',
                                            filterInputType: 'date',
                                            filterFunc(date, chosenDate) {
                                                return DateUtils.isAfter(chosenDate, date, 'YYYY-MM-DD', 'MM/DD/YYYY');
                                            }
                                        }
                                    ]}
                                    columns={[
                                        { label: 'Title', key: 'Title' },
                                        {
                                            label: 'Start Date',
                                            key: 'StartDate',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        {
                                            label: 'End Date',
                                            key: 'EndDate',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        {
                                            label: 'Registration Deadline',
                                            key: 'RegistrationDeadline',
                                            sortFunction: (d1, d2) => DateUtils.compare(d1, d2, 'MM/DD/YYYY')
                                        },
                                        { label: 'Charge Frequency', key: 'ChargeFrequency' },
                                        { label: 'Shown on Kiosk and Portal', key: 'ShownOnKioskAndPortal' },
                                        { label: 'No of Registrations', key: 'RegisteredAttendeeCount' }
                                    ]}
                                    data={classes}
                                    enableFilters
                                    enableExports={false}
                                    onRowClick={viewClass}
                                    showButtonOne
                                    buttonOneLabel="Cancel"
                                    onButtonOneClick={showCancelClassModal}
                                    defaultSortColumnKey="RegistrationDeadline"
                                    itemsPerPage={60}
                                />
                            </CardBody>
                        </Card>
                    </Row>
                    <Modal isOpen={cancelModal} toggle={showCancelClassModal} className="default">
                        <ModalHeader toggle={hideCancelClassModal}>Cancel Class</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <legend className="col-form-legend font-weight-bold">
                                    Are you sure you want to cancel the class
                                </legend>
                                <div>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            onChange={changeRefundAll}
                                            checked={refundClass.RefundAll}
                                        />
                                        &nbsp;
                                        Refund Fee of all the Registrants/Students
                                    </Label>
                                </div>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button loading={loading} color="danger" onClick={cancelClass}>Cancel Class</Button>
                            {' '}
                            <Button color="secondary" onClick={hideCancelClassModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

IslamicClassList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => ({
    classes: getClasses(state)
});

export default connect(mapStateToProps)(IslamicClassList);
