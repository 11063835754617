import client, { returnResponse } from './client';

const {
    get, put, post
} = client;

const subscribe = async newMasjid => returnResponse(
    await post('/masjids', newMasjid)
);

const getMasjid = async email => returnResponse(
    await get(`/admins/${email}`)
);

const putMasjid = async masjid => returnResponse(
    await put(`/masjids/${masjid.ID}`, masjid)
);

const refundDonation = async donationIdForRefund => returnResponse(
    await put(`/donations/${donationIdForRefund}/refund`, { donationIdForRefund })
);

const cancelRecurringDonation = async donationIdForCancel => returnResponse(
    await put(`/recurringDonations/${donationIdForCancel}/cancel`)
);

const addDonationCategory = async (masjidId, category) => returnResponse(
    await post(`/categories/${masjidId}`, category)
);

const editDonationCategory = async category => returnResponse(
    await put(`/categories/${category.ID}`, category)
);
const deleteDonationCategory = async categoryID => returnResponse(
    await client.delete(`/categories/${categoryID}/remove`)
);

const changeDonationCategoryPosition = async category => returnResponse(
    await client.put(`/categories/${category.ID}/change-position`, category)
);

const getInventory = async () => returnResponse(
    await get('/inventory')
);

const purchaseKiosks = async ({
    stripeToken,
    useExistingCard,
    numberOfFourteenInch,
    numberOfEighteenInch,
    numberOfTwentyOneInch,
    priceOfFourteenInchKiosk,
    priceOfEighteenInchKiosk,
    priceOfTwentyOneInchKiosk,
    shippingPrice,
    masjidID
}) => returnResponse(
    await put(`/masjids/${masjidID}/kiosks/${numberOfFourteenInch}/${numberOfEighteenInch}/${numberOfTwentyOneInch}`, {
        StripeToken: stripeToken,
        UseExistingCard: useExistingCard,
        PriceOfFourteenInchKiosk: priceOfFourteenInchKiosk,
        PriceOfEighteenInchKiosk: priceOfEighteenInchKiosk,
        PriceOfTwentyOneInchKiosk: priceOfTwentyOneInchKiosk,
        ShippingPrice: shippingPrice
    })
);

const rentKiosks = async ({
    stripeToken,
    useExistingCard,
    numberOfFourteenInch,
    numberOfEighteenInch,
    numberOfTwentyOneInch,
    rentalMonths,
    rentalPriceForFourteenInchKiosk,
    rentalPriceForEighteenInchKiosk,
    rentalPriceForTwentyOneInchKiosk,
    shippingPrice,
    masjidID
}) => returnResponse(
    await put(`/masjids/${masjidID}/kiosks/${numberOfFourteenInch}/${numberOfEighteenInch}/${numberOfTwentyOneInch}/rent`, {
        StripeToken: stripeToken,
        UseExistingCard: useExistingCard,
        RentalMonths: rentalMonths,
        RentalPriceForFourteenInchKiosk: rentalPriceForFourteenInchKiosk,
        RentalPriceForEighteenInchKiosk: rentalPriceForEighteenInchKiosk,
        RentalPriceForTwentyOneInchKiosk: rentalPriceForTwentyOneInchKiosk,
        ShippingPrice: shippingPrice
    })
);

const addAdmin = async (masjidId, admin) => returnResponse(
    await post('/admins', { ...admin, MasjidID: masjidId })
);

const editAdmin = async admin => returnResponse(
    await put(`/admins/${admin.Email}`, admin)
);

const deleteAdmin = async email => returnResponse(
    await put(`/admins/${email}/remove`)
);

const addGroup = async group => returnResponse(
    await post(`/groups/masjids/${group.MasjidID}`, group)
);

const updateGroup = async group => returnResponse(
    await put(`/groups/${group.ID}`, group)
);

const deleteGroup = async ID => returnResponse(
    await put(`/groups/${ID}/remove`)
);

const updatePlan = async (masjidPlan, ID) => returnResponse(
    await put(`/masjids/${ID}/changeplan`, masjidPlan)
);
const unsubscribe = async ID => returnResponse(
    await put(`/masjids/${ID}/unsubscribe`)
);
const getPlanPrices = async () => returnResponse(
    await get('/addons/prices')
);

const addProgram = async (program, masjidID) => returnResponse(
    await post(`/programs/${masjidID}`, program)
);
const updateProgram = async program => returnResponse(
    await put(`/programs/${program.ID}`, program)
);
const cancelProgram = async program => returnResponse(
    await client.delete(`/programs/${program.ID}/cancel`, {}, { data: program })
);
const refundAttendee = async registration => returnResponse(
    await put(`/programs/${registration.ProgramID}/attendees/${registration.AttendeeID}/charge/${registration.ChargeID}/refund`, registration)
);
const unenrollStudent = async registration => returnResponse(
    await put(`/programs/${registration.ProgramID}/attendees/${registration.AttendeeID}/unenroll`, registration)
);
const updateAttendeePayment = async registration => returnResponse(
    await put(`/programs/${registration.ProgramID}/attendees/${registration.AttendeeID}/manualPayment`, registration)
);
const purchasePOSDevices = async (
    stripeToken,
    numberOfPOSDevices,
    masjidID
) => returnResponse(
    await put(`/masjids/${masjidID}/pos/${numberOfPOSDevices}`, {
        StripeToken: stripeToken
    })
);

export {
    getMasjid,
    putMasjid,
    refundDonation,
    cancelRecurringDonation,
    addDonationCategory,
    editDonationCategory,
    deleteDonationCategory,
    changeDonationCategoryPosition,
    getInventory,
    purchaseKiosks,
    rentKiosks,
    addAdmin,
    editAdmin,
    deleteAdmin,
    addGroup,
    updateGroup,
    deleteGroup,
    updatePlan,
    unsubscribe,
    getPlanPrices,
    addProgram,
    updateProgram,
    cancelProgram,
    subscribe,
    refundAttendee,
    unenrollStudent,
    updateAttendeePayment,
    purchasePOSDevices
};
