import client, { returnResponse } from './client';

const { get, put } = client;

const getSalatTimesForMonth = async (masjidID, month, year) => returnResponse(
    await get(`/prayertimes/${masjidID}/month/${month}/year/${year}`)
);

const saveSalatTimesForMonth = async (masjidID, timings) => returnResponse(
    await put(`/prayertimes/${masjidID}`, timings)
);

const saveJumuahSalatTimesForMonth = async (masjidID, timings) => returnResponse(
    await put(`/prayertimes/${masjidID}/jumuah`, timings)
);

export {
    getSalatTimesForMonth,
    saveSalatTimesForMonth,
    saveJumuahSalatTimesForMonth
};
