import createActionCreator from './create';
import {
    SET_MASJID,
    REFRESHING_MASJID,
    SET_ADMIN_DETAILS,
    UPDATE_MAJSID,
    UPDATE_DONATION,
    UPDATE_RECURRING_DONATION,
    ADD_DONATION_CATEGORY,
    UPDATE_DONATION_CATEGORY,
    DELETE_DONATION_CATEGORY,
    KIOSK_PURCHASED,
    ADD_ADMIN,
    EDIT_ADMIN,
    DELETE_ADMIN,
    ADD_GROUP,
    EDIT_GROUP,
    REMOVE_GROUP,
    UPDATE_PROGRAM,
    CANCEL_PROGRAM,
    REFUND_ATTENDEE,
    UPDATE_ATTENDEE_PAYMENT,
    UNENROLL_ATTENDEE,
    ADD_PROGRAM,
    POS_PURCHASED,
    UPDATE_DONATION_CATEGORY_POSITION,
} from '../types';
import {
    showNoInternetAlert,
    showServerErrorAlert,
    showSuccessAlert,
    showDangerAlert,
    showBadRequestAlert,
} from './alert';
import { MasjidAPI, Constants } from '../../api';
import { setBugsnagUser } from '../../providers/ErrorBoundary';
import { ProgramUtils } from '../../utils';

// Creating this action creator here since I would run
// into an import cycle if I import the one from './admin.js'.
const setAdminDetails = createActionCreator(SET_ADMIN_DETAILS, 'firstName', 'lastName', 'email', 'role');
export const setMasjid = createActionCreator(SET_MASJID, 'masjid');
export const setRefreshingMasjid = createActionCreator(REFRESHING_MASJID, 'refreshing');
export const updateMasjid = createActionCreator(UPDATE_MAJSID, 'masjid');
export const updateDonation = createActionCreator(UPDATE_DONATION, 'donation');
export const updateRecurringDonation = createActionCreator(UPDATE_RECURRING_DONATION, 'donation');
export const addDonationCategory = createActionCreator(ADD_DONATION_CATEGORY, 'category');
export const changeDonationCategory = createActionCreator(UPDATE_DONATION_CATEGORY, 'category');
export const changeDonationCategoryPosition = createActionCreator(UPDATE_DONATION_CATEGORY_POSITION, 'category');
export const removeDonationCategory = createActionCreator(DELETE_DONATION_CATEGORY, 'category');
export const kiosksPurchased = createActionCreator(KIOSK_PURCHASED, 'kiosks', 'charges');
export const addAdmin = createActionCreator(ADD_ADMIN, 'admins');
export const editAdmin = createActionCreator(EDIT_ADMIN, 'admins');
export const deleteAdmin = createActionCreator(DELETE_ADMIN, 'email');
export const addGroup = createActionCreator(ADD_GROUP, 'group');
export const updateGroup = createActionCreator(EDIT_GROUP, 'group');
export const removeGroup = createActionCreator(REMOVE_GROUP, 'groupID');
export const addProgram = createActionCreator(ADD_PROGRAM, 'program', 'programType');
export const updateProgram = createActionCreator(UPDATE_PROGRAM, 'program', 'programType');
export const cancelProgram = createActionCreator(CANCEL_PROGRAM, 'program', 'programType');
export const refundAttendee = createActionCreator(REFUND_ATTENDEE, 'registration', 'programType');
export const updateAttendeePayment = createActionCreator(UPDATE_ATTENDEE_PAYMENT, 'registration', 'programType');
export const unenrollAttendee = createActionCreator(UNENROLL_ATTENDEE, 'registration', 'programType');
export const posPurchased = createActionCreator(POS_PURCHASED, 'posDevices', 'charges');


export const refreshMasjid = () => async (dispatch, getState) => {
    const { masjid: { updatedAt, interval }, admin: { email } } = getState();
    if (new Date().getTime() <= (updatedAt + interval)) {
        // This case indicates the data is not stale enough
        // and it has been fetched recently.
        // We don't need to do anything now.
        return null;
    }

    // To indicate we're currently refreshing data.
    dispatch(setRefreshingMasjid(true));

    // Refresh masjid data.
    const { error, data } = await MasjidAPI.getMasjid(email);

    if (error) {
        // Ignoring CLIENT_ERROR since we won't get that here.
        if (error === Constants.SERVER_ERROR) {
            dispatch(showServerErrorAlert());
            return error;
        }
        dispatch(showNoInternetAlert());
        return error;
    }

    const {
        FirstName,
        LastName,
        Email,
        Role,
        Masjid,
        Masjid: {
            ID,
            Name
        }
    } = data;

    // Store Masjid's data.
    dispatch(setMasjid(Masjid));

    // Store Admin details.
    dispatch(setAdminDetails(FirstName, LastName, Email, Role));

    // We're done refreshing data.
    dispatch(setRefreshingMasjid(false));

    // Set the user inside Bugsnag.
    setBugsnagUser(`${FirstName} ${LastName}`, Email, ID, Name);

    return null;
};

export const updateNewMasjid = (masjid) => async dispatch => {
    const { error, data } = await MasjidAPI.putMasjid(masjid);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('One or both Stripe API keys are invalid. Please try again with appropriate ones.'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateMasjid(data));

    dispatch(showSuccessAlert('Successfully updated your masjid info!'));

    return null;
};

// Handling all the errors in this very action creator.
export const refundDonation = donationID => async dispatch => {
    const { error, data } = await MasjidAPI.refundDonation(donationID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('Could not refund this payment. Are you sure you have not refunded this payment before?'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    // Update the donation to reflect refund.
    dispatch(updateDonation(data));

    dispatch(showSuccessAlert('Successfully refunded this donation.'));

    return null;
};


// Handling all the errors in this very action creator.
export const cancelRecurringDonation = donationID => async dispatch => {
    const { error, data } = await MasjidAPI.cancelRecurringDonation(donationID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showDangerAlert('Could not cancel this payment. Are you sure you have not cancelled this payment before?'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    // Update the donation to reflect cancel.
    dispatch(updateRecurringDonation(data));

    dispatch(showSuccessAlert('Successfully cancelled this recurring donation.'));

    return null;
};

export const addNewDonationCategory = (category) => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();
    const { error, data, originalError } = await MasjidAPI.addDonationCategory(ID, category);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(addDonationCategory(data));

    dispatch(showSuccessAlert('Successfully add new donation category!'));

    return null;
};

export const updateDonationCategoryPosition = category => async dispatch => {
    const { error, data, originalError } = await MasjidAPI.changeDonationCategoryPosition(category);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(changeDonationCategoryPosition(data));

    dispatch(showSuccessAlert('Successfully updated category position!'));

    return null;
};

export const updateDonationCategory = category => async dispatch => {
    const { error, data, originalError } = await MasjidAPI.editDonationCategory(category);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(changeDonationCategory(data));

    dispatch(showSuccessAlert('Successfully updated category!'));

    return null;
};
export const deleteDonationCategory = categoryID => async dispatch => {
    const { error, data, originalError } = await MasjidAPI.deleteDonationCategory(categoryID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(removeDonationCategory(data));

    dispatch(showSuccessAlert('Successfully deleted category!'));

    return null;
};

export const purchaseKiosks = ({
    stripeToken,
    useExistingCard,
    numberOfFourteenInch,
    numberOfEighteenInch,
    numberOfTwentyOneInch,
    priceOfFourteenInchKiosk,
    priceOfEighteenInchKiosk,
    priceOfTwentyOneInchKiosk,
    shippingPrice,
}) => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();

    const { error, data, status } = await MasjidAPI.purchaseKiosks({
        stripeToken,
        useExistingCard,
        numberOfFourteenInch,
        numberOfEighteenInch,
        numberOfTwentyOneInch,
        priceOfFourteenInchKiosk,
        priceOfEighteenInchKiosk,
        priceOfTwentyOneInchKiosk,
        shippingPrice,
        masjidID: ID
    });

    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 400) {
                dispatch(showDangerAlert('Your plan does not include the Musalleen Kiosk Platform. Please upgrade your plan to purchase a kiosk. If your card was charged, you will get a refund very soon.'));
                break;
            }
            if (status === 413) {
                dispatch(showDangerAlert('Sadly, we just went out of stock for the number of kioks that you asked for. Please try again later. If your card was charged, you will get a refund very soon.'));
                break;
            }
            dispatch(showDangerAlert('Something went wrong. Are you sure you have entered the correct payment details?'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(kiosksPurchased(data.Kiosks, data.Charges));

    dispatch(showSuccessAlert('Your purchase is now complete! Take a look inside your kiosks screen to find your new kiosks.'));

    return null;
};

export const rentKiosks = ({
    stripeToken,
    useExistingCard,
    numberOfFourteenInch,
    numberOfEighteenInch,
    numberOfTwentyOneInch,
    rentalMonths,
    rentalPriceForFourteenInchKiosk,
    rentalPriceForEighteenInchKiosk,
    rentalPriceForTwentyOneInchKiosk,
    shippingPrice,
}) => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();

    const { error, data, status } = await MasjidAPI.rentKiosks({
        stripeToken,
        useExistingCard,
        numberOfFourteenInch,
        numberOfEighteenInch,
        numberOfTwentyOneInch,
        rentalMonths,
        rentalPriceForFourteenInchKiosk,
        rentalPriceForEighteenInchKiosk,
        rentalPriceForTwentyOneInchKiosk,
        shippingPrice,
        masjidID: ID
    });

    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 400) {
                dispatch(showDangerAlert('Your plan does not include the Musalleen Kiosk Platform. Please upgrade your plan to purchase a kiosk. If your card was charged, you will get a refund very soon.'));
                break;
            }
            if (status === 413) {
                dispatch(showDangerAlert('Sadly, we just went out of stock for the number of kioks that you asked for. Please try again later. If your card was charged, you will get a refund very soon.'));
                break;
            }
            dispatch(showDangerAlert('Something went wrong. Are you sure you have entered the correct payment details?'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(kiosksPurchased(data.Kiosks, data.Charges));

    dispatch(showSuccessAlert('Your purchase is now complete! Take a look inside your kiosks screen to find your newly rented kiosks.'));

    return null;
};

export const addAdminAsync = admin => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();
    const {
        error, data, status, originalError
    } = await MasjidAPI.addAdmin(ID, admin);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            // This status indicates a non unique email.
            if (status === 409) {
                dispatch(showDangerAlert('The email you have supplied already exists in our records. Please use a different one and try again.'));
                break;
            }
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(addAdmin(data.Masjid.Admins));

    dispatch(showSuccessAlert('Successfully added new admin!'));

    return null;
};

export const updateAdminAsync = admin => async (dispatch) => {
    const { error, data, originalError } = await MasjidAPI.editAdmin(admin);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(editAdmin(data.Masjid.Admins));

    dispatch(showSuccessAlert('Successfully updated admin!'));

    return null;
};

export const deleteAdminAsync = email => async (dispatch) => {
    const { error, originalError } = await MasjidAPI.deleteAdmin(email);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(deleteAdmin(email));

    dispatch(showSuccessAlert('Successfully deleted admin!'));

    return null;
};

export const addGroupAsync = group => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();
    const {
        error, data, status, originalError
    } = await MasjidAPI.addGroup({ ...group, MasjidID: ID });
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 409) {
                dispatch(showDangerAlert('The group code that you have entered is not unique in our system. Please try again.'));
                break;
            }
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(addGroup(data));

    dispatch(showSuccessAlert('Successfully added group!'));

    return null;
};

export const updateGroupAsync = group => async dispatch => {
    const {
        error, data, status, originalError
    } = await MasjidAPI.updateGroup(group);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 409) {
                dispatch(showDangerAlert('The group code that you have entered is not unique in our system. Please try again.'));
                break;
            }
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateGroup(data));

    dispatch(showSuccessAlert('Successfully updated group!'));

    return null;
};

export const removeGroupAsync = ID => async dispatch => {
    const { error, originalError } = await MasjidAPI.deleteGroup(ID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(removeGroup(ID));

    dispatch(showSuccessAlert('Successfully deleted group!'));

    return null;
};

export const updatePlanAsync = masjidPlan => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();

    // check whether stripe account exists or not

    const { error, data, originalError } = await MasjidAPI.updatePlan(masjidPlan, ID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }
    dispatch(updateMasjid(data));

    dispatch(showSuccessAlert('Successfully updated masjid\'s plan!'));

    return null;
};

export const unsubscribeAsync = () => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();
    const { error, data, originalError } = await MasjidAPI.unsubscribe(ID);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateMasjid(data));

    dispatch(showSuccessAlert('Successfully unsubscribed from Musalleen platform!'));

    return null;
};

const addProgramAsync = (newProgram, programType) => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();
    const {
        error, data, status, originalError
    } = await MasjidAPI.addProgram(
        { ...newProgram, Type: programType },
        ID
    );
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 417) {
                dispatch(showDangerAlert('Please make sure all your start times are earlier than your end times.'));
                break;
            }
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(addProgram(data, programType));

    dispatch(showSuccessAlert(`Successfully added  ${programType}!`));

    return null;
};

const updateProgramAsync = (program, programType) => async dispatch => {
    const {
        error, data, status, originalError
    } = await MasjidAPI.updateProgram(program);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 417) {
                dispatch(showDangerAlert('Please make sure all your start times are earlier than your end times.'));
                break;
            }
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateProgram(data, programType));

    dispatch(showSuccessAlert(`Successfully updated ${programType}!`));

    return null;
};

const cancelProgramAsync = (program, programType) => async (dispatch) => {
    const { error, data, originalError } = await MasjidAPI.cancelProgram(program);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(cancelProgram(data, programType));

    dispatch(showSuccessAlert(`Successfully cancelled ${programType}!`));

    return null;
};

const refundAttendeeAsync = (registration, programType) => async (dispatch) => {
    const { error, data, originalError } = await MasjidAPI.refundAttendee(registration);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(refundAttendee(data, programType));

    dispatch(showSuccessAlert('Successfully refunded attendee!'));

    return null;
};

const updateAttendeePaymentAsync = (registration, programType) => async dispatch => {
    const { error, data, originalError } = await MasjidAPI.updateAttendeePayment(registration);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(updateAttendeePayment(data, programType));

    dispatch(showSuccessAlert('Successfully updated payment details!'));

    return null;
};

const unenrollAttendeeAsync = (registration, programType) => async dispatch => {
    const { error, data, originalError } = await MasjidAPI.unenrollStudent(registration);
    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            dispatch(showBadRequestAlert(originalError));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(unenrollAttendee(data, programType));

    dispatch(showSuccessAlert('Successfully unenrolled Attendee!'));

    return null;
};

const { CLASS, EVENT } = ProgramUtils.types;

// Action creators for classes.

export const addClassAsync = newClass => addProgramAsync(newClass, CLASS);

export const updateClassAsync = islamicClass => updateProgramAsync(islamicClass, CLASS);

export const cancelClassAsync = islamicClass => cancelProgramAsync(islamicClass, CLASS);

export const refundStudentAsync = registration => refundAttendeeAsync(registration, CLASS);

export const updateStudentPaymentAsync = registration => updateAttendeePaymentAsync(
    registration,
    CLASS
);

export const unenrollStudentAsync = registration => unenrollAttendeeAsync(registration, CLASS);

// Action creators for events.

export const addEventAsync = newEvent => addProgramAsync(newEvent, EVENT);

export const updateEventAsync = event => updateProgramAsync(event, EVENT);

export const cancelEventAsync = event => cancelProgramAsync(event, EVENT);

export const refundParticipantAsync = registration => refundAttendeeAsync(registration, EVENT);

export const updateParticipantPaymentAsync = registration => updateAttendeePaymentAsync(
    registration,
    EVENT
);

export const unenrollParticipantAsync = registration => unenrollAttendeeAsync(registration, EVENT);

export const purchasePOSDevices = (
    stripeToken,
    numberOfPOSDevices
) => async (dispatch, getState) => {
    const { masjid: { data: { ID } } } = getState();

    const { error, data, status } = await MasjidAPI.purchasePOSDevices(
        stripeToken,
        numberOfPOSDevices,
        ID
    );

    if (error) {
        switch (error) {
        case Constants.CLIENT_ERROR:
            if (status === 400) {
                dispatch(showDangerAlert('Your plan does not include the Musalleen POS Platform. Please upgrade your plan to purchase a pos. If your card was charged, you will get a refund very soon.'));
                break;
            }
            if (status === 413) {
                dispatch(showDangerAlert('Sadly, we just went out of stock for the number of pos devices that you asked for. Please try again later. If your card was charged, you will get a refund very soon.'));
                break;
            }
            dispatch(showDangerAlert('Something went wrong. Are you sure you have entered the correct payment details?'));
            break;
        case Constants.SERVER_ERROR:
            dispatch(showServerErrorAlert());
            break;
        default:
            // In case of NETWORK_ERROR or CONNECTION_ERROR.
            dispatch(showNoInternetAlert());
        }
        return error;
    }

    dispatch(posPurchased(data.POSDevices, data.Charges));

    dispatch(showSuccessAlert('Your purchase is now complete! Take a look inside your pos screen to find your new pos devices.'));

    return null;
};


// export const addNewMasjidAddress = (address) => (dispatch) => {
//     dispatch(setMasjid(address));
//     dispatch(showSuccessAlert('Successfully filled the address!'));
//     return null;
// };
