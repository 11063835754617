/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
import React, {
    Fragment, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import {
    Form,
    Card,
    CardBody,
    Row,
    Fade
} from 'reactstrap';

import {
    Button, Logo, FormInput, PaymentWidget
} from '../../../shared/components';
import '../style.scss';
import { StringUtils, PaymentUtils } from '../../../utils';
import {
    showDialog,
    showNoInternetAlert,
    showServerErrorAlert,
    showBadRequestAlert,
    showSuccessAlert,
    login,
} from '../../../store/actions';
import {
    AdminAPI,
    MasjidAPI,
    Constants,
} from '../../../api';
import { fetchCitiesByState, fetchCountries, fetchStatesByCountries } from '../../../store/actions/address';
import useLatLng from '../../maps/useLatLng';

const base64 = require('base-64');

export const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}/;

export const requiredLabel = (label) => <>{`${label} `}<sub style={{ color: 'red', fontSize: '15px' }}>*</sub></>;


function Register({
    location: {
        query: {
            params
        }
    },
    dispatch,
}) {
    if (!params) {
        // If no params are found,
        // move to sign in instead.
        browserHistory.replace('/pages/signin');
        return null;
    }

    const plan = JSON.parse(base64.decode(params));
    const [invalidNameMessage, setInvalidNameMessage] = useState('');
    const [position, geoAddress] = useLatLng();

    const [masjidName, setMasjidName] = useState('');
    const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
    const [invalidPhoneNumberMessage, setInvalidPhoneNumberMessage] = useState('');

    const [adminFirstName, setAdminFirstName] = useState('');
    const [invalidAdminFirstNameMessage, setInvalidAdminFirstNameMessage] = useState('');

    const [adminLastName, setAdminLastName] = useState('');
    const [invalidAdminLastNameMessage, setInvalidAdminLastNameMessage] = useState('');

    const [countries, setCountries] = useState([]);
    const [statesByCountries, setStatesByCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const [newMasjid, setNewMasjid] = useState({
        aptNumber: '',
        city: '',
        country: '',
        street: '',
        zipcode: '',
        houseNumber: '',
        state: '',
        latitude: '',
        longitude: ''
    });

    const [invalidAddressMessages, setInvalidAddressMessages] = useState({
        city: '',
        country: '',
        street: '',
        zipcode: '',
        houseNumber: '',
        state: '',
    });

    const [adminEmail, setAdminEmail] = useState('');
    const [invalidAdminEmailMessage, setInvalidAdminEmailMessage] = useState('');

    const [password, setPassword] = useState('');
    const [invalidPasswordMessage, setInvalidPasswordMessage] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [invalidConfirmPasswordMessage, setInvalidConfirmPasswordMessage] = useState('');

    const [promoCode, setPromoCode] = useState('');
    const [invalidPromoCodeMessage, setInvalidPromoCodeMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [showPaymentWidget, setShowPaymentWidget] = useState(false);


    const getCountries = async () => {
        const data = await dispatch(fetchCountries());
        setCountries(data);
    };

    const getStatesByCountries = async (countryName) => {
        const data = await dispatch(fetchStatesByCountries(countryName));
        setStatesByCountries(data);
    };

    const getCitiesByState = async (stateName) => {
        const data = await dispatch(fetchCitiesByState(stateName));
        setCities(data);
    };

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        const {
            country, city, county, state, postcode, latitude, longitude
        } = geoAddress || {};

        if (country) {
            getStatesByCountries(country);
            getCitiesByState(state);
        }
        if (county && city && state && postcode) {
            setNewMasjid({
                ...newMasjid, country, state, zipcode: postcode, city, latitude, longitude
            });
        } else {
            setNewMasjid({
                ...newMasjid, country: 'United States'
            });
            getStatesByCountries('United States');
        }
    }, [geoAddress, position]);


    function resetInvalidMessages() {
        setInvalidNameMessage('');
        setInvalidAdminEmailMessage('');
        setInvalidAdminFirstNameMessage('');
        setInvalidAdminLastNameMessage('');
        setInvalidPasswordMessage('');
        setInvalidConfirmPasswordMessage('');
        setInvalidPromoCodeMessage('');
        setInvalidAddressMessages({
            city: '',
            country: '',
            street: '',
            zipcode: '',
            houseNumber: '',
            state: '',
        });
    }


    async function submit() {
        resetInvalidMessages();
        if (!masjidName.trim()) {
            setInvalidNameMessage('Name cannot be empty.');
            return;
        }
        if (!adminPhoneNumber.trim()) {
            setInvalidPhoneNumberMessage('Phone number cannot be empty.');
            return;
        }
        if (!newMasjid.country) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, country: 'Country cannot be empty.'
            });
            return;
        }
        if (!newMasjid.houseNumber) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, houseNumber: 'House Number cannot be empty.'
            });
            return;
        }
        if (!newMasjid.street) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, street: 'Street cannot be empty.'
            });
            return;
        }
        if (!newMasjid.city) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, city: 'City cannot be empty.'
            });
            return;
        }
        if (!newMasjid.state) {
            setInvalidAddressMessages({
                ...invalidAddressMessages, state: 'State cannot be empty.'
            });
            return;
        }
        if (!adminEmail.trim()) {
            setInvalidAdminEmailMessage('Admin email cannot be empty.');
            return;
        }
        if (!emailRegex.test(adminEmail.trim())) {
            setInvalidAdminEmailMessage('Admin email is not valid.');
            return;
        }
        if (!adminFirstName.trim()) {
            setInvalidAdminFirstNameMessage('Admin first name cannot be empty.');
            return;
        }
        if (!adminLastName.trim()) {
            setInvalidAdminLastNameMessage('Admin last name cannot be empty.');
            return;
        }
        if (!password) {
            setInvalidPasswordMessage('Password cannot be empty.');
            return;
        }
        if (!confirmPassword) {
            setInvalidConfirmPasswordMessage('Confirm password cannot be empty.');
            return;
        }
        if (password !== confirmPassword) {
            setInvalidPasswordMessage('Passwords don\'t match. Please try again.');
            setInvalidConfirmPasswordMessage('Passwords don\'t match. Please try again.');
            return;
        }
        const confirm = await dispatch(showDialog(
            'By choosing to register, you accept the following terms and conditions.',
            `
            ${plan.totalPrice === 0 ? '' : `• Masjid Registration fee can change anytime without notice.
            <br />
            <br />`}
            • User is responsible to save the credentials of masjid.
            <br />
            `,
            'Okay, Let\'s Continue',
            'Cancel'
        ));
        if (!confirm) {
            return;
        }
        setLoading(true);
        const { error } = await AdminAPI.emailExists(adminEmail.trim().toLowerCase());
        setLoading(false);
        if (error) {
            switch (error) {
            case Constants.CLIENT_ERROR:
                setInvalidAdminEmailMessage('That email already exists in our system. Please try again with a different one.');
                break;
            case Constants.SERVER_ERROR:
                dispatch(showServerErrorAlert());
                break;
            default:
                dispatch(showNoInternetAlert());
            }
        }
        // sending country, state, city and zip code to get the lat longs
        const newAddress = newMasjid.zipcode.concat(
            ',',
            newMasjid.houseNumber,
            ',',
            newMasjid.street,
            ',',
            newMasjid.City,
            ',',
            newMasjid.state,
            ',',
            newMasjid.country,
        );
        const coordinates = {};
        const results = await findLatLng(newAddress);
        coordinates.latitude = results[0] || null;
        coordinates.longitude = results[1] || null;

        if (plan.totalPrice > 0) {
            setShowPaymentWidget(true);
        } else {
            onPayment(null, coordinates);
        }
    }

    function onCancelPayment() {
        setShowPaymentWidget(false);
    }

    const onPayment = async (token, coordinates) => {
        setLoading(true);
        setShowPaymentWidget(false);
        const { error, status, originalError } = await MasjidAPI.subscribe(token ? {
            SuperAdminFirstName: adminFirstName.trim(),
            SuperAdminLastName: adminLastName.trim(),
            SuperAdminEmail: adminEmail.trim().toLowerCase(),
            SuperAdminPassword: password,
            MasjidName: masjidName.trim(),
            SuperAdminPhoneNumber: adminPhoneNumber.trim(),
            StripeToken: token,
            NumberOfFourteenInchKiosks: 0,
            NumberOfEighteenInchKiosks: 0,
            NumberOfTwentyOneInchKiosks: 0,
            PromoCode: promoCode.trim(),
            Latitude: coordinates.latitude,
            Longitude: coordinates.longitude,
            AptNumber: newMasjid.aptNumber,
            HouseNumber: newMasjid.houseNumber,
            Street: newMasjid.street,
            City: newMasjid.city,
            Zipcode: newMasjid.zipcode,
            State: newMasjid.state,
            Country: newMasjid.country,
            ...plan
        } : {
            SuperAdminFirstName: adminFirstName.trim(),
            SuperAdminLastName: adminLastName.trim(),
            SuperAdminEmail: adminEmail.trim().toLowerCase(),
            SuperAdminPassword: password,
            MasjidName: masjidName.trim(),
            SuperAdminPhoneNumber: adminPhoneNumber.trim(),
            // StripeToken: token,
            NumberOfFourteenInchKiosks: 0,
            NumberOfEighteenInchKiosks: 0,
            NumberOfTwentyOneInchKiosks: 0,
            PromoCode: promoCode.trim(),
            Latitude: coordinates.latitude,
            Longitude: coordinates.longitude,
            AptNumber: newMasjid.aptNumber,
            HouseNumber: newMasjid.houseNumber,
            Street: newMasjid.street,
            City: newMasjid.city,
            Zipcode: newMasjid.zipcode,
            State: newMasjid.state,
            Country: newMasjid.country,
            ...plan
        });
        if (error) {
            switch (error) {
            case Constants.CLIENT_ERROR:
                if (status === 409) {
                    setInvalidAdminEmailMessage('That email already exists in our system. Please try again with a different one.');
                    break;
                }
                dispatch(showBadRequestAlert(originalError));
                break;
            case Constants.SERVER_ERROR:
                dispatch(showServerErrorAlert());
                break;
            default:
                dispatch(showNoInternetAlert());
            }
            setLoading(false);
            return;
        }
        // I know I have all the masjid data
        // but let's do another API call for login just
        // to keep the code cleaner with a linear flow.
        await dispatch(login(
            adminEmail.trim().toLowerCase(),
            password
        ));

        // We don't need to handle any CLIENT_ERROR here since the
        // combination will always be correct.

        dispatch(showSuccessAlert('Your registeration has successfully completed. Welcome to the Musalleen platform!'));

        setLoading(false);

        browserHistory.replace('/');
    };

    const handleCountryChange = async (country) => {
        if (country.trim()) {
            getStatesByCountries(country);
            setNewMasjid({ ...newMasjid, country });
        }
    };
    const handleStateByCountryChange = async (state) => {
        if (state.trim()) {
            getCitiesByState(state);
            setNewMasjid({ ...newMasjid, state });
        }
    };

    const handleCityChange = (city) => {
        if (city.trim()) {
            setNewMasjid({ ...newMasjid, city });
        }
    };

    function handleNewMasjidPropertyChange(column) {
        return function (value) {
            setNewMasjid({ ...newMasjid, [column]: value });
        };
    }

    function renderForm() {
        return (
            <Fragment>
                <p style={{ color: 'red' }}>
                    <sup> *  Required</sup>
                </p>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            label={requiredLabel('Masjid Name')}
                            name="masjidName"
                            state={invalidNameMessage ? 'danger' : 'default'}
                            message={invalidNameMessage}
                            value={masjidName}
                            onChangeValue={setMasjidName}
                            maxLength={50}
                            placeholder="Masjid name"
                        />

                    </div>
                    <div className="col-md-6">
                        <FormInput
                            label={requiredLabel("Admin's Phone Number")}
                            placeholder="Admin's Phone Number"
                            name="phoneNumber"
                            state={invalidPhoneNumberMessage ? 'danger' : 'default'}
                            message={invalidPhoneNumberMessage}
                            value={adminPhoneNumber}
                            onChangeValue={setAdminPhoneNumber}
                            maxLength={45}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.country}
                            onChangeValue={handleCountryChange}
                            type="select"
                            label={requiredLabel('Country/Region')}
                            placeholder="Country"
                            name="adminCountry"
                            state={invalidAddressMessages.country ? 'danger' : 'default'}
                            message={invalidAddressMessages.country}
                            options={countries && countries.map(country => ({ key: country, value: country, label: country }))}
                        />
                    </div>
                    <div className="col-md-6 ">
                        <FormInput
                            value={newMasjid.aptNumber}
                            onChangeValue={handleNewMasjidPropertyChange('aptNumber')}
                            label="Apt/Suite"
                            placeholder="Apt, suite, unit, building, floor, etc."
                            maxLength={10}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.houseNumber}
                            onChangeValue={handleNewMasjidPropertyChange('houseNumber')}
                            label={requiredLabel('House Number')}
                            maxLength={10}
                            placeholder="House Number"
                            state={invalidAddressMessages.houseNumber ? 'danger' : 'default'}
                            message={invalidAddressMessages.houseNumber}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.street}
                            onChangeValue={handleNewMasjidPropertyChange('street')}
                            label={requiredLabel('Street address')}
                            placeholder="Street Address"
                            maxLength={30}
                            state={invalidAddressMessages.street ? 'danger' : 'default'}
                            message={invalidAddressMessages.street}
                        />
                    </div>

                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.state}
                            onChangeValue={handleStateByCountryChange}
                            label={requiredLabel('State')}
                            type="select"
                            name="adminState"
                            placeholder="Select State"
                            state={invalidAddressMessages.state ? 'danger' : 'default'}
                            message={invalidAddressMessages.state}
                            options={statesByCountries && statesByCountries.map(stateName => ({ key: stateName, value: stateName, label: stateName }))}
                            disabled={!newMasjid.country}
                            extraMessage={!newMasjid.country && 'Please select country first'}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.city}
                            onChangeValue={handleCityChange}
                            label={requiredLabel('City')}
                            type="select"
                            placeholder="City"
                            maxLength={25}
                            state={invalidAddressMessages.city ? 'danger' : 'default'}
                            message={invalidAddressMessages.city}
                            disabled={!newMasjid.state}
                            options={cities && cities.map(city => ({ key: city, value: city, label: city }))}
                            extraMessage={!newMasjid.state && 'Please select state first'}
                        />
                    </div>

                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            value={newMasjid.zipcode}
                            onChangeValue={handleNewMasjidPropertyChange('zipcode')}
                            label={requiredLabel('Zip Code')}
                            type="text"
                            placeholder="Zip Code"
                            maxLength={10}
                            state={invalidAddressMessages.zipcode ? 'danger' : 'default'}
                            message={invalidAddressMessages.zipcode}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            label={requiredLabel("Admin's Email")}
                            type="email"
                            name="email"
                            placeholder="someone@xyz.com"
                            state={invalidAdminEmailMessage ? 'danger' : 'default'}
                            message={invalidAdminEmailMessage}
                            value={adminEmail}
                            onChangeValue={setAdminEmail}
                            maxLength={75}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            label={requiredLabel("Admin's First Name")}
                            placeholder="Admin's First Name"
                            name="adminFirstName"
                            state={invalidAdminFirstNameMessage ? 'danger' : 'default'}
                            message={invalidAdminFirstNameMessage}
                            value={adminFirstName}
                            onChangeValue={setAdminFirstName}
                            maxLength={25}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            placeholder="Admin's Last Name"
                            label={requiredLabel("Admin's Last Name")}
                            name="adminLastName"
                            state={invalidAdminLastNameMessage ? 'danger' : 'default'}
                            message={invalidAdminLastNameMessage}
                            value={adminLastName}
                            onChangeValue={setAdminLastName}
                            maxLength={25}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            placeholder="Enter Password"
                            label={requiredLabel('Enter Password')}
                            type="password"
                            state={invalidPasswordMessage ? 'danger' : 'default'}
                            message={invalidPasswordMessage}
                            value={password}
                            onChangeValue={setPassword}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            placeholder="Confirm Password"
                            label={requiredLabel('Confirm Password')}
                            type="password"
                            state={invalidConfirmPasswordMessage ? 'danger' : 'default'}
                            message={invalidConfirmPasswordMessage}
                            value={confirmPassword}
                            onChangeValue={setConfirmPassword}
                            onEnter={submit}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <FormInput
                            label="Promocode"
                            name="promoCode"
                            placeholder="If Any"
                            state={invalidPromoCodeMessage ? 'danger' : 'default'}
                            message={invalidPromoCodeMessage}
                            value={promoCode}
                            onChangeValue={setPromoCode}
                            onEnter={submit}
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6 d-flex align-items-center">
                        <span>
                            Price charged after
                            {' '}
                            {plan.TrialPeriodDays}
                            {' '}
                            Days:
                            <br />
                            <span className="h5">
                                {StringUtils.formatAmount(plan.totalPrice, false)}
                            </span>
                            {' '}
                            / monthly
                        </span>
                    </div>
                    <div className="col-md-6 button-right">
                        <Button
                            loading={loading}
                            onClick={submit}
                        >
                            Continue
                        </Button>
                    </div>
                </Row>
            </Fragment>
        );
    }

    return (
        <div className="view" style={{ padding: '0px !important' }}>
            <div className="view-content view-pages view-session d-md-flex justify-content-md-center" style={{ padding: '0px !important' }}>
                <Card className="mb-12 form-card-register col-md-6">
                    <CardBody>
                        <header className="mb-12">
                            <Link to="/">
                                <Logo align="center" />
                            </Link>
                            <p className="lead text-center">
                                {!showPaymentWidget
                                    ? 'Fill the form below to get started.'
                                    : 'Fill in card details to continue.'
                                }
                            </p>
                        </header>
                        <Form>
                            <Fade in={!showPaymentWidget} unmountOnExit mountOnEnter>
                                {renderForm()}
                            </Fade>
                            <Fade in={showPaymentWidget} unmountOnExit mountOnEnter>
                                <PaymentWidget
                                    mode="collapse"
                                    show
                                    onClose={onCancelPayment}
                                    onPayment={onPayment}
                                    totalAmount={StringUtils.formatAmount(plan.totalPrice, false)}
                                    apiKey={PaymentUtils.PUBLISHABLE_API_KEY}
                                />
                            </Fade>
                        </Form>
                    </CardBody>
                </Card>


            </div>
        </div>
    );
}

Register.propTypes = {
    location: PropTypes.shape({
        query: PropTypes.shape({
            params: PropTypes.string
        }).isRequired
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    masjid: PropTypes.shape({
        data: PropTypes.shape({
            country: PropTypes.string,
            state: PropTypes.string,
            city: PropTypes.string,
            postcode: PropTypes.string,
            county: PropTypes.string,
            latitude: PropTypes.number,
            longitude: PropTypes.number
        })
    }).isRequired

};
const mapStateToProps = ({ masjid }) => ({
    masjid
});
export default connect(mapStateToProps)(Register);


export const findLatLng = (address) => new Promise(((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
            resolve([results[0].geometry.location.lat().toFixed(6),
                results[0].geometry.location.lng().toFixed(6)]);
        } else {
            reject(new Error(`Couldnt't find the location ${address}`));
        }
    });
}));
